import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

/*
import '@assets/css/slider.css';
*/

const PromptSlider = ({ title, titleImg, card, slide, loop, isCircle }) => {
    const sliderRef = useRef(null);

    const prompts = [
        {
            id: 1,
            title: "아트디오스 스테인글라스 제작",
            author: "Zombie",
            price: "W1,100",
            rating: 2.5,
            category: "디자인",
            tags: ["스테인글라스", "아트"]
        },
        {
            id: 2,
            title: "수채화 스타일의 동화",
            author: "Zombie",
            price: "W1,200",
            rating: 2.8,
            category: "일러스트",
            tags: ["수채화", "동화"]
        },
        {
            id: 3,
            title: "미래도시 사이버펑크 컨셉",
            author: "Zombie",
            price: "W1,300",
            rating: 2.2,
            category: "컨셉아트",
            tags: ["사이버펑크", "도시"]
        },
        {
            id: 4,
            title: "판타지 캐릭터 초상화 생성기",
            author: "Zombie",
            price: "W1,400",
            rating: 2.7,
            category: "캐릭터",
            tags: ["판타지", "초상화"]
        },
        {
            id: 5,
            title: "일본 애니메이션 스타일",
            author: "Zombie",
            price: "W1,500",
            rating: 2.4,
            category: "배경",
            tags: ["애니메이션", "풍경"]
        },
        {
            id: 6,
            title: "80년대 레트로 게임 도트 아트",
            author: "Zombie",
            price: "W1,600",
            rating: 2.6,
            category: "픽셀아트",
            tags: ["레트로", "게임"]
        },
        {
            id: 7,
            title: "중세 판타지 무기",
            author: "Zombie",
            price: "W1,700",
            rating: 2.3,
            category: "프롭디자인",
            tags: ["중세", "무기"]
        },
        {
            id: 8,
            title: "미니멀리스트 로고",
            author: "Zombie",
            price: "W1,800",
            rating: 2.9,
            category: "로고",
            tags: ["미니멀", "브랜딩"]
        },
        {
            id: 9,
            title: "추상적 현대 미술",
            author: "Zombie",
            price: "W1,900",
            rating: 2.5,
            category: "현대미술",
            tags: ["추상", "모던"]
        },
        {
            id: 10,
            title: "자연 풍경 포토",
            author: "Zombie",
            price: "W2,000",
            rating: 2.7,
            category: "포토",
            tags: ["자연", "풍경"]
        },
        {
            id: 11,
            title: "스팀펑크 기계 장치 디자인",
            author: "Zombie",
            price: "W2,100",
            rating: 2.4,
            category: "기계디자인",
            tags: ["스팀펑크", "기계"]
        },
        {
            id: 12,
            title: "빈티지 여행 포스터 스타일",
            author: "Zombie",
            price: "W2,200",
            rating: 2.8,
            category: "포스터",
            tags: ["빈티지", "여행"]
        }
    ];

    const settings = {
        direction: 'horizontal',
        slidesPerView: card,
        slidesPerGroup: slide,
        spaceBetween: isCircle ? -900 : 20,
        loop: loop,
        centeredSlides: isCircle,
        modules: [Navigation, A11y],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            1280: {
                slidesPerView: isCircle ? 7 : 6,
                spaceBetween: isCircle ? -30 : 20,
            },
            1024: {
                slidesPerView: isCircle ? 5 : 4,
                spaceBetween: isCircle ? -25 : 16,
            },
            640: {
                slidesPerView: isCircle ? 3 : 2,
                spaceBetween: isCircle ? -20 : 12,
            },
            320: {
                slidesPerView: isCircle ? 3 : 1,
                spaceBetween: isCircle ? -15 : 8,
            }
        }
    };

    const handlePrev = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current && sliderRef.current.swiper) {
            sliderRef.current.swiper.slideNext();
        }
    };

    return (
        <>
            <div className="max-w-6xl mx-auto py-4">
                <div className="flex items-center justify-between mb-4 px-4"
                style={{ width: '1504px',
                    height: '24px',
                    flexGrow: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '5',
                }}>
                    <h2 className="text-lg font-medium">
                        {titleImg && (
                            <img
                                src={titleImg}
                                alt=""
                                className="w-[100px] h-[100px] object-contain rounded-full ml-4"
                            />
                        )}
                        {title}
                    </h2>
                </div>

                <div className="relative px-4 overflow-hidden flex items-center">
                    <button
                        onClick={handlePrev}
                        className="p-1.5 hover:bg-gray-100 rounded-full transition-colors"
                    >
                        <span className="w-5 h-5">{"<"}</span>
                    </button>
                    <Swiper ref={sliderRef} {...settings} className={`!px-8 ${isCircle ? 'circular-slider' : 'square-slider'}`}>
                        {prompts.map((prompt) => (
                            <SwiperSlide style={{ border: '1px solid black' }} key={prompt.id} className={isCircle ? 'circular-slide' : 'square-slide'}>
                                <div className={`
                                relative group cursor-pointer
                                ${isCircle ? 'circular-container' : 'square-container'}
                            `}>
                                    <div className={isCircle ? 'circle-wrapper' : 'square-wrapper'}>
                                        <img
                                            src={`/api/placeholder/240/240?text=${prompt.id}`}
                                            alt={prompt.title}
                                            className={isCircle ? 'circle-image' : 'square-image'}
                                        />
                                        <h3 className="font-medium text-sm mb-2 line-clamp-2">{prompt.title}</h3>
                                    </div>
                                    {!isCircle && (
                                        <div className="p-3 bg-white rounded-lg border border-gray-100" style={{
                                            height: '57px',
                                            alignSelf: 'stretch',
                                            flexGrow: 0,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: '8px',
                                            padding: '8px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        }}>
                                            <div className="flex items-center gap-2 mb-2">
                                                <img
                                                    src={`/api/placeholder/24/24?text=${prompt.id}`}
                                                    className="w-6 h-6 rounded-full"
                                                />
                                                <span className="text-sm font-medium line-clamp-1">
                                                {prompt.author}
                                            </span>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div className="flex text-yellow-400 text-sm">
                                                    {"★".repeat(Math.floor(prompt.rating))}
                                                    {"☆".repeat(3 - Math.floor(prompt.rating))}
                                                </div>
                                                <span className="text-sm font-medium">
                                                {prompt.price}
                                            </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <button
                        onClick={handleNext}
                        className="p-1.5 hover:bg-gray-100 rounded-full transition-colors"
                    >
                        <span className="w-5 h-5">{">"}</span>
                    </button>
                </div>

                <style jsx global>{`
                    .circular-slider .swiper-slide {
                        transition: all 0.3s ease;
                        opacity: 0.5;
                        transform: scale(0.8);
                    }

                    .circular-slider .swiper-slide-active {
                        opacity: 1;
                        transform: scale(1.2);
                        z-index: 2;
                    }

                    .circular-slider .swiper-slide-prev,
                    .circular-slider .swiper-slide-next {
                        opacity: 0.8;
                        transform: scale(0.9);
                        z-index: 1;
                    }

                    .square-slider .swiper-slide {
                        transition: all 0.3s ease;
                    }

                    .circle-wrapper {
                        width: 100px;
                        height: 100px;
                        border: 3px solid #000;
                        border-radius: 50%;
                        overflow: hidden;
                        flex-shrink: 0;
                        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
                    }

                    .square-wrapper {
                        width: 100%;
                        aspect-ratio: 1;
                        overflow: hidden;
                        border-radius: 8px;
                    }

                    .circle-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .square-image {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 1;
                        object-fit: cover;
                    }

                    .circular-container {
                        padding: 0.5rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.3s ease;
                    }

                    .square-container {
                        display: flex;
                        flex-direction: column;
                        transition: all 0.3s ease;
                    }

                    @media (min-width: 768px) {
                        .circle-wrapper {
                            width: 120px;
                            height: 120px;
                            border-width: 4px;
                        }
                    }

                    @media (min-width: 1024px) {
                        .circle-wrapper {
                            width: 140px;
                            height: 140px;
                        }
                    }
                `}</style>
            </div>
        </>
    );
};

export default PromptSlider;
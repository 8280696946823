import React, { useEffect } from 'react';
// component
import ListTop10Music from '@pages/main/ListTop10Music';
import ListNewMusic from '@pages/main/ListNewMusic';
import ListGenreMusic from '@pages/main/ListGenreMusic';
import ListPopularMusic from '@pages/main/ListPopularMusic';
// path
import * as PATH from '@routes/pathName';
// lib
import { validateSession } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';

const ContentMusic = (props) => {
  var isPopup;

  // 음악 플레이어 열기
  const openMusicPlayer = () => {
    closeChildPopup();
    isPopup = window.open(PATH.MUSIC_PLAYER, '_blank', 'width=375,height=650');
  };

  // 현재 페이지를 '새로고침' 또는 '창닫기'를 하면, 음악플레이어 강제 닫기
  const closeChildPopup = () => {
    isPopup?.close();
  };

  useEffect(() => {
    window.addEventListener('unload', closeChildPopup);
  }, []);

  return (
    <>
      <ListTop10Music openMusicPlayer={openMusicPlayer} />
      <ListNewMusic openMusicPlayer={openMusicPlayer} />
      <ListGenreMusic openMusicPlayer={openMusicPlayer} />
      <ListPopularMusic openMusicPlayer={openMusicPlayer} />
    </>
  );
};

export default ContentMusic;

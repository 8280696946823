import { useState } from 'react';
import { Button, Form, Input } from 'antd';

const BusinessRegist = (props) => {
  const [businessNumber, setBusinessNumber] = useState({
    code1: '',
    code2: '',
    code3: '',
  });

  // 사업자 번호 변경 핸들러
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      // 숫자만 입력 가능
      setBusinessNumber({
        ...businessNumber,
        [name]: value,
      });
    }
  };

  // 저장
  const handleSave = async () => {
    const { code1, code2, code3 } = businessNumber;
    const fullNumber = `${code1}${code2}${code3}`;
    console.log('사업자 번호:', fullNumber);

    if (code1.length !== 3 || code2.length !== 2 || code3.length !== 5) {
      alert('유효한 사업자 번호를 입력해주세요. (형식: 3-2-5 자리)');
      return;
    }

    // 사업자번호 확인
    try {
      const response = await fetch(
        'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=H4Kdqb1b6rx0tzvRndo%2B4FjGuwI0y4Dl%2FtqW%2FqHrSSiuM22UrFhxHQIxjj6%2F5%2F2LtzGozXmy6m7UiYLv1w0kJw%3D%3D',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify({ b_no: [fullNumber] }),
        },
      );
      const result = await response.json();

      if (result.match_cnt === 1) {
        // 유저정보에 사업자번호 저장
        console.log('사업자번호 확인 성공');
      } else {
        alert(result.data[0]['tax_type'] + '\n올바른 사업자 번호를 등록하여 주시기 바랍니다.');
        return;
      }
    } catch (error) {
      console.error('사업자번호 확인 에러:', error);
      return;
    }

    // 저장 완료 후 초기화
    setBusinessNumber({ code1: '', code2: '', code3: '' });
    alert.log('사업자 번호가 저장되었습니다!');
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
      <Form>
        <Form.Item label="사업자등록번호" required>
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <Input name="code1" value={businessNumber.code1} onChange={handleChange} maxLength={3} style={{ width: '50px' }} placeholder="000" />
            <span>-</span>
            <Input name="code2" value={businessNumber.code2} onChange={handleChange} maxLength={2} style={{ width: '40px' }} placeholder="00" />
            <span>-</span>
            <Input name="code3" value={businessNumber.code3} onChange={handleChange} maxLength={5} style={{ width: '70px' }} placeholder="00000" />
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave}>
            저장
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BusinessRegist;

import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_SELLERLIST = {
  query: {
    keyword_text: '',
    startDt: '',
    endDt: '',
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const sellerSlice = createSlice({
  name: 'sellerList',
  initialState: INIT_STATE_SELLERLIST,
  reducers: {
    clearSellerList: () => INIT_STATE_SELLERLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setSellerList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearSellerList, setQuery, setSellerList, setLocationKey, setYScrollPosition } = sellerSlice.actions;

export default sellerSlice.reducer;

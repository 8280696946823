import React from 'react';
import logo from '@assets/images/common/logo.png';

const BasicFooter = (props) => {
  return (
    <footer id="footer">
      <article className="flexRowCenter">
        <img src={logo} alt="logo" />
        <p>© 2024. JMCORPORation INC. All rights reserved.</p>
      </article>
    </footer>
  );
};

export default BasicFooter;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import qs from 'qs';
// icon
import userIcon3 from '@assets/images/common/user_icon_3.png';
// mobile
import promptCreateIcon from '@assets/images/common/prompt_create_icon.png';
import promptTrashIcon from '@assets/images/common/prompt_trash_icon.png';
// active
import activePromptCreateIcon from '@assets/images/common/active_prompr_create_icon.png';
import activePromptTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import logoutIcon from '@assets/images/common/logout_icon.png';
// path
import * as PATH from '@routes/pathName';
// lib
import { dayFormatKR } from '@utils/lib';
// redux
import { clearAuth } from '@stores/auth';
import { INQUIRY_HOME, MY_MARKET_HOME, MYPAGE_PROFILE } from '@routes/pathName';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const MySimpleMenu = ({ userToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 선택한 메뉴로 이동
  const moveToPage = (path) => {
    userToggle();
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key } });
  };

  const logout = () => {
    window.confirm('로그아웃 하시겠습니까?').then(async (result) => {
      if (result) {
        userToggle();
        await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, '{}'), dispatch(clearAuth())]);
        navigate(PATH.LOGIN);
      } else return;
    });
  };

  return (
    <div className="userWrapper">
      {auth?.mem_key ? (
        <>
          <div className="userInfo flexRowStart">
            <div className="userThumb flexColCenter">
              <img src={auth.mem_img_path || userIcon3} />
            </div>

            <div className="flexColStart">
              <h1>{auth.mem_nick}</h1>
              <p>{`${dayFormatKR(auth.fir_dt)} 가입`}</p>
            </div>
          </div>

          <div className="tabBox flexRowBetween">
            <div className="tab flexColCenter">Bagic</div>
            <div className="tab active flexColCenter">UPGRADE</div>
          </div>

          <div className="flexColCenter menuBox">
            <div className="menu flexRowStart" onClick={() => moveToPage(PATH.PROFILE_PAGE)}>
              <img src={activePromptCreateIcon} alt="promt_icon" className="activeIcon" />
              <img src={promptCreateIcon} alt="promt_icon" />
              <p>내 프로필</p>
            </div>
            <div className="menu flexRowStart" onClick={() => moveToPage(PATH.CREATE_PROMPT)}>
              <img src={activePromptCreateIcon} alt="promt_icon" className="activeIcon" />
              <img src={promptCreateIcon} alt="promt_icon" />
              <p>프롬프트 빌더</p>
            </div>
            <div className="menu flexRowStart" onClick={() => moveToPage(PATH.MY_MARKET_HOME)}>
              <img src={activePromptTrashIcon} alt="promt_icon" className="activeIcon" />
              <img src={promptTrashIcon} alt="promt_icon" />
              <p>내 마켓플레이스</p>
            </div>
            <div className="menu flexRowStart" onClick={() => navigate(PATH.INQUIRY_HOME)}>
              <img src={activePromptTrashIcon} alt="promt_icon" className="activeIcon" />
              <img src={promptTrashIcon} alt="promt_icon" />
              <p>1 : 1 문의</p>
            </div>
            <div id="logoutBtn" onClick={logout}>
              <img src={logoutIcon} />
              <p>로그아웃</p>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div id="logoutBtn" onClick={() => moveToPage(PATH.LOGIN)} style={{ width: '200px' }}>
            <p>로그인</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MySimpleMenu;

import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Tabs, Button } from 'antd';
import qs from 'qs';
import Draggable from 'react-draggable';
import Table from '@components/Table';
import { secondsFormat } from '@utils/lib';
import { ADMIN_USER_DETAIL } from '@routes/pathName';

const ComplainHistory = ({ isOpen, onClose, data }) => {
  const navigate = useNavigate();
  const fromComplainHistoryRef = useRef();
  const toComplainHistoryRef = useRef();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // 그리드 설정
  const gridProps = {
    autoGridHeight: true,
  };

  // 그리드 칼럼 레이아웃 정의
  const columnLayout = (tab) => {
    return [
      {
        dataField: 'bad_div',
        headerText: '구분',
        width: '10%',
        editable: false,
      },
      {
        dataField: 'fir_dt',
        headerText: '일시',
        dataType: 'date',
        dateInputFormat: 'yyyy-mm-dd', // 데이터의 날짜 형식
        formatString: 'yyyy.mm.dd', // 그리드에 보여줄 날짜 형식
        width: '20%',
        editable: false,
        labelFunction: (rowIndex, columnIndex, value) => {
          return secondsFormat(value);
        },
      },
      {
        ...(tab === 1 && {
          dataField: 'bad_mem_email',
          renderer: {
            type: 'LinkRenderer',
            baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
            // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
            jsCallback: function (rowIndex, columnIndex, value, item) {
              onClose();
              navigate(`${ADMIN_USER_DETAIL}?${qs.stringify({ mem_email: item?.bad_mem_email, mem_key: item?.bad_mem_key })}`);
            },
          },
        }),
        ...(tab === 2 && {
          dataField: 'own_mem_email',
          renderer: {
            type: 'LinkRenderer',
            baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
            // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
            jsCallback: function (rowIndex, columnIndex, value, item) {
              onClose();
              navigate(`${ADMIN_USER_DETAIL}?${qs.stringify({ mem_email: item?.own_mem_email, mem_key: item?.own_mem_key })}`);
            },
          },
        }),
        headerText: '이메일',
        width: '20%',
        editable: false,
      },
      {
        dataField: 'bad_cd',
        headerText: '콘텐츠명',
        width: '10%',
        editable: false,
      },
      {
        dataField: 'contents',
        headerText: '내용',
        width: '40%',
        editable: false,
      },
    ];
  };

  const fromComplainHistoryColumnLayout = columnLayout(1);
  const toComplainHistoryColumnLayout = columnLayout(2);

  const complainHistoryList = [
    {
      label: `신고한 내역 ${data?.promptBadListFromMember.length ?? 0}`,
      key: 0,
      children: <Table ref={fromComplainHistoryRef} columnLayout={fromComplainHistoryColumnLayout} customGridProps={gridProps} />,
      forceRender: true,
    },
    {
      label: `신고받은 내역 ${data?.promptBadListToMember.length ?? 0}`,
      key: 1,
      children: <Table ref={toComplainHistoryRef} columnLayout={toComplainHistoryColumnLayout} customGridProps={gridProps} />,
      forceRender: true,
    },
  ];

  useEffect(() => {
    if (isOpen) {
      Promise.all([
        fromComplainHistoryRef?.current?.setGridData(data?.promptBadListFromMember),
        toComplainHistoryRef?.current?.setGridData(data?.promptBadListToMember),
      ]);
    }
  }, [isOpen]);

  const handleTab = (key) => {
    if (key === 0) fromComplainHistoryRef?.current?.resize('100%');
    if (key === 1) toComplainHistoryRef?.current?.resize('100%');
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          신고
        </div>
      }
      width={820}
      open={isOpen}
      closable={false}
      maskClosable={false}
      getContainer={() => document.getElementById('root')}
      footer={
        <Button type="primary" onClick={onClose}>
          확인
        </Button>
      }
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Tabs type="card" items={complainHistoryList} onChange={handleTab} />
    </Modal>
  );
};

export default ComplainHistory;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Card, Checkbox, Col, Form, Image, Input, Layout, List, Modal, Row, Segmented, Select, Space, Table } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import { shallowEqual, useSelector } from 'react-redux';
import { segDisplay } from '@utils/constants';
import { isEmpty, useDebounce } from '@utils/lib';
import { PROMPT_MANAGEMENT } from '@routes/pathName';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeartFilled, HeartOutlined, MoreOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ContextMenu from '@components/ContextMenu';
import Meta from 'antd/es/card/Meta';
import dayjs from 'dayjs';
import { MouseTransition, MultiBackend, TouchTransition } from 'react-dnd-multi-backend';
import { TouchBackend } from 'react-dnd-touch-backend'; // or any other pipeline
const PromptManagement = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const [activeSegKey, setActiveSegKey] = useState('List'); // 활성화된 tab (리스트, 카드)

  const [searchForm] = Form.useForm(); // 검색조건
  const aiModelList = useRef(); // AI모델 리스트 ( 공통코드)

  const HTML5toTouch = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  const searchOptions = [
    { label: '제목', value: 'title' },
    { label: 'AI모델', value: 'model' },
  ];
  const sortOptions = [
    { label: '최신순', value: 'recent' },
    { label: '제목순', value: 'title' },
  ];
  const initUserPromptList = {
    query: {
      keyword_type: 'title',
      keyword_text: '',
      sort: 'recent',
      bookmark: '',
      mem_key: auth?.mem_key,
      parent_obj_id: auth?.mem_key + '-1', // 최상위 폴더
      trash_obj_id: auth?.mem_key + '-2', // 휴지통
      offset: 0,
      limit: 20,
    },
    items: [],
    currentTitle: auth?.mem_nick + '님의 폴더',
  };
  const [userPromptList, setUserPromptList] = useState({ ...initUserPromptList, ...state?.userPromptList });
  const [selectedItems, setSelectedItems] = useState([]); //선택된 item
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 }); // 우클릭 position
  const [menuList, setMenuList] = useState([]); // 우클릭 메뉴 List
  const [editFolderModal, setEditFolderModal] = useState({ visible: false, editItem: null }); // 폴더명저장 모달
  const [editable, setEditable] = useState(false); // 폴더명저장 버튼 컨트롤

  const alertTest = (e) => {
    e.preventDefault();
    console.log('돌까?');
  };
  /** 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // seg Key 변경
  const handleSegChange = (value) => {
    setActiveSegKey(value);
  };

  /** item 이벤트 */
  // 검색버튼 클릭시
  const handleSearchPromptList = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...userPromptList.query, keyword_type, keyword_text, offset: 0 };
    handleDebounce(() => getUserPromptList(newQuery));
  };

  // 더블클릭시 해당 폴더로 진입
  const handleDoubleClick = (item) => {
    /** 00:폴더 / 01:파일 / 99:휴지통 */
    // 파일이 아니고, 휴지통에 들어있지 않을 때 진입가능
    if (item.folder_div != '01' && item.parent_obj_id != userPromptList.query.trash_obj_id) {
      const newQuery = { ...userPromptList.query, parent_obj_id: item.obj_id };
      setUserPromptList((prev) => ({ ...prev, query: newQuery, currentTitle: item.obj_nm }));
      navigate(PROMPT_MANAGEMENT, { state: { userPromptList: { ...userPromptList, query: newQuery, currentTitle: item.obj_nm } } });
    }
  };

  // drop 이벤트
  const handleDrop = (draggedItems, targetId) => {
    draggedItems.forEach((draggedId) => {
      // draggedId를 이용해 아이템을 targetId에 드롭 처리
      console.log(`드래그한 항목zz: ${draggedId}, 드롭 대상: ${targetId}`);
    });

    // 드래그 대상이 본인이면 return 처리
    if (draggedItems.includes(targetId)) {
      return;
    }
    movePromptFolder(targetId, draggedItems);
  };

  // 우클릭 이벤트
  const handleRightClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.folder_div == '99') return;

    const selectedId = item.obj_id;
    setSelectedItems([selectedId]);

    switch (item.parent_obj_id) {
      case userPromptList.query.trash_obj_id:
        // 부모가 휴지통일때
        setMenuList([
          {
            id: 'restore',
            name: '복원',
            callback: () => {
              restorePromptFolder(selectedId);
            },
          },
          {
            id: 'remove',
            name: '영구삭제',
            callback: () => {
              removePromptFolder(selectedId);
            },
          },
        ]);
        break;
      default:
        // 폴더
        setMenuList([
          {
            id: 'edit',
            name: '수정',
            callback: () => {
              editPromptFolder(selectedId);
            },
          },
          {
            id: 'add',
            name: '삭제',
            callback: () => {
              alert('삭제');
            },
          },
        ]);
        break;
    }

    setMenuPosition({ x: e.pageX, y: e.pageY }); // 메뉴 위치 설정
  };

  // 폴더명 변경 모달 open
  const editPromptFolder = (targetId) => {
    const editItem = userPromptList.items.find((item) => item.obj_id === targetId);
    setEditFolderModal({ visible: true, editItem: editItem }); //폴더명 변경 모달창 open
  };

  // 폴더명 변경 input change 핸들러
  const handleInputChange = (e) => {
    setEditable(e.target.value ? false : true);
    if (e.target.value) {
      setEditFolderModal((prev) => ({
        ...prev,
        editItem: { ...prev.editItem, folder_nm: e.target.value },
      }));
    }
  };

  // 모달창 close
  const closeModal = (flag) => {
    switch (flag) {
      case 'folderName': // 폴더 이름변경
        setEditFolderModal({ visible: false });
        setEditable(false);
    }
  };

  /** api */
  // 유저 프롬프트 목록 조회
  const getUserPromptList = useCallback(async (query) => {
    try {
      setLoading((prev) => ({ ...prev, promptLoad: true }));
      const { data } = await api.getUserPromptList(query);

      setUserPromptList((prev) => ({
        ...prev,
        items: data.items,
        currentTitle: prev.currentTitle,
      }));
    } catch (e) {
      console.log('조회 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, promptLoad: false }));
    }
  }, []);

  // 폴더 이동 (휴지통으로이동 포함)
  const movePromptFolder = async (parentId, draggedItems) => {
    console.log(draggedItems);
    try {
      setLoading((prev) => ({ ...prev, movePromptFolder: true }));
      const { data } = await api.movePromptFolder({
        mem_key: userPromptList.query.mem_key,
        parent_obj_id: parentId,
        drag_items: draggedItems,
      });
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log('저장에러');
    } finally {
      setLoading((prev) => ({ ...prev, movePromptFolder: false }));
    }
  };

  // 휴지통에서 복원
  const restorePromptFolder = async (targetId) => {
    console.log(targetId);
    try {
      setLoading((prev) => ({ ...prev, restorePromptFolder: true }));
      const { data } = await api.restorePromptFolder({
        mem_key: userPromptList.query.mem_key,
        obj_id: targetId,
      });
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({ ...prev, restorePromptFolder: false }));
    }
  };

  // 휴지통에서 영구삭제
  const removePromptFolder = async (targetId) => {
    console.log(targetId);
    try {
      setLoading((prev) => ({ ...prev, restorePromptFolder: true }));
      const { data } = await api.removePromptFolder({
        mem_key: userPromptList.query.mem_key,
        obj_id: targetId,
      });
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({ ...prev, restorePromptFolder: false }));
    }
  };

  // 폴더명 저장
  const debounceEditFolderName = () => {
    handleDebounce(() => editFolderName());
  };
  const editFolderName = async (e) => {
    try {
      const { data } = await api.savePromptFolder(editFolderModal.editItem);
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log(e);
    } finally {
      setEditFolderModal({ visible: false, editItem: null });
      setEditable(false);
    }
  };
  const toggleBookmark = async (targetItem) => {
    try {
      console.log(isEmpty(targetItem.bookmark));
      const { data } = await api.toggleBookmark({
        mem_key: userPromptList.query.mem_key,
        obj_id: targetItem.obj_id,
        bookmark: isEmpty(targetItem.bookmark) ? 'Y' : '',
      });

      getUserPromptList(userPromptList.query);
    } catch (e) {}
  };
  /////////////////////////////////////////////////////////////////

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  /** 상태관리? */
  useEffect(() => {
    // 첫 진입시
    if (isEmpty(state)) {
      getCommonCode(); // 공통코드 조회
      setUserPromptList(initUserPromptList);
      handleDebounce(() => getUserPromptList(initUserPromptList.query)); // 프롬프트 리스트 조회
    } else {
      setUserPromptList(state.userPromptList);
      handleDebounce(() => getUserPromptList(state.userPromptList.query)); // 프롬프트 리스트 조회
    }
  }, [state]);

  useEffect(() => {
    setSelectedItems([]); // selected Item 초기화
  }, [userPromptList]);

  const handleChecked = (e, targetId) => {
    const isChecked = e.target.checked; // 현재 체크 상태
    setSelectedItems((prev) => {
      if (isChecked) {
        // 체크된 경우 targetId 추가
        return [...prev, targetId];
      } else {
        // 체크 해제된 경우 targetId 제거
        return prev.filter((id) => id !== targetId);
      }
    });
  };

  // Segmented Component
  const ListComponent = ({ item, getDescription, getThumbnail, folderPath }) => {
    return (
      <>
        <Checkbox checked={selectedItems.includes(item.obj_id)} onChange={(e) => handleChecked(e, item.obj_id)} disabled={item.folder_div === '99' ? true : false} />
        <img width={50} height={50} src={getThumbnail()} />
        <List.Item.Meta title={item.obj_nm} description={getDescription()} />
        <Space>
          {item.parent_obj_id == userPromptList.query.trash_obj_id ? (
            <>
              <Row>{folderPath}</Row>
              <Row>{dayjs(item.del_dt).format('YYYY-MM-DD')}</Row>
            </>
          ) : (
            <Row>{dayjs(item.lst_dt).format('YYYY-MM-DD')}</Row>
          )}

          <Button
            onClick={() => toggleBookmark(item)}
            icon={item.bookmark ? <StarFilled style={{ color: 'orange' }} /> : <StarOutlined style={{ color: 'orange' }} />}
            type="link"
            style={{ marginLeft: '15px', marginRight: '-3px' }}
          />
          <MoreOutlined onClick={alertTest} />
        </Space>
      </>
    );
  };
  const CardComponent = ({ item, getDescription, getThumbnail }) => {
    return (
      <Card style={{ background: 'none' }} cover={<img width={50} height={50} src={getThumbnail()} />}>
        <Checkbox checked={selectedItems.includes(item.obj_id)} onChange={(e) => handleChecked(e, item.obj_id)} disabled={item.folder_div === '99' ? true : false} />
        <Meta title={item.obj_nm} />
        {getDescription()}
        <Button
          //onClick={() => toggleLike(item.doc_no, item.lst_id)}
          icon={item.bookmark ? <StarFilled style={{ color: 'yellow' }} /> : <StarOutlined style={{ color: 'yellow' }} />}
          type="link"
          style={{ marginLeft: '15px', marginRight: '-3px' }}
        />
        <MoreOutlined onClick={alertTest} />
      </Card>
    );
  };
  const DraggableDroppableItem = ({ id, item, onDrop }) => {
    const folderPath = item.path_nm.slice(0, -1).join(' > ');
    const getThumbnail = () => {
      switch (item.folder_div) {
        case '00':
          return 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png';
        case '01':
          return item.thum_path;
        case '99':
          return 'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png';
      }
    };

    const getDescription = () => {
      if (item.folder_div == '00') {
        return <Space>총 {item.child_cnt}개</Space>;
      } else if (item.folder_div == '01') {
        return (
          <Space size={'middle'}>
            <Col>{item.ai_model_nm}</Col>
            <Col>
              {item.cate_nm}
              {!isEmpty(item.genre_nm) ? `/${item.genre_nm}` : '대체 텍스트'}
            </Col>
          </Space>
        );
      }
    };

    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'ITEM',
      item: { selectedItems },
      canDrag: selectedItems.includes(id), // selectedItems에 포함된 항목만 드래그 가능
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
      accept: 'ITEM', // type이 ITEM인 객체만 drop 가능
      drop: (draggedItem) => onDrop(draggedItem.selectedItems, id), // 드래그된 모든 아이템과 현재 ID를 전달
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }));

    // 드래그 및 드롭을 위한 ref 결합
    const dndRef = React.useRef(null);
    drag(drop(dndRef)); // drag와 drop을 결합

    return (
      <List.Item
        ref={dndRef}
        key={item.obj_id}
        data-id={item.obj_id}
        className={`${activeSegKey} ${selectedItems.includes(item.obj_id) ? 'selected' : ''}`}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          background: selectedItems.includes(item.obj_id) ? '#fff' : isOver && canDrop ? '#ddd' : '',
        }}
        onDoubleClick={() => {
          handleDoubleClick(item);
        }}
        onContextMenu={() => handleRightClick(event, item)}
      >
        {activeSegKey === 'List' ? (
          <ListComponent item={item} getDescription={getDescription} getThumbnail={getThumbnail} folderPath={folderPath} />
        ) : (
          <CardComponent item={item} getDescription={getDescription} getThumbnail={getThumbnail} folderPath={folderPath} />
        )}
      </List.Item>
    );
  };

  return (
    <>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <Loading isLoading={isLoading} />
        <ContextMenu menuPosition={menuPosition} menuList={menuList} />

        {editFolderModal.visible && (
          <Modal
            open={editFolderModal.visible}
            title={<div>이름 변경</div>}
            okText="저장"
            cancelText="취소"
            closable={false}
            maskClosable={false}
            okButtonProps={{ disabled: editable }}
            onOk={debounceEditFolderName}
            onCancel={() => closeModal('folderName')}
          >
            <div>제목을 입력하세요.</div>
            <div>{editFolderModal.editItem?.folder_nm}</div>

            <Input placeholder="제목을 입력해주세요." id="newName" allowClear defaultValue={editFolderModal.editItem?.folder_nm} onChange={handleInputChange} />
          </Modal>
        )}

        <Layout>
          <Header style={{ background: 'none', height: 'auto' }}>
            <Row>{userPromptList.currentTitle}</Row>
            <Checkbox onChange={() => alert('ㅎㅇ')}>Checkbox</Checkbox>
            <Row style={{ justifyContent: 'space-between' }}>
              <Form form={searchForm} layout="inline" initialValues={userPromptList.query} onFinish={handleSearchPromptList}>
                <Form.Item name="keyword_type">
                  <Select options={searchOptions} style={{ width: '150px' }} />
                </Form.Item>
                <Form.Item name="keyword_text">
                  <Input placeholder="검색어를 입력해주세요." allowClear style={{ minWidth: '300px' }} />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  검색
                </Button>
              </Form>
              <Row>
                <Select options={sortOptions} style={{ width: '150px' }} defaultValue="recent" />
                <Button danger onClick={() => movePromptFolder(userPromptList.query.trash_obj_id, selectedItems)}>
                  삭제
                </Button>
                <Segmented options={segDisplay} onChange={handleSegChange} />
              </Row>
            </Row>
          </Header>
          <Content>
            <List
              grid={
                activeSegKey != 'List'
                  ? {
                      gutter: 4,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 4,
                      xl: 5,
                      xxl: 6,
                    }
                  : undefined
              }
              dataSource={userPromptList.items}
              renderItem={(item) => {
                return <DraggableDroppableItem id={item.obj_id} item={item} onDrop={handleDrop} />;
              }}
            />
          </Content>
        </Layout>
      </DndProvider>
    </>
  );
};

export default PromptManagement;

import { restApi, querystring, headersForSession } from './config';
import { AUTH_ADMIN } from '@utils/constants';

// 배너 목록
export const getBannerList = (data) => {
  return restApi.get('/api/admin/bannerList', querystring(data));
};

// 배너 저장 / 수정
export const updatedBanner = (data) => {
  headersForSession(AUTH_ADMIN);
  return restApi.post('/api/admin/updatedBanner', data);
};

// 위치별 배너 조회
export const getBannerByLoc = (data) => {
  return restApi.get('/api/admin/getBannerByLoc', querystring(data));
};

// 배너 view 업데이트
export const updateBannerView = (data) => {
  return restApi.post('/api/admin/updateBannerView', data);
};

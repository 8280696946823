import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Drawer, Input, message, Skeleton, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import { CaretRightOutlined, CloseOutlined, CustomerServiceOutlined, ShareAltOutlined, StarFilled, UpOutlined, DownOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import saveIcon from '@assets/images/common/save_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import activeHeadsetIcon from '@assets/images/common/active_headset_icon.png';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeCartIcon from '@assets/images/common/active_cart_icon.png';
import downloadIcon from '@assets/images/common/download_icon.png';
import activeDownloadIcon from '@assets/images/common/active_download_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import thumbnail from '@assets/images/common/thumb.png';
import blueArrowDown from '@assets/images/common/blue_arrow_down.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import headsetIcon from '@assets/images/common/headset_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
import whiteCheckIcon from '@assets/images/common/white_check_icon.png';
// component
import Banner from '@components/slider/Banner';
// path
import * as PATH from '@routes/pathName';
// lib
import { useDebounce, validateSession } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';

const checkedMusicList = [1, 2, 3];
const downloadedMusicList = [2, 5];

const MusicHome = (props) => {
  const navigate = useNavigate();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterDrawerMobile, setFilterDrawerMobile] = useState(false);
  // 보관함 목록 데이터: prpt_id만 있음
  const [savedMusicPrptIds, setsavedMusicPrptIds] = useState([]);
  // 좋아요 목록 데이터: prpt_id만 있음
  const [likedMusicPrptIds, setlikedMusicPrptIds] = useState([]);
  // 장바구니 목록 데이터: prpt_id만 있음
  const [cartMusicPrptIds, setcartMusicPrptIds] = useState([]);
  // 장바구니 목록 데이터
  const [cartMusicList, setcartMusicList] = useState([]);
  // 음악 리스트
  const [musicList, setMusicList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((s) => s.auth, shallowEqual);
  var isPopup;

  const filterToggle = useCallback(() => {
    setFilterDrawer(!filterDrawer);
  }, [filterDrawer]);

  const filterToggleMobile = useCallback(() => {
    setFilterDrawerMobile(!filterDrawerMobile);
  }, [filterDrawerMobile]);

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.listMyMusic({ mem_key: auth?.mem_key });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setsavedMusicPrptIds(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 추가
  const addMyMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.addMyMusic({
        mem_key: auth?.mem_key,
        prpt_id: music?.prpt_id,
        prpt_title: music?.prpt_title,
        prpt_path: music?.music_org_link,
      });
      if (!data) return;
      await listSavedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.deleteMyMusic({
        mem_key: auth?.mem_key,
        prpt_id: music?.prpt_id,
      });
      if (!data) return;
      await listSavedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 목록 가져오기
  const listLikedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.getMyPromptList({ mem_key: auth?.mem_key, keyword_tab: 'likes', filter_cate: ['CATE003'] });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setlikedMusicPrptIds(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 추가
  const addLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
        fir_id: auth?.mem_key,
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 삭제
  const deleteLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 목록 가져오기
  const listCartMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.getCartList({ mem_key: auth?.mem_key, gds_div: 'GDS003' });
      if (!data) return;
      const ids = data.map((music) => music.gds_key);
      setcartMusicPrptIds(ids);
      setcartMusicList(data);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 곡 추가
  const addCartMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.addToCart({
        gds_key: music?.prpt_id,
        gds_nm: music?.prpt_title,
        gds_amt: music?.sale_amt,
        gds_url: music?.thum_path,
        gds_div: 'GDS003',
      });
      if (!data || data.returnStatus !== 'success') return;
      await listCartMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 곡 삭제
  const deleteCartMusic = useCallback(
    async (music) => {
      try {
        if (!auth?.mem_key) return;
        // 음악 프롬프트 아이디로 매핑되는 장바구니 프롬프트 찾기
        const findCartList = cartMusicList?.filter((cart) => cart.gds_key === JSON.stringify(music.prpt_id));
        if (!findCartList.length) return;
        const { data } = await api.deleteCart({ mem_key: auth?.mem_key, gds_seq: findCartList[0].gds_seq });
        if (!data || data.returnStatus !== 'success') return;
        await listCartMusic();
      } catch (error) {
        message.warning(error.message);
      }
    },
    [cartMusicList],
  );

  // 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE003',
        keyword_tab: '',
        offset: 0,
        limit: 60,
      });
      if (!data) return;
      setMusicList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 음악 플레이어 열기
  const openMusicPlayer = async (music) => {
    try {
      if (!music) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        await api.addPlaylistMusic({
          mem_key: auth?.mem_key,
          prpt_id: music?.prpt_id,
          prpt_title: music?.prpt_title,
          prpt_path: music?.music_org_link,
        });
        closeChildPopup();
        isPopup = window.open(PATH.MUSIC_PLAYER, '_blank', 'width=375,height=720');
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 현재 페이지를 '새로고침' 또는 '창닫기'를 하면, 음악플레이어 강제 닫기
  const closeChildPopup = () => {
    isPopup?.close();
  };

  useEffect(() => {
    handleDebounce(() => listMusic());
    listSavedMusic();
    listLikedMusic();
    listCartMusic();
    window.addEventListener('unload', closeChildPopup);
  }, []);

  return (
    <>
      <article id="musicWrapper" className="flexColCenter">
        <Banner bannerCd="BANNER001" bannerTp="Top" />

        <div className="filter flexColCenter" id={filterDrawerMobile ? 'filter' : 'nothing'}>
          <div className="rsWrapper flexColCenter">
            <div className="listBox flexRowStart">
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>정렬</h2>
              </div>

              <Radio.Group
                value={2}
                style={{
                  display: `flex`,
                  flexDirection: `row`,
                }}
              >
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={2}
                >
                  Midjourney
                </Radio>
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={3}
                >
                  Dall-E
                </Radio>
              </Radio.Group>
            </div>

            <div className="listBox flexRowStart">
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>모델</h2>
              </div>

              <Radio.Group
                value={2}
                style={{
                  display: `flex`,
                  flexDirection: `row`,
                }}
              >
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={2}
                >
                  Midjourney
                </Radio>
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={3}
                >
                  Dall-E
                </Radio>
              </Radio.Group>
            </div>

            <div className="listBox flexRowStart">
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>장르</h2>
              </div>
              <Radio.Group
                value={2}
                style={{
                  display: `flex`,
                  flexDirection: `row`,
                }}
              >
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={2}
                >
                  카테고리1
                </Radio>
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={3}
                >
                  카테고리2
                </Radio>
                <Radio
                  style={{
                    width: `140px`,
                    fontSize: `14px`,
                  }}
                  value={4}
                >
                  카테고리3
                </Radio>
              </Radio.Group>
            </div>

            <div className="reset flexRowBetween">
              {filterDrawerMobile ? (
                <button id="whiteBtn">
                  <img src={resetIcon} />
                  <p>초기화</p>
                </button>
              ) : (
                <div className="flexRowStart title">
                  <img src={mobileFilterIcon} />
                  <h2>필터</h2>
                </div>
              )}

              {filterDrawerMobile ? (
                <button id="greyBtn2" onClick={filterToggleMobile}>
                  <UpOutlined />
                </button>
              ) : (
                <button id="greyBtn4" onClick={filterToggleMobile}>
                  <DownOutlined />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="rsWrapper flexColCenter">
          <div className="flexRowBetween">
            <div className="content flexColCenter">
              <div className="titleBox flexRowBetween">
                <h1>
                  K-pop SUNO <br />
                  <span>트렌드 음악 프롬프트</span>
                </h1>

                <div className="search flexRowBetween">
                  <img src={searchIcon} />
                  <Input placeholder="음악을 검색해 주세요." />
                </div>

                <img src={mobileFilterIcon} onClick={filterToggle} />
              </div>

              <div className="searchBox flexRowBetween">
                <div className="flexRowCenter ">
                  <div id="pinkBtn2">All</div>
                  <div id="pinkBtn2" className="active">
                    Pop
                  </div>
                  <div id="pinkBtn2">Ballad</div>
                  <div id="pinkBtn2">Rock</div>
                </div>

                <div className="flexRowCenter">
                  <div id="pinkBtn">
                    <p>
                      <ShareAltOutlined />
                    </p>
                    <p>내 음악 공유</p>
                  </div>
                  <div id="whiteBtn" className="flexRowCenter">
                    <p>
                      <CustomerServiceOutlined /> 듣기
                    </p>
                  </div>
                  <div id="whiteBtn" className="flexRowCetner">
                    <div className="flexColCenter">
                      <img src={saveIcon} />
                      <img src={activeSaveIcon} className="activeImg" />
                    </div>
                    <p>보관함</p>
                  </div>
                  <div id="whiteBtn" className="flexRowCenter">
                    <div className="flexColCenter">
                      <img src={cartIcon} />
                      <img src={activeCartIcon} className="activeImg" />
                    </div>
                    <p>담기</p>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <>
                  <Skeleton
                    active
                    paragraph={{
                      rows: 4,
                    }}
                  />
                  <Skeleton
                    active
                    paragraph={{
                      rows: 4,
                    }}
                  />
                </>
              ) : (
                <div className="listBox flexColCenter">
                  {musicList?.map((music) => (
                    <div key={music?.prpt_id} className="list flexRowBetween" id={checkedMusicList?.includes(music?.prpt_id) ? 'active' : ''}>
                      <Checkbox className="check" checked={checkedMusicList?.includes(music?.prpt_id) ? true : false} />
                      <div className="rankBox flexRowBetween">
                        <h1>{music?.prpt_id}</h1>

                        <div className="flexRowCenter">
                          <img src={blueArrowDown} />
                          <p id="down">1</p>
                        </div>
                      </div>

                      <img className="thumb" src={music?.thum_path ?? thumbnail} />

                      <div className="flexRowCenter titleBox">
                        <div className="flexColStart title">
                          <div className="flexRowStart ">
                            <img src={music?.icon_path ?? userThum} />
                            <p>{music?.ai_model_nm}</p>
                          </div>
                          <h1 onClick={() => moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id })}>{music?.prpt_title}</h1>
                        </div>

                        <div className="flexRowStart userBox">
                          <img src={music?.mem_img_path ?? userIcon3} />
                          <p onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}>{music?.mem_nick}</p>
                        </div>
                      </div>

                      <div
                        className="heart flexColCenter btn"
                        id={likedMusicPrptIds?.includes(music?.prpt_id) ? 'active' : ''}
                        onClick={() => (likedMusicPrptIds?.includes(music?.prpt_id) ? deleteLikeMusic(music) : addLikeMusic(music))}
                      >
                        <img src={activeHeartIcon} className="activeIcon" />
                        <img src={heartIcon} />
                      </div>

                      <div className="views flexRowEnd">
                        <p className="icon">
                          <CaretRightOutlined />
                        </p>
                        <p>1,542</p>
                      </div>

                      <div className="flexRowCenter star">
                        <p id="active">
                          <StarFilled />
                        </p>
                        <p id="active">
                          <StarFilled />
                        </p>
                        <p id="active">
                          <StarFilled />
                        </p>
                        <p id="active">
                          <StarFilled />
                        </p>
                        <p>
                          <StarFilled />
                        </p>
                      </div>

                      <div className="flexColCenter btn mobile mobile" onClick={() => openMusicPlayer(music)}>
                        <img src={headsetIcon} />
                        <img src={activeHeadsetIcon} className="activeIcon" />
                      </div>

                      <div
                        className="flexColCenter btn"
                        id={savedMusicPrptIds?.includes(music?.prpt_id) ? 'active' : ''}
                        onClick={() => (savedMusicPrptIds?.includes(music?.prpt_id) ? deleteMyMusic(music) : addMyMusic(music))}
                      >
                        <img src={activeSaveIcon} className="activeIcon" />
                        <img src={saveIcon} />
                      </div>

                      <div
                        className="flexColCenter btn"
                        id={cartMusicPrptIds?.includes(JSON.stringify(music?.prpt_id)) ? 'active' : ''}
                        onClick={() => (cartMusicPrptIds?.includes(JSON.stringify(music?.prpt_id)) ? deleteCartMusic(music) : addCartMusic(music))}
                      >
                        <img src={activeCartIcon} className="activeIcon" />
                        <img src={cartIcon} />
                      </div>

                      <div className="flexColCenter btn" id={downloadedMusicList?.includes(music?.prpt_id) ? 'active' : ''}>
                        <img src={downloadIcon} />
                        <img src={activeDownloadIcon} className="activeIcon" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div id="mobileFixedBox" className="flexRowBetween">
          <div className="flexColCenter select">
            <p>
              <span>2</span>곡 선택
            </p>
          </div>
          <div className="flexRowCenter">
            <img src={resetIcon} />
            <img src={cartUpdateIcon} />
            <img src={headsetIcon} />
            <img src={saveIcon} />
            <img src={cartIcon} />
          </div>
        </div>

        <Banner bannerCd="BANNER001" bannerTp="Bottom" />

        <Drawer open={filterDrawer} width={`100%`} headerStyle={{ display: `none` }}>
          <div id="musicMobileFilter" className="flexColCenter">
            <div className="flexColEnd">
              <p onClick={filterToggle}>
                <CloseOutlined />
              </p>
            </div>

            <div className="content flexColStart">
              <h1>필터</h1>
              <h2>어떤 음악을 찾고 계신가요?</h2>

              <div className="searchBox flexRowBetween">
                <Input placeholder="검색어를 입력해주세요." />
                <img src={searchIcon} />
              </div>

              <div className="listBox flexColStart ">
                <h3>타입</h3>

                <div className="flexRowStart">
                  <div className="list flexRowCenter" id="active">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>전체</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>음악</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>이미지</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>영상</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>텍스트</p>
                  </div>
                </div>
              </div>

              <div className="listBox flexColStart ">
                <h3>정렬</h3>

                <div className="flexRowStart">
                  <div className="list flexRowCenter" id="active">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>전체</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>트렌드</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>인기</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>최신</p>
                  </div>
                </div>
              </div>

              <div className="listBox flexColStart ">
                <h3>모델</h3>

                <div className="flexRowStart">
                  <div className="list flexRowCenter" id="active">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>전체</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>Midjuerny</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>Dall-E</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>GPT</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>SUNO</p>
                  </div>
                </div>
              </div>

              <div className="listBox flexColStart ">
                <h3>카테고리</h3>

                <div className="flexRowStart">
                  <div className="list flexRowCenter" id="active">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>전체</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>k-pop</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>J-pop</p>
                  </div>
                  <div className="list flexRowCenter">
                    <div className="round flexColCenter">
                      <img src={whiteCheckIcon} />
                    </div>

                    <p>Pop</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="btnBox flexRowBetween">
              <button id="logoutBtn">
                <img src={resetIcon} />
                <p>초기화</p>
              </button>
              <button id="pinkBtn">검색</button>
            </div>
          </div>
        </Drawer>
      </article>
    </>
  );
};

export default MusicHome;

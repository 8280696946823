import React from 'react';
import Tabs from "@components/Tabs";
import CommunityHome from "@pages/boards/Home";
import TotalBoardList from "@pages/boards/TotalBoardList";
import { getLocalStorage } from "@utils/lib";
import { message } from "antd";

const CommunityPage = () => {
  const tabs = [
    { id: "boardHome", label: "홈", component: <CommunityHome /> },
    { id: "boardSearch", label: "통합 검색", component: <TotalBoardList /> },
  ];
  const sessionChk = getLocalStorage('prptbk-token', 'mem_key');

  const handleTabClick = (tabId) => {
    if (!sessionChk && tabId === "boardSearch") {
      message.error('로그인을 해주세요.');
      return false;
    }
    return true;
  };

  return (
      <>
        <Tabs tabs={tabs} onTabClick={handleTabClick} />
      </>
  );
};

export default CommunityPage;
import React from 'react';
import { RightOutlined } from '@ant-design/icons';

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className="arrowRight flexColCenter" onClick={onClick}>
      <p>
        <RightOutlined />
      </p>
    </div>
  );
};

export default NextArrow;

import React, { useState, useEffect, useCallback } from 'react';
import Tabs from "@components/Tabs";
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { getLocalStorage } from '@utils/lib';
import ChatBase from "@pages/chat/chatBase";
import { setCurrentRoom, clearMessages } from '@stores/chat.js';
import { useLocation, useNavigate } from "react-router-dom";

const ChatInterface = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sessionChk = getLocalStorage('prptbk-token', 'mem_key');

    // 초기 탭
    const getInitialTab = useCallback(() => {
        const routeState = location.state?.selectedTab;
        if (routeState) {
            return routeState;
        }

        const storedTab = localStorage.getItem('selectedChatTab');
        if (storedTab) {
            return storedTab;
        }
        return 'userChat';
    }, [location.state?.selectedTab]);

    // 상태 설정
    const [initialTab, setInitialTab] = useState(getInitialTab);
    const [isTabReady, setIsTabReady] = useState(true);

    useEffect(() => {
        const newInitialTab = getInitialTab();

        if (newInitialTab !== initialTab) {
            setIsTabReady(false); // 준비 전 렌더링 중단
            setInitialTab(newInitialTab);
        }

        // location.state 정리
        if (location.state?.selectedTab) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.state?.selectedTab, getInitialTab]);

    useEffect(() => {
        setIsTabReady(true);
    }, [initialTab]);

    const tabs = [
        { id: "userChat", label: "메세지", component: <ChatBase tabId="normal" /> },
        { id: "systemChat", label: "알림", component: <ChatBase tabId="system" /> },
    ];

    const handleTabClick = (clickedTabId) => {
        if (!sessionChk) {
            message.error('로그인을 해주세요.');
            return false;
        }
        setInitialTab(clickedTabId);
        localStorage.setItem('selectedChatTab', clickedTabId);

        dispatch(setCurrentRoom(null));
        dispatch(clearMessages());

        navigate(location.pathname, {
            state: { selectedTab: clickedTabId },
            replace: true,
        });
        return true;
    };

    return (
        <>
            {isTabReady && (
                <Tabs tabs={tabs} onTabClick={(tabId) => handleTabClick(tabId)} initialTab={initialTab} />
            )}
        </>
    );
};

export default ChatInterface;
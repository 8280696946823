import React, {useEffect} from 'react'
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import '../../assets/css/payment.css';
import * as api from '@api/index';

const Failure = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const errorCode = searchParams.get("code");
  const errorMessage = searchParams.get("message");
  const orderId = searchParams.get("orderId");
  const failureUrl = location.state?.failureUrl;

  useEffect(() => {
    if(orderId){
      api.deleteMemberPayment({pay_seq : orderId})
    }
  }, [orderId])

  return (
    <div className="wrapper w-100">
      <div className="flex-column align-center w-100 max-w-540">
        <img
          src="https://static.toss.im/lotties/error-spot-apng.png"
          width="120"
          height="120"
        />
        <h2 className="title">결제를 실패했어요</h2>
        <div className="response-section w-100">
          <div className="flex justify-between">
            <span className="response-label">code</span>
            <span id="error-code" className="response-text">
              {errorCode}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="response-label">message</span>
            <span id="error-message" className="response-text">
              {errorMessage}
            </span>
          </div>
        </div>

        <div className="w-100 button-group">
          <button className="btn" onClick={() => navigate(failureUrl)}>상세 화면 가기</button>
        </div>
      </div>
    </div>
  );
}

export default Failure
import React from "react";
import Slider from "react-slick";
import "../../assets/css/bannerSlider.css";
import "../../assets/css/common.css";
import "../../assets/css/reset.css";
import banner from "../../assets/images/common/last_banner.png";

const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="slider-container" id="bannerSlider">
      <Slider {...settings}>
        <div className="bannerBox">
          <div className="flexColCenter">
            <div className="rsWrapper flexColCenter">
              <h1>BANNER</h1>
            </div>
          </div>

          <img src={banner} alt="main_banner" />
        </div>
        <div className="bannerBox">
          <div className="flexColCenter">
            <div className="rsWrapper flexColCenter">
              <h1>BANNER</h1>
            </div>
          </div>

          <img src={banner} alt="main_banner" />
        </div>
      </Slider>
    </div>
  );
};

export default BannerSlider;

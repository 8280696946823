import React, { useEffect, useState } from 'react';
import { Tree, Layout, Input, Modal } from 'antd';
import * as api from '@api/index';
import useWidth from '@hooks/useWidth';

const { DirectoryTree } = Tree;
const { Content, Sider } = Layout;

const LyricsStep = (props) => {
  const width = useWidth();
  const { TextArea } = Input;

  const [treeData, setTreeData] = useState([]);
  const [childNodes, setChildNodes] = useState([]); // 클릭한 노드의 자식 노드
  const [selectedLyricsText, setSelectedLyricsText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [soundEffectList, setSoundEffectList] = useState([]);
  const [selectedLyricsNode, setSelectedLyricsNode] = useState(null);
  const [selectedSoundEffectList, setSelectedSoundEffectList] = useState([])
  const [lyricsText, setLyricsText] = useState(null);

  const processTreeData = (node) => {
    if (!node.children || node.children.length === 0) return null; // 자식 없는 노드 제외
    return {
      title: node.ko_nm,
      key: node.el_id,
    };
  };


  const lyricsTreeData = (node) => {

    // 새로운 객체 생성
    const newNode = {
      ...node,
      title: node.ko_nm,
      key: node.parent_seq + "-" + node.el_id,
    };

    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children
        .map((childNode) => lyricsTreeData(childNode)) // 재귀적으로 자식 처리

      newNode.children = filteredChildren;
      newNode.isLeaf = filteredChildren.length === 0; // 자식이 없으면 리프 노드로 설정
    } else {
      newNode.isLeaf = true;
      newNode.children = [];
    }

    return newNode;
  };

  useEffect(() => {
    const updatedTreeData = props.stepData.map((node) => processTreeData(node)).filter((node) => node !== null);
    setTreeData(updatedTreeData);
  }, [props.stepData]);

  const handleSelect = (_, { node }) => {
    const children = props.stepData
      .find((parent) => parent.el_id === node.key)?.children || [];
    setChildNodes(children);
  };

  const handleChildClick = (childText) => {
    setSelectedLyricsText(childText);
  };

  const createText = (lyricsText) => {
    // 텍스트 조합
    const soundEffectsText = selectedSoundEffectList.map((effect) => effect.title).join(', ');
    return `${selectedLyricsText}\n${soundEffectsText}\n${lyricsText}`;
  };

  const onSubmit = () => {
    if(!lyricsText){
      alert('가사를 입력해주세요.');
      return false;
    }
    props.handleSubmitLyrics(createText(lyricsText)); // 가사 입력 전달
    setLyricsText(null);
  };

  const openModal = async () => {
    setIsModalOpen(true)
    const params = {
      el_id : props.lyricsId
    }
    const response = await api.fetchElementsList(params);

    const updatedLyricsList = JSON.parse(response.data.treeData).map((node) => lyricsTreeData(node)).filter(node => node !== null);
    setSoundEffectList(updatedLyricsList)
  }

  const handleSelectLyrics = () => {
    if(selectedLyricsNode.el_div !== '01'){
      window.alert('최하위 요소를 선택해주세요.');
      return false;
    }
    setIsModalOpen(false);
    setSelectedSoundEffectList((prev) => [...prev, selectedLyricsNode]);
  }

  const handleDeleteSoundEffect = (soundEffect) => {
    setSelectedSoundEffectList((prev) => prev.filter((item) => !(item.el_id === soundEffect.el_id && item.parent_seq === soundEffect.parent_seq)));
  }

  return (
    <Layout
      style={{
        background: "#ffffff",
        height: `auto`,
      }}
    >
      <Sider
        width={width < 700 ? `100%` : 200}
        style={{
          background: "#ffffff",
          height: `auto`,
        }}
      >
        <DirectoryTree
          treeData={treeData}
          onSelect={handleSelect}
        />
      </Sider>
      <Layout
        style={{
          padding: width < 700 ? `20px 0 0` : "0 24px 24px",
          background: "#ffffff",
        }}
      >
        <Content
          style={{
            padding: 0,
            margin: 0,
            minHeight: 280,
          }}
        >
          <div className="tabBox flexRowStart">
            {childNodes.map((item) => (
              <button id='blueBtn' onClick={() => handleChildClick(item.ko_nm)}>{item.ko_nm}</button>
            ))}
          </div>

          <div className="inputBox flexColStart">
            <div className="flexRowStart">
              {selectedLyricsText ? (
                <h1>[{selectedLyricsText}]</h1>
              ) : null}
              <button id="whiteBtn" onClick={openModal}>Sound Effect 추가 +</button>
              {selectedSoundEffectList.map((item) => (
                <button id='blueBtn' onClick={() => handleDeleteSoundEffect(item)} key={`${item.el_id}-${item.parent_seq}`}>{item.ko_nm}</button>
              ))}
            </div>

            <div className="textAreaBox flexColEnd">
              <TextArea
                className="textArea"
                style={{ resize: `none`, height: `200px` }}
                placeholder="가사 텍스트를 입력하세요."
                value={lyricsText}
                onChange={(e) => setLyricsText(e.target.value)}
              />
              <button id="darkGrey3" onClick={onSubmit}>입력</button>
            </div>
          </div>
        </Content>
      </Layout>
      <Modal open={isModalOpen} onOk={handleSelectLyrics} onCancel={() => setIsModalOpen(false)} closable={false}>
        <DirectoryTree
          treeData={soundEffectList}
          onSelect={(selectedKeys, {node}) => setSelectedLyricsNode(node)}
        />
      </Modal>
    </Layout>
  );
};

export default LyricsStep;

import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { MARKET_PURCHASES_HOME, MARKET_SALES_HOME } from '@routes/pathName';
import SubTabs from '@pages/marketplaces/subTabs';
import MarketPromptList from '@pages/marketplaces/promptList';
import SalesPrompt from '@pages/myPages/salesPrompt';

const MyMarketplacesPage = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();

  const onChangeTab = (tabKey) => {
    switch (tabKey) {
      case 'purchases':
        navigate(MARKET_PURCHASES_HOME); // 해당 경로로 이동
        break;
      case 'sales':
        navigate(MARKET_SALES_HOME); // 해당 경로로 이동
        break;
      default:
        break;
    }
    setActiveTab(tabKey);
  };

  const tabs = [
    { value: 'purchases', label: '구매 현황' },
    { value: 'sales', label: '판매 현황' },
  ];

  useEffect(() => {
    if (pathname.includes('*')) onChangeTab('purchases');
    setActiveTab(pathname.split('/')[2]);
  }, [pathname]);

  return (
    <>
      <article className="flexColCenter" id="prompt">
        <div className="rsWrapper flexColCenter">
          <div className="flexRowCenter title">
            {tabs.map((tab, index) => (
              <div
                key={tab.value}
                className="flexColCenter"
                onClick={() => onChangeTab(tab.value)} // tab의 key로 이동
                id={activeTab == tab.value ? 'active' : 'nothing'}
              >
                <div className="round"></div>
                <h1>{tab.label}</h1>
              </div>
            ))}
          </div>
        </div>
        <Routes>
          <Route path="purchases" element={<SubTabs />}>
            <Route index element={<Navigate to="purchaseList" />} /> {/*기본 페이지*/}
            <Route path="purchaseList" element={<MarketPromptList />} />
            <Route path="likeList" element={<MarketPromptList />} />
          </Route>
          <Route path="sales" element={<SubTabs />}>
            <Route index element={<Navigate to="salesList" />} /> {/*기본 페이지*/}
            <Route path="salesList" element={<MarketPromptList />} />
            <Route path="salesPrompt" element={<SalesPrompt />} />
          </Route>
        </Routes>
      </article>
    </>
  );
};

export default MyMarketplacesPage;

import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {Button, Card, Form, Input, Layout, List, message, Select} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import { useLocation, useNavigate } from 'react-router-dom';
import {getLocalStorage, useDebounce} from '@utils/lib';
import Loading from '@components/Loading';
import {boardSearch} from "@utils/constants";
import qs from 'qs';
import {BOARD_DETAIL, BOARD_EDIT} from "@routes/pathName";
import {EyeOutlined, HeartFilled, HeartOutlined, MoreOutlined, CommentOutlined} from "@ant-design/icons";

const BoardList = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { board_no: queryBoardNo, board_title: queryBoardTitle, dsp_meth: dsp_meth } = qs.parse(search, { ignoreQueryPrefix: true });
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [activeSegKey, setActiveSegKey] = useState(dsp_meth === '1' ? 'List' : 'Card');
    const [lstId, setLstId] = useState(null);
    const [likedStatus, setLikedStatus] = useState({});
    const boardGridRef = useRef();
    const abortControllerRef = useRef(null);
    const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';

    const [currentBoardTitle, setCurrentBoardTitle] = useState(() => {
        const storedBoardTitle = localStorage.getItem('board_title');
        return queryBoardTitle || storedBoardTitle || '';
    });

    // 메모이제이션된 초기 쿼리 상태
    const initialQuery = useMemo(() => ({
        keyword_type: boardSearch[0].value,
        keyword_text: '',
        board_no: localStorage.getItem('board_no') || null,
        like_mem_key: memKey || null,
        offset: 0,
        limit: 20,
    }), [queryBoardNo]);

    const [boardListState, setBoardListState] = useState({
        query: initialQuery,
        items: [],
        totalCount: 0,
        currentCount: 0,
    });

    const handleDebounce = useCallback(useDebounce((func) => func(), 300), []);

    const boardGridProps = useMemo(() => ({
        editable: false,
        showRowCheckColumn: false,
        showRowNumColumn: false,
        showStateColumn: false,
        usePaging: true,
        showPageButtonCount: 5,
        showPageRowSelect: true,
        pageRowCount: 20,
        pageRowSelectValues: [20, 50, 100, 200],
        height: 660,
    }), []);

    // 조회수 증가 함수 메모이제이션
    const handleViewCountIncrease = useCallback(async (board_no, doc_no) => {
        try {
            await api.increaseViewCount({ board_no, doc_no });
        } catch (error) {
            message.error('조회수 증가 실패: ' + error.message);
        }
    }, []);

    // 검색 조건 저장/로드 함수
    const saveSearchCondition = useCallback((condition) => {
        localStorage.setItem('boardSearchCondition', JSON.stringify(condition));
    }, []);

    const loadSearchCondition = useCallback(() => {
        const savedCondition = localStorage.getItem('boardSearchCondition');
        return savedCondition ? JSON.parse(savedCondition) : null;
    }, []);

    const getBoardList = useCallback(async (query) => {
        try {
            // 이전 요청 취소
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            setIsLoading(true);
            const { data } = await api.getBoardList(query, {
                signal: abortControllerRef.current.signal
            });

            if (!data) return;

            setBoardListState(prev => ({
                ...prev,
                items: data.items,
                totalCount: data.total,
                currentCount: data.items.length,
            }));

            // 썸네일 처리
            const thumbnails = data.items.map(boardDetail => {
                const thumbnailFile = boardDetail.thumnail;
                return thumbnailFile || null;
            });

            setThumbnail(thumbnails);
            setLstId(data.items.map(item => item.lst_id));
        } catch (error) {
            if (error.name !== 'AbortError') {
                message.warning(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getLikes = useCallback(async () => {
        try {
            const like_mem_key = localStorage.getItem('mem_key') || '0';
            const { data } = await api.likeList({
                params: {
                    target_id: Number('0'),
                    like_mem_key
                }
            });

            setLikedStatus(data.reduce((acc, like) => {
                acc[like.target_id] = true;
                return acc;
            }, {}));
        } catch (error) {
            message.error('좋아요를 불러오는데 실패했습니다.');
        }
    }, []);

    // 게시글 제목 클릭 핸들러
    if (!window.$agRendererTemplate) {
        window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleTitleClick = async (boardNo, docNo, title, rowIndex, event) => {
        await handleViewCountIncrease(boardNo, docNo);
        navigate(`${BOARD_DETAIL}?${qs.stringify({
            board_no: boardNo,
            doc_no: docNo,
            board_title: queryBoardTitle
        })}`);
    };

    useEffect(() => {
        if(activeSegKey === 'List'){
            boardGridRef.current.bind('cellClick', (event) => {
                const gridData = boardGridRef.current.getGridDataWithState('state');
                const selectedRowData = gridData[event.rowIndex];
                console.log(selectedRowData);
                $agRendererTemplate.handleTitleClick(
                    selectedRowData.board_no,
                    selectedRowData.doc_no,
                    selectedRowData.title,
                );
            });
        }
    }, []);

    // 게시판 컬럼 레이아웃 메모이제이션
    const boardColumnLayout = useMemo(() => [
        { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
        { dataField: 'doc_no', headerText: '글 번호', width: '8%' },
        {
            dataField: 'title',
            headerText: '제목',
            width: '58%',
            editable: false,
            renderer: {
                type: 'TemplateRenderer'
            },
            labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                const commentCount = item.comment_cnt || 0;
                const commentIconSvg = `
                    <svg viewBox="64 64 896 896" 
                         focusable="false" 
                         data-icon="comment" 
                         width="1em" 
                         height="1em" 
                         fill="#1890ff" 
                         aria-hidden="true">
                        <path d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z"></path>
                        <path d="M894 345c-48.1-66-115.3-110.1-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4 5.3 16.9 23.3 26.2 40.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1C613.7 788.2 680.7 742.2 729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z"></path>
                    </svg>`;

                let template = '<div style="display: flex; align-items: center;">';
                template += `${item.title}`;
                if (commentCount > 0) {
                    template += `<span style="margin-left: 10px; display: flex; align-items: center;">${commentIconSvg}</span>`;
                    template += `<span style="margin-left: 3px;">[${commentCount}]</span>`;
                }
                template += '</div>';
                return template;
            }
        },
        { dataField: 'lst_dt', headerText: '작성일', dataType: 'date', formatString: 'yyyy.mm.dd hh:mm:ss', width: '18%' },
        { dataField: 'like_cnt', headerText: '좋아요', width: '8%', dataType: 'numeric' },
        { dataField: 'view_cnt', headerText: '조회수', width: '8%', dataType: 'numeric' },
    ], []);

    // 게시판 검색 핸들러
    const handleSearchBoard = useCallback(({ keyword_type, keyword_text }) => {
        const newQuery = {
            ...boardListState.query,
            keyword_type,
            keyword_text,
            offset: 0
        };
        setBoardListState(prev => ({ ...prev, query: newQuery }));
        saveSearchCondition(newQuery);
        handleDebounce(() => getBoardList(newQuery));
    }, [boardListState.query, handleDebounce, getBoardList, saveSearchCondition]);

    // 좋아요 토글 핸들러
    const toggleLike = useCallback(async (docNo, lstId) => {
        const like_mem_key = localStorage.getItem('mem_key') || '0';
        const data = {
            target_id: docNo,
            like_mem_key,
            own_mem_key: lstId,
            like_div: 'B',
        };

        try {
            await api.increaseLikeCount(data);
            setLikedStatus(prev => ({
                ...prev,
                [docNo]: !prev[docNo]
            }));

            getBoardList(boardListState.query);
            message.success(likedStatus[docNo] ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');
        } catch (error) {
            message.error('좋아요 처리 중 오류가 발생했습니다.');
        }
    }, [boardListState.query, getBoardList, likedStatus]);

    // 게시글 작성 핸들러
    const handleWriteClick = useCallback(() => {
        const queryString = qs.stringify({
            board_no: boardListState.query.board_no,
            board_title: queryBoardTitle
        });
        navigate(`${BOARD_EDIT}?${queryString}`);
    }, [navigate, boardListState.query.board_no, queryBoardTitle]);

    const handleRowClick = useCallback((event) => {
        const item = event.item;
        if (item) {
            window.$agRendererTemplate.handleTitleClick(
                item.board_no,
                item.doc_no,
                item.title,
                event.rowIndex,
                event
            );
        }
    }, []);

    // 리스트 컴포넌트
    const ListComponent = useCallback(() => {
        return (
            <Table
                ref={boardGridRef}
                columnLayout={boardColumnLayout}
                customGridProps={boardGridProps}
                onRowClick={handleRowClick}
            />
        );
    }, [boardColumnLayout, boardGridProps, handleRowClick]);

    // 카드 컴포넌트
    const CardComponent = useCallback(() => {
        return (
            <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={boardListState.items}
                renderItem={(item, index) => (
                    <List.Item key={item.board_no}>
                        <Card
                            title={item.title}
                            extra={
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleLike(item.doc_no, item.lst_id);
                                        }}
                                        icon={likedStatus[item.doc_no] ?
                                            <HeartFilled style={{color: 'red'}}/> :
                                            <HeartOutlined style={{color: 'red'}}/>
                                        }
                                        type="link"
                                        style={{marginLeft: '15px', marginRight: '-3px'}}
                                    />
                                    <p style={{marginRight: '15px'}}>{item.like_cnt}</p>
                                    <EyeOutlined style={{marginRight: '6px'}}/>
                                    <p style={{marginRight: '10px'}}>{item.view_cnt}</p>
                                    <CommentOutlined style={{marginRight: '5px'}}/>
                                    <p style={{marginRight: '8px'}}>{item.comment_cnt}</p>
                                    <MoreOutlined style={{marginRight: '4px', marginLeft: '4px', cursor: 'pointer'}}/>
                                </div>
                            }
                            onClick={(event) => {
                                window.$agRendererTemplate.handleTitleClick(
                                    item.board_no,
                                    item.doc_no,
                                    item.title,
                                    item.currentCount,
                                    event
                                );
                            }}
                            style={{ height: '250px', cursor: 'pointer', overflow: 'hidden' }}
                        >
                            {thumbnail[index] ? (
                                <img
                                    src={thumbnail[index]}
                                    alt="썸네일"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <span style={{
                                    color: '#999',
                                    fontSize: '14px',
                                    width: '100%',
                                    objectFit: 'cover'
                                }}>썸네일이 없습니다</span>
                            )}
                        </Card>
                    </List.Item>
                )}
            />
        );
    }, [boardListState.items, thumbnail, likedStatus, toggleLike]);

    // 검색 조건이 변경될 때만 실행되도록 의존성 배열 최적화
    useEffect(() => {
        const savedSearchCondition = loadSearchCondition();
        const newBoardNo = queryBoardNo || boardListState.query.board_no;

        const newQuery = {
            ...savedSearchCondition,
            board_no: newBoardNo,
            offset: 0,
            limit: 20,
        };

        setBoardListState(prev => ({ ...prev, query: newQuery }));
        getBoardList(newQuery);

        form.setFieldsValue({
            keyword_type: newQuery.keyword_type,
            keyword_text: newQuery.keyword_text,
        });

        if (queryBoardTitle) {
            setCurrentBoardTitle(queryBoardTitle);
            localStorage.setItem('board_title', queryBoardTitle);
        }
        localStorage.setItem('board_no', newBoardNo);

    }, [queryBoardNo, queryBoardTitle, form, getBoardList, loadSearchCondition]);

    // 좋아요 상태는 보드 리스트가 업데이트될 때만 가져오도록 수정
    useEffect(() => {
        getLikes();
    }, [getLikes, boardListState.items]);

    // 그리드 데이터 업데이트
    useEffect(() => {
        if (boardGridRef.current) {
            boardGridRef.current.setGridData(boardListState.items);
        }
    }, [boardListState.items]);

    // 컴포넌트 언마운트 시 검색 조건 저장
    useEffect(() => {
        return () => {
            saveSearchCondition(boardListState.query);
        };
    }, [boardListState.query, saveSearchCondition]);

    // 디스플레이 모드 변경 시 업데이트
    useEffect(() => {
        setActiveSegKey(dsp_meth === '1' ? 'List' : 'Card');
    }, [dsp_meth]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <Layout>
                <Header style={{fontSize: '40px', background: 'none', padding: '0', height: 'unset'}}>
                    {currentBoardTitle || '게시판 제목이 없습니다.'}
                    <Form form={form} layout="inline" onFinish={handleSearchBoard}>
                        <Form.Item name="keyword_type">
                            <Select style={{minWidth: '100px'}} options={boardSearch}/>
                        </Form.Item>
                        <Form.Item name="keyword_text">
                            <Input placeholder="게시판명을 입력해주세요." style={{minWidth: '300px'}}/>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            검색
                        </Button>
                        <Button onClick={handleWriteClick} style={{position: 'absolute', right: '50px'}}>
                            작성
                        </Button>
                    </Form>
                </Header>
                <Content style={{marginTop: '15px'}}>
                    <div style={{marginBottom: '10px', fontSize: '16px'}}>
                        총 {boardListState.totalCount} 개
                    </div>
                    {activeSegKey === 'List' ? <ListComponent /> : <CardComponent />}
                </Content>
            </Layout>
        </>
    );
};

export default React.memo(BoardList);
import React, { useState } from 'react';
import { Checkbox, Row, Col, Button, Avatar, List, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ADMIN_USER_EDIT, BOARD_HOME, PROMPT_DETAIL, PROMPT_MANAGEMENT } from '@routes/pathName';
import qs from 'qs';
import * as api from '@api/index';
import { updatePromptView } from '@api/index';
import thumbnail from '../../assets/images/common/thumb.png';
import userIcon2 from '@assets/images/common/user_icon_2.png';

const CardItem = ({ cardItem }) => {
  const navigate = useNavigate();

  // 상세로 이동
  const moveToDetail = (promptId) => {
    navigate(PROMPT_DETAIL, {
      state: { prpt_id: promptId },
    });
    // navigate(`${PROMPT_DETAIL}?${qs.stringify({ prpt_id: promptId })}`);
    updatePromptView(promptId, 'V'); // 조회 카운트 update
  };

  const updatePromptView = (promptId, viewType) => {
    //  조회 타입 'D'/'P'/'V' (다운로드/플레이/조회)
    api.updatePromptView({ prpt_id: promptId, view_type: viewType });
  };

  return (
    <>
      {/*<Card*/}
      {/*  onDoubleClick={() => moveToDetail(cardItem.prpt_id)}*/}
      {/*  hoverable*/}
      {/*  cover={*/}
      {/*    <>*/}
      {/*      <Row*/}
      {/*        style={{*/}
      {/*          position: 'absolute',*/}
      {/*          top: '5px',*/}
      {/*          left: '5px',*/}
      {/*          zIndex: 1,*/}
      {/*          color: 'white',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <Avatar src={cardItem.icon_path ? cardItem.icon_path : ''} size="small" />*/}
      {/*        <span style={{ marginLeft: '8px' }}>{cardItem.ai_model_nm}</span>*/}
      {/*      </Row>*/}
      {/*      <img*/}
      {/*        alt="example"*/}
      {/*        src={cardItem.thum_path ? cardItem.thum_path : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStqQBAUYBAfwyvGCTBMkq6e3XTWiA5MPXBRg&s'}*/}
      {/*        style={{ width: '100%' }}*/}
      {/*      />*/}
      {/*    </>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <Row>*/}
      {/*    <Col>{cardItem.prpt_title}</Col>*/}
      {/*  </Row>*/}
      {/*  <Row gutter={10}>*/}
      {/*    <Col>좋아요 : {cardItem?.like_cnt}</Col>*/}
      {/*    <Col>판매수 : {cardItem?.sales_cnt}</Col>*/}
      {/*    <Col>금액 : {cardItem.sale_amt ? cardItem.sale_amt : 0}</Col>*/}
      {/*  </Row>*/}
      {/*</Card>*/}

      <Card
        style={{
          border: `none`,
          height: `100%`,
        }}
        bodyStyle={{
          padding: `0`,
          boxShadow: `none`,
          transition: `0s`,
        }}
        onDoubleClick={() => moveToDetail(cardItem.prpt_id)}
      >
        <div className="list flexColCenter">
          <div className="flexColCenter">
            <img src={cardItem.thum_path ? cardItem.thum_path : thumbnail} />

            <div className="flexColBetween absoluteBox">
              <div className="flexRowBetween user">
                <div className="flexRowCenter">
                  <img src={cardItem.icon_path ? cardItem.icon_path : userIcon2} />
                  <p>{cardItem.ai_model_nm}</p>
                </div>
              </div>

              <div className="flexColStart title">
                <div className="greyBox flexRowBetween">
                  <p>{cardItem.prpt_title}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default CardItem;

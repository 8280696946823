import React, { useEffect, useRef, useState } from 'react';
import { Menu } from 'antd';

const ContextMenu = ({ menuPosition, menuList }) => {
  /**
   * menuPosition : {x: x좌표, y: y좌표}
   * munuList: [ {id: 메뉴Id, name: 메뉴명, callback: 클릭시 호출함수}, .... ]
   * */
  const contextMenuRef = useRef();
  const [menuVisible, setMenuVisible] = useState(false);

  // 클릭시
  const handleMenuClick = (e) => {
    const selectedMenu = menuList.find((menu) => menu.id === e.key);
    selectedMenu?.callback();
    setMenuVisible(false); // 메뉴 항목 클릭 후 메뉴 숨김
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const menuItems = menuList.map((menu) => ({
    key: menu.id,
    label: menu.name,
  }));

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }, [menuList]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      // 메뉴 외부 클릭 여부 확인
      if (contextMenuRef.current && !contextMenuRef.current.menu.list.contains(e.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuVisible]);

  // menuVisible false : 랜더링 X
  if (!menuVisible) return null;

  return (
    <Menu
      ref={contextMenuRef}
      onClick={handleMenuClick}
      mode="inline"
      items={menuItems}
      style={{
        position: 'absolute',
        top: menuPosition.y,
        left: menuPosition.x,
        backgroundColor: 'white',
        boxShadow: '0px 0px 20px 0px rgba(34, 34, 34, 0.1)',
        zIndex: 10,
        width: 100,
      }}
    />
  );
};

export default ContextMenu;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ProfileHome from '@pages/profile/ProfileHome';
import ProfileDetail from '@pages/profile/ProfileDetail';
import PlanInfo from '@pages/profile/PlanInfo';
import NoticeSetting from '@pages/profile/NoticeSetting';
import Ranking from '@pages/profile/Ranking';
import BlockedUsers from '@pages/profile/BlockedUser';
import Tabs from '@components/Tabs';
import * as api from '@api/index';
import {getLocalStorage} from "@utils/lib";

const ProfilePage = () => {
  const location = useLocation();
  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const memPlan = getLocalStorage('prptbk-token', 'plan_cd');

  useEffect(() => {
    // URL 파라미터 읽기
    const paymentData = location.state;
    if (Object.keys(paymentData).length !== 0) {
      api.updateMembershipAfterPayment(paymentData);
      // URL 정리
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, []);

  const tabs = [
    { id: 'home', label: '홈', component: <ProfileHome overrideData={location.state} /> },
    { id: 'profileDetail', label: '내 프로필', component: <ProfileDetail overrideData={location.state} /> },
    { id: 'planInfo', label: '플랜 정보', component: <PlanInfo /> },
    { id: 'noticeSetting', label: '알림 설정', component: <NoticeSetting /> },
    { id: 'ranking', label: '랭킹', component: <Ranking /> },
    { id: 'blocked', label: '차단', component: <BlockedUsers /> },
  ];
  const filteredTabs = () => {
    if (location.state?.mem_key === memKey) {
      // mem_key 일치
      return tabs;
    } else if (memPlan === 'R001') {
      // mem_key 불일치 + memPlan이 'R001'
      return tabs.filter(tab => !['noticeSetting', 'ranking', 'blocked'].includes(tab.id));
    } else {
      // mem_key 불일치
      return tabs.filter(tab => tab.id === 'home');
    }
  };

  return <Tabs tabs={window.location.pathname.includes('admin') ? tabs.slice(0, 2) : filteredTabs()} />;
};


export default ProfilePage;

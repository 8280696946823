import { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Layout, Form, Input, Button, Row, Col } from 'antd';
import Table from '@components/Table';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { makeDataFormat, useDebounce } from '@utils/lib';
import { boardCategoryList, boardDisp, selectYN } from '@utils/constants';
import ButtonExport from '@components/ButtonExportAUIGrid';

const { Content, Header } = Layout;

const IntegratedBoard = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const imgRef = useRef();
  const [recentItem, setRecentItem] = useState(null);
  const [fileCaches, setFileCaches] = useState([]);
  const [delFileCaches, setDelFileCaches] = useState([]);
  const [boardAuth, setBoardAuth] = useState([]);
  const [boardListState, setBoardListState] = useState({
    query: {
      keyword_text: '',
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });
  const integratedBoardGridRef = useRef();
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const integratedBoardGridProps = {
    showRowCheckColumn: true,
  };

  // 이미지 업로드 처리 함수
  const imgUpload = (event) => {
    let file = event.target.files[0];
    const tempUrl = URL.createObjectURL(file);
    setFileCaches((prevFileCaches) => {
      const filteredItems = prevFileCaches.filter((item) => item.id !== recentItem._$uid);
      return [...filteredItems, { id: recentItem._$uid, file, tempUrl }];
    });

    setDelFileCaches(Array.from(new Set([...delFileCaches, recentItem.board_img_path])));

    integratedBoardGridRef.current.updateRowsById({
      _$uid: recentItem._$uid,
      board_img_path: tempUrl,
      board_img_nm: file.name,
    });
    imgRef.current.value = ''; // Reset
  };

  // 이미지 삭제 함수
  window.imgDelete = (imgPath, rowIndex) => {
    setDelFileCaches(Array.from(new Set([...delFileCaches, imgPath])));
    if (imgPath.startsWith('blob:')) {
      setFileCaches((prevFileCaches) => prevFileCaches.filter((item) => item.tempUrl !== imgPath));
      URL.revokeObjectURL(imgPath); // Free up memory
    }
    integratedBoardGridRef.current.updateRows({ board_img_path: null, board_img_nm: null }, rowIndex);
  };

  const integratedBoardColumnLayout = [
    { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
    {
      dataField: 'board_cate',
      headerText: '카테고리',
      width: '6%',
      renderer: { type: 'DropDownListRenderer', list: boardCategoryList, keyField: 'value', valueField: 'label' },
    },
    { dataField: 'board_title', headerText: '게시판 명', width: '12%' },
    { dataField: 'board_desc', headerText: '게시판 설명', width: '22%' },
    { dataField: 'dsp_seq', headerText: '표시순', width: '4%', dataType: 'numeric' },
    {
      dataField: 'dsp_meth',
      headerText: '표시 방법',
      width: '8%',
      renderer: { type: 'DropDownListRenderer', list: boardDisp, keyField: 'value', valueField: 'label' },
    },

    {
      headerStyle: 'required-custom-header',
      headerText: '게시판 썸네일',
      children: [
        {
          dataField: 'board_img_path',
          headerText: '게시판 이미지',
          width: '15%',
          headerStyle: 'required-custom-header',
          renderer: { type: 'TemplateRenderer' },
          labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
            let template = '';
            if (value) {
              const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
              template += `<img src="${imgSrc}" alt="board" style="height:25px; max-width:100%;">`;
              template += '<span onClick="imgDelete(\'' + item.board_img_path + "'," + rowIndex + ')" style="position: absolute; top: 0px; right: 5px; cursor: pointer;">X</span>';
            } else {
              template += '<span>No image</span>';
            }
            return template;
          },
          editable: false,
        },
        {
          dataField: 'board_add_btn',
          headerText: '추가',
          width: '5%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'ButtonRenderer',
            labelText: '파일 선택',
            onclick: function (rowIndex, columnIndex, value, item) {
              setRecentItem(item);
              imgRef?.current?.click();
            },
          },
          editable: false,
        },
      ],
    },
    { dataField: 'board_img_nm', headerText: '게시판 이미지명', width: '10%', visible: false },
    {
      dataField: 'use_lv',
      headerText: '읽기 권한',
      width: '5%',
      renderer: {
        type: 'DropDownListRenderer',
        list: Object.entries(boardAuth).map(([key, value]) => ({ plan_cd: key, plan_nm: value })),
        keyField: 'plan_cd',
        valueField: 'plan_nm',
      },
    },
    {
      dataField: 'write_lv',
      headerText: '쓰기 권한',
      width: '5%',
      renderer: {
        type: 'DropDownListRenderer',
        list: Object.entries(boardAuth).map(([key, value]) => ({ plan_cd: key, plan_nm: value })),
        keyField: 'plan_cd',
        valueField: 'plan_nm',
      },
    },
    {
      dataField: 'menu_yn',
      headerText: '메뉴',
      width: '4.5%',
      renderer: { type: 'DropDownListRenderer', list: selectYN, keyField: 'value', valueField: 'label' },
    },
    {
      dataField: 'attach_yn',
      headerText: '첨부',
      width: '4.5%',
      renderer: { type: 'DropDownListRenderer', list: selectYN, keyField: 'value', valueField: 'label' },
    },
    {
      dataField: 'reply_yn',
      headerText: '댓글',
      width: '4.5%',
      renderer: { type: 'DropDownListRenderer', list: selectYN, keyField: 'value', valueField: 'label' },
    },
    {
      dataField: 'use_yn',
      headerText: '사용',
      width: '4.5%',
      renderer: { type: 'DropDownListRenderer', list: selectYN, keyField: 'value', valueField: 'label' },
    },
  ];

  const setupGridEvents = useCallback(() => {
    if (integratedBoardGridRef.current) {
      integratedBoardGridRef.current.bind('vScrollChange', (event) => {
        handleDebounce(() => setBoardListState((prev) => ({ ...prev, yScrollPosition: event.position })));
        if (event.position === event.maxPosition && boardListState.currentCount < boardListState.totalCount) {
          handleScroll();
        }
      });
    }
  }, [handleDebounce, boardListState.currentCount, boardListState.totalCount]);

  const handleScroll = () => {
    const newQuery = { ...boardListState.query, offset: boardListState.query.offset + boardListState.query.limit };
    setBoardListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getIntegratedBoardList(newQuery));
  };

  const getIntegratedBoardList = useCallback(
    async (query) => {
      try {
        setIsLoading(true);
        const { data } = await api.getIntegratedBoardList(query);
        if (!data) return;

        setBoardListState((prev) => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));

        if (integratedBoardGridRef.current) {
          integratedBoardGridRef.current.setGridData(data.items);
        }
        setupGridEvents();
      } catch (error) {
        message.warning(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setupGridEvents],
  );

  // 멤버십 플랜 조회
  const getPlanInfo = async () => {
    try {
      const response = await api.getPlanInfo();
      const planList = makeDataFormat(response?.data, 'plan_nm', 'plan_cd');
      const updatedPlanList = {
        PLAN000: '전체',
        PLAN999: '관리자',
        ...planList,
      };
      setBoardAuth(updatedPlanList);
    } catch (error) {
      console.log('멤버십 조회에러', error);
    }
  };

  const handleSearchBoard = ({ keyword_text }) => {
    const newQuery = { ...boardListState.query, keyword_text, offset: 0 };
    setBoardListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getIntegratedBoardList(newQuery));
  };

  const handleAddRow = () => {
    integratedBoardGridRef.current.addRow({}, 'last');
  };

  // 추가, 수정, 삭제
  const updatedIntegratedBoard = async (type) => {
    let saveItems = integratedBoardGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    let despSeqItems = integratedBoardGridRef.current.getGridData();
    let delItems = integratedBoardGridRef.current.getCheckedRowItems().map(({ item }) => {
      return { ...item, state: 'del' };
    });

    const duplicateDspSeqs = validDspSeqDup(despSeqItems);
    if (duplicateDspSeqs.length > 0) {
      alert(`중복된 표시순이 있습니다: ${duplicateDspSeqs.join(', ')}`);
      return false;
    }

    if (type === 's') {
      if (saveItems.length < 1) {
        alert('저장할 데이터가 없습니다.');
        return false;
      }

      const isValid = integratedBoardGridRef.current.validateChangedGridData(
        ['board_cate', 'board_title', 'board_desc', 'dsp_seq', 'dsp_meth', 'use_lv', 'write_lv', 'menu_yn', 'attach_yn', 'reply_yn', 'use_yn'],
        '비어있는 필드가 있습니다.',
      );
      if (!isValid) return false;

      try {
        setIsLoading(true);
        const formData = new FormData();
        if (fileCaches.length > 0) {
          fileCaches.map((fileObj) => {
            formData.append('files', fileObj.file);
            formData.append('fileIds', fileObj.id);
          });
        }
        if (delFileCaches.length > 0) {
          delFileCaches.map((delFile) => {
            formData.append('delfiles', delFile);
          });
        }
        console.log(saveItems);
        formData.append('updateBoardList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));
        const response = await api.updatedIntegratedBoard(formData);
        alert(response.data.returnMessage, response.data.returnStatus === 'fail' ? 'error' : 'success');
      } catch (e) {
        console.log('통합게시판 저장 error', e);
      } finally {
        setIsLoading(false);
        getIntegratedBoardList(boardListState.query);
      }
    } else if (type === 'd') {
      if (delItems.length < 1) {
        alert('삭제할 데이터가 없습니다.');
        return false;
      }

      if (delItems.length > 0) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append('updateBoardList', new Blob([JSON.stringify(delItems)], { type: 'application/json' }));
          await api.updatedIntegratedBoard(formData);
          alert(response.data.returnMessage, response.data.returnStatus === 'fail' ? 'error' : 'success');
        } catch (e) {
          console.log('통합게시판 삭제 error', e);
        } finally {
          setIsLoading(false);
          getIntegratedBoardList(boardListState.query);
        }
      } else {
        alert('삭제할 항목이 없습니다.');
      }
    }
  };

  // 중복 체크
  const validDspSeqDup = (data) => {
    const countMap = new Map();
    const duplicates = new Set();

    data.forEach((item) => {
      const dspSeq = item.dsp_seq;
      countMap.set(dspSeq, (countMap.get(dspSeq) || 0) + 1);
    });

    countMap.forEach((count, dspSeq) => {
      if (count > 1) {
        duplicates.add(dspSeq);
      }
    });

    return Array.from(duplicates); // 중복된 값 반환
  };

  useEffect(() => {
    getPlanInfo();
    getIntegratedBoardList(boardListState.query);
  }, [location.pathname]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
          <Form form={form} layout="inline" initialValues={boardListState.query} onFinish={handleSearchBoard}>
            <input type="file" accept="image/*" ref={imgRef} onChange={imgUpload} style={{ display: 'none' }} />
            <Form.Item name="keyword_text">
              <Input placeholder="게시판명을 입력해주세요." style={{ minWidth: '300px' }} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              검색
            </Button>
            <ButtonExport ref={integratedBoardGridRef} fileName="통합게시판 관리" text="엑셀다운" />
          </Form>
          <Row>
            <Row>검색결과</Row>
            <Row style={{ marginLeft: '10px' }}>{`총 ${boardListState.totalCount}건`}</Row>
            <Row>
              <Button onClick={handleAddRow}>추가</Button>
              <Button style={{ marginLeft: '3px' }} type="primary" htmlType="submit" onClick={() => updatedIntegratedBoard('s')}>
                저장
              </Button>
              <Button style={{ marginLeft: '3px' }} htmlType="submit" onClick={() => updatedIntegratedBoard('d')} danger>
                삭제
              </Button>
            </Row>
          </Row>
        </Header>
        <Content style={{ marginTop: '5px' }}>
          <Table ref={integratedBoardGridRef} columnLayout={integratedBoardColumnLayout} customGridProps={integratedBoardGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default IntegratedBoard;

import React from 'react';
import { Col } from 'antd';

const ColumnStatus = ({ type = 'default', text, style: customStyle }) => {
  return (
    <Col
      style={{
        backgroundColor: {
          default: '#525162',
          warning: '#F8C930',
          danger: '#FE393C',
        }[type],
        color: '#ffffff',
        padding: '6px 14px',
        borderRadius: '2rem',
        ...customStyle,
      }}
    >
      {text}
    </Col>
  );
};

export default ColumnStatus;

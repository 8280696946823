// 메인이지
export const MAIN = '/';
// 로그인
export const LOGIN = '/login';
export const LOGIN_SOCIAL = '/social-login';
export const FORGET_ACCOUNT = '/forgetAccount';
export const FORGET_EMAIL = '/forgetEmail';
export const FORGET_PASSWORD = '/forgetPassword';
export const NEW_PASSWORD = '/newPassword';

// 유저 관리
export const SIGNUP = '/signup';

// 음악
export const MUSIC_HOME = '/music';
export const MUSIC_PLAYER = '/music/player';

// 이미지
export const IMAGE_HOME = '/image';

// 영상
export const VIDEO_HOME = '/video';

// 텍스트
export const TEXT_HOME = '/text';

// 커뮤니티
export const COMMUNITY_HOME = '/community';

/** 마이페이지 */
export const MYPAGE_HOME = '/mypage';
export const MYPAGE_PROFILE = '/mypage/profile';

// 사업자 등록 팝업
export const BUSINESS_REGIST = '/mypage/businessRegist';
// 프롬프트 상세
export const PROMPT_DETAIL = '/mypage/PromptDetail';

// Admin
export const ADMIN_HOME = '/admins';
export const ADMIN_USER_LIST = '/admins/user';
export const ADMIN_USER_DETAIL = '/admins/user/detail';
export const ADMIN_USER_EDIT = '/admins/user/edit';
export const ADMIN_RANKING_HOME = '/admins/rainkings';
export const ADMIN_MODEL_HOME = '/admins/models';
export const ADMIN_BUILDER_HOME = '/admins/elementsManagement/builder';
export const ADMIN_CATEGORY_HOME = '/admins/elementsManagement/category';
export const ADMIN_LIBRARY_HOME = '/admins/elementsManagement/library';
export const ADMIN_GROUP_HOME = '/admins/elementsManagement/group';
export const ADMIN_PARAM_HOME = '/admins/params';
export const COMMON_CODE_HOME = '/admins/commonCodes';
export const BASE_DATA_HOME = '/admins/baseData';
export const ADMIN_PLAN_HOME = '/admins/plans';
export const EVENT_COUPON_HOME = '/admins/eventCoupon';
export const INTEGRATED_BOARD_HOME = '/admins/integratedBoard';
export const PROMPT_REVIEW_HOME = '/admins/promptReview';
export const BANNER_HOME = '/admins/banner';
export const BOARD_HOME = '/boards/boardList';
export const BOARD_EDIT = '/boards/boardEdit';
export const BOARD_DETAIL = '/boards/boardDetail';
export const ADMIN_PAY_LIST = '/admins/pay';
export const ADMIN_ALARM_HOME = '/admins/alarm';
export const ADMIN_CHARGE_MANAGEMENT_HOME = '/admins/chargeManagement';
export const ADMIN_PAYMENT_MANAGEMENT_HOME = '/admins/paymentManagement';
export const ADMIN_REFUND_HOME = '/admins/refund';
export const ADMIN_INQUIRY_HOME = '/admins/inquiry';
export const ADMIN_INQUIRY_DETAIL = '/admins/inquiry/detail';

export const CHAT_HOME = '/chat/chatLayout';
export const PROFILE_PAGE = '/profile/ProfilePage';
export const PAYMENT_WIDGET = '/payment/widget';
export const PAYMENT_SUCCESS = '/payment/success';
export const PAYMENT_FAILURE = '/payment/failure';
export const SELLER_LIST = '/admins/sellerList';

export const PROMPT_HOME = '/prompt/*';
// 프롬프트 관리
export const PROMPT_MANAGEMENT = '/prompt/management';
// 프롬프트 생성
export const CREATE_PROMPT = '/prompt/create';

export const INQUIRY_HOME = '/mypage/inquiry/*';
export const INQUIRY_EDIT = '/mypage/inquiry/edit';
export const INQUIRY_LIST = '/mypage/inquiry/list';
export const INQUIRY_DETAIL = '/mypage/inquiry/list/detail';

export const AI_CERT = '/admins/aicertManagement';

// 판매 프롬프트
export const SALES_PROMPT_HOME = '/mypage/salesPrompt';
// 내 마켓플레이스
export const MY_MARKET_PLACE = '/mypage/myMarketplaces';

// 마켓플레이스
export const MY_MARKET_HOME = '/myMarketplaces/*';
export const MARKET_PURCHASES_HOME = '/myMarketplaces/purchases';
export const MARKET_SALES_HOME = '/myMarketplaces/sales';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Button, Card, Col, Descriptions, Form, Image, Input, message, Row, Select, Upload } from 'antd';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import Divider from '@components/samples/DividerList';
import * as api from '@api/index';
import { getLocalStorage, makeDataFormat, makeSelectBoxList, phoneFormat } from '@utils/lib';
import AiModelCertModal from '@components/AiModelCertModal';

const memKey = getLocalStorage('prptbk-token', 'mem_key');

const CONSTANTS = {
  INIT_STATE_USER_DETAIL_SEARCH: {
    mem_email: '',
    mem_key: memKey,
  },
  INIT_STATE_EDIT_INFO: {
    mem_email: '',
    mem_nick: '',
    mem_tel: '',
    web: '',
    twitter_link: '',
    youtube_link: '',
    about_me: '',
    loc_cd_1: '',
    loc_cd_2: '',
    group: '',
    mem_div: '',
    plan_nm: '',
    mem_stat: '',
    fir_dt: '',
    cnl_dt: '',
    login_dt: '',
    cnl_rsn_cd: 'CNL_RSN001',
    cnl_rsn: '',
    desc: '',
    ai_fl_cd: '',
    ai_fl_nm: '',
  },
  IMAGE_DIMENSIONS: {
    PROFILE: { width: 140, height: 140 },
    INTRO: { maxHeight: 300 },
  },
};

const useCommonCodes = () => {
  const [codes, setCodes] = useState({
    memStatFormat: {},
    memDivFormat: {},
    cnlRsnCdFormat: {},
    loc1List: [],
    loc2List: [],
    aiFormat: {},
    aiList: [],
  });

  const fetchCommonCodes = useCallback(async () => {
    try {
      const [memStat, memDiv, cnlRsn, loc1, model] = await Promise.all([
        api.fetchCommonDetailCode({ grp_cd: 'MEM_STAT' }),
        api.fetchCommonDetailCode({ grp_cd: 'MEM_DIV' }),
        api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' }),
        api.fetchCommonDetailCode({ grp_cd: 'LOC01' }),
        api.fetchCommonDetailCode({ grp_cd: 'MODEL' }),
      ]);

      setCodes({
        memStatFormat: makeDataFormat(memStat?.data, 'cd_nm', 'cd'),
        memDivFormat: makeDataFormat(memDiv?.data, 'cd_nm', 'cd'),
        cnlRsnCdFormat: makeDataFormat(cnlRsn?.data, 'cd_nm', 'cd'),
        loc1List: makeSelectBoxList(loc1?.data, 'cd_desc', 'cd'),
        aiFormat: makeDataFormat(model?.data, 'cd_desc', 'cd'),
        aiList: makeSelectBoxList(model?.data, 'cd_desc', 'cd'),
      });
    } catch (error) {
      message.warning('공통코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  const fetchLoc2Format = useCallback(async (value) => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'LOC02', parent_cd: value });
      setCodes((prev) => ({
        ...prev,
        loc2List: makeSelectBoxList(response?.data, 'cd_desc', 'cd'),
      }));
    } catch (error) {
      message.warning('지역 상세 코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  return { codes, fetchCommonCodes, fetchLoc2Format };
};

const AiField = React.memo(({ isEditMode, aiList, selectedAis, handleAiSelect, handleAiRemove }) => {
  return (
    <Row>
      <Col flex="150px">
        <Col>전문 AI 분야</Col>
      </Col>
      <Col flex="auto" style={{ paddingRight: '10px' }}>
        <Row gutter={16}>
          {isEditMode && (
            <Col span={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  height: '34px',
                  width: '221px',
                }}
              >
                <span>AI 분야 선택</span>
                <span style={{ color: '#3182F6', fontSize: '12px' }}>중복 선택 가능</span>
              </div>
              <Form.Item name="ai_fi_nm" style={{ margin: '0', width: '100%' }}>
                <Select placeholder="AI 분야 선택(중복)" style={{ width: '100%' }} options={aiList} onChange={handleAiSelect} />
              </Form.Item>
            </Col>
          )}
          <Col span={20}>
            <Row gutter={[8, 8]}>
              {selectedAis.map((ai) => (
                <Col key={ai.value}>
                  <Card
                    style={{
                      borderRadius: '6px',
                      textAlign: 'center',
                      background: '#F8FAFC',
                      border: '1px solid #E2E8F0',
                      padding: '10px 8px',
                      margin: '0 4px',
                      lineHeight: '20px',
                      height: 'fit-content',
                      minHeight: '40px',
                    }}
                    bodyStyle={{
                      padding: '0',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                      <Col flex="auto">{ai.label}</Col>
                      {isEditMode && (
                        <Col>
                          <Button
                            icon={<CloseOutlined style={{ fontSize: '12px' }} />}
                            onClick={() => handleAiRemove(ai.value)}
                            type="text"
                            style={{
                              padding: '0 0 0 4px',
                              height: '20px',
                              lineHeight: '20px',
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

const Profile = ({ overrideData = {} }) => {
  const [form] = Form.useForm();
  const { search } = useLocation();
  const params = useMemo(
    () => ({
      ...CONSTANTS.INIT_STATE_USER_DETAIL_SEARCH,
      ...qs.parse(search?.substring(1) || ''),
      ...overrideData,
    }),
    [search],
  );
  const { codes, fetchCommonCodes, fetchLoc2Format } = useCommonCodes();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetailData, setUserDetailData] = useState(null);
  const [aiFormat, setAiFormat] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedAis, setSelectedAis] = useState([]);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [introImageFile, setIntroImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [introImageUrl, setIntroImageUrl] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const selectUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.userProfile({ params });
      setUserDetailData(response.data);
      setProfileImageUrl(getImageUrl(response.data?.mem_img_path));
      setIntroImageUrl(getImageUrl(response.data?.ch_img_path));

      if (response.data?.ai_fl_cd && codes.aiList.length > 0) {
        const aiCodes = response.data.ai_fl_cd.split('|');
        const initialSelectedAis = aiCodes
          .map((code) => {
            return codes.aiList.find((item) => item.value === code);
          })
          .filter(Boolean);
        setSelectedAis(initialSelectedAis);
      }

      form.setFieldsValue({
        mem_key: response.data.mem_key,
        mem_email: response.data?.mem_email,
        mem_nick: response.data?.mem_nick,
        mem_tel: response.data?.mem_tel,
        web: response.data?.web,
        youtube_link: response.data?.youtube_link,
        insta_link: response.data?.insta_link,
        x_link: response.data?.x_link,
        about_me: response.data?.about_me,
        about_chanel: response.data?.about_chanel,
        loc_nm_1: response.data?.loc_cd_1,
        loc_nm_2: response.data?.loc_cd_2,
        group: response.data?.group,
        mem_div: response.data?.mem_div,
        plan_nm: response.data?.plan_nm,
        mem_stat: response.data?.mem_stat,
        fir_dt: response.data?.fir_dt,
        cnl_dt: response.data?.cnl_dt,
        login_dt: response.data?.login_dt,
        cnl_rsn_cd: response.data?.cnl_rsn_cd,
        cnl_rsn: response.data?.cnl_rsn,
        desc: response.data?.desc,
        ai_fl_cd: response.data?.ai_fl_cd,
        ai_fl_nm: response.data?.ai_fl_nm,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return '';
    return `${imagePath}`;
  };

  const renderField = (name, value, inputProps = {}) => {
    if (isEditMode) {
      return (
        <Form.Item name={name} style={{ margin: '0', width: '100%' }}>
          {name === 'about_chanel' ? (
            <Input.TextArea rows={4} placeholder="상세 소개" value={value} {...inputProps} />
          ) : (
            <Input
              {...inputProps}
              {...(name === 'mem_tel' && {
                onChange: () => {
                  handlePhoneNumberChange();
                },
              })}
            />
          )}
        </Form.Item>
      );
    }
    return <span>{value}</span>;
  };

  const renderSelect = (name, value, options, selectProps = {}) => {
    if (isEditMode) {
      return (
        <Form.Item name={name} style={{ margin: '0', width: '100%' }}>
          <Select
            options={options}
            {...selectProps}
            {...(name === 'loc_nm_1' && {
              onChange: (value) => {
                fetchLoc2Format(value);
                form.setFieldsValue({ loc_nm_2: undefined });
              },
            })}
            value={name === 'loc_nm_2' ? userDetailData?.loc_cd_2 : value}
          />
        </Form.Item>
      );
    }
    return <span>{value}</span>;
  };

  const renderUpload = (name) => {
    if (name === 'profile_image') {
      return (
        <div>
          <div style={{ marginTop: '40px' }}>
            {isEditMode ? (
              <div style={{ position: 'relative' }}>
                {profileImageUrl && (
                  <div
                    style={{
                      marginBottom: '16px',
                      width: '140px',
                      height: '140px',
                      overflow: 'hidden',
                    }}
                  >
                    <Image
                      src={profileImageUrl}
                      alt="Profile Preview"
                      style={{
                        width: '140px',
                        height: '140px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                )}
                <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile} style={{ margin: '0', width: '100%' }}>
                  <Upload
                    action=""
                    maxCount={1}
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={(file) => {
                      setProfileImageFile(file);
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setProfileImageUrl(reader.result);
                      };
                      reader.readAsDataURL(file);
                      return false;
                    }}
                  >
                    <div style={{ padding: '8px' }}>
                      <PlusOutlined style={{ fontSize: '20px' }} />
                      <div style={{ marginTop: 8, fontSize: '12px' }}>{profileImageUrl ? '이미지 변경' : '이미지 등록'}</div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            ) : profileImageUrl ? (
              <Image
                src={profileImageUrl}
                alt="Profile Preview"
                style={{
                  width: '140px',
                  height: '140px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <div>등록된 이미지가 없습니다.</div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: '100%' }}>
          {isEditMode ? (
            <div>
              {introImageUrl && (
                <div style={{ marginBottom: '16px', maxWidth: '100%', maxHeight: '300px', overflow: 'hidden' }}>
                  <Image
                    src={introImageUrl}
                    alt="Intro Preview"
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
              <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile} style={{ margin: '0', width: '100%' }}>
                <Upload
                  action=""
                  maxCount={1}
                  listType="picture"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setIntroImageFile(file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setIntroImageUrl(reader.result);
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <Button icon={<PlusOutlined />}>{introImageUrl ? '이미지 변경' : '이미지 등록하기'}</Button>
                  <div style={{ marginTop: 8, color: '#666' }}>이미지를 등록하거나 드래그앤 드롭해 주세요.</div>
                </Upload>
              </Form.Item>
            </div>
          ) : introImageUrl ? (
            <Image
              src={introImageUrl}
              alt="Intro Preview"
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'cover',
              }}
            />
          ) : (
            <div>등록된 이미지가 없습니다.</div>
          )}
        </div>
      );
    }
  };

  const renderAiFl = () => <AiField isEditMode={isEditMode} aiList={codes.aiList} selectedAis={selectedAis} handleAiSelect={handleAiSelect} handleAiRemove={handleAiRemove} />;

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 추출
    let formattedValue = '';

    if (value.length <= 3) {
      formattedValue = value;
    } else if (value.length <= 7) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length <= 11) {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
    } else {
      formattedValue = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
    }

    form.setFieldValue('mem_tel', value);
    setPhoneNumber(formattedValue);
  };

  const handleAiSelect = (value) => {
    const selectedOption = codes.aiList.find((ai) => ai.value === value);
    if (selectedOption && !selectedAis.some((ai) => ai.value === value)) {
      setSelectedAis([...selectedAis, selectedOption]);
    }
  };

  const handleAiRemove = (value) => {
    setSelectedAis(selectedAis.filter((ai) => ai.value !== value));
  };

  const handleCancel = () => {
    setIsEditMode(false);
    selectUser();
    setProfileImageUrl(getImageUrl(userDetailData?.mem_img_path));
    setIntroImageUrl(getImageUrl(userDetailData?.ch_img_path));
    setProfileImageFile(null);
    setIntroImageFile(null);
    form.setFieldsValue(userDetailData);
  };

  const handleSave = useCallback(async () => {
    try {
      const values = await form.validateFields();

      if (!hasChanges()) {
        message.info('변경된 내용이 없습니다.');
        return;
      }

      const formData = new FormData();
      const params = {
        ...values,
        mem_key: 2,
        mem_tel: phoneNumber.replace(/[^0-9]/g, ''),
        loc_cd_1: values.loc_nm_1,
        loc_cd_2: values.loc_nm_2,
        ai_fl_cd: selectedAis.map((ai) => ai.value).join('|'),
      };

      if (profileImageFile) {
        formData.append('files', profileImageFile);
        params.mem_img_path = profileImageFile.name;
      }

      if (introImageFile) {
        formData.append('files', introImageFile);
        params.ch_img_path = introImageFile.name;
      }

      formData.append(
        'memberVO',
        new Blob([JSON.stringify(params)], {
          type: 'application/json',
        }),
      );

      const response = await api.updateProfile(formData);

      if (response.status === 200) {
        message.success('저장되었습니다.');
        handleCancel();
      }
    } catch (error) {
      message.error('저장 중 오류가 발생했습니다.');
      console.error('Save error:', error);
    }
  }, [form, phoneNumber, selectedAis, profileImageFile, introImageFile]);

  const hasChanges = () => {
    const currentValues = form.getFieldsValue();
    const hasFormChanges = Object.keys(currentValues).some((key) => {
      if (key === 'profile_image' || key === 'intro_image') return false;
      return currentValues[key] !== userDetailData?.[key];
    });

    const hasProfileImageChange = profileImageFile !== null;
    const hasIntroImageChange = introImageFile !== null;

    const originalAiCodes = userDetailData?.ai_fl_cd ? userDetailData.ai_fl_cd.split('|') : [];
    const currentAiCodes = selectedAis.map((ai) => ai.value);
    const hasAiChanges = JSON.stringify(originalAiCodes.sort()) !== JSON.stringify(currentAiCodes.sort());

    return hasFormChanges || hasProfileImageChange || hasIntroImageChange || hasAiChanges;
  };

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        await fetchCommonCodes();
        await selectUser();
      } catch (error) {
        message.error('데이터 로딩 중 오류가 발생했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [fetchCommonCodes, selectUser]);

  useEffect(() => {
    if (userDetailData?.mem_tel) {
      setPhoneNumber(phoneFormat(userDetailData.mem_tel));
    }
  }, [userDetailData]);

  useEffect(() => {
    const loc_cd_1 = form.getFieldValue('loc_nm_1');
    const loc_cd_2 = userDetailData?.loc_cd_2;

    if (loc_cd_1 && loc_cd_2) {
      fetchLoc2Format(loc_cd_1).then(() => {
        form.setFieldsValue({
          loc_nm_2: loc_cd_2,
        });
      });
    }
  }, [userDetailData?.loc_cd_2]);

  useEffect(() => {
    if (userDetailData?.ai_fl_cd && codes.aiList.length > 0) {
      const aiCodes = userDetailData.ai_fl_cd.split('|');
      const initialSelectedAis = aiCodes.map((code) => codes.aiList.find((item) => item.value === code)).filter(Boolean);
      setSelectedAis(initialSelectedAis);
    }
  }, [userDetailData?.ai_fl_cd, codes.aiList]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Form form={form} layout="vertical" onFinish={handleSave} initialValues={CONSTANTS.INIT_STATE_EDIT_INFO} autoComplete="off">
        <Divider dashed text="기본정보" style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Row style={{ flexFlow: 'unset' }}>
          <Col flex="150px">회원 정보</Col>
          <Col flex="auto" style={{ paddingRight: '10px' }}>
            <Descriptions size="small" layout="vertical" column={6} colon={false}>
              <Descriptions.Item span={3} label="닉네임" style={{ paddingRight: '30px' }}>
                {renderField('mem_nick', userDetailData?.mem_nick)}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="이메일">
                {renderField('mem_email', userDetailData?.mem_email, { disabled: true })}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="연락처" style={{ paddingRight: '30px' }}>
                {isEditMode ? (
                  <Row>
                    <Col flex="auto">
                      <Input value={phoneNumber} onChange={handlePhoneNumberChange} placeholder='"-"를 제외한 숫자만 입력해 주세요.' maxLength={13} />
                    </Col>
                    <Col>
                      <Button style={{ marginLeft: 8 }}>인증요청</Button>
                    </Col>
                  </Row>
                ) : (
                  <Col flex="auto">{renderField('mem_tel', phoneFormat(userDetailData?.mem_tel), { disabled: true })}</Col>
                )}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="위치">
                {isEditMode ? (
                  <Row gutter={8} wrap={false} style={{ width: '100%' }}>
                    <Col span={12} style={{ minWidth: '45%', maxWidth: '50%' }}>
                      {renderSelect('loc_nm_1', userDetailData?.loc_nm_1, codes.loc1List, { placeholder: '도, 시' })}
                    </Col>
                    <Col span={12} style={{ minWidth: '45%', maxWidth: '50%' }}>
                      {renderSelect('loc_nm_2', userDetailData?.loc_nm_2, codes.loc2List, { placeholder: '구' })}
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={8} wrap={false} style={{ width: '100%' }}>
                    <Col span={12} style={{ minWidth: '45%', maxWidth: '50%' }}>
                      {renderField('loc_nm_1', userDetailData?.loc_nm_1, { disabled: true })}
                    </Col>
                    <Col span={12} style={{ minWidth: '45%', maxWidth: '50%' }}>
                      {renderField('loc_nm_2', userDetailData?.loc_nm_2, { disabled: true })}
                    </Col>
                  </Row>
                )}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="비밀번호" style={{ paddingRight: '30px' }}>
                <Button style={{ marginLeft: 8 }}>변경</Button>
              </Descriptions.Item>
              <Descriptions.Item span={3} label="소속 조직">
                {renderField('group', userDetailData?.group, { placeholder: '소속' })}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider text="내 소개" style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Row style={{ flexFlow: 'unset' }}>
          <Col flex="150px">내 소개</Col>
          <Col flex="auto" style={{ paddingRight: '10px' }}>
            <Row>
              <Col span={6}>
                <Descriptions.Item label="내 프로필 이미지">
                  <Row style={{ marginBottom: '10px' }}>
                    <Col span={24}>
                      <div style={{ position: 'absolute' }}>내 프로필 이미지</div>
                      <div style={{ position: 'absolute', right: '20px', color: '#1C8FD6' }}>* 최소 140px X 140px</div>
                    </Col>
                  </Row>
                  <div style={{ marginTop: '40px' }}>{renderUpload('profile_image', userDetailData?.profile_image)}</div>
                </Descriptions.Item>
              </Col>
              <Col span={18}>
                <Descriptions size="small" layout="vertical" column={2}>
                  <Descriptions.Item label="웹사이트">{renderField('web', userDetailData?.web, { placeholder: 'https://' })}</Descriptions.Item>
                  <Descriptions.Item label="Youtube">{renderField('youtube_link', userDetailData?.youtube_link, { placeholder: 'https://' })}</Descriptions.Item>
                  <Descriptions.Item label="Instagram">{renderField('insta_link', userDetailData?.insta_link, { placeholder: 'https://' })}</Descriptions.Item>
                  <Descriptions.Item label="X">{renderField('x_link', userDetailData?.x_link, { placeholder: 'https://' })}</Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            <Descriptions size="small" layout="vertical" style={{ marginTop: '24px' }}>
              <Descriptions.Item
                label="소개 이미지"
                span={24}
                labelStyle={{ paddingTop: '30px' }}
                contentStyle={{ border: '1px solid #E1E1E1', borderRadius: '0.8rem', padding: '30px', width: '100%' }}
              >
                {renderUpload('intro_image', userDetailData?.intro_image)}
              </Descriptions.Item>
              <Descriptions.Item label="소개 및 설명" span={24}>
                <Row style={{ width: '100%' }}>
                  <Col span={24} style={{ marginTop: '0px', marginBottom: '8px' }}>
                    {renderField('about_me', userDetailData?.about_me, { placeholder: '한 줄 소개' })}
                  </Col>
                  <Col span={24} style={{ marginTop: '16px', marginBottom: '16px' }}>
                    {renderField('about_chanel', userDetailData?.about_chanel, { placeholder: '상세 소개' })}
                  </Col>
                </Row>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        {renderAiFl('ai_fi_nm', aiFormat[userDetailData?.ai_fi_cd], codes.aiList, { placeholder: 'AI 분야 선택(중복)' })}
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Col flex="150px">판매자정보</Col>
        <Col flex="auto" style={{ paddingRight: '10px' }}>
          <Row>
            <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
              <Button style={{ minWidth: '100px' }} onClick={() => setIsModalVisible(true)}>
                등록
              </Button>
              <AiModelCertModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
            </Col>
          </Row>
        </Col>
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        {isEditMode ? (
          <Row style={{ display: 'block', textAlign: 'center' }}>
            <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
              <Button style={{ minWidth: '100px' }} onClick={handleCancel}>
                취소
              </Button>
            </Col>
            <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
              <Button type="primary" style={{ minWidth: '100px' }} onClick={handleSave}>
                저장
              </Button>
            </Col>
          </Row>
        ) : (
          <Row style={{ display: 'block', textAlign: 'center' }}>
            <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
              <Button type="primary" icon={<EditOutlined />} onClick={toggleEditMode} style={{ minWidth: '100px' }}>
                수정
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default Profile;

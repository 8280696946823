import React, { forwardRef } from 'react';
import { message, Button } from 'antd';
import FileSaver from 'file-saver';
import { secondsCompactFormat } from '@utils/lib';

const ButtonExportAUIGrid = forwardRef(({ fileName, type = 'xlsx', text, style }, ref) => {
  const exportFile = () => {
    if (!ref?.current?.isAvailableLocalDownload()) {
      message.warning('로컬 다운로드 불가능한 브라우저 입니다. 서버 사이드로 전송하여 다운로드 처리하십시오.');
      return;
    }

    if (type === 'xlsx') {
      ref?.current?.exportToXlsx({
        fileName: fileName ?? `${secondsCompactFormat(new Date())}`,
        progressBar: true,
      });
    } else if (type === 'pdf') {
      if (!ref?.current?.isAvailabePdf()) {
        alert('PDF 저장은 HTML5를 지원하는 최신 브라우저에서 가능합니다.(IE는 10부터 가능)');
        return;
      }
      ref?.current?.exportToPdf({
        fileName: fileName ?? `${secondsCompactFormat(new Date())}`,
        progressBar: true,
        // 폰트 경로 지정 (반드시 지정해야 함)
        fontPath: '../assets/font/webfonts/jejugothic-regular.ttf',
      });
    }
  };

  return (
    <Button style={{ ...style }} onClick={exportFile}>
      {text}
    </Button>
  );
});

export default ButtonExportAUIGrid;

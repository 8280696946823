import dayjs from 'dayjs';
import JSEncrypt from 'jsencrypt';
import { Modal } from 'antd';
import AhoCorasick from 'ahocorasick';
// path
import * as PATH from '@routes/pathName';
// const
import { AUTH_LOGIN, AUTH_ADMIN, AUTH_BEGINNER, AUTH_BASIC, AUTH_PRO, FORBIDDEN_WORD_KEY } from '@utils/constants';

const WEB_HOST = process.env.REACT_APP_WEB_HOST;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

export const showAlert = (value, flag) => {
  const formattedValue = value.replace(/<br\s*\/?>/gi, '\n');

  if (!flag) {
    flag = 'warning';
  }
  if (flag === 'warning') {
    Modal.warning({ content: <pre>{formattedValue}</pre> });
  }
  if (flag === 'success') {
    Modal.info({ content: <pre>{formattedValue}</pre> });
  }
  if (flag === 'error') {
    Modal.error({ content: <pre>{formattedValue}</pre> });
  }
};

window.alert = (value, flag) => {
  return showAlert(value, flag);
};

export const showConfirm = (value) => {
  return new Promise((resolve) => {
    const formattedValue = value.replace(/<br\s*\/?>/gi, '\n');

    Modal.confirm({
      content: <pre>{formattedValue}</pre>,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
      mask: false,
    });
  });
};

window.confirm = (value) => {
  return showConfirm(value);
};

export const dayFormat = (date) => {
  return date ? dayjs(date)?.format('YYYY-MM-DD') : date;
};

export const dayFormatKR = (date) => {
  return date ? dayjs(date)?.format('YYYY년 MM월 DD일') : date;
};

export const secondsFormat = (date) => {
  return date ? dayjs(date)?.format('YYYY-MM-DD HH:mm:ss') : date;
};

export const secondsCompactFormat = (date) => {
  return date ? dayjs(date)?.format('YYYYMMDDHHmmss') : date;
};

export const msTimestampFormat = (date) => {
  return date ? dayjs(date)?.format('x') : date;
};

export const isEmpty = (value) => {
  return value === null || value === undefined || value === '';
};

export const encryptRSA = (str) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
  return encrypt.encrypt(str);
};

// 디바운싱
export const useDebounce = (callback, delay) => {
  let timer;
  return (...args) => {
    //실행한 함수(setTimeout())취소
    clearTimeout(timer);
    //delay가 지나면 callback 함수 실행
    timer = setTimeout(() => callback(...args), delay);
  };
};

// 관리자 권한인지 아닌지
export const isAdmin = (value) => {
  return value === 'R002';
};

// 탈퇴한 유저가 맞는지 아닌지
export const isCanceledUser = (value) => {
  return value === '99';
};

// Select Box 리스트용, [{ label, value }] 형태로 만들기
export const makeSelectBoxList = (items = [], labelField, valueField) => {
  const result = [];
  if (items.length > 0) {
    const newArray = items.map((item) => {
      return { label: item[labelField], value: item[valueField] };
    });
    result.push(...newArray);
  }
  return result;
};

// 데이터 포멧터, { key: value } 형태로 만들기
export const makeDataFormat = (items = [], formatedValueField, valueField) => {
  const result = {};
  if (items.length > 0) {
    items.map((item) => {
      const key = item[valueField];
      const value = item[formatedValueField];
      result[key] = value;
      return result;
    });
  }
  return result;
};

// 천단위 콤마
export const koKRFormat = (value) => {
  if (!Number.isInteger(value)) return value;
  return value.toLocaleString('ko-KR');
};

export const phoneFormat = (number) => {
  if (!number) return '';
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  return match ? `${match[1]}-${match[2]}-${match[3]}` : number;
};

export const formatMessageDate = (dateString) => {
  const messageDate = new Date(dateString);
  const today = new Date();
  const isToday = messageDate.toDateString() === today.toDateString();
  const isYesterday = messageDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString();
  if (isToday) {
    return messageDate.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit' });
  } else if (isYesterday) {
    return '어제';
  } else {
    return messageDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
};

export function formatEncodingDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}

export function formatNumber(value) {
  if (!isNaN(value)) {
    return Number(value).toLocaleString(); // 3자리마다 , 추가
  }
  return value; // 숫자가 아닐 경우 원래 값을 반환
}
export const getLocalStorage = (storageKey, field) => {
  /**
   * @param {string} storageKey - localStorage에서 가져올 키 (예: 'prptbk-token').
   * @param {string} field - JSON 데이터에서 찾을 필드 이름 (예: 'mem_key').
   * @returns {*} 지정된 필드 값 또는 undefined.
   */
  try {
    const storedData = localStorage.getItem(storageKey);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData[field];
    }
  } catch (error) {
    console.error(`Error parsing localStorage data for key "${storageKey}":`, error);
  }
  return null;
};

export const sessionErrorConfirm = (errorCode) => {
  switch (errorCode) {
    case 'ERR701':
      window.confirm('로그인이 필요한 서비스입니다.<br/>로그인 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.LOGIN}`;
        } else return;
      });
      break;
    case 'ERR702':
      window.confirm('관리자만 사용가능한 서비스입니다.<br/>관리자로 전환 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR703':
      window.confirm('플랜 구독이 필요한 서비스입니다.<br/>구독 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR704':
      window.confirm('베이직 플랜 이상만 사용가능한 서비스입니다.<br/>플랜을 업그레이드 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR705':
      window.confirm('프로 플랜만 사용가능한 서비스입니다.<br/>플랜을 업그레이드 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    default:
      break;
  }
};

// API를 호출한 경우, isCallApi: true
// 콜백으로 컨펌창을 띄워야하는 경우, isCallbackConfirm: true
export const validateSession = ({ authType, isCallApi = false, isCallbackConfirm = false }) => {
  const tokenStore = JSON.parse(window?.localStorage?.getItem(TOKEN_STORE_KEY) || '{}');
  // 로그인 여부
  const isLogin = tokenStore.mem_key ? true : false;
  // 관리자 여부
  const isAdmin = ['R002'].includes(tokenStore.mem_auth) ? true : false;
  // 비기너 플랜 여부
  const isBeginner = ['PLAN002', 'PLAN003', 'PLAN004'].includes(tokenStore.plan_cd) ? true : false;
  // 베이직 플랜 여부
  const isBasic = ['PLAN003', 'PLAN004'].includes(tokenStore.plan_cd) ? true : false;
  // 프로 플랜 여부
  const isPro = ['PLAN004'].includes(tokenStore.plan_cd) ? true : false;

  const handleError = (errorCode) => {
    return isCallApi ? Promise.reject(errorCode) : isCallbackConfirm ? sessionErrorConfirm(errorCode) : false;
  };

  switch (authType) {
    // 세션이 없는 경우
    case AUTH_LOGIN:
      if (!isLogin) return handleError('ERR701');
      return true;
    // 관리자가 아닌 경우
    case AUTH_ADMIN:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin) return handleError('ERR702');
      return true;
    // 플랜구독 안한 경우
    case AUTH_BEGINNER:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isBeginner) return handleError('ERR703');
      return true;
    // 베이직 플랜 이상이 아닌 경우
    case AUTH_BASIC:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isBasic) return handleError('ERR704');
      return true;
    case AUTH_PRO:
      // 프로 플랜 이상이 아닌 경우
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isPro) return handleError('ERR705');
      return true;
    default:
      return true;
  }
};

// 금칙어 검사
export const validateForbiddenWord = (word) => {
  const forbiddenWordStore = JSON.parse(window?.localStorage?.getItem(FORBIDDEN_WORD_KEY) || '[]');
  const ac = new AhoCorasick(forbiddenWordStore);
  const result = ac.search(word);
  if (result.length) return result[0][1];
  return 'Y';
};

// 데이터를 MB, GB, TB로 변환하여 반환하는 함수
export const formatDataSize = (sizeInMB) => {
  if (sizeInMB < 1024) {
    // MB 단위
    return `${formatNumber(sizeInMB)}MB`;
  } else if (sizeInMB < 1024 * 1024) {
    // GB 단위
    const sizeInGB = (sizeInMB / 1024).toFixed(2);
    return `${formatNumber(sizeInGB)}GB`;
  } else {
    // TB 단위
    const sizeInTB = (sizeInMB / (1024 * 1024)).toFixed(2);
    return `${formatNumber(sizeInTB)}TB`;
  }
};
import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import '../../assets/css/payment.css';
import { message } from "antd";

const clientKey = process.env.REACT_APP_PAYMENT_CLIENT_KEY;

const PaymentWidget = () => {

  const location = useLocation();
  const { orderId, orderName, customerName, customerEmail, amount: amountValue, successUrl, failureUrl } = location.state || {};

  const [widgets, setWidgets] = useState(null);
  const [amount, setAmount] = useState({
    currency: "KRW",
    value: Number(amountValue || 0),
  });

  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);
      const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
      setWidgets(widgets);
    }

    fetchPaymentWidgets();
  }, [clientKey]);

  useEffect(() => {
    async function renderPaymentWidgets() { 
      if (widgets == null) {
        return;
      }
      await widgets.setAmount(amount);

      await Promise.all([
        // 결제창을 렌더링
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
        // 약관을 렌더링
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

    }

    renderPaymentWidgets();
  }, [widgets]);


  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />
        <div className="btn-wrapper w-100">
          <button
            className="btn primary w-100"
            onClick={async () => {
              try {
                await widgets?.requestPayment({
                  orderId: orderId,
                  orderName: orderName ? orderName : '',
                  customerName: customerName ? customerName : '',
                  customerEmail: customerEmail ? customerEmail : '',
                  successUrl: window.location.origin + "/payment/success?successUrl="+successUrl+"&failureUrl="+failureUrl,
                  failUrl: window.location.origin + "/payment/failure?failureUrl="+failureUrl
                });
              } catch (error) {
                // TODO: 에러 처리
                message.error(error.message)
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentWidget;

import { restApi } from './config';

// AI 모델 정보 목록 조회
export const getModelInfo = (data) => {
  return restApi.post('/api/admin/aiModel/getAiModelInfo', data);
};
// AI 모델 정보 저장
export const saveModelInfo = (data) => {
  return restApi.post('/api/admin/aiModel/saveAiModelInfo', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
// AI 모델 강조 목록 조회
export const getAiModelWeight = (data) => {
  return restApi.post('/api/admin/aiModel/getAiModelWeight', data);
};
// AI 모델 강조 목록 저장
export const saveAiModelWeight = (data) => {
  return restApi.post('/api/admin/aiModel/saveAiModelWeight', data);
};
// 카테고리에 따른 AI모델 가져오기
export const getAiModelByCate = (data) => {
  return restApi.post('/api/admin/aiModel/getAiModelByCate', data);
};
// 파라미터 목록 조회
export const getParamInfo = (data) => {
  return restApi.post('/api/admin/param/getParamInfo', data);
};
// 파라미터 목록 저장
export const saveParamInfo = (data) => {
  return restApi.post('/api/admin/param/saveParamInfo', data);
};

//빌더 정보 조회
export const fetchBuilderData = (data) => {
  return restApi.post('/api/promptBuilder/fetchBuilderData', data);
};
//프롬프트 텍스트 저장
export const savePromptText = (data) => {
  return restApi.post('/api/promptBuilder/savePromptText', data);
}

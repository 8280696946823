import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Input, Layout, List, Row, Tabs } from 'antd';
import { Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import { shallowEqual, useSelector } from 'react-redux';
import { marketplaceSrot } from '@utils/constants';
import { useDebounce } from '@utils/lib';
import CardItem from '@components/cardMarketPlace/CardItem';
import { useLocation } from 'react-router-dom';
import Filter from '@components/cardMarketPlace/Filter';
import { CloseOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';

const MarketPromptList = () => {
  const { pathname } = useLocation();
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const [loading, setLoading] = useState({});

  const [activeTabKey, setActiveTabKey] = useState(''); // 활성화된 tab
  const [promptList, setPromptList] = useState({
    query: {
      mem_key: auth?.mem_key,
      keyword_tab: '', // 조회 tab (판매/구매/좋아요 목록)
      filter_text: '', // 프롬프트 제목/tag 검색
      filter_cate: '', // 프롬프트 카테고리
      filter_model: '', // 프롬프트 모델
      filter_stat: '', // 프롬프트 상태
      filter_genre: '', // 프롬프트 장르
      filter_sort: '', // 정렬
      offset: 0,
      limit: 20,
    },
    items: [],
  });

  // 공통코드 (filter 용)
  const [commonCodeList, setCommonCodeList] = useState([]);

  /** 이벤트 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // filter 변경 시
  const handleChangeFilter = (selectedItem, filterText) => {
    const newQuery = {
      ...promptList.query,
      filter_text: filterText,
      filter_cate: selectedItem.CATE == 'all' ? '' : selectedItem.CATE,
      filter_model: selectedItem.MODEL == 'all' ? '' : selectedItem.MODEL,
      filter_stat: selectedItem.PRPT_STAT == 'all' ? '' : selectedItem.PRPT_STAT,
      filter_genre: selectedItem.GENRE == 'all' ? '' : selectedItem.GENRE,
      filter_sort: selectedItem.sort,
    };

    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
    console.log(newQuery);
  };

  // 공통코드 조회
  const getCommonCodes = useCallback(async () => {
    const params = [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }, { grp_cd: 'PRPT_STAT' }, { grp_cd: 'GENRE' }];
    const { data } = await api.fetchCodesByGroupCodes(params);

    const transFormat = data.map((grpCode) => ({
      label: grpCode.grp_nm,
      value: grpCode.grp_cd,
      children: grpCode.detailData.map((item) => ({
        label: item?.cd_nm,
        value: item?.cd,
        parent: item?.parent_cd,
      })),
    }));

    transFormat.push(marketplaceSrot);
    setCommonCodeList(transFormat);
  }, []);

  // 프롬프트 리스트 조회
  const getMyPromptList = useCallback(async (query) => {
    try {
      const { data } = await api.getMyPromptList(query);
      setPromptList((prev) => ({ ...prev, items: data.items }));
    } catch (e) {
      // 에러 처리
    } finally {
    }
  }, []);

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    const pathSegments = pathname.split('/');
    const subTab = pathSegments[3];
    setActiveTabKey(subTab);

    const newQuery = { ...promptList.query, keyword_tab: subTab };
    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
  }, [pathname]);

  return (
    <>
      <article id="otherWrapper" className="flexColCenter">
        <Filter filterItem={commonCodeList} handleChangeFilter={handleChangeFilter} key={pathname} />

        <div className="flexRowStart box">
          {promptList.items.map((item) => (
            <CardItem key={item.prpt_id} cardItem={item} />
          ))}
        </div>
      </article>
    </>
  );
};

export default MarketPromptList;

import React, { useCallback, useEffect, useState } from 'react';
// icon
import closeIcon from '@assets/images/common/close_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
// lib
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as api from '@api/index';
import { setCartAmount, setCartCount, setCartFlag, setCartItems } from '@stores/cart';
import CartCardSquare from '@components/CartCardSquare';
import { koKRFormat, useDebounce } from '@utils/lib';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const CartSimpleList = ({ isMobile = false, cartToggle }) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const cartState = useSelector((s) => s.cart, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectCart, setSelectCart] = useState({
    items: [],
    count: 0,
    amount: 0,
  }); // 선택된 cart

  // 장바구니 조회
  const getCartList = async () => {
    const params = { mem_key: auth?.mem_key };
    const { data } = await api.getCartList(params);
    if (data) {
      const totAmount = data.reduce((acc, item) => {
        return acc + item.gds_amt;
      }, 0);

      dispatch(setCartItems(data));
      dispatch(setCartCount(data.length));
      dispatch(setCartAmount(totAmount));

      setSelectCart({
        items: data,
        count: data.length,
        amount: totAmount,
      });
    }
  };

  // 장바구니 삭제
  const deleteCart = async (e, seq) => {
    e.stopPropagation(); // 상위 이벤트 전파 방지

    try {
      const params = { mem_key: auth?.mem_key, gds_seq: seq };
      const { data } = await api.deleteCart(params);

      if (data.returnStatus == 'success') message.success(data.returnMessage);
      else message.error(data.returnMessage);
    } catch (e) {
      console.log(e);
      message.error('삭제에 실패했습니다.');
    } finally {
      getCartList(); //장바구니 재조회
    }
  };

  // 장바구니 선택
  const handleSelect = (cart) => {
    setSelectCart((prev) => {
      const isSelect = prev.items.some((item) => item.gds_seq === cart.gds_seq);
      const updatedItems = isSelect ? prev.items.filter((item) => item.gds_seq !== cart.gds_seq) : [...prev.items, cart];
      const updatedAmt = updatedItems.reduce((acc, item) => {
        return acc + item.gds_amt;
      }, 0);

      return {
        items: updatedItems,
        count: updatedItems.length,
        amount: updatedAmt,
      };
    });
  };

  const paymentCart = async () => {
    if (!auth?.mem_key) {
      message.error('로그인 후 이용해주세요.');
      return;
    }
    if (selectCart.count < 1) alert('결제 항목이 없습니다.');

    const updatedGdsNm = selectCart.count > 1 ? selectCart.items[0].gds_nm + ' 외 ' + (selectCart.count - 1) + '건' : selectCart.items[0].gds_nm;
    const params = {
      mem_key: auth?.mem_key,
      inout_tp: 'IN',
      pay_meth: 'pay_meth',
      gds_list: selectCart.items,
      pay_amt: selectCart.amount,
      gds_nm: updatedGdsNm,
    };

    try {
      const { data } = await api.paymentCart(params);
      const pay_seq = String(data.pay_seq).padStart(19, '0'); // L pad

      handleOpenPayment(pay_seq, updatedGdsNm, selectCart.amount);
    } catch (e) {
      console.log(e);
      message.error('결제에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      cartToggle(); // 토글 닫기
    }
  };

  /**토스 페이먼츠 결제 */
  const handleOpenPayment = (seq, name, amount) => {
    navigate('/payment/widget', {
      state: {
        // orderId: '0000000000000000002', //결제번호
        orderId: seq, // 결제번호
        //orderName: '테스트 품목', //품목명
        orderName: name, //품목명
        // customerName: '테스트', //사용자명(필수X)
        customerName: auth?.mem_nick, //사용자명(필수X)
        customerEmail: auth?.mem_email, //사용자 메일(필수X)
        amount: amount, //결제 금액
        successUrl: '/mypage/CreatePrompt', //결제 성공 시 URL
        failureUrl: '/mypage/CreatePrompt', //결제 실패 시 URL
      },
    });
  };

  useEffect(() => {
    if (auth?.mem_key) {
      getCartList();
    }
  }, []);

  return (
    <div className={`cartWrapper ${isMobile ? 'flexColCenter' : ''}`}>
      <div className="flexRowBetween">
        <div className="titleBox flexRowCenter">
          <img src={cartIcon} alt="cart_icon" />
          <h1>장바구니</h1>
          <div>{cartState.count}</div>
        </div>

        <img src={closeIcon} alt="close_icon" onClick={cartToggle} />
      </div>

      <div className="cartBox flexRowBetween">
        {cartState.items.map((cart) => {
          const isSelect = selectCart.items.some((item) => item.gds_seq === cart.gds_seq);
          return <CartCardSquare key={cart.gds_seq} cart={cart} selected={isSelect} deleteCart={deleteCart} onClick={handleSelect} />;
        })}
      </div>

      <div className="priceBox flexColCenter">
        <div className="price flexRowBetween">
          <h1>선택 {koKRFormat(selectCart.count)}개</h1>

          <p>주문 합계 {koKRFormat(selectCart.amount)}원</p>
        </div>
        <div id="pinkBtn" onClick={paymentCart}>
          구매하기
        </div>
      </div>
    </div>
  );
};

export default CartSimpleList;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Layout, Form, Input, Button, Row, Modal } from 'antd';
import Table from '@components/Table';
import Loading from '@components/Loading';
import * as api from '@api/index';
import {makeDataFormat, useDebounce} from '@utils/lib';
import ButtonExport from "@components/ButtonExportAUIGrid";
import {aiCertApr, bannerApr, periodList} from "@utils/constants";
import RangePicker from "@components/RangePicker";
import dayjs from "dayjs";
import iconDate from "@static/AUIGrid/images/calendar-icon.png";

const { Content, Header } = Layout;

const AiCertList = () =>{
  /*
      const location = useLocation();
  */
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [aiCertLocation, setAiCertLocation] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const today = dayjs(); // 오늘 날짜
  const oneWeekAgo = today.subtract(7, 'day'); // 일주일 전 날짜

  const [sendDate, setSendDate] = useState({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });
  const [aiCertListState, setAiCertListState] = useState({
    query: {
      keyword_text: '',
      startDt: sendDate.st_date,
      endDt: sendDate.end_date,
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const handleDate = ({ startDt, endDt }) => {
    setSendDate({
      st_date: startDt,
      end_date: endDt,
    });
    console.log(sendDate);
  };

  window.handleImageClick = (imagePath) => {
    setModalImage(imagePath);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setAiCertListState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        startDt: sendDate.st_date,
        endDt: sendDate.end_date,
      },
    }));
  }, [sendDate]);

  const aiCertGridRef = useRef();
  const handleDebounce = useCallback(
      useDebounce((func) => func(), 300),
      [],
  );

  const aiCertGridProps = {
    showRowCheckColumn: false,
  };

  const aiCertColumnLayout = [
    { dataField: 'seq', headerText: '순번', width: '8%', visible: false },
    { dataField: 'mem_key', headerText: '멤버 식별키', width: '8%', editable: false, visible: false },
    { dataField: 'mem_email', headerText: 'Email', width: '14%', editable: false },
    { dataField: 'ai_model_nm', headerText: 'AI 모델명', width: '13%', editable: false },
    { dataField: 'img_path', headerText: '이미지', width: '21%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        let template = '';
        if (value) {
          const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
          template += `<img src="${imgSrc}" alt="Banner" style="height:25px; max-width:100%;" onclick="handleImageClick('${imgSrc}');">`;
        } else {
          template += '<span>No image</span>';
        }
        return template;
      },
      editable: false,
    },
    {
      headerText: '인증 기간',
      children: [
        {
          dataField: 'cert_start_dt',
          headerText: '시작일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '10%',
          editable: false,
/*          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },*/
        },
        {
          dataField: 'cert_end_dt',
          headerText: '종료일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '10%',
          editable: false,
          /*renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
          },*/
        },
      ],
    },
    { dataField: 'cert_stat', headerText: '인증상태', width: '12%', renderer: { type: 'DropDownListRenderer', list: aiCertApr, keyField: 'value', valueField: 'label' },},
    { dataField: 'confirm_url', headerText: '인증 URL', width: '20%', editable: false, },
  ]

  const setupGridEvents = useCallback(() => {
    if (aiCertGridRef.current) {
      aiCertGridRef.current.bind('vScrollChange', (event) => {
        handleDebounce(() => setAiCertListState((prev) => ({ ...prev, yScrollPosition: event.position })));
        if (event.position === event.maxPosition && aiCertListState.currentCount < aiCertListState.totalCount) {
          handleScroll();
        }
      });
    }
  }, [handleDebounce, aiCertListState.currentCount, aiCertListState.totalCount, aiCertLocation]);

  const handleScroll = () => {
    const newQuery = { ...aiCertListState.query, offset: aiCertListState.query.offset + aiCertListState.query.limit };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };

  const getAiCertList = useCallback(
      async (query) => {
        try {
          setIsLoading(true);
          const {data} = await api.getAiCertList(query);
          if (!data) return;

          setAiCertListState((prev) => ({
            ...prev,
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }));

          if (aiCertGridRef.current) {
            aiCertGridRef.current.setGridData(data.items);
          }
          setupGridEvents();
        } catch (error) {
          message.warning(error.message);
        } finally {
          setIsLoading(false);
        }
      },
      [setupGridEvents],
  );

  const handleSearchAiCert = ({ keyword_text }) => {
    const newQuery = { ...aiCertListState.query, keyword_text, offset: 0 };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };

  const updateAiCert = async (type) => {
    let saveItems = aiCertGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    /*
    let delItems = aiCertGridRef.current.getCheckedRowItems().map(({ item }) => {
        return { ...item, state: 'del' };
    });
    */
    if (type === 's') {
      if (saveItems.length < 1) {
        alert('저장할 데이터가 없습니다.');
        return false;
      }
    }
    try {
      setIsLoading(true);
      console.log(saveItems);
      const formData = new FormData();
      formData.append('updateAiCerList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));

      const response = await api.updateAiCert(formData);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
    } catch (e) {
      console.log('인증상태 갱신 error', e);
    } finally {
      setIsLoading(false);
      getAiCertList(aiCertListState.query);
    }
  }

  useEffect(() => {
    getAiCertList(aiCertListState.query);
  }, []);

  return (
      <>
        <Loading isLoading={isLoading} />
        <Layout>
          <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
            <Form form={form} layout="inline" initialValues={aiCertListState.query} onFinish={handleSearchAiCert}>
              <Form.Item name="keyword_text">
                <Input placeholder="검색어를 입력해주세요." style={{minWidth: '300px'}}/>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <div style={{
                position: 'absolute',
                right: 20,
                display: 'flex',
                alignItems: 'center',
                // marginRight: '10px'
              }}>
                <RangePicker
                    styleSelect={{minWidth: '80px', /*marginRight: '10px'*/}}
                    placeholderSelect="생성일시"
                    optionsSelect={periodList}
                    valueRangePicker={[sendDate?.st_date ? dayjs(sendDate?.st_date) : oneWeekAgo, sendDate?.end_date ? dayjs(sendDate?.end_date) : today]}
                    onChange={handleDate}
                />
              </div>
            </Form>
            <Row style={{position: 'relative', lineHeight: '32px', margin: '10px 0 3px'}}>
              <Row>검색결과</Row>
              <Row style={{marginLeft: '10px'}}>{`총 ${aiCertListState.totalCount}건`}</Row>
              <Row style={{position: 'absolute', right: 0}}>
                <Button style={{marginLeft: '3px'}} type="primary" htmlType="submit"
                        onClick={() => updateAiCert('s')}>
                  저장
                </Button>
                {/*<Button style={{ marginLeft: '3px' }} htmlType="submit" onClick={() => updateAiCertState('d')} danger>
                                삭제
                            </Button>*/}
              </Row>
            </Row>
          </Header>
          <Content style={{ marginTop: '5px' }}>
            <Table ref={aiCertGridRef} columnLayout={aiCertColumnLayout} customGridProps={aiCertGridProps} />
          </Content>
        </Layout>

        <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalCancel}
            width="50%"
            height="50%"
            style={{ textAlign: 'center' }}
        >
          <img src={modalImage} alt="Enlarged Image" style={{ width: '100%' }} />
        </Modal>
      </>
  );
};

export default AiCertList;
import { Input, Layout } from 'antd';
import React, {useRef, useEffect, useState} from 'react'
import {
  PlusOutlined,
} from "@ant-design/icons";

const StepZero = (props) => {
  const stepZeroList = props.stepZeroList;

  const [ inputs, setInputs ] = useState([]);

  const handleInputChange = (index, newValue) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [index]: newValue,
    }));
  };

  useEffect(() => {
    setInputs([]);
  }, [props.builderId])
  
  return (
    <Layout style={{ background: `#ffffff` }}>
      {stepZeroList.map((step, index) => (
        <div className="flexColStart inputBox">
          <h1>{step.title}</h1>
          <div className="flexRowBetween">
            <Input className="input" onChange={(e) => handleInputChange(index, e.target.value)}/>
            <button id="darkGrey2" onClick={() => props.handleSubmitPromptText(inputs[index], step.prpt_seq)}>
              <PlusOutlined />
              &nbsp;입력
            </button>
          </div>
        </div>
      ))}
    </Layout>
  )
}

export default StepZero
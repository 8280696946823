import { restApi, headersForAccessToken, querystring, headersForSession } from './config';
import { AUTH_LOGIN } from '@utils/constants';

// 사용자 전체 조회
export const listUser = (data) => {
  headersForAccessToken();
  return restApi.get(`/api/user`, querystring(data));
};
// 사용자 상세 조회
export const selectUser = (data) => {
  return restApi.get(`/api/user/${data?.mem_email}/${data?.mem_key}`);
};
// 사용자 정보 변경
export const updateUser = (data) => {
  headersForSession(AUTH_LOGIN);
  return restApi.put(`/api/user/${data?.mem_email}/${data?.mem_key}`, data);
};
// 내 프로필 업데이트
export const updateProfile = (data) => {
  return restApi.post('/api/user/updateProfile', data);
};
// 신고 내역 전체 조회
export const listPromptBad = (data) => {
  return restApi.get('/api/promptBad', querystring(data));
};
// 프롬프트 신고하기
export const addPromptBad = (data) => {
  return restApi.post('/api/addPromptBad', data);
};
// 팔로워 추가 요청
export const addFollower = (data) => {
  return restApi.post('/api/follower', data);
};
// 팔로워 전체 조회
export const listFollower = (data) => {
  return restApi.get('/api/follower', querystring(data));
};
// AI모델 인증현황 전체 조회
export const listCert = (data) => {
  return restApi.get('/api/cert', querystring(data));
};
// 유저 랭킹 조회
export const getUserRanking = (data) => {
  return restApi.get('/api/rank/getUserRanking', querystring(data));
};
// 유저 랭킹 저장
export const saveUserRanking = (data) => {
  return restApi.post('/api/rank/saveUserRanking', data);
};
// 랭킹 점수 관리 조회
export const getRankMgmt = () => {
  return restApi.get('/api/rank/getRankMgmt');
};
// 랭킹 점수 관리 저장
export const saveRankMgmt = (data) => {
  return restApi.post('/api/rank/saveRankMgmt', data);
};
// 멤버십 플랜 조회
export const getPlanInfo = () => {
  return restApi.get('/api/membership/getPlanInfo');
};
// 멤버십 플랜 저장
export const savePlanInfo = (data) => {
  return restApi.post('/api/membership/savePlanInfo', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
// 마켓플레이스 뱃지 조회
export const getBadgeInfo = () => {
  return restApi.get('/api/membership/getBadgeInfo');
};
// 마켓플레이스 뱃지 저장
export const saveBadgeInfo = (data) => {
  return restApi.post('/api/membership/saveBadgeInfo', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
// 차단목록조회
export const getBlockedList = (data) => {
  return restApi.get('/api/blocked/getBlockedList', querystring(data));
};
// 차단해제
export const deleteBlocked = (data) => {
  return restApi.post('/api/blocked/deleteBlocked', data);
};
// 알림설정 조회
export const getAlertSetting = () => {
  return restApi.get('/api/alert/getAlertSetting');
};
// 알림설정 저장
export const updAlrtSetting = (data) => {
  return restApi.post('/api/alert/updAlrtSetting', data);
};
//판매자 목록 조회
export const listSeller = (data) => {
  return restApi.post('/api/user/fetchSellerList', data);
}
//사용자 플랜 정보 조회
export const fetchPlanInformation = () => {
  return restApi.post('/api/user/fetchPlanInformation');
}
//사용자 멤버십 업데이트
export const updateMembershipAfterPayment = (data) => {
  return restApi.post('/api/user/updateMembershipAfterPayment', data);
}
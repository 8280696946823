import { restApi, querystring } from './config';

// 프롬프트 조회/다운로드/플레이수 업데이트
export const updatePromptView = (data) => {
  return restApi.post('/api/prompt/updatePromptView', data);
};

/** admin */
// 프롬프트 검토 조회
export const getPromptList = (data) => {
  return restApi.get('/api/prpt/getPromptList', querystring(data));
};
export const updatePromptReview = (data) => {
  return restApi.post('/api/prpt/updatePromptReview', data);
};

/** MyPage - 프롬프트 관리 */
// 프롬프트 리스트 조회
export const getUserPromptList = (data) => {
  return restApi.get('/api/promptManagement/getUserPromptList', querystring(data));
};

// 폴더이동 (삭제포함)
export const movePromptFolder = (data) => {
  return restApi.post('/api/promptManagement/movePromptFolder', data);
};

// 휴지통에서 복원
export const restorePromptFolder = (data) => {
  return restApi.post('/api/promptManagement/restorePromptFolder', data);
};

// 영구삭제
export const removePromptFolder = (data) => {
  return restApi.post('/api/promptManagement/removePromptFolder', data);
};

// 폴더저장 (폴더명 변경/ 추가)
export const savePromptFolder = (data) => {
  return restApi.post('/api/promptManagement/savePromptFolder', data);
};

// 중요표시
export const toggleBookmark = (data) => {
  console.log(data);
  return restApi.post('/api/promptManagement/toggleBookmark', data);
};

/** prompt 리스트 조회 */
// 프롬프트 리스트 조회
export const getMyPromptList = (data) => {
  return restApi.get('/api/MyMarketplaces/getMyPromptList', querystring(data));
};
// 장바구니 추가
export const addToCart = (data) => {
  return restApi.post('/api/cart/insertPrptCart', data);
};

/** 매인페이지 */
// 프롬프트 리스트 조회
export const getAllPromptList = (data) => {
  return restApi.get('/api/MyMarketplaces/getAllPromptList', querystring(data));
};

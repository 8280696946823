import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Col, Descriptions, Divider, Form, Input, Layout, Radio, Row, Select, Tooltip, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as api from '@api/index';
import axios from 'axios';
import { QuestionCircleOutlined, ReadFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import '@assets/css/form.css';

const SalesPrompt = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const prpt_id = params.get('prpt_id');

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState({});
  const [isAgreed, setIsAgreed] = useState(false);
  const [fileList, setFileList] = useState([null]);
  const [aiFile, setAiFile] = useState(null);
  const [thumFile, setThumFile] = useState(null);
  const [selectedAiModel, setSelectedAiModel] = useState('');
  const baseURL = process.env.REACT_APP_API_HOST;
  const request = axios.create({
    baseURL,
    timeout: 30000,
  });
  const [form] = Form.useForm();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  // 공통코드
  const levelList = useRef();
  const categoryList = useRef();
  const genreList = useRef();
  const aiModelList = useRef();
  const samplerList = useRef();

  // 카테고리 선택 시 상태 업데이트
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedAiModel(null);
    form.setFieldValue('genre_cd', null);
    form.setFieldValue('ai_model_cd', null);
    setTags([]);
    if (e.target.value == 'CATE003') {
      form.setFieldValue('prpt_remark', '');
      form.setFieldValue('test_prpt', '');
      form.setFieldValue('text_prpt', '');
    } else {
      form.setFieldValue('music_org_link', '');
    }
  };

  const handleAiModelChange = (e) => {
    const isCertified = aiModelCertChk(e.target.value, selectedCategory);
    if (isCertified) {
      setSelectedAiModel(e.target.value); // 선택한 AI 모델 상태 업데이트
    }
  };

  const aiModelCertChk = async (ai_model_cd, cate_cd) => {
    try {
      const response = await api.aiModelCertChk({
        ai_model_cd: ai_model_cd,
        cate_cd: cate_cd,
      });
      const data = response.data;
      if (data == false) {
        alert('ai model 인증을 진행하여 주세요.');
        return false;
      }
    } catch (error) {
      console.error('error');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag(inputValue);
    }
  };

  const addTag = (tag) => {
    if (tag && tags.length < 10 && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const goToPage = () => {
    // 마이페이지 생기면 마이페이지로 이동 ( 무료회원은 가격 수정불가)
  };

  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      // 단계
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'LEVEL' });
      levelList.current = response1.data;
      // 카테고리
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      categoryList.current = response2.data;
      // 장르
      const response3 = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'GENRE', parent_cd: selectedCategory });
      genreList.current = response3.data;
      // AI모델
      const response4 = await api.getAiModelByCate({ cate_cd: selectedCategory });
      aiModelList.current = response4.data;
      // 샘플러
      const response5 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SAMPLER' });
      samplerList.current = response5.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  const handleFileChange = (file, index) => {
    const newFileList = [...fileList];
    newFileList[index] = { file, attr1: 'c' }; // '프롬프트 아웃풋 입력' 파일의 attr1을 'c'로 설정
    if (newFileList.length < 5 && !newFileList.includes(null)) {
      newFileList.push(null);
    }

    setFileList(newFileList);
  };
  const handleFileChange2 = (file) => {
    setAiFile({ file, attr1: 'a' });
  };
  const handleFileChange3 = (file) => {
    setThumFile({ file, attr1: 's' });
  };

  // 조회
  const getSalesPrompt = async () => {
    try {
      const response = await api.getSalesPrompt({
        params: { prpt_id: prpt_id },
      });
      const data = response.data;
      if (data.cate_cd) {
        form.setFieldsValue({ cate_cd: data.cate_cd });
        handleCategoryChange({ target: { value: data.cate_cd } });
      }
      form.setFieldsValue(response.data);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 저장
  const onFinish = async (values, flag) => {
    // Validation
    if (!isAgreed) {
      alert('판매자 이용약관에 동의해야 합니다.');
      const div = document.querySelector('#agreeChk');
      if (div) {
        div.focus();
      }
      return;
    }
    if (values.prpt_title == null) {
      alert('프롬프트 제목은 반드시 입력해야합니다.');
      const div = document.querySelector('#prpt_title');
      if (div) {
        div.focus();
      }
      return;
    }

    // if (auth.business_no == null || auth.business_no == '') {
    //   alert('사업자번호를 등록해주세요.');
    //   window.open('/mypage/businessRegist', '_blank', 'noopener, noreferrer');
    //   return;
    // }

    // 사업자등록번호 확인
    const response = await api.businessNoChk({
      mem_key: auth.mem_key,
    });
    const result = response.data;

    if (result) {
      // 성공
      console.log('사업자번호 확인 성공');
    } else {
      // 실패
      console.log('사업자번호 확인 실패');
      return;
    }

    try {
      const formData = new FormData();
      const tagsAsString = tags.join(',');
      if ((flag = 'reqSales')) {
        formData.append('prpt_stat', '10');
      } else {
        formData.append('prpt_stat', '01');
      }
      formData.append('tag', tagsAsString);
      formData.append('ai_model_cd', selectedAiModel);
      formData.append('etc_nm', values.etc_nm);
      formData.append('biz_confirm', values.biz_confirm ? 'Y' : 'N');
      formData.append('cate_cd', values.cate_cd);
      formData.append('clip_gdc', values.clip_gdc);
      formData.append('req_amt', values.req_amt != undefined ? values.req_amt : null);
      formData.append('genre_cd', values.genre_cd);
      formData.append('music_org_link', values.music_org_link);
      formData.append('prpt_cfg', values.prpt_cfg);
      formData.append('prpt_desc', values.prpt_desc);
      formData.append('prpt_remark', values.prpt_remark);
      formData.append('prpt_sample', values.prpt_sample);
      formData.append('prpt_seed', values.prpt_seed);
      formData.append('prpt_step', values.prpt_step);
      formData.append('prpt_title', values.prpt_title);
      formData.append('prpt_ver', values.prpt_ver);
      formData.append('req_step_cd', values.req_step_cd);
      formData.append('sale_amt', values.sale_amt != undefined ? values.sale_amt : null);
      formData.append('test_prpt', values.test_prpt);
      formData.append('text_prpt', values.text_prpt);
      formData.append('thum_path', values.thum_path);
      formData.append('use_amt', values.use_amt != undefined ? values.use_amt : null);

      const validFiles = fileList.filter(Boolean);
      for (let i = 0; i < validFiles.length; i++) {
        formData.append('files', validFiles[i].file);
        formData.append('attr1', validFiles[i].attr1);
      }

      if (aiFile) {
        formData.append('files', aiFile.file);
        formData.append('attr1', aiFile.attr1);
      }

      if (thumFile) {
        formData.append('files', thumFile.file);
        formData.append('attr1', thumFile.attr1);
      }

      try {
        const response = await api.salesPrompt(formData);
        if (response.data.returnStatus == 'success') {
          alert(response.data.returnMessage || 'No message provided');
          window.location.reload();
        } else if (response.data.returnStatus == 'error') {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error('임시저장 중 에러 발생:', error);
    }
  };

  useEffect(() => {
    getCommonCode();
    getSalesPrompt();
  }, [selectedCategory]);

  return (
    <>
      <article className="flexColCenter" id="sales_prompt">
        <div className="rsWrapper flexColCenter">
          <Form form={form} onFinish={(values) => onFinish(values, 'formSubmit')} className="top form">
            <Form.Item className="flexRowCenter essential">
              <Checkbox onChange={(e) => setIsAgreed(e.target.checked)}>
                (필수) 마켓플레이스 판매자 이용약관 동의{' '}
                <a href="http://www.naver.com" target="_blank">
                  (보기)
                </a>
              </Checkbox>
            </Form.Item>

            <Divider variant="dashed" dashed>
              <ReadFilled /> 프롬프트 정보
            </Divider>

            <Row>
              {/*<Col className="tbTitle">모델 정보 및 설정</Col>*/}
              <Col>
                <Descriptions>
                  <Descriptions.Item label="모델 정보 및 설정">
                    <Form.Item name="cate_cd">
                      <Radio.Group onChange={handleCategoryChange}>
                        {(categoryList.current || []).map((option, index) => (
                          <Radio key={index} value={option.cd}>
                            {option.cd_nm}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="장르">
                    <Form.Item name="genre_cd">
                      <Radio.Group>
                        {(genreList.current || []).map((option, index) => (
                            <Radio key={index} value={option.cd}>
                              {option.cd_nm}
                            </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="AI 모델명">
                    <Form.Item>
                      <Form.Item name="ai_model_cd">
                        <Radio.Group onChange={handleAiModelChange}>
                          {(aiModelList.current || []).map((option, index) => (
                              <Radio key={index} value={option.cd}>
                                {option.cd_nm}
                              </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      {selectedAiModel == 'ETC' && (
                          <Form.Item name="etc_nm" noStyle>
                            <Input placeholder="직접입력" style={{ width: '150px' }} />
                          </Form.Item>
                      )}
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="AI 모델 정보 및 설정">
                    <Form.Item >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <div style={{ width: '90px', textAlign: 'right' }}>버전</div>
                        <Form.Item name="prpt_ver" noStyle>
                          <Input style={{ width: '150px' }} />
                        </Form.Item>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <div style={{ width: '90px', textAlign: 'right' }}>샘플러</div>
                        <Form.Item name="prpt_sample" noStyle>
                          <Select placeholder="샘플러" style={{ width: '150px' }} options={samplerList.current} fieldNames={{ label: 'cd_nm', value: 'cd' }} />
                        </Form.Item>
                        <div>CFG</div>
                        <Form.Item name="prpt_cfg" noStyle>
                          <Input style={{ width: '150px' }} />
                        </Form.Item>
                        <div>스텝</div>
                        <Form.Item name="prpt_step" noStyle>
                          <Input style={{ width: '150px' }} />
                        </Form.Item>
                        <div>시드</div>
                        <Form.Item name="prpt_seed" noStyle>
                          <Input style={{ width: '150px' }} />
                        </Form.Item>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <div style={{ width: '90px', textAlign: 'right' }}>클립 가이던스</div>
                        <Form.Item name="clip_gdc" noStyle>
                          <Input style={{ width: '150px' }} />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item
                      label={
                        <span>
                        판매가격
                          <Tooltip
                              title={
                                <div>
                                  <div>무료 플랜은 판매가격을 설정할 수 없으며, 무료 공유만 가능합니다. 무료로 공유하더라도 랭킹 점수는 획득할 수 있습니다.</div>
                                  <Button style={{ marginTop: '8px' }} onClick={() => goToPage()}>
                                    플랜정보 확인하기
                                  </Button>
                                </div>
                              }
                              placement="right"
                          >
                            <span style={{ cursor: 'pointer' }}>
                              <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
                              {/*<Button style={{ width: '120px', height: '120px' }}>플랜정보 확인하기</Button>*/}
                            </span>
                          </Tooltip>
                        </span>
                      }
                  >
                    <Form.Item name="sale_amt">
                      <Input type="number" style={{ width: '150px' }} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="사용권 가격">
                    <Form.Item name="use_amt">
                      <Input type="number" style={{ width: '150px' }} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="작곡 의뢰 가격">
                    <Form.Item>
                      <Form.Item name="req_step_cd" noStyle>
                        <Select placeholder="단계" style={{ width: '150px' }} options={levelList.current} fieldNames={{ label: 'cd_nm', value: 'cd' }} />
                      </Form.Item>
                      <Form.Item name="req_amt">
                        <Input type="number" style={{ width: '150px' }} />
                      </Form.Item>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="제목">
                    <Form.Item name="prpt_title">
                      <Input id="prpt_title" placeholder="제목을 입력하세요." style={{ width: '600px' }} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="소개">
                    <Form.Item name="prpt_desc">
                      <TextArea
                          placeholder="잠재 구매자에게 어필할 수 있는 프롬프트 소개 및 홍보글을 작성하세요."
                          style={{ width: '600px', height: '200px', paddingTop: '8px' }}
                          autoSize={{ minRows: 8, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            {/*{selectedAiModel === 'ETC' && (*/}
            {/*  <Form.Item label="AI 모델명">*/}
            {/*    <TextArea placeholder="AI 모델명" style={{ width: '600px', height: '100px' }} />*/}
            {/*  </Form.Item>*/}
            {/*)}*/}

            {/*카테고리가 뮤직이 아닐때*/}
            {selectedCategory != 'CATE003' && (
            <>
            <Row>
              <Col>
                <Descriptions>
                  <Descriptions.Item label="상세 내용 입력">
                    <Form.Item name="prpt_remark">
                      <TextArea
                        placeholder={`판매자 작성 팁\n1) 다운로드 콘텐츠 없이 프롬프트만 판매할 경우 파라미터, 네거티브 프롬프트를 포함한\n모든 내용을 작성하고 프롬프트 사용 관련한 팁도 작성해주세요.\n\n2) 프롬프트 및 다운로드 가능한 콘텐츠를 판매할 경우:\n프롬프트 및 다운로드 콘텐츠에 대한 내용과 사용 팁을 모두 작성해주세요.`}
                        style={{ width: '600px', height: '200px', paddingTop: '8px' }}
                        autoSize={{ minRows: 8, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="프롬프트 입력">
                    <Form.Item name="test_prpt">
                      <TextArea placeholder="검수팀이 테스트 할 프롬프트를 입력하세요." style={{ width: '600px', height: '200px', paddingTop: '8px' }} autoSize={{ minRows: 8, maxRows: 8 }} />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label={
                    <>
                      프롬프트 아웃풋 입력
                      <br />
                      (콘텐츠 업로드, 최대 5개)
                    </>
                  }>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      {fileList.map((file, index) => (
                        <Upload key={index} showUploadList={false} beforeUpload={() => false} onChange={(info) => handleFileChange(info.file, index)}>
                          <Button style={{ width: '120px', height: '120px' }}>{file ? file.file.name : '+'}</Button>
                        </Upload>
                      ))}
                      <div> 또는 </div>
                      <Form.Item name="text_prpt" noStyle>
                        <TextArea placeholder="텍스트 프롬프트 아웃풋" style={{ width: '300px', height: '200px', paddingTop: '8px' }} autoSize={{ minRows: 8, maxRows: 8 }} />
                      </Form.Item>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            </>
            )}
            {/*카테고리가 뮤직일 때*/}
            {selectedCategory == 'CATE003' && (
              <>
              <Row>
                <Col>
                  <Descriptions>
                    <Descriptions.Item label="상업적 이용 확인">
                      <Form.Item name="biz_confirm" valuePropName="checked">
                        <Checkbox>상업적 이용에 동의합니다.</Checkbox>
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="AI 음악 업로드">
                      <Form.Item name="">
                        {/*<Upload>*/}
                        <Upload showUploadList={false} beforeUpload={() => false} onChange={(info) => handleFileChange2(info.file)} accept="audio/mpeg">
                          {/*<Button style={{ width: '120px', height: '120px' }}>+</Button>*/}
                          <Button style={{ width: '120px', height: '120px' }}>{aiFile ? aiFile.file.name : '+'}</Button>
                        </Upload>
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="썸네일">
                      <Form.Item name="thum_path">
                        <Upload showUploadList={false} beforeUpload={() => false} onChange={(info) => handleFileChange3(info.file)} accept="image/*">
                          <Button style={{ width: '120px', height: '120px' }}>{thumFile ? thumFile.file.name : '+'}</Button>
                        </Upload>
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="AI 음악 원본 링크">
                      <Form.Item name="music_org_link">
                        <TextArea placeholder="AI 원본 페이지 링크" style={{ width: '600px', height: '50px', paddingTop: '8px' }} autoSize={{ minRows: 8, maxRows: 8 }} />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </>
            )}
            <Descriptions>
              <Descriptions.Item label="태그">
                <Form.Item name="tags">
                  <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
                    <TextArea style={{ width: '150px', height: '40px', paddingTop: '8px', marginBottom: '16px' }} value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} />
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                      {tags.map((tag, index) => (
                        <span key={index} style={{ display: 'inline-block', background: '#e0e0e0', padding: '4px', borderRadius: '4px' }}>
                          {tag}
                          <Button size="small" type="link" onClick={() => handleDelete(tag)} style={{ marginLeft: '4px' }}>
                            X
                          </Button>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div style={{ marginBottom: '16px' }}>
                    태그는 최대 10개까지 입력할 수 있으며, 등록한 태그는 마켓플레이스에서 검색 시 사용됩니다.
                    <br />
                    원하는 단어를 입력한 뒤에 쉼표 혹은 엔터키를 입력하시면 태그가 등록됩니다.
                  </div>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
            <Descriptions>
              <Descriptions.Item>
                <Form.Item>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '300px' }}>
                    <Button>취소</Button>
                    <Button type="primary" htmlType="submit" style={{ marginLeft: '16px' }}>
                      임시저장
                    </Button>
                    <Button
                      style={{ marginLeft: '16px' }}
                      onClick={() => {
                        form.submit();
                        onFinish(form.getFieldsValue(), 'reqSales');
                      }}
                    >
                      판매요청
                    </Button>
                  </div>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Form>
        </div>
      </article>
    </>
  );
};

export default SalesPrompt;

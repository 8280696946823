import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Popover } from 'antd';

const ParameterStep = ({ parameterList, selectedParameters, setSelectedParameters, promptList }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [inputValues, setInputValues] = useState({}); // 입력형 파라미터의 임시 값을 저장

  useEffect(() => {
    if (promptList.length > 0) {
      setSelectedPrompt(0); // 첫 번째 프롬프트 영역의 인덱스로 초기화
    }
  }, [promptList]);

  const handleInputSubmit = (param) => {
    const value = inputValues[param.param_id];
    setSelectedParameters((prev) => {
      const updatedParameters = [...prev];
      const paramEntry = { param_id: param.param_id, in_txt: param.in_txt, value };

      updatedParameters[selectedPrompt] = updatedParameters[selectedPrompt] || [];
      updatedParameters[selectedPrompt] = updatedParameters[selectedPrompt].filter(p => p.param_id !== param.param_id);
      
      if (value) updatedParameters[selectedPrompt].push(paramEntry);

      return updatedParameters;
    });
  };

  const handleYesNoClick = (param, value) => {
    setSelectedParameters((prev) => {
      const updatedParameters = [...prev];
      updatedParameters[selectedPrompt] = updatedParameters[selectedPrompt] || [];

      // 기존 param_id가 있으면 제거
      updatedParameters[selectedPrompt] = updatedParameters[selectedPrompt].filter(p => p.param_id !== param.param_id);
      
      // Yes 선택 시 in_txt만 추가
      if (value === 'Yes') {
        updatedParameters[selectedPrompt].push({ param_id: param.param_id, in_txt: param.in_txt });
      }

      return updatedParameters;
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#ffffff' }}>
      <Select
        placeholder="프롬프트 영역 선택"
        style={{ width: '200px' }}
        value={selectedPrompt}
        onChange={setSelectedPrompt}
        options={promptList.map((prompt, index) => ({ value: index, label: prompt.ko_nm }))}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {parameterList.map((param) => (
          <Popover
            key={param.ko_nm}
            content={
              param.in_tp === 'input' ? (
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Input
                    placeholder={`${param.in_min} - ${param.in_max}`}
                    value={inputValues[param.param_id] || ''}
                    onChange={(e) =>
                      setInputValues((prev) => ({
                        ...prev,
                        [param.param_id]: e.target.value,
                      }))
                    }
                  />
                  <Button size="small" onClick={() => handleInputSubmit(param)}>
                    Submit
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Button onClick={() => handleYesNoClick(param, 'Yes')}>Yes</Button>
                  <Button onClick={() => handleYesNoClick(param, 'No')}>No</Button>
                </div>
              )
            }
            trigger="click"
          >
            <Button>{param.ko_nm}</Button>
          </Popover>
        ))}
      </div>
    </div>
  );
};

export default ParameterStep;

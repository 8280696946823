import React, { useEffect, useState, useCallback, useRef } from 'react';
import { message, Layout, Form, Input, Button, Row, Col, Modal } from 'antd';
import RangePicker from '@components/RangePicker';
import { useLocation } from 'react-router-dom';
import Table from '@components/Table';
import ButtonExport from '@components/ButtonExportAUIGrid';
import { periodList } from '@utils/constants';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setPaymentList, setLocationKey, setYScrollPosition, INIT_STATE_PAYMENTLIST, clearPaymentList } from '@stores/paymentList';
import { useDebounce, secondsCompactFormat } from '@utils/lib';
import * as api from '@api/index';

const { Content, Header } = Layout;

const PaymentManagement = () => {
  const { key: locationKey } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const paymentListRef = useRef();
  const paymentList = useSelector((s) => s.paymentList, shallowEqual);
  const [modalVisible, setModalVisible] = useState(false);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 그리드 설정
  const paymentListGridProps = {
    editable: false,
    showRowCheckColumn: true,
  };

  // 그리드 칼럼 레이아웃 정의
  const paymentColumnLayout = [
    {
      dataField: 'pay_yn',
      headerText: '상태',
      width: '5%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        if (item.pay_dt) {
          return '지급';
        } else {
          return '미지급';
        }
      },
    },
    { dataField: 'gds_nm', headerText: '상품명', width: '15%', editable: false },
    { dataField: 'gds_qty', headerText: '상품수량', width: '5%', editable: false, dataType: 'numeric', formatString: '#,###', style: 'right' },
    { dataField: 'settle_due_date', headerText: '지급 예정일', width: '10%', editable: false },
    { dataField: 'pay_date', headerText: '지급일시', width: '12%', editable: false },
    {
      dataField: 'request_amt',
      headerText: '요청금액',
      width: '10%',
      editable: false,
      dataType: 'numeric',
      formatString: '#,###',
      style: 'right',
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        value = item.gds_amt * item.gds_qty;
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    },
    { dataField: 'charge_amt', headerText: '수수료', width: '8%', editable: false, dataType: 'numeric', formatString: '#,###', style: 'right' },
    {
      dataField: 'payment_amy',
      headerText: '지급 금액(원)',
      width: '10%',
      editable: false,
      dataType: 'numeric',
      formatString: '#,###',
      style: 'right',
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        value = item.gds_amt * item.gds_qty - item.charge_amt;
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    },
    { dataField: 'bdg_nm', headerText: '셀러', width: '10%', editable: false },
    { dataField: 'mem_nick', headerText: '닉네임', width: '15%', editable: false },
  ];

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...paymentList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listPayment(newQuery));
  };

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    paymentListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  const handleSettle = () => {
    if (window.confirm('정산하시겠습니까?')) {
    }
  };

  const handleShowAccount = () => {
    setModalVisible(true); // 팝업 보이기
  };

  const handleShowAccountCancel = () => {
    setModalVisible(false); // 팝업 보이기
  };

  const handleSearchPayment = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...paymentList.query, keyword_text, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listPayment(newQuery));
  };

  //지급관리 목록 조회
  const listPayment = useCallback(async (query) => {
    paymentListRef.current.showAjaxLoader();
    try {
      const { data } = await api.listPayment(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && paymentListRef.current.getGridData()) {
        dispatch(
          setPaymentList({
            items: [...paymentListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: paymentListRef.current.getGridData().length + data.items.length,
          }),
        );
        paymentListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setPaymentList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        paymentListRef.current.setGridData(data.items);
      }
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: paymentListRef.current.getGridData().length,
      });
      paymentListRef.current.removeAjaxLoader();
    } catch (error) {
      message.warning(error.message);
    } finally {
    }
  }, []);

  // 날짜 필터링
  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...paymentList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listPayment(newQuery));
  };

  useEffect(() => {
    // 새로고침 또는 뒤로가기로 렌더링 됐을때
    if (locationKey === paymentList.locationKey) {
      paymentListRef.current.setGridData(paymentList.items);
      paymentListRef.current.setRowPosition(paymentList.yScrollPosition);
      setupGridEvents({
        offset: paymentList.query.offset,
        limit: paymentList.query.limit,
        totalCount: paymentList.totalCount,
        currentCount: paymentList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      dispatch(clearPaymentList());
      dispatch(setLocationKey(locationKey));
      listPayment(INIT_STATE_PAYMENTLIST.query);
      form.setFieldsValue({ keyword_text: '' });
    }

    paymentListRef.current.bind('cellClick', function (e) {
      if (e.dataField === 'pay_yn') {
        if (e.item.pay_dt) {
          handleShowAccount();
        }
      }
    });
  }, [locationKey]);

  return (
    <>
      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
          <Row>
            <Form form={form} layout="inline" initialValues={paymentList?.query} onFinish={handleSearchPayment}>
              <Form.Item name="keyword_text">
                <Input placeholder="닉네임을 입력해주세요." allowClear style={{ minWidth: '300px' }} />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
            <Row>
              <RangePicker
                styleSelect={{ minWidth: '80px' }}
                placeholderSelect="지급일시"
                optionsSelect={periodList}
                valueRangePicker={[paymentList?.query?.startDt ? dayjs(paymentList?.query?.startDt) : '', paymentList?.query?.endDt ? dayjs(paymentList?.query?.endDt) : '']}
                onChange={handleDatePay}
              />
            </Row>
          </Row>
          <Row>
            <Row>검색결과</Row>
            <Row>{`총 ${paymentList.totalCount}건`}</Row>
            <Row>
              <Col>
                <Button onClick={handleSettle}>정산</Button>
                <ButtonExport ref={paymentListRef} fileName={`${secondsCompactFormat(new Date())}_지급내역조회`} text="엑셀 다운" />
              </Col>
            </Row>
          </Row>
        </Header>
        <Content>
          <Table ref={paymentListRef} columnLayout={paymentColumnLayout} customGridProps={paymentListGridProps} />
        </Content>
      </Layout>
      <Modal open={modalVisible} footer={null} onCancel={handleShowAccountCancel}></Modal>
    </>
  );
};

export default PaymentManagement;

import { Button, Form, message, Row, Col, Avatar } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '@api/index';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '@components/Loading';
import qs from 'qs';
import { BOARD_EDIT } from '@routes/pathName';
import CommentSystem from './Comment';
import {
    HeartOutlined,
    HeartFilled,
    UserOutlined,
    StarFilled,
    PictureOutlined,
    PlayCircleOutlined, BookOutlined, DownOutlined
} from '@ant-design/icons';
import {formatEncodingDate, formatNumber, getLocalStorage} from "@utils/lib";

const BoardDetail = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const { board_no: queryBoardNo, doc_no: queryDocNo, board_title: queryTitle } = qs.parse(search, { ignoreQueryPrefix: true });
    const [form] = Form.useForm();
    const formRef = useRef(form);
    const [isLoading, setIsLoading] = useState(false);
    const [lstId, setLstId] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [writerProfile, setWriterProfile] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const memKey = getLocalStorage('prptbk-token', 'mem_key');
    const memPlan = getLocalStorage('prptbk-token', 'plan_cd');

    const [boardListState, setBoardListState] = useState({
        query: {
            keyword_type: 0,
            ...(queryDocNo && { keyword_text: queryDocNo }),
            board_no: queryBoardNo || localStorage.getItem('board_no') || null,
            like_mem_key: localStorage.getItem('mem_key') || null,
            selector_2: '',
            offset: 0,
            limit: 20,
        },
        items: [],
        totalCount: 0,
        currentCount: 0,
    });

    const getBoardList = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.getBoardList(query);
            if (!data) {
                setIsLoading(false);
                return;
            }

            const boardDetail = data.items?.[0];
            if (data.items) {
                formRef.current.setFieldsValue({
                    title: boardDetail.title,
                    contents: replaceImageSrcs(boardDetail.contents, boardDetail.totalFileList),
                });
                setLstId(boardDetail.lst_id);
            }
            setBoardListState((prev) => ({
                ...prev,
                items: data.items,
                totalCount: data.total,
                currentCount: data.items.length,
            }));
            getProfile(boardDetail.lst_id);
        } catch (error) {
            message.warning(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const getLikes = async () => {
        try {
            const like_mem_key = memKey || '0';
            const params = {
                target_id: Number(boardListState.query.keyword_text),
                like_mem_key
            };
            const { data } = await api.likeList({ params });
           if(data.length > 0) {
                setIsLiked(true);
            }
        } catch (error) {
            message.error('좋아요를 불러오는데 실패했습니다.');
        }
    };

    const replaceImageSrcs = (content, files) => {
        const contentImgs = files?.filter(file => file.attr1 === 'c') || [];
        let updatedContent = content;
        contentImgs.forEach(img => {
            const imgRegex = new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g');
            updatedContent = updatedContent.replace(imgRegex, `src="${img.file_dwn_path}"`);
        });
        return updatedContent;
    };

    const downloadFile = async (filePath, fileName) => {
        try {
            const response = await fetch(filePath);
            if (!response.ok) throw new Error('파일 다운로드에 실패하였습니다.');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const toggleLike = async () => {
        const like_mem_key = localStorage.getItem('mem_key') || '0';
        const data = {
            target_id: Number(boardListState.query.keyword_text),
            like_mem_key: like_mem_key,
            own_mem_key: lstId,
            like_div: 'B',
        };
        try {
            await api.increaseLikeCount(data);
            setIsLiked((prev) => !prev);
            message.success(isLiked ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');
        } catch (error) {
            message.error('좋아요 처리 중 오류가 발생했습니다.');
        }
    };

    const handleReWriteClick = () => {
        const queryString = qs.stringify({ board_no: queryBoardNo, doc_no: queryDocNo, board_title: queryTitle });
        navigate(`${BOARD_EDIT}?${queryString}`);
    };
    useEffect(() => {
        getBoardList(boardListState.query);
        getLikes();

    }, [search]);

    const getProfile = async (room) => {
        const params = { mem_key: room };
        const response = await api.userProfile({ params });
        setWriterProfile(response.data);
        console.log(response);
    }
    const renderStars = (score) => {
        const totalStars = 5;
        const validScore = typeof score === 'number' && score >= 0 && score <= 5 ? score : 0;
        const filledStars = Math.floor(validScore);
        const halfStar = validScore % 1 !== 0;

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {[...Array(filledStars)].map((_, index) => (
                    <StarFilled key={`filled-${index}`} style={{ color: '#FFD700', fontSize: '18px' }} />
                ))}

                {halfStar && (
                    <StarFilled
                        style={{
                            color: '#FFD700',
                            fontSize: '18px',
                            clipPath: 'inset(0 50% 0 0)',
                        }}
                    />
                )}
                {[...Array(totalStars - filledStars - (halfStar ? 1 : 0))].map((_, index) => (
                    <StarFilled key={`gray-${index}`} style={{ color: 'gray', fontSize: '18px' }} />
                ))}
            </div>
        );
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Loading isLoading={isLoading}/>
            <section style={{ height: '3514px',
                alignSelf: 'stretch',
                flexGrow: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0 190px' }}>
                <div style={{width: '100%', backgroundColor: '#fff', position: 'relative', padding: '16px'}}>
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="">
                                    <div className="title" style={{
                                        height: '164px',
                                        alignSelf: 'stretch',
                                        flexGrow: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        gap: '40px',
                                        padding: 0
                                    }}>
                                        <h1 style={{
                                            width: "61px",
                                            height: "18px",
                                            flexGrow: 0,
                                            fontFamily: "Pretendard",
                                            fontSize: "15px",
                                            fontWeight: 500,
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.2",
                                            letterSpacing: "-0.75px",
                                            textAlign: "left",
                                            color: "#939ca7",
                                        }}>{boardListState.items?.[0]?.board_cate}</h1>
                                        <span style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            height: "48px",
                                            fontFamily: "Pretendard",
                                            fontSize: "40px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: 1.2,
                                            letterSpacing: "-2px",
                                            color: "#0e0e1f",
                                        }}>
                                        {form.getFieldValue('title')}
                                            <Form.Item style={{margin: 0}}>
                                                <div style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    justifyContent: "flex-end"
                                                }}>
                                                    {(memKey === 0 || memKey == boardListState.items?.[0]?.lst_id) && (
                                                        <Form.Item style={{ margin: 0 }}>
                                                            <Button onClick={handleReWriteClick} type="primary" style={{ marginRight: '10px' }}>
                                                                수정
                                                            </Button>
                                                            <Button onClick={{}} type="primary" danger>
                                                                삭제
                                                            </Button>
                                                        </Form.Item>
                                                    )}

                                                </div>
                                            </Form.Item>
                                        </span>
                                        <div className="info" style={{
                                            height: "18px",
                                            alignSelf: "stretch",
                                            flexGrow: 0,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-end",
                                            gap: "20px",
                                            padding: 0,
                                        }}>
                                            <span
                                                style={{
                                                    width: "85px",
                                                    height: "17px",
                                                    flexGrow: 0,
                                                    fontFamily: "Pretendard",
                                                    fontSize: "14px",
                                                    fontWeight: "normal",
                                                    fontStretch: "normal",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                    letterSpacing: "normal",
                                                    textAlign: "left",
                                                    color: "#0e0e1f",
                                                }}>{formatEncodingDate(boardListState.items?.[0]?.lst_dt)}</span>
                                            <span
                                                style={{
                                                    width: "70px",
                                                    height: "17px",
                                                    flexGrow: 0,
                                                    fontFamily: "Pretendard",
                                                    fontSize: "14px",
                                                    fontWeight: "normal",
                                                    fontStretch: "normal",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                    letterSpacing: "normal",
                                                    textAlign: "right",
                                                    color: "#0e0e1f",
                                                }}>조회수 {boardListState.items?.[0]?.view_cnt}</span>

                                            <div className="good" style={{
                                                width: "48px",
                                                height: "18px",
                                                flexGrow: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "4px",
                                                padding: 0,
                                                fontSize: "14px",
                                            }}><Button
                                                onClick={toggleLike}
                                                icon={isLiked ? (<HeartFilled style={{color: 'red'}}/>) : (
                                                    <HeartOutlined style={{color: 'red'}}/>)}
                                                type="text"
                                            />
                                                {boardListState.items?.[0]?.like_cnt}</div>
                                        </div>
                                    </div>
                                    <div className="profile" style={{
                                        width: "1000px",
                                        height: "75px",
                                        flexGrow: 0,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "24px",
                                        padding: "4px 0",
                                        borderRadius: "10px",
                                        marginTop: "25px",
                                    }}>
                                        <div className="userIcon">
                                            <p>
                                                <Avatar src={writerProfile.mem_img_path} size={80}
                                                        icon={<UserOutlined/>}/>
                                            </p>
                                        </div>
                                        <div className="menu_text" style={{
                                            width: "90px",
                                            height: "67px",
                                            flexGrow: 0,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "stretch",
                                            gap: "4px",
                                            padding: "8px 0",
                                        }}>
                                            <div className="User" style={{
                                                height: "21px",
                                                alignSelf: "stretch",
                                                flexGrow: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                gap: "4px",
                                                padding: "0",
                                            }}>
                                                <form style={{
                                                    width: "60px",
                                                    height: "25px",
                                                    flexGrow: 0,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                    padding: "4px 2px",
                                                    borderRadius: "20px",
                                                    borderStyle: "solid",
                                                    borderWidth: "1px",
                                                    borderImageSource: "linear-gradient(to right, #13d5df 0%, #f138fc 100%)",
                                                    borderImageSlice: 1,
                                                    backgroundImage: "linear-gradient(to right, #b041f3 0%, #ec15ff 100%), linear-gradient(to right, #13d5df 0%, #f138fc 100%)",
                                                    backgroundOrigin: "border-box",
                                                    backgroundClip: "content-box, border-box",
                                                }}>
                                                <span style={{
                                                    width: "40px",
                                                    height: "13px",
                                                    flexGrow: 0,
                                                    fontFamily: "Pretendard",
                                                    fontSize: "11px",
                                                    fontWeight: 600,
                                                    fontStretch: "normal",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                    letterSpacing: "normal",
                                                    textAlign: "justify",
                                                    color: "#fff",
                                                    marginLeft: "12px",
                                                }}>{writerProfile.plan_nm}</span>
                                                </form>
                                                <span style={{
                                                    width: "106px",
                                                    height: "19px",
                                                    flexGrow: 0,
                                                    fontFamily: "Pretendard",
                                                    fontSize: "16px",
                                                    fontWeight: 500,
                                                    fontStretch: "normal",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                    letterSpacing: "-0.32px",
                                                    textAlign: "center",
                                                    color: "#0e0e1f",
                                                }}>{writerProfile.mem_nick}</span>
                                            </div>
                                            <div className="info" style={{
                                                height: "26px",
                                                alignSelf: "stretch",
                                                flexGrow: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                gap: "20px",
                                                padding: "8px 0 0",
                                            }}>
                                                <div style={{position: 'relative', width: '100%', height: '70%'}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '10px',
                                                        borderRadius: '8px',
                                                        backgroundColor: 'white',
                                                        // border: '1px solid #ddd',
                                                        cursor: 'pointer', height: '70%'
                                                    }} onClick={toggleDropdown}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            gap: '5px'
                                                        }}>
                                                            <PictureOutlined style={{fontSize: '22px'}}/>
                                                            <div>
                                                                #{writerProfile.agg_rank
                                                                ? formatNumber(writerProfile.agg_rank.split(',')[0])
                                                                : 'N/A'}
                                                            </div>
                                                        </div>
                                                        <DownOutlined style={{fontSize: '18px'}}/>
                                                    </div>
                                                    {isOpen && (
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '200%',
                                                            left: '0',
                                                            width: '100%',
                                                            backgroundColor: 'white',
                                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                            borderRadius: '8px',
                                                            padding: '10px',
                                                            zIndex: 10,
                                                            border: '1px solid #ddd',
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                gap: '5px'
                                                            }}>
                                                                <PlayCircleOutlined style={{fontSize: '22px'}}/>
                                                                <div>
                                                                    #{writerProfile.agg_rank
                                                                    ? formatNumber(writerProfile.agg_rank.split(',')[1])
                                                                    : 'N/A'}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                gap: '5px',
                                                                marginTop: '10px'
                                                            }}>
                                                                <BookOutlined style={{fontSize: '22px'}}/>
                                                                <div>
                                                                    #{writerProfile.agg_rank
                                                                    ? formatNumber(writerProfile.agg_rank.split(',')[3])
                                                                    : 'N/A'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{
                                                    width: "14.5px",
                                                    height: "14px",
                                                    flexGrow: 0,
                                                }}>{renderStars(writerProfile.score_avg)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                                <div className="document" style={{
                                    height: 'auto',
                                    alignSelf: 'stretch',
                                    flexGrow: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '40px',
                                    padding: '0',}}></div>

                                <Form.Item label="">
                                    <div className="uploaddownload" style={{
                                        height: 'auto',
                                        alignSelf: 'stretch',
                                        flexGrow: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'stretch',
                                        gap: '12px',
                                        padding: '12px 0 16px',
                                        borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
                                        borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
                                    }}>
                                        <span style={{
                                            width: '42px',
                                            height: '14px',
                                            flexGrow: 0,
                                            fontFamily: 'Pretendard',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'center',
                                            color: '#464548',
                                        }}>첨부파일</span>
                                        {boardListState.items?.[0]?.totalFileList?.filter(file => file.attr1 === 'a').length ? (
                                            <ul style={{
                                                listStyle: 'none',
                                                paddingLeft: 0,
                                                display: 'flex',
                                                flexWrap: 'wrap'
                                            }}>
                                                {boardListState.items[0].totalFileList.filter(file => file.attr1 === 'a').map((file) => (
                                                    <li key={file.file_seq}
                                                        style={{marginBottom: '10px', marginRight: '5px'}}>
                                                        <Button
                                                            onClick={() => downloadFile(file.file_dwn_path, file.file_org_nm)}>{file.file_org_nm}</Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div>첨부된 파일이 없습니다</div>
                                        )}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="contents" label="">
                            <div
                                style={{
                                    height: 'auto',
                                    minHeight: '450px',
                                    maxHeight: '800px',
                                    overflowY: 'auto',
                                    // border: '1px solid #d9d9d9',
                                    padding: '8px',
                                    borderRadius: '2px',
                                }}
                                dangerouslySetInnerHTML={{__html: form.getFieldValue('contents')}}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <form
                                    style={{
                                        width: '106px',
                                        height: '34px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        padding: '8px 12px',
                                        borderRadius: '24px',
                                        border: 'solid 1px #e2dfe4',
                                    }}
                                >
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.389 3.666h9.777M6.39 8.999h9.777M6.39 14.333h9.777M2.833 3.666v.009m0 5.324v.01m0 5.324v.009"
                                            stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"/>
                                    </svg>
                                    <span
                                        style={{
                                            width: '49px',
                                            height: '17px',
                                            flexGrow: 0,
                                            fontFamily: 'Pretendard',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            fontStretch: 'normal',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            letterSpacing: 'normal',
                                            textAlign: 'justify',
                                            color: '#3e3d53',
                                        }}
                                    >
                                    뒤로가기
                                </span>
                                </form>
                            </div>
                        </Form.Item>
                    </Form>
                    <CommentSystem board_no={queryBoardNo} doc_no={queryDocNo}/>
                </div>
            </section>
        </>
    );
};

export default BoardDetail;
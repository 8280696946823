import React, {useCallback, useEffect, useState} from "react";
import Guide from "@pages/profile/guide";
import PromptSlider from "@components/Slider";
import popularIcon from "@assets/images/profile/popular.svg";
import newIcon from "@assets/images/profile/new.svg";
import {
    BookOutlined,
    GlobalOutlined,
    InstagramOutlined,
    PictureOutlined,
    PlayCircleOutlined,
    StarFilled
} from '@ant-design/icons';
import * as api from "@api/index";
import {getLocalStorage} from "@utils/lib";
import { useLocation } from "react-router-dom";
import '@assets/css/profile.css';
import { message } from 'antd';
import { sendAlertContents } from '@api/index';
import {profileHomeType} from "@utils/constants";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}년 ${month}월 ${day}일 가입`;
};

const ProfileHome = ({ overrideData = {} }) => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({});

  const getProfile = async () => {
    const params = { mem_key: overrideData?.mem_key ?? memKey };
    const response = await api.userProfile({ params });
    setUserProfile(response.data);
  };

    const renderStars = (score) => {
        const totalStars = 5;
        const validScore = typeof score === 'number' && score >= 0 && score <= 5 ? score : 0;
        const filledStars = Math.floor(validScore);
        const halfStar = validScore % 1 !== 0;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {[...Array(filledStars)].map((_, index) => (
          <StarFilled key={`filled-${index}`} style={{ color: '#FFD700', fontSize: '18px' }} />
        ))}
                {halfStar && (
                    <StarFilled
                        style={{
                            color: '#FFD700',
                            fontSize: '18px',
                            clipPath: 'inset(0 50% 0 0)',
                        }}
                    />
                )}
                {[...Array(totalStars - filledStars - (halfStar ? 1 : 0))].map((_, index) => (
                    <StarFilled key={`gray-${index}`} style={{ color: 'gray', fontSize: '18px' }} />
                ))}
            </div>
        );
    };

    const handleLinkClick = (url) => {
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    const [params, setParams] = useState({
        mem_key: 2,
        alrt_cd: 'ALRT007'
    });
    const [variables, setVariables] = useState(['홍길동', '판매', '2024년 12월', '판매']); // 기본 배열 설정

    const getAlertContents = useCallback(async () => {
        try {
            if (!params.mem_key || !params.alrt_cd) {
                message.warning('mem_key와 alrt_cd는 필수입니다.');
                return;
            }

            const { data } = await api.sendAlertContents({params, variables});

            if (!data) {
                message.warning('응답을 받지 못했습니다.');
                return;
            }
            message.success('성공적으로 알림이 전송되었습니다.');
        } catch (error) {
            message.warning(error.message);
        }
    });

    useEffect(() => {
        getProfile();
    }, []);

  return (
    <>
      <div
        className="profileBackArea"
        style={{
          '--background-image': `url(${userProfile.ch_img_path})`,
        }}
      >
        <div className="profileArea">
          <img src={userProfile.mem_img_path} alt={userProfile.img_file_nm} className="profileImage" />
          <div className="proBadge">{userProfile.plan_nm}</div>
          <div className="socialIcons">
            <GlobalOutlined className="icon" onClick={() => handleLinkClick(userProfile.web)} />

            {/* Instagram Icon */}
            <InstagramOutlined className="icon" onClick={() => handleLinkClick(userProfile.insta_link)} />
          </div>

          <div
            style={{
              position: 'absolute',
              left: '145px',
              marginTop: '28px',
              fontSize: '28px',
              fontWeight: 'bold',
            }}
          >
            {`@${userProfile.mem_nick}`}
          </div>
          <div
            style={{
              position: 'absolute',
              left: '145px',
              fontSize: '12px',
              marginTop: '60px',
              marginBottom: '15px',
            }}
          >
            {userProfile.fir_dt ? formatDate(userProfile.fir_dt) : 'N/A'}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '120px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '8px',
                width: '50%',
                fontSize: '16px',
                boxSizing: 'border-box',
                color: 'black',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                <PictureOutlined style={{ fontSize: '22px' }} />
                <div>#{userProfile.agg_rank ? userProfile.agg_rank.split(',')[0] : 'N/A'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                <PlayCircleOutlined style={{ fontSize: '22px' }} />
                <div>#{userProfile.agg_rank ? userProfile.agg_rank.split(',')[1] : 'N/A'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                <BookOutlined style={{ fontSize: '22px' }} />
                <div>#{userProfile.agg_rank ? userProfile.agg_rank.split(',')[3] : 'N/A'}</div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                width: '50%',
                fontSize: '14px',
                padding: '10px',
                boxSizing: 'border-box',
                color: 'white',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                {userProfile.follwer_cnt}
                <br />
                팔로워 수
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>{userProfile.score_avg}</span>
                {renderStars(userProfile.score_avg)}
                <span style={{ fontSize: '12px', color: 'gray' }}>(13)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div className="filterBox">
                <div className="type">
                    <div className="menu">
                        <div className="filterVector">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#9xhlv8ujua)">
                                    <path
                                        d="M13.577 5.25h-3.5m-2 0h-3.5m9 4h-4.5m-2 0h-2.5m9 4h-2.5m-2 0h-4.5m5.5-9v2m-3 2v2m4 2v2"
                                        stroke="#000" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="9xhlv8ujua">
                                        <path fill="#fff" transform="translate(3 3)" d="M0 0h12v12H0z"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="menu_text">
                        <span>타입</span>
                    </div>
                    <ul>
                        {profileHomeType.map((type) => (
                            <li key={type.id}>
                                <input type="radio" id={type.id} name="type" value={type.value}/>
                                <label htmlFor={type.id}>{type.label}</label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

      <div className="thumBox_Best">
        <PromptSlider title={'인기 프롬프트'} titleImg={popularIcon} card={6} slide={1} loop={true} isCircle={false} />
        <PromptSlider title={'최신 프롬프트'} titleImg={newIcon} card={6} slide={1} loop={true} isCircle={false} />
      </div>
      <button onClick={getAlertContents}>test</button>
    </>
  );
};

export default ProfileHome;
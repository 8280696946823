import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Layout, Row, Select, Tabs, Typography, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import RangePicker from '@components/RangePicker';
import Table from '@components/Table';
import * as api from '@api/index';
import { periodList } from '@utils/constants';
import ButtonExport from '@components/ButtonExportAUIGrid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDebounce, secondsCompactFormat } from '@utils/lib';
import { setQuery, setRefundList, setLocationKey, setYScrollPosition, INIT_STATE_REFUNDLIST, clearRefundList } from '@stores/refundList';
import dayjs from 'dayjs';

const Refund = () => {
  const [form] = Form.useForm();
  const { key: locationKey } = useLocation();
  const refundList = useSelector((s) => s.refundList, shallowEqual);
  const refundListRef = useRef();
  const dispatch = useDispatch();

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  //조회
  const handleSearch = ({ keyword_text }) => {
    const newQuery = { ...refundList.query, keyword_text, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listRefund(newQuery));
  };

  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...refundList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listRefund(newQuery));
  };

  const listRefund = useCallback(async (query) => {
    refundListRef.current.showAjaxLoader();
    try {
      const { data } = await api.listRefund(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && refundListRef.current.getGridData()) {
        dispatch(
          setRefundList({
            items: [...refundListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: refundListRef.current.getGridData().length + data.items.length,
          }),
        );
        refundListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setRefundList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        refundListRef.current.setGridData(data.items);
      }
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: refundListRef.current.getGridData().length,
      });

      // refundListRef.current.setGridData(data.items);

      refundListRef.current.removeAjaxLoader();
    } catch (error) {
      message.warning(error.message);
    } finally {
    }
  }, []);

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...refundList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listRefund(newQuery));
  };

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    refundListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  const handleRefund = () => {
    if (window.confirm('환불하시겠습니까?')) {
    }
  };

  // 그리드 설정
  const refundGridProps = {
    editable: false,
    showRowCheckColumn: true,
  };

  // 그리드 칼럼 레이아웃 정의
  const refundColumnLayout = [
    { dataField: 'refund_seq', headerText: '환불SEQ', visible: false },
    { dataField: 'pay_seq', headerText: '결제SEQ', visible: false },
    { dataField: 'gds_seq', headerText: '상품SEQ', visible: false },
    { dataField: 'gds_key', headerText: '상품 식별키', visible: false },
    { dataField: 'refund_stat', headerText: '상태', visible: false },
    { dataField: 'refund_stat_nm', headerText: '상태', width: '5%', editable: false },
    { dataField: 'gds_nm', headerText: '상품명', width: '15%', editable: false },
    { dataField: 'gds_amt', headerText: '상품금액', width: '8%', editable: false, dataType: 'numeric', formatString: '#,###', style: 'right' },
    { dataField: 'gds_qty', headerText: '상품수량', width: '5%', editable: false, dataType: 'numeric', formatString: '#,###', style: 'right' },
    { dataField: 'gds_refund_amt', headerText: '환불금액', width: '8%', editable: false, dataType: 'numeric', formatString: '#,###', style: 'right' },
    { dataField: 'refund_rsn_cd', headerText: '환불사유코드', visible: false },
    { dataField: 'refund_rsn_cd_nm', headerText: '환불사유', width: '8%', editable: false },
    { dataField: 'refund_due_date', headerText: '환불예정일', width: '8%', editable: false },
    { dataField: 'pay_date', headerText: '지급일시', width: '10%', editable: false },
    { dataField: 'mem_nick', headerText: '회원닉네임', width: '10%', editable: false },
    { dataField: 'remark', headerText: '비고', width: '23%', editable: false },
  ];

  useEffect(() => {
    // 새로고침 또는 뒤로가기로 렌더링 됐을때
    if (locationKey === refundList.locationKey) {
      refundListRef.current.setGridData(refundList.items);
      refundListRef.current.setRowPosition(refundList.yScrollPosition);
      setupGridEvents({
        offset: refundList.query.offset,
        limit: refundList.query.limit,
        totalCount: refundList.totalCount,
        currentCount: refundList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      dispatch(clearRefundList());
      dispatch(setLocationKey(locationKey));
      listRefund(INIT_STATE_REFUNDLIST.query);
      form.setFieldsValue({ keyword_text: '' });
    }
  }, [locationKey]);

  return (
    <>
      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
          <Row>
            <Form form={form} layout="inline" initialValues={refundList?.query} onFinish={handleSearch}>
              <Form.Item name="keyword_text">
                <Input placeholder="닉네임을 입력해주세요." allowClear style={{ minWidth: '300px' }} />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
            <Row>
              <RangePicker
                styleSelect={{ minWidth: '80px' }}
                placeholderSelect="지급일시"
                optionsSelect={periodList}
                valueRangePicker={[refundList?.query?.startDt ? dayjs(refundList?.query?.startDt) : '', refundList?.query?.endDt ? dayjs(refundList?.query?.endDt) : '']}
                onChange={handleDatePay}
              />
            </Row>
          </Row>
          <Row>
            <Row>검색결과</Row>
            <Row>{`총 ${refundList.totalCount}건`}</Row>
            <Row>
              <Col>
                <Button onClick={handleRefund}>환불</Button>
                <ButtonExport ref={refundListRef} fileName={`${secondsCompactFormat(new Date())}_환불내역`} text="엑셀 다운" />
              </Col>
            </Row>
          </Row>
        </Header>
        <Content>
          <Table ref={refundListRef} columnLayout={refundColumnLayout} customGridProps={refundGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default Refund;

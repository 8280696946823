import { Button, Form, Input, Layout, Modal, Select } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { promptStatusList, promptReviewList, rejectRsnCodeList, periodList, staffPick } from '@utils/constants';
import React, { useEffect, useRef, useState } from 'react';
import Table from '@components/Table';
import * as api from '@api/index';
import Draggable from 'react-draggable';
import dayjs from 'dayjs';
import RangePicker from '@components/RangePicker';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';

const PromptReview = (props) => {
  const [form] = Form.useForm();
  const [totalCnt, setTotalCnt] = useState('00');
  const promptListRef = useRef();
  const [sendData, setSendData] = useState({
    keyword_type: 'mem_email',
    keyword_text: '',
    prpt_stat: '000',
  });
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // 선택된 row index
  const [rejectReason, setRejectReason] = useState({
    reject_rsn_cd: '', // 선택한 거절 사유 코드
    reject_rsn: '', // 상세 내용
  });

  //'00'' 작성완료 / ''01'' 판매 요청 / ''02'' 판매중 / ''98'' 판매거절 / ''99'' 판매중지';
  var prptStat = [
    { label: '작성완료', value: '00' },
    { label: '판매 요청', value: '01' },
    { label: '판매중', value: '02' },
    { label: '판매거절', value: '98' },
    { label: '판매중지', value: '99' },
  ];

  const today = dayjs(); // 오늘 날짜
  const oneWeekAgo = today.subtract(7, 'day'); // 일주일 전 날짜

  const [sendDate, setSendDate] = useState({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });
  const aiModelList = useRef([]);
  const categoryList = useRef([]);
  const genreList = useRef([]);
  const statList = useRef([]);
  const rejectRsnList = useRef([]);
  const [rejectRsnModal, setRejectRsnModal] = useState(false);

  const onChange = (name, value) => {
    setSendData({
      ...sendData,
      [name]: value,
    });
  };

  const handleDate = ({ startDt, endDt }) => {
    setSendDate({
      st_date: startDt,
      end_date: endDt,
    });
  };

  const columnLayout = [
    {
      dataField: 'cate_cd',
      headerText: '카테고리',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const categoryCode of categoryList.current) {
          if (categoryCode['cd'] == value) {
            retStr = categoryCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'ai_model_cd',
      headerText: 'AI모델',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const aiModelCode of aiModelList.current) {
          if (aiModelCode['cd'] == value) {
            retStr = aiModelCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'genre_cd',
      headerText: '장르',
      width: '6%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const genreCode of genreList.current) {
          if (genreCode['cd'] == value) {
            retStr = genreCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_email',
      headerText: '회원ID',
      width: '12%',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'prpt_title', headerText: '프롬프트명', width: '13%', editable: false },
    { dataField: 'fir_dt', headerText: '생성일시', width: '10%', editable: false },
    { dataField: 'lst_dt', headerText: '최종상태일시', width: '10%', editable: false },
    {
      dataField: 'prpt_stat',
      headerText: '상태',
      width: '8%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return statList.current;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'reject_rsn_cd',
      headerText: '거절사유',
      width: '13%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return rejectRsnList.current;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'reject_rsn',
      headerText: '거절사유 상세',
      width: '10%',
      style: 'my-wrap-column left-custom-column',
      editable: true,
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'staff_pick',
      headerText: '스탭 pick',
      editable: true,
      width: '7%',
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: staffPick,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const gridProps = {
    showRowCheckColumn: true,
    fillColumnSizeMode: true,
    editable: true,
    usePaging: true,
    showPageButtonCount: 5,
    showPageRowSelect: true,
    pageRowCount: 20,
    pageRowSelectValues: [20, 50, 100, 200],
  };

  const getCommonCode = async () => {
    try {
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
      // 카테고리
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      categoryList.current = response2.data;
      // 장르
      const response3 = await api.fetchEqualCommonGroupCode({ grp_cd: 'GENRE' });
      genreList.current = response3.data;
      // 상태
      const response4 = await api.fetchEqualCommonGroupCode({ grp_cd: 'PRPT_STAT' });
      statList.current = response4.data;
      // 거절사유
      const response5 = await api.fetchEqualCommonGroupCode({ grp_cd: 'REJECT' });
      rejectRsnList.current = response5.data;
      // rejectRsnList.current = [
      //   { cd: '', cd_nm: '' }, // 빈 값 옵션 추가
      //   ...response5.data,
      // ];
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      prptSearch(); // 프롬프트 조회
    }
  };

  const prptSearch = async () => {
    try {
      const response = await api.getPromptList({
        keyword_type: sendData.keyword_type,
        keyword_text: sendData.keyword_text,
        prpt_stat: sendData.prpt_stat,
        st_date: sendDate.st_date,
        end_date: sendDate.end_date,
      });
      promptListRef.current.setGridData(response.data);
      setTotalCnt(response.data.length);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 거절 사유 모달창 열기
  const openRejectSaleModal = () => {
    setRejectRsnModal(true);
  };
  // 거절 사유 모달창 닫기
  const closeRejectSaleModal = () => {
    setRejectRsnModal(false);
  };

  const updatePromptReview = async () => {
    let saveItems = promptListRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    // 거절인데 사유없으면 return
    for (var i = 0; i < saveItems.length; i++) {
      var prptStat = saveItems[i].prpt_stat;
      var rejectRsnCd = saveItems[i].reject_rsn_cd;

      if ((prptStat == '98' && rejectRsnCd == '') || (prptStat == '98' && rejectRsnCd == null)) {
        alert('판매거절한 프롬프트의 거절사유를 선택하세요.');
        return;
      }
    }

    // 저장 - 상태, 거절사유, 가절사유상세, staffPick
    try {
      const response = await api.updatePromptReview(saveItems);
      alert(response.data.returnMessage);
      prptSearch();
    } catch (error) {
      console.error(error);
    }
  };

  // 모달창 저장버튼 기능
  const saveRejectReason = () => {
    if (selectedRowIndex !== null) {
      const updatedData = {
        reject_rsn_cd: rejectReason.reject_rsn_cd || 'REJECT001',
        reject_rsn: rejectReason.reject_rsn,
      };

      promptListRef.current.updateRow(updatedData, selectedRowIndex);

      // 모달 닫기
      closeRejectSaleModal();

      // 모달 초기화
      form.setFieldValue('reject_rsn_cd', 'REJECT001');
      form.setFieldValue('reject_rsn', '');
    } else {
      alert('선택된 행이 없습니다.');
    }
  };

  useEffect(() => {
    // 공통코드 조회
    getCommonCode();

    promptListRef.current.bind('cellEditEnd', function (event) {
      const columnIdx = event.columnIndex;
      const rowIndex = event.rowIndex;
      const item = event.item;

      const updatedData = {
        reject_rsn_cd: '',
        reject_rsn: '',
      };
      if (item.prpt_stat == '98' && columnIdx == 7) {
        setSelectedRowIndex(rowIndex);
        openRejectSaleModal(true);
      } else if (item.prpt_stat == '98' && columnIdx == 8) {
        const updatedData = {
          reject_rsn_cd: event.value,
        };
        promptListRef.current.updateRow(updatedData, selectedRowIndex);
      } else if (item.prpt_stat == '98' && columnIdx == 9) {
        const updatedData = {
          reject_rsn: event.value,
        };
        promptListRef.current.updateRow(updatedData, selectedRowIndex);
      } else {
        promptListRef.current.updateRow(updatedData, rowIndex);
      }
    });
  }, []);

  return (
    <>
      <Layout>
        <Header style={{ background: 'none', lineHeight: '0', padding: '0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Form form={form} layout="inline" onFinish={prptSearch} initialValues={{ keyword_type: 'mem_email', keyword_text: '', prpt_stat: '000' }}>
              <Form.Item name="prpt_stat" style={{ width: '100px' }}>
                <Select value={sendData.prpt_stat} options={promptStatusList} onChange={(e) => onChange('prpt_stat', e)} />
              </Form.Item>
              <Form.Item name="keyword_type" style={{ width: '130px' }}>
                <Select value={sendData.keyword_type} options={promptReviewList} onChange={(e) => onChange('keyword_type', e)} />
              </Form.Item>
              <Form.Item name="keyword_text">
                <Input placeholder="검색어를 입력해주세요." style={{ minWidth: '300px' }} value={sendData.keyword_text} onChange={(e) => onChange('keyword_text', e.target.value)} />
              </Form.Item>
              <RangePicker
                styleSelect={{ minWidth: '80px' }}
                placeholderSelect="생성일시"
                optionsSelect={periodList}
                valueRangePicker={[sendDate?.st_date ? dayjs(sendDate?.st_date) : oneWeekAgo, sendDate?.end_date ? dayjs(sendDate?.end_date) : today]}
                onChange={handleDate}
              />
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px', gap: '10px' }}>
            <span>
              <strong>검색결과</strong>
            </span>
            <span>총 {totalCnt}개</span>
            <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
              <Button onClick={() => updatePromptReview()}>저장</Button>
            </div>
          </div>
        </Header>
        <Content style={{ marginTop: '15px' }}>
          <Table ref={promptListRef} columnLayout={columnLayout} customGridProps={gridProps} />
        </Content>
      </Layout>
      <Modal visible={rejectRsnModal} title={<div>거절사유</div>} okText="저장" cancelText="취소" closable={false} maskClosable={false} onOk={saveRejectReason} onCancel={closeRejectSaleModal}>
        <Form form={form} name="rejectRsn" layout="vertical" style={{ maxWidth: 500, margin: '30px auto 40px' }} autoComplete="off" scrollToFirstError initialValues={{ reject_rsn_cd: 'REJECT001' }}>
          <Form.Item name="reject_rsn_cd" label="거절 사유 선택" rules={[{ required: true, message: '거절 사유를 선택해주세요.' }]}>
            {/*<Select>*/}
            <Select onChange={(value) => setRejectReason({ ...rejectReason, reject_rsn_cd: value })}>
              {rejectRsnList.current.map((item) => (
                <Select.Option key={item.cd} value={item.cd}>
                  {item.cd_nm}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="reject_rsn" label="거절 사유 상세">
            <Input.TextArea rows={6} showCount maxLength={100} onChange={(e) => setRejectReason({ ...rejectReason, reject_rsn: e.target.value })} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PromptReview;

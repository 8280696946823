import React, { useState } from 'react';
import { Form, Input, Button, Row, message, Col } from 'antd';
// icon
import logo from '@assets/images/common/logo.png';
// api
import * as api from '@api/index';

const INIT_STATE_FORGET_EMAIL_INFO = {
  mem_nick: '',
};

const ForgetEmail = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  // 이메일 찾기
  const handleForgetEmail = async (formData) => {
    try {
      setIsLoading(true);
      const { data } = await api.forgetEmail(formData);
      if (data.returnStatus === 'success') setEmail(data.mem_email);
      else {
        setEmail('');
        message.warning(data.returnMessage);
      }
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="flexColCenter">
      <article id="findPw" className="flexColCenter">
        <div className="flexColCenter logo">
          <img src={logo} alt="logo" />
        </div>

        <h1>아이디 찾기</h1>

        <Form
          form={form}
          name="forgetEmail"
          layout="vertical"
          initialValues={INIT_STATE_FORGET_EMAIL_INFO}
          onFinish={handleForgetEmail}
          autoComplete="off"
          scrollToFirstError
          style={{ width: '100%' }}
        >
          <Form.Item name="mem_nick" rules={[{ required: true, message: '닉네임을 입력해주세요!' }]}>
            <Input className="input" placeholder="닉네임을 입력해주세요." allowClear />
          </Form.Item>
          <Row style={{ display: email ? 'block' : 'none', textAlign: 'center', marginBottom: '24px' }}>
            <Col>가입하신 이메일은</Col>
            <Col>{email} 입니다.</Col>
          </Row>
          <Form.Item>
            <Button id="purpleBtn" htmlType="submit" loading={isLoading}>
              아이디 찾기
            </Button>
          </Form.Item>
        </Form>
      </article>
    </section>
  );
};

export default ForgetEmail;

import React from 'react';
// icon
import starIcon from '@assets/images/common/star_icon.png';
import userThumb from '@assets/images/common/user_thumb.png';
import albumThumb from '@assets/images/common/album_thumb.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
// lib
import { koKRFormat } from '@utils/lib';

const CardSquareImage3 = ({ data, moveToDetailPage }) => {
  return (
    <div className="staffBox flexColCenter" key={data?.prpt_id}>
      <div className="absoluteBox flexColBetween" onClick={moveToDetailPage}>
        <div className="flexRowStart">
          <img src={data?.icon_path ?? userThumb} />
          <p>{data?.ai_model_nm}</p>
        </div>

        <div className="flexColStart">
          <h1>{data?.prpt_title}</h1>

          <div className="greyBox flexRowStart">
            <img src={data?.mem_img_path ?? userIcon3} />

            <div className="flexColStart">
              <h1>{data?.mem_nick}</h1>

              <div className="flexRowBetween">
                <div className="imgBox flexRowCenter">
                  <img src={data?.icon_path ?? userThumb} />
                  {[...Array(data?.score)].map(() => (
                    <img src={starIcon} />
                  ))}
                </div>

                <p>{`₩ ${koKRFormat(data?.sale_amt)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={data?.thum_path ?? albumThumb} />
    </div>
  );
};

export default CardSquareImage3;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setUserPayList, setLocationKey, setYScrollPosition, INIT_STATE_USER_PAYLIST, clearUserPayList } from '@stores/userPayList';
import { message, Layout, Form, Input, Button, Select, Row, Col } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import dayjs from 'dayjs';
import Table from '@components/Table';
import Loading from '@components/Loading';
import ButtonExport from '@components/ButtonExportAUIGrid';
import RangePicker from '@components/RangePicker';
import UserPayDetail from './UserPayDetail';
import * as api from '@api/index';
import { secondsFormat, useDebounce, secondsCompactFormat, makeDataFormat } from '@utils/lib';
import { periodList } from '@utils/constants';
import { ADMIN_PAY_LIST, ADMIN_USER_DETAIL } from '@routes/pathName';

const { Content, Header } = Layout;

const UserPayList = ({ isActive }) => {
  const navigate = useNavigate();
  const { key: locationKey } = useLocation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const userPayListRef = useRef();
  const dispatch = useDispatch();
  const userPayList = useSelector((s) => s.userPayList, shallowEqual);
  const [isOpenUserPayDetail, setIsOpenUserPayDetail] = useState(false);
  const [inoutTpFormat, setInoutTpFormat] = useState({});
  const [gdsDivFormat, setGdsDivFormat] = useState({});
  const [payMethFormat, setPayMethFormat] = useState({});

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 결제내역 목록 가져오기 그리드 설정
  const payListGridProps = {
    showRowNumColumn: false, // 행번호 유무
    showRowCheckColumn: false,
    showStateColumn: false,
    editable: false,
    groupingFields: ['pay_no'],
    // 합계(소계) 설정
    groupingSummary: {
      // 합계 필드는 gds_amt 1개에 대하여 실시 합니다.
      dataFields: ['gds_amt'],
      rows: [
        {
          opertaion: 'SUM',
          // 소계 제외 시키는 함수
          // items (Array) : 소계의 대상이 되는 행들
          exceptFunction: function (items) {
            if (items.length <= 1) {
              // 건수가 1행인 경우 제외 시킴
              return false; // false 를 반환하면 제외 시킴
            }
            return true;
          },
        },
      ],
    },
    // 브랜치에 해당되는 행을 출력 여부
    showBranchOnGrouping: false,
    // 그룹핑 후 셀 병합 실행
    enableCellMerge: true,
    // enableCellMerge 할 때 실제로 rowspan 적용 시킬지 여부
    // 만약 false 설정하면 실제 병합은 하지 않고(rowspan 적용 시키지 않고) 최상단에 값만 출력 시킵니다.
    cellMergeRowSpan: true,
    // 그리드 ROW 스타일 함수 정의
    rowStyleFunction: function (rowIndex, item) {
      // 그룹핑으로 만들어진 합계 필드인지 여부
      if (item._$isGroupSumField) {
        // 그룹핑을 더 많은 필드로 하여 depth 가 많아진 경우는 그에 맞게 스타일을 정의하십시오.
        // 현재 3개의 스타일이 기본으로 정의됨.(AUIGrid_style.css)
        switch (
          item._$depth // 계층형의 depth 비교 연산
        ) {
          case 2:
            return 'aui-grid-row-depth3-style';
          case 3:
            return 'aui-grid-row-depth2-style';
          case 4:
            return 'aui-grid-row-depth1-style';
          default:
            return 'aui-grid-row-depth-default-style';
        }
      }
      return null;
    },
  };

  // 결제내역 목록 가져오기 그리드 칼럼 레이아웃 정의
  const payListColumnLayout = [
    {
      dataField: 'pay_no',
      headerText: '결제번호',
      width: '10%',
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          // 추후 적용
          openUserPayDetail();
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'inout_tp',
      headerText: '구분',
      width: '8%',
      labelFunction: (rowIndex, columnIndex, value) => {
        return inoutTpFormat[value] || value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'gds_div',
      headerText: '상품구분',
      width: '12%',
      labelFunction: (rowIndex, columnIndex, value) => {
        return gdsDivFormat[value] || value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'gds_nm',
      headerText: '상품명',
      width: '20%',
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          // 추후 적용
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_nick',
      headerText: '닉네임',
      width: '12%',
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(`${ADMIN_USER_DETAIL}?${qs.stringify({ mem_email: item?.mem_email, mem_key: item?.mem_key })}`);
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'pay_meth',
      headerText: '결제방법',
      width: '12%',
      labelFunction: (rowIndex, columnIndex, value) => {
        return payMethFormat[value] || value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'gds_amt',
      headerText: '결제금액',
      width: '12%',
      dataType: 'numeric',
      editRenderer: {
        autoThousandSeparator: true, // 천단위 구분자 삽입 여부
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    {
      dataField: 'fir_dt',
      headerText: '거래일시',
      dataType: 'date',
      dateInputFormat: 'yyyy-mm-dd', // 데이터의 날짜 형식
      formatString: 'yyyy.mm.dd', // 그리드에 보여줄 날짜 형식
      width: '14%',
      labelFunction: (rowIndex, columnIndex, value) => {
        return secondsFormat(value);
      },
    },
  ];

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    userPayListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  // 회원 거래내역 상세 모달창 열기
  const openUserPayDetail = () => {
    setIsOpenUserPayDetail(true);
  };

  // 회원 거래내역 상세 모달창 닫기
  const closeUserPayDetail = () => {
    setIsOpenUserPayDetail(false);
  };

  // 공통코드 결제구분 조회
  const makeInoutTpFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'INOUT_TP' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setInoutTpFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 상품구분 조회
  const makeGdsDivFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'GDS_DIV' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setGdsDivFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 결제방법 조회
  const makePayMethFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'PAY_METH' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setPayMethFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 결제내역 목록 가져오기
  const listUserPay = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.listPay({ ...query, inout_div: '01' });
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && userPayListRef.current.getGridData()) {
        dispatch(
          setUserPayList({
            items: [...userPayListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: userPayListRef.current.getGridData().length + data.items.length,
          }),
        );
        userPayListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setUserPayList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        userPayListRef.current.setGridData(data.items);
      }
      dispatch(setLocationKey(locationKey));
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: userPayListRef.current.getGridData().length,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 검색
  const handleSearchPay = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...userPayList.query, keyword_type, keyword_text, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listUserPay(newQuery));
  };

  // 날짜 필터링
  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...userPayList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listUserPay(newQuery));
  };

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...userPayList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listUserPay(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearUserPayList());
    navigate(ADMIN_PAY_LIST, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    if (isActive) {
      // 공통코드 조회
      makeInoutTpFormat();
      makeGdsDivFormat();
      makePayMethFormat();
    }

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === userPayList.locationKey) {
      userPayListRef?.current?.resize('100%');
      userPayListRef.current.setGridData(userPayList.items);
      userPayListRef.current.setRowPosition(userPayList.yScrollPosition);
      setupGridEvents({
        offset: userPayList.query.offset,
        limit: userPayList.query.limit,
        totalCount: userPayList.totalCount,
        currentCount: userPayList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      listUserPay(INIT_STATE_USER_PAYLIST.query);
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, [isActive]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
        <Row>
          <Form form={form} name="userPayList" layout="inline" initialValues={userPayList?.query} onFinish={handleSearchPay}>
            <Form.Item name="keyword_type">
              <Select options={[{ label: '닉네임', value: 'mem_nick' }]} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." allowClear />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
          <Row>
            <RangePicker
              styleSelect={{ minWidth: '80px' }}
              placeholderSelect="거래일시"
              optionsSelect={periodList}
              valueRangePicker={[userPayList?.query?.startDt ? dayjs(userPayList?.query?.startDt) : '', userPayList?.query?.endDt ? dayjs(userPayList?.query?.endDt) : '']}
              onChange={handleDatePay}
            />
          </Row>
        </Row>
        <Row>
          <Row>검색결과</Row>
          <Row style={{ marginLeft: '10px' }}>{`총 ${userPayList.totalCount}건`}</Row>
          <Row>
            <Col>
              <ButtonExport ref={userPayListRef} fileName={`${secondsCompactFormat(new Date())}_결제내역조회`} text="엑셀 다운" />
            </Col>
          </Row>
        </Row>
      </Header>
      <Content>
        <Table ref={userPayListRef} columnLayout={payListColumnLayout} customGridProps={payListGridProps} />
      </Content>
      <UserPayDetail isOpen={isOpenUserPayDetail} onClose={closeUserPayDetail} />
    </>
  );
};

export default UserPayList;

import React from 'react';
// icon
import userThumb from '@assets/images/common/user_thumb.png';
import squreThumb2 from '@assets/images/common/squre_thumb2.png';

const CardSquareMusic1 = ({ data, moveToDetailPage }) => {
  return (
    <div className="music flexColCenter" key={data?.prpt_id} onClick={moveToDetailPage}>
      <div className="flexRowStart">
        {/* <img src={data?.icon_path} /> */}
        <img src={userThumb} />
        <p>{data?.ai_model_nm}</p>
      </div>

      {/* <img src={data?.thum_path} /> */}
      <img src={squreThumb2} />

      <p>{data?.prpt_title}</p>
    </div>
  );
};

export default CardSquareMusic1;

import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Button, Form, Input, Upload, Drawer, Modal, Rate, Select} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as api from '@api/index';
import {useLocation, useNavigate} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import ClientLayout from '@components/ClientLayout';
import * as PATH from '@routes/pathName';
import {
  ArrowRightOutlined,
  CloseOutlined,
  CopyFilled,
  DownOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BannerSlider from '@components/slider/bannerSlider';

import userThum from "../../assets/images/common/user_thumb.png";
import userIcon from "../../assets/images/common/squre_thumb2.png";
import thumbnail from "../../assets/images/common/thumb.png";
import randumIcon from "../../assets/images/common/random_icon.png";
import prevIcon from "../../assets/images/common/prev_arrow_icon.png";
import nextIcon from "../../assets/images/common/next_arrow_icon.png";
import playIcon from "../../assets/images/common/play_icon3.png";
import repeatIcon from "../../assets/images/common/repeat_icon.png";
import downliadIcon from "../../assets/images/common/white_download_icon.png";
import sirenIcon from "../../assets/images/common/siren_icon.png";
import activeSirenIcon from "../../assets/images/common/active_siren_icon.png";
import arrowRight from "../../assets/images/common/grey_arrow_right2.png";
import marketIcon from "../../assets/images/common/market_icon.png";
import marketIcon2 from "../../assets/images/common/market_icon2.png";
import cartIcon from "../../assets/images/common/white_cart_icon.png";
import cartUpdateIcon from "../../assets/images/common/white_cart_update_icon.png";
import couponIcon from "../../assets/images/common/coupon_icon.png";
import questionIcon from "../../assets/images/common/question_icon.png";
import whiteQuestionIcon from "../../assets/images/common/white_question_icon.png";
import markIcon from "../../assets/images/common/yellow_mark_icon.png";
import heartIcon from "../../assets/images/common/white_heart_icon.png";
import saveIcon from "../../assets/images/common/white_save_icon.png";

import PopulerPromptSlider from '@components/slider/populerPromptSlider';
import ImageSlider from '@components/slider/imageSlider';
import useWidth from '@hooks/useWidth';
import {PROFILE_PAGE} from "@routes/pathName";
import {addPromptBad} from "@api/index";

const PromptDetail = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const location = useLocation();
  const width = useWidth();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const prpt_id = location.state.prpt_id;
  // const prpt_id = 19;
  const [form] = Form.useForm();
  const [reviewForm] = Form.useForm();
  const [reportForm] = Form.useForm();
  const [data, setData] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [replyContents, setReplyContents] = useState({});
  const badRsnList = useRef([]);

  const [sirenFlag, setSirenFlag] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  // 모바일일땐 Modal open={false} Drawer open={reviewModal}
  // 웹일땐 Modal open={reviewModal} Drawer open={false}
  const [reviewModal, setReviewModal] = useState(false);
  const [reportContentModal, setReportContentModal] = useState(false);
  const [reportContent, setReportContent] = useState({
    bad_cd: '',   // 신고 코드
    contents: '', // 상세내용
  });
  const handleReplyChange = (reviewId, value) => {
    setReplyContents((prev) => ({ ...prev, [reviewId]: value })); // 특정 reviewId에 해당하는 답글 내용만 업데이트
  };

  const reviewToggle = useCallback(() => {
    setReviewModal(!reviewModal);
  }, [reviewModal]);

  const sirenToggle = useCallback(() => {
    setSirenFlag(!sirenFlag);
  }, [sirenFlag]);

  const imageToggle = useCallback(() => {
    setImageModal(!imageModal);
  }, [imageModal]);

  const getCommonCode = async () => {
    try {
      // 신고사유
      const badRsn = await api.fetchEqualCommonGroupCode({ grp_cd: 'BAD_RSN' });
      badRsnList.current = badRsn.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  // 신고 모달창 열기
  const openReportContentModal = () => {
    setReportContentModal(true);
  };
  // 신고 모달창 닫기
  const closeReportContentModal = () => {
    setReportContentModal(false);
  };

  // 상세조회
  const getPromptDetail = async () => {
    try {
      const response = await api.getPromptDetail({
        params: { prpt_id: prpt_id },
      });
      const responseData = response.data;
      setData(responseData);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 리뷰조회
  const searchReview = async () => {
    try {
      const response = await api.getSearchReview({
        params: { prpt_id: prpt_id },
      });
      const responseData = response.data;
      setReviews(responseData);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 리뷰저장
  const registerReview = async () => {
    try {
      const reviewData = reviewForm.getFieldsValue();

      const params = {
        prpt_id: prpt_id,
        score_mem_key: auth?.mem_key,
        own_mem_key: data?.mem_key,
        score: rating,
        contents: reviewData.contents,
        parent_seq: '',
      };
      const response = await api.registerReview(params);
      window.location.reload();

      // const responseData = response.data;
      // console.log("responseData = "+responseData );
    } catch (error) {
      console.error('리뷰 등록 에러', error);
    }
  };

  // 리뷰 댓글 저장
  const addReviewComment = async (seq, scoreMemKey, ownMemKey, reviewId) => {
    try {
      const replyContent = replyContents[reviewId];
      const params = {
        prpt_id: prpt_id,
        score_mem_key: scoreMemKey,
        own_mem_key: ownMemKey,
        score: '',
        contents: replyContent,
        parent_seq: seq,
      };
      const response = await api.registerReview(params);
      window.location.reload();

      // const responseData = response.data;
    } catch (error) {
      console.error('리뷰 등록 에러', error);
    }
  };

  const handleClick = (index) => {
    const newRating = index + 1;
    setRating(newRating);
  };

  // 장바구니 담기
  const addToCart = async () => {
    const formData = form.getFieldsValue();

    const CartVO = {
      gds_key: prpt_id,
      gds_nm: data.prpt_title,
      gds_amt: Number(formData.sale_amt),
      gds_url: data.thum_path,
    };

    try {
      const response = await api.addToCart(CartVO);
      alert(response.data.returnMessage);

      if ((response.data.returnStatus = 'fail')) {
        return;
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 신고하기
  const fn_prptBad = async () => {
    try {
      const reportData = reportForm.getFieldsValue();
      const params = {
        target_id: prpt_id,
        // bad_mem_key: auth?.mem_key, // 신고자
        own_mem_key: data?.mem_key, // 신고대상
        bad_cd: reportData.bad_cd,
        contents: reportData.contents,
      };
      const response = await api.addPromptBad(params)
      alert(response.data.returnMessage);

      if (response.data.returnStatus == 'success') {
        closeReportContentModal();
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyText = () => {
    const textToCopy = data?.test_prpt || "";
    navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert("복사되었습니다!");
        })
        .catch((err) => {
          console.error("복사 실패:", err);
        });
  };

  const goToPage = (path) => {
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key } });
  };

  useEffect(() => {
    getCommonCode();
    getPromptDetail();
    searchReview();
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data)
      form.setFieldsValue(data);
      if (data.tag) {
        const formattedTags = data.tag
          .split(',')
          .map((tag) => `#${tag.trim()}`)
          .join(' ');
        form.setFieldsValue({ tag: formattedTags });
      }

      if (typeof data.sale_amt === 'number' && !isNaN(data.sale_amt)) {
        const formattedSaleAmt = data.sale_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        form.setFieldsValue({ sale_amt: formattedSaleAmt });
      }
    }
  }, [data, form]);

  return (
    <>
      <article className="flexColCenter" id="detail">
        <div className="top flexColCenter">
          <Form className="rsWrapper flexRowBetween" form={form}>
              <div className="left flexColCenter">
                <div className="user flexRowBetween">
                  <div className="flexRowCenter">
                    <img src={userThum} />
                    <p>{data ? data.ai_model_nm : ''}</p>
                  </div>

                  {width < 700 && (
                      <div className="flexRowCenter">
                        <img
                            src={data?.bad_cnt == 1  ? activeSirenIcon : sirenIcon}
                            onClick={data?.bad_cnt != 1 ? openReportContentModal : undefined}
                            // onClick={openReportContentModal}
                            // onClick={sirenToggle}
                        />
                        <img src={marketIcon2} />
                      </div>
                  )}
                </div>
                <div
                    className={
                      sirenFlag
                          ? "imgScroll flexColCenter scroll"
                          : "imgBox flexColCenter scroll"
                    }
                >
                  <div className="flexColCenter">
                    {sirenFlag && (
                        <div className="activeSiren flexRowStart">
                          <div className="flexColCenter">
                            <img src={thumbnail} />
                          </div>
                          <div className="flexColCenter">
                            <img src={thumbnail} />
                          </div>
                          <div className="flexColCenter">
                            <img src={thumbnail} />
                          </div>
                          <div className="flexColCenter">
                            <img src={thumbnail} />
                          </div>
                          <div className="flexColCenter">
                            <img src={thumbnail} />
                          </div>
                        </div>
                    )}

                    <div className="thumbnail flexColCenter">
                      <div className="absoluteBox flexRowBetween">
                        <div className="flexColCenter">
                          <img />
                        </div>

                        <div className="flexColCenter">
                          <div className="icon flexColCenter">
                            <img src={heartIcon} />
                            <img className="activeIcon" />
                          </div>
                          {/*<div className="icon flexColCenter">*/}
                          {/*  <img className="activeIcon" />*/}
                          {/*  <img src={saveIcon} />*/}
                          {/*</div>*/}
                        </div>
                      </div>

                      <img src={thumbnail} />
                    </div>
                  </div>
                </div>
                {!sirenFlag && (
                    <>
                      <div className="barBox flexColEnd">
                        <div className="bar flexColStart">
                          <div className="activeBar flexColCenter"></div>
                        </div>

                        <p>
                          <span>02:24</span> / 03:50
                        </p>
                      </div>

                      <div className="play flexRowCenter">
                        <img src={randumIcon} />
                        <img src={prevIcon} />
                        <img src={playIcon} />
                        <img src={nextIcon} />
                        <img src={repeatIcon} />
                      </div>
                    </>
                )}
                <button id="purpleBtn">
                  <img src={downliadIcon} />
                  <p>다운로드</p>
                </button>
              </div>

              <div className="right flexColEnd">
                <div className="mobileUser flexRowStart">
                  <div className="userIcon flexColCenter">
                    <p>
                      <UserOutlined />
                    </p>
                  </div>

                  <div className="flexColStart">
                    <div className="grade flexRowStart">
                      <div className="flexColCenter">베이직</div>
                      <h2>Zombimi</h2>
                    </div>

                    <p>@Zombie painter</p>
                  </div>
                </div>
                <div className="icon flexColEnd">
                  <img
                      // src={sirenFlag ? activeSirenIcon : sirenIcon}
                      src={data?.bad_cnt == 1  ? activeSirenIcon : sirenIcon}
                      onClick={data?.bad_cnt != 1 ? openReportContentModal : undefined}
                      // onClick={openReportContentModal}
                  />
                </div>

                <h1>{data ? data.prpt_title : ''}</h1>
                <div className="user flexRowStart">
                  {/*유저 썸네일정보 가져오기*/}
                  <div className="userIcon flexColCenter">
                    <p>
                      <UserOutlined />
                    </p>
                  </div>

                  <div className="flexColStart">
                    <div className="grade flexRowStart">
                      <div className="flexColCenter">{data ? data.plan_nm : '무료'}</div>
                      <h2>{form.getFieldValue("mem_nick")}</h2>
                    </div>
                    <p>@{form.getFieldValue("mem_email")}</p>
                  </div>
                </div>

                <div className="userInfo flexRowStart">
                  <div className="playNum flexRowCenter">
                    <img src={arrowRight} />
                    <p>1,542</p>
                  </div>
                  <div className="flexRowStart">
                    <Rate
                        value={data?.avg_score || 0} // 점수를 기반으로 별 표시
                        disabled
                        allowHalf // 소수점반영
                    />
                    <p>({data ? data.total_cnt : '0'})</p>
                  </div>
                </div>

                <div className="mobilePrice flexRowBetween">
                  {/*<h1>₩ 1,100</h1>*/}

                  <button id="purpleBtn">
                    <img src={downliadIcon} />
                    <p>다운로드</p>
                  </button>
                </div>

                <div className="content flexColStart" >
                  <div className="flexRowStart typeBox">
                    {/*<div className="type flexColCenter">{form.getFieldValue("cate_cd")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("genre_cd")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("prpt_ver")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("prpt_sample")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("prpt_cfg")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("prpt_step")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("prpt_seed")}</div>*/}
                    {/*<div className="type flexColCenter">{form.getFieldValue("clip_gdc")}</div>*/}
                    {form.getFieldValue("cate_cd") && (
                        <div className="type flexColCenter">{form.getFieldValue("cate_cd")}</div>
                    )}
                    {form.getFieldValue("genre_cd") && (
                        <div className="type flexColCenter">{form.getFieldValue("genre_cd")}</div>
                    )}
                    {form.getFieldValue("prpt_ver") && (
                        <div className="type flexColCenter">{form.getFieldValue("prpt_ver")}</div>
                    )}
                    {form.getFieldValue("prpt_sample") && (
                        <div className="type flexColCenter">{form.getFieldValue("prpt_sample")}</div>
                    )}
                    {form.getFieldValue("prpt_cfg") && (
                        <div className="type flexColCenter">{form.getFieldValue("prpt_cfg")}</div>
                    )}
                    {form.getFieldValue("prpt_step") && (
                        <div className="type flexColCenter">{form.getFieldValue("prpt_step")}</div>
                    )}
                    {form.getFieldValue("prpt_seed") && (
                        <div className="type flexColCenter">{form.getFieldValue("prpt_seed")}</div>
                    )}
                    {form.getFieldValue("clip_gdc") && (
                        <div className="type flexColCenter">{form.getFieldValue("clip_gdc")}</div>
                    )}
                  </div>
                  {/*설명*/}
                  <div
                      className={
                        sirenFlag
                            ? "stirenText flexColCenter"
                            : "text flexColCenter"
                      }

                  >
                    <p style={{
                      whiteSpace: "pre-wrap",    // 줄바꿈 적용
                      lineHeight: "1.5",        // 줄 간격
                      padding: "10px",          // 여백 추가
                      overflowY: "auto",        // 스크롤 가능
                      textAlign: "left"         // 텍스트 왼쪽 정렬
                    }}>{data ? data.prpt_desc : '설명 내용을 불러오는 중...'}</p>
                  </div>
                  <div className="flexRowStart tagBox">
                    <p>{form.getFieldValue("tag")}</p>
                  </div>
                </div>

                <Button id="logoutBtn" onClick={() => goToPage(PATH.MY_MARKET_PLACE)}>
                  <img src={marketIcon} />
                  <p>마켓플레이스로 가기</p>
                </Button>
                <div className="priceBox flexRowBetween">
                  <h1>₩ {form.getFieldValue("sale_amt")}</h1>
                  <Button id="darkGrey" onClick={addToCart}>
                    <img src={cartIcon} />
                    <p>장바구니</p>
                  </Button>
                  <Button id="purpleBtn" onClick={sirenFlag ? undefined : () => goToPage(PATH.PROFILE_PAGE)} >
                    {/*{sirenFlag ? (*/}
                    {/*    <>*/}
                    {/*      <img src={markIcon} className="markIcon" />*/}
                    {/*      <img src={cartUpdateIcon} />*/}
                    {/*      <p>바로구매</p>*/}
                    {/*    </>*/}
                    {/*) : (*/}
                    {/*    <>*/}
                          <img src={couponIcon} />
                          <p >사용권 구매</p>
                    {/*    </>*/}
                    {/*)}*/}
                  </Button>
                </div>
                {sirenFlag && (
                  <>
                    <div className="flexColStart tip">
                      <div className="flexColStart">
                        <h1>
                          판매자 작성 팁 판매자 작성 팁 판매자 작성 팁 판매자 작성
                          팁
                        </h1>
                        <p>
                          판매자 작성 팁 판매자 작성 팁판매자 작성 팁 판매자 작성
                          팁판매자 작성 팁 판매자 작성 팁판매자 작성 팁 판매자
                          작성 팁판매자 작성 팁 판매자 작성 팁판매자 작성 팁
                          판매자 작성 팁판매자 작성 팁 판매자 작성 팁판매자 작성
                          팁 판매자 작성 팁판매자 작성 팁 판매자 작성 팁판매자
                          작성 팁 판매자 작성 팁
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
          </Form>
          <Modal visible={reportContentModal} title={<div>신고사유</div>} okText="신고하기" cancelText="취소" closable={false} maskClosable={false} onOk={fn_prptBad} onCancel={closeReportContentModal}>
            <Form form={reportForm} name="badRsn" layout="vertical" style={{ maxWidth: 500, margin: '30px auto 40px' }} autoComplete="off" scrollToFirstError initialValues={{ bad_cd: 'BAD_RSN001' }}>
              <Form.Item name="bad_cd" label="신고 사유 선택" rules={[{ required: true, message: '신고 사유를 선택해주세요.' }]}>
                {/*<Select>*/}
                <Select onChange={(value) => setReportContent({ ...reportContent, bad_cd: value })}>
                  {badRsnList.current.map((item) => (
                      <Select.Option key={item.cd} value={item.cd}>
                        {item.cd_nm}
                      </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="contents" label="신고 사유 상세">
                <Input.TextArea rows={6} showCount maxLength={100} onChange={(e) => setReportContent({ ...reportContent, contents: e.target.value })} />
              </Form.Item>
            </Form>
          </Modal>
        </div>
        <div className="rsWrapper flexColCenter">
          <Form form={reviewForm}>
            <div className="prompt flexColStart">
              <h1>프롬프트</h1>

              <div className="flexColStart">
                <TextArea
                    value={data?.test_prpt || ""}
                    disabled
                    style={{ resize: `none`, color: `#3e3d53`, height: `100px` }}
                />
                <div className="btnBox flexRowBetween">
                  <p>
                    <DownOutlined />
                    &nbsp; 펼치기
                  </p>

                  <Button id="pinkBtn" onClick={copyText}>
                    <CopyFilled />
                    &nbsp; 복사
                  </Button>
                </div>
              </div>
            </div>

            <div className="mobileQuestion flexColCenter">
              <p>프롬프트 사용에 어려움이 있나요?</p>
              <button id="darkGrey2">
                <img src={questionIcon} />
                <img src={whiteQuestionIcon} className="hoverIcon" />
                <p>판매자에게 문의하기</p>
              </button>
            </div>

            <div className="flexRowStart reviewBox">
              <h1>리뷰 남기기</h1>
              <div className="flexRowCenter">
                <h2>{data ? data.avg_score : '0'}</h2>
                <Rate
                    value={data?.avg_score || 0} // 점수를 기반으로 별 표시
                    disabled
                    allowHalf // 소수점반영
                />
                <p>({data ? data.total_cnt : '0'})</p>
              </div>
            </div>
            <div className="reviewCreate flexRowBetween">
              <div className="review flexColStart">
                <p>별점 만족도를 남겨주세요.</p>
                <div className="flexColStart">
                  <Rate style={{ fontSize: `24px` }} />
                  {/*{Array.from({ length: 5 }, (_, index) => (*/}
                  {/*  <span*/}
                  {/*    key={index}*/}
                  {/*    onClick={() => handleClick(index)}*/}
                  {/*    style={{*/}
                  {/*      color: index < rating ? 'gold' : 'gray',*/}
                  {/*      marginRight: '5px',*/}
                  {/*      cursor: 'pointer',*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    ★*/}
                  {/*  </span>*/}
                  {/*))}*/}
                </div>
              </div>
              <div className="create flexColStart">
                <p>리뷰를 남겨주세요.</p>
                <div className="flexRowBetween">
                  <Input
                      className="input"
                      placeholder="작품에 대한 리뷰를 남겨 주세요."
                  />
                  <TextArea
                      className="mobileInput"
                      placeholder="작품에 대한 리뷰를 남겨 주세요."
                  />
                  <Button id="greyBtn" onClick={registerReview}>저장</Button>
                  {/*지울거*/}
                  {/*<Form.Item name="contents">*/}
                  {/*  <TextArea*/}
                  {/*    style={{*/}
                  {/*      width: '1000px',*/}
                  {/*      height: '30px',*/}
                  {/*      marginTop: '10px',*/}
                  {/*      marginBottom: '10px',*/}
                  {/*      marginRight: '10px',*/}
                  {/*    }}*/}
                  {/*    placeholder={'작품에 대한 리뷰를 담겨주세요.'}*/}
                  {/*  />*/}
                  {/*</Form.Item>*/}
                  {/*<Button style={{ marginTop: '10px', marginBottom: '10px', marginRight: '10px', backgroundColor: 'gray', color: 'white' }} onClick={registerReview}>*/}
                  {/*  저장*/}
                  {/*</Button>*/}
                </div>
              </div>
              <div className="question flexColCenter">
                <p>프롬프트 사용에 어려움이 있나요?</p>
                <Button id="darkGrey2">
                  <img src={questionIcon} />
                  <img src={whiteQuestionIcon} className="hoverIcon" />
                  <p>판매자에게 문의하기</p>
                </Button>
              </div>
            </div>
          {reviews
            .filter((review) => !review.parent_seq)
            .map((review, index) => (
              <div key={index} className="reviewList flexColStart">
                <div className="list flexRowBetween">
                  <div className="userReview flexRowStart">
                    <img src={userIcon} />

                    <div className="flexColStart">
                      <div className="top flexRowBetween">
                        <div className="flexRowCenter">
                          <img src={userIcon} />
                          <p>@{review.prpt_nm}</p>
                          <Rate
                              value={review.score}
                              disabled
                              allowHalf
                              className="mobileRate"
                          />
                        </div>
                        <p>{review.fir_dt}</p>
                        {auth?.mem_key == review?.own_mem_key && review?.re_cnt == 0 && <Button id="darkGreyBorder">답글</Button>}

                      </div>

                      <div className="rateBox flexRowStart">
                        <Rate
                            value={review.score}
                            disabled
                            allowHalf
                        />
                        <div className="line"></div>
                        <p>{review.fir_dt}</p>
                      </div>

                      <p className="content">
                        {review.contents}
                      </p>
                    </div>
                  </div>

                  <div className="mobileBtn flexColEnd">
                    {auth?.mem_key == review?.own_mem_key && review?.re_cnt == 0 && <Button id="darkGreyBorder">답글</Button>}
                  </div>

                  <div className="answer flexRowBetween">
                    <Input
                        className="input"
                        placeholder="답글을 남겨주세요."
                        defaultValue={review.rep_contents || ''}
                        readOnly={!!review.rep_contents}
                        onChange={(e) => handleReplyChange(review.seq, e.target.value)}
                    />
                    <TextArea
                        className="mobileInput"
                        placeholder="답글을 남겨주세요."
                        defaultValue={review.rep_contents || ''}
                        readOnly={!!review.rep_contents}
                        onChange={(e) => handleReplyChange(review.seq, e.target.value)}
                    />
                    {review.re_cnt == 0 && data?.mem_key == auth?.mem_key && (
                        <Button id="greyBtn" onClick={() => addReviewComment(review.seq, review.score_mem_key, review.own_mem_key, review.seq)}>
                          저장
                        </Button>
                    )}
                  </div>
                </div>
                {/*지울거*/}
                {/*<div style={{ width: '700px', marginTop: '110px', display: 'flex' }}>*/}
                {/*  <Form.Item>*/}
                {/*     <TextArea*/}
                {/*      placeholder="답글을 남겨주세요."*/}
                {/*      style={{ marginRight: '10px', width: '650px' }}*/}
                {/*      defaultValue={review.rep_contents || ''}*/}
                {/*      onChange={(e) => handleReplyChange(review.seq, e.target.value)}*/}
                {/*    /> */}
                {/*  </Form.Item>*/}
                {/*  {review.re_cnt == 0 && data?.mem_key == auth?.mem_key && (*/}
                {/*    <Button style={{ marginTop: '20px' }} onClick={() => addReviewComment(review.seq, review.score_mem_key, review.own_mem_key, review.seq)}>*/}
                {/*      저장*/}
                {/*    </Button>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            ))}
          <div className="allReview flexColStart">
            <p onClick={reviewToggle}>
              전체 리뷰 보기 <ArrowRightOutlined />
            </p>
          </div>

          {/*<div className="promptBox flexColCenter">*/}
          {/*  <div className="line"></div>*/}

          {/*  <div className=" flexRowCenter">*/}
          {/*    <img />*/}
          {/*    <p>인기 프롬프트</p>*/}
          {/*    <button id="greyBtn2">*/}
          {/*      <RightOutlined />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="flexColCenter slide">*/}
          {/*  <PopulerPromptSlider />*/}
          {/*</div>*/}
          {/* 슬라이드 */}

          {/*<div className="promptBox flexColCenter">*/}
          {/*  <div className="line"></div>*/}

          {/*  <div className=" flexRowCenter">*/}
          {/*    <img />*/}
          {/*    <p>최신 프롬프트</p>*/}
          {/*    <button id="greyBtn2">*/}
          {/*      <RightOutlined />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="flexColCenter slide">*/}
          {/*  <PopulerPromptSlider />*/}
          {/*</div>*/}
          </Form>
        </div>

        <BannerSlider />
      </article>

      <Drawer
          open={false}
          footer={null}
          closable={false}
          width={`100%`}
          bodyStyle={{ background: `#eff8f9` }}
      >
        <aticle id="reviewModal" className="flexColCenter">
          <div className="reviewTitle flexColEnd">
            <p onClick={reviewToggle}>
              <CloseOutlined />
            </p>
          </div>

          <div className="reviewBox flexRowStart">
            <h1>리뷰</h1>
            <h2>@Zombie painter에 대한 리뷰</h2>
            <h3>3.8</h3>
            <Rate />
            <p>(13)</p>
          </div>

          <div className="listBox flexColCenter scroll">
            <div className="listScroll flexColCenter">
              <div className="list flexRowBetween">
                <div className="userReview flexRowStart">
                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <div className="flexRowCenter">
                        <img src={userIcon} />
                        <p>@Musicker</p>
                        <Rate
                            className="mobileRate"
                            style={{ fontSize: `10px` }}
                        />
                      </div>

                      <p>22 sep 2024</p>
                    </div>

                    <div className="rateBox flexRowStart">
                      <Rate />
                      <div className="line"></div>
                      <p>22 sep 2024</p>
                    </div>

                    <p className="content">
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~ 능력자~~♥
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥
                    </p>
                  </div>
                </div>

                <div className="mobileBtn flexColEnd">
                  <button id="darkGreyBorder">답글</button>
                </div>

                <div className="answer flexRowBetween">
                  <Input className="input" placeholder="답글을 남겨주세요." />
                  <TextArea
                      className="mobileInput"
                      placeholder="답글을 남겨주세요."
                  />
                  <button id="greyBtn">저장</button>
                </div>
              </div>

              <div className="list flexRowBetween">
                <div className="userReview flexRowStart">
                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <div className="flexRowCenter">
                        <img src={userIcon} />
                        <p>@Musicker</p>
                        <Rate
                            className="mobileRate"
                            style={{ fontSize: `10px` }}
                        />
                      </div>

                      <p>22 sep 2024</p>
                    </div>

                    <div className="rateBox flexRowStart">
                      <Rate />
                      <div className="line"></div>
                      <p>22 sep 2024</p>
                    </div>

                    <p className="content">
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~ 능력자~~♥
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥
                    </p>
                  </div>
                </div>

                <div className="mobileBtn flexColEnd">
                  <button id="darkGreyBorder">답글</button>
                </div>

                <div className="answer flexRowBetween">
                  <Input className="input" placeholder="답글을 남겨주세요." />
                  <TextArea
                      className="mobileInput"
                      placeholder="답글을 남겨주세요."
                  />
                  <button id="greyBtn">저장</button>
                </div>
              </div>

              <div className="list flexRowBetween">
                <div className="userReview flexRowStart">
                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <div className="flexRowCenter">
                        <img src={userIcon} />
                        <p>@Musicker</p>
                        <Rate
                            className="mobileRate"
                            style={{ fontSize: `10px` }}
                        />
                      </div>

                      <p>22 sep 2024</p>
                    </div>

                    <div className="rateBox flexRowStart">
                      <Rate />
                      <div className="line"></div>
                      <p>22 sep 2024</p>
                    </div>

                    <p className="content">
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥너무너무 환상적이에요~ 저도 이렇게 만들면
                      좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~ 저도 이렇게
                      만들면 좋겠어요~ 능력자~~♥너무너무 환상적이에요~ 저도
                      이렇게 만들면 좋겠어요~ 능력자~~♥ 너무너무 환상적이에요~
                      저도 이렇게 만들면 좋겠어요~ 능력자~~♥너무너무
                      환상적이에요~ 저도 이렇게 만들면 좋겠어요~ 능력자~~♥
                      너무너무 환상적이에요~ 저도 이렇게 만들면 좋겠어요~
                      능력자~~♥
                    </p>
                  </div>
                </div>

                <div className="mobileBtn flexColEnd">
                  <button id="darkGreyBorder">답글</button>
                </div>

                <div className="answer flexRowBetween">
                  <Input className="input" placeholder="답글을 남겨주세요." />
                  <TextArea
                      className="mobileInput"
                      placeholder="답글을 남겨주세요."
                  />
                  <button id="greyBtn">저장</button>
                </div>
              </div>
            </div>
          </div>
        </aticle>
      </Drawer>

      <Modal open={reviewModal} footer={null} closable={false} width={`800px`}>
        <aticle id="reviewModal" className="flexColCenter">
          <div className="reviewTitle flexRowBetween">
            <h1>리뷰</h1>
            <p onClick={reviewToggle}>
              <CloseOutlined />
            </p>
          </div>

          <div className="reviewBox flexRowStart">
            <h2>@{form.getFieldValue("mem_nick")}에 대한 리뷰</h2>
            <h3>{form.getFieldValue("avg_score")}</h3>
            <Rate
                value={data?.avg_score || 0}
                disabled
                allowHalf
            />
            <p>({data ? data.total_cnt : '0'})</p>
          </div>
          {reviews
              .filter((review) => !review.parent_seq)
              .map((review, index) => (
                <div key={index} className="listBox flexColCenter scroll">
                  <div className="listScroll flexColCenter">
                    <div className="list flexRowBetween">
                      <div className="userReview flexRowStart">
                        <img src={userIcon} />

                        <div className="flexColStart">
                          <div className="top flexRowBetween">
                            <p>@{review.prpt_nm}</p>
                          </div>

                          <div className="rateBox flexRowStart">
                            <Rate
                                value={review.score}
                                disabled
                                allowHalf
                            />
                            <div className="line"></div>
                            <p>{review.fir_dt}</p>
                          </div>

                          <p className="content">
                            {review.contents}
                          </p>
                        </div>
                      </div>

                      <div className="answer flexColStart">
                        <div className="flexRowStart">
                          <h1>@{review.re_mem_nm}</h1>
                          <p>{review.rep_fir_dt}</p>
                        </div>
                        <p>"{review.rep_contents}"</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </aticle>
      </Modal>

      {imageModal && (
          <div className="imageModal flexColCenter">
            <div className="flexColCenter">
              <div className="flexRowEnd pagination">
                <h1>1 / 5</h1>
                <p>
                  <CloseOutlined />
                </p>
              </div>

              <h1>1 / 5</h1>

              <ImageSlider />
            </div>
          </div>
      )}
    </>
  );
};

export default PromptDetail;

import { restApi, querystring } from './config';

// 기초 데이터 조회
export const getBaseData = (data) => {
  return restApi.get('/api/admin/baseData', querystring(data));
};
// 기초 데이터 저장
export const saveBaseData = (data) => {
  return restApi.post('api/admin/saveBaseData', data);
};
// 파일 업로드
export const fileUpload = (formData) => {
  return restApi.post('/api/admin/baseDataFileUpload', formData);
};
// 전체 트리 조회
export const fetchElementsList = (data) => {
  return restApi.post('/api/elementsManagement/fetchElementsList', data);
};
//기존 요소 조회
export const fetchExistingElements = (data) => {
  return restApi.post('/api/elementsManagement/fetchExistingElements', data);
};
// 요소 저장
export const saveElementInfo = (formData) => {
  return restApi.post('/api/elementsManagement/saveElementInfo', formData);
};
// 가사 빌더 조회
export const fetchLyricsList = (data) => {
  return restApi.post('/api/elementsManagement/fetchBuilderList', data);
};
// 요소 조회
export const fetchElementList = (data) => {
  return restApi.post('/api/elementsManagement/fetchElementList', data);
};
// 요소 단일 조회
export const fetchElementInformation = (data) => {
  return restApi.post('/api/elementsManagement/fetchElementInformation', data);
};
// 업로드 파일 조회
export const elFileSearch = (data) => {
  return restApi.post('/api/admin/elFileSearch', data);
};
// 빌더 옵션 조회
export const fetchBuilderOptions = (data) => {
  return restApi.post('/api/elementsManagement/fetchBuilderOptions', data);
};
// 카테고리 하위의 빌더 조회
export const fetchBuilderUnderCategory = (data) => {
  return restApi.post('/api/elementsManagement/fetchBuilderUnderCategory', data);
};

// 프롬프트 판매
export const salesPrompt = (formData) => {
  return restApi.post('/api/myPages/salesPrompt', formData);
};
// 프롬프트 조회
export const getSalesPrompt = (data) => {
  return restApi.get('/api/myPages/getSalesPrompt', data);
};
// 사업자번호 체크
export const businessNoChk = (data) => {
  return restApi.post('/api/myPages/businessNoChk', data);
};
// ai_model 인증여부 체크
export const aiModelCertChk = (data) => {
  return restApi.post('/api/myPages/aiModelCertChk', data);
};

// 프롬프트 상세 조회
export const getPromptDetail = (data) => {
  return restApi.get('/api/myPages/getPromptDetail', data);
};

// 리뷰 조회
export const getSearchReview = (data) => {
  return restApi.get('/api/myPages/searchReview', data);
};

// 프롬프트 리뷰 등록
export const registerReview = (data) => {
  return restApi.post('/api/myPages/registerReview', data);
};

import React from 'react';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

const CustomRangePicker = ({
  placeholderRangePicker = ['시작일', '종료일'],
  placeholderSelect,
  valueRangePicker = ['', ''],
  onChange,
  optionsSelect,
  styleRangePicker,
  styleSelect,
  picker = 'date', // year | month | date
  isDisabledDate = false,
  maxYears = 0,
  maxMonths = 0,
  maxDays = 0,
}) => {
  const getYearMonth = (date) => date.year() * 12 + date.month();

  // Disabled max years from the selected date
  const disabledYearsDate = (current, from) => {
    if (from && maxYears > 0) {
      const minDate = from.add(-(maxYears - 1), 'years');
      const maxDate = from.add(maxYears - 1, 'years');

      return current.year() < minDate.year() || current.year() > maxDate.year();
    }
    return false;
  };

  // Disabled max months from the selected date
  const disabledMonthsDate = (current, from) => {
    if (from && maxMonths > 0) {
      const minDate = from.add(-(maxMonths - 1), 'months');
      const maxDate = from.add(maxMonths - 1, 'months');

      return getYearMonth(current) < getYearMonth(minDate) || getYearMonth(current) > getYearMonth(maxDate);
    }
    return false;
  };

  // Disabled max days from the selected date
  const disabledDaysDate = (current, from) => {
    if (from && maxDays > 0) {
      return Math.abs(current.diff(from, 'days')) >= maxDays;
    }
    return false;
  };

  const handleDisabledDate = (current, { from, type }) => {
    if (!isDisabledDate) return false;

    if (type === 'year') return disabledYearsDate(current, from);
    if (type === 'month') return disabledMonthsDate(current, from);
    if (type === 'date') return disabledDaysDate(current, from);

    return false;
  };

  return (
    <>
      {!!optionsSelect && (
        <Select
          style={{ ...styleSelect }}
          options={optionsSelect}
          placeholder={placeholderSelect}
          onChange={(value) => {
            const dateValue = value.substring(0, value.length - 1);
            const dateText = value.substring(value.length - 1);
            onChange({
              startDt: dayjs().subtract(dateValue, dateText).format('YYYY-MM-DD'),
              endDt: dayjs().format('YYYY-MM-DD'),
            });
          }}
        />
      )}
      <DatePicker.RangePicker
        picker={picker}
        style={{ ...styleRangePicker }}
        value={valueRangePicker}
        placeholder={placeholderRangePicker}
        disabledDate={handleDisabledDate}
        onChange={(period) => {
          onChange({
            startDt: period && period[0] ? period[0].startOf(picker).format('YYYY-MM-DD') : '',
            endDt: period && period[1] ? period[1].endOf(picker).format('YYYY-MM-DD') : '',
          });
        }}
      />
    </>
  );
};

export default CustomRangePicker;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useWidth from '@hooks/useWidth';
import { Button, Checkbox, Input, List, message, Row, Select, Space } from 'antd';
import { MoreOutlined, StarFilled, StarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import '@assets/css/admin.css';

import thumbanil from '@assets/images/common/thumb.png';
import tashIcon from '@assets/images/common/prompt_trash_icon.png';
import activeTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import starIcon from '@assets/images/common/star_icon.png';
import searchIcon from '@assets/images/common/search_icon.png';
import listIcon from '@assets/images/common/list_icon.png';
import folderIcon from '@assets/images/common/folder_icon.png';
import { isEmpty, useDebounce } from '@utils/lib';
import * as api from '@api/index';
import dayjs from 'dayjs';
import { MultiBackend } from 'react-dnd-multi-backend';
import HTML5toTouch from '@assets/js/HTML5toTouch';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { PROMPT_MANAGEMENT } from '@routes/pathName'; // 설정 파일 경로

const PromptManagement = () => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);
  const width = useWidth();
  const [listTab, setListTab] = useState(0);
  const aiModelList = useRef(); // AI모델 리스트 ( 공통코드)
  const searchOptions = [
    { label: '제목', value: 'title' },
    { label: 'AI모델', value: 'model' },
  ];
  const sortOptions = [
    { label: '최신순', value: 'recent' },
    { label: '제목순', value: 'title' },
  ];
  const initUserPromptList = {
    query: {
      keyword_type: 'title',
      keyword_text: '',
      sort: 'recent',
      bookmark: '',
      mem_key: auth?.mem_key,
      parent_obj_id: auth?.mem_key + '-1', // 최상위 폴더
      trash_obj_id: auth?.mem_key + '-2', // 휴지통
      offset: 0,
      limit: 20,
    },
    items: [],
    currentTitle: auth?.mem_nick + '님의 폴더',
  };
  const [userPromptList, setUserPromptList] = useState({ ...initUserPromptList, ...state?.userPromptList });
  const [selectedItems, setSelectedItems] = useState([]); //선택된 item
  const [dragActive, setDragActive] = useState(false); // 드래그 상태

  /** 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // 섬네일
  const getThumbnail = (item) => {
    switch (item.folder_div) {
      case '00': // 폴더
        return <img src={folderIcon} className="icon" />;
      case '01': // 프롬프트
        return <img src={item.thum_path} />;
      case '99': // 휴지통
        return <img src={tashIcon} className="icon" />;
    }
  };

  /** drag and drop */

  // 체크
  const handleChecked = (e, targetId) => {
    const isChecked = e.target.checked; // 현재 체크 상태
    setSelectedItems((prev) => {
      if (isChecked) {
        // 체크된 경우 targetId 추가
        return [...prev, targetId];
      } else {
        // 체크 해제된 경우 targetId 제거
        return prev.filter((id) => id !== targetId);
      }
    });
  };

  /** api */
  // 유저 프롬프트 목록 조회
  const getUserPromptList = useCallback(async (query) => {
    try {
      setLoading((prev) => ({ ...prev, promptLoad: true }));
      const { data } = await api.getUserPromptList(query);

      setUserPromptList((prev) => ({
        ...prev,
        items: data.items,
        currentTitle: prev.currentTitle,
      }));
      console.log(data);
    } catch (e) {
      console.log('조회 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, promptLoad: false }));
    }
  }, []);

  const handleDrop = (draggedItems, targetId) => {
    draggedItems.forEach((draggedId) => {
      // draggedId를 이용해 아이템을 targetId에 드롭 처리
      console.log(`드래그한 항목: ${draggedId}, 드롭 대상: ${targetId}`);
    });

    // 드래그 대상이 본인이면 return 처리
    if (draggedItems.includes(targetId)) {
      return;
    }
    console.log(draggedItems);

    movePromptFolder(targetId, draggedItems);
  };

  // 북마크 저장, 해제
  const toggleBookmark = async (targetItem) => {
    try {
      console.log(isEmpty(targetItem.bookmark));
      const { data } = await api.toggleBookmark({
        mem_key: userPromptList.query.mem_key,
        obj_id: targetItem.obj_id,
        bookmark: isEmpty(targetItem.bookmark) ? 'Y' : '',
      });

      message.info(isEmpty(targetItem.bookmark) ? '즐겨찾기 등록' : '즐겨찾기 해제');

      getUserPromptList(userPromptList.query);
    } catch (e) {}
  };

  // 폴더 이동 (휴지통으로이동 포함)
  const movePromptFolder = async (parentId, draggedItems) => {
    console.log(draggedItems);
    try {
      setLoading((prev) => ({ ...prev, movePromptFolder: true }));
      const { data } = await api.movePromptFolder({
        mem_key: userPromptList.query.mem_key,
        parent_obj_id: parentId,
        drag_items: draggedItems,
      });
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log('저장에러');
    } finally {
      setSelectedItems([]); // selected Item 초기화
      setLoading((prev) => ({ ...prev, movePromptFolder: false }));
    }
  };

  // 더블클릭시 해당 폴더로 진입
  const handleDoubleClick = (item) => {
    /** 00:폴더 / 01:파일 / 99:휴지통 */
    // 파일이 아니고, 휴지통에 들어있지 않을 때 진입가능
    if (item.folder_div != '01' && item.parent_obj_id != userPromptList.query.trash_obj_id) {
      const newQuery = { ...userPromptList.query, parent_obj_id: item.obj_id };
      setUserPromptList((prev) => ({ ...prev, query: newQuery, currentTitle: item.obj_nm }));
      navigate(PROMPT_MANAGEMENT, { state: { userPromptList: { ...userPromptList, query: newQuery, currentTitle: item.obj_nm } } });
    }
  };
  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  /** 상태관리? */
  useEffect(() => {
    // 첫 진입시
    if (isEmpty(state)) {
      getCommonCode(); // 공통코드 조회
      setUserPromptList(initUserPromptList);
      handleDebounce(() => getUserPromptList(initUserPromptList.query)); // 프롬프트 리스트 조회
    } else {
      setUserPromptList(state.userPromptList);
      handleDebounce(() => getUserPromptList(state.userPromptList.query)); // 프롬프트 리스트 조회
    }
  }, [state]);

  useEffect(() => {
    setSelectedItems([]); // selected Item 초기화
  }, [userPromptList]);

  const ListComponent = () => {
    return (
      <div className="flexColCenter">
        <div className="list flexColCenter">
          {userPromptList.items.map((item) => {
            const [{ isDragging }, drag] = useDrag(() => ({
              type: 'ITEM',
              item: () => {
                // 드래그 item 설정 & 드래그 시작 이벤트 설정

                // selectedItems에 포함되지 않은 항목 드래그 시 선택항목에 추가
                if (!selectedItems.includes(item.obj_id)) {
                  requestAnimationFrame(() => {
                    setSelectedItems([item.obj_id]);
                  });
                  return { selectedItems: [item.obj_id] };
                }
                return { selectedItems };
              },
              collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
              }),
              canDrag: item.folder_div != '99', // 휴지통인 경우 드래그 불가
            }));

            const [{ isOver, canDrop }, drop] = useDrop(() => ({
              accept: 'ITEM', // type이 ITEM인 객체만 drop 가능
              drop: (draggedItem) => {
                handleDrop(draggedItem.selectedItems, item.obj_id);
              }, // 드래그된 모든 아이템과 현재 ID를 전달
              canDrop: (draggedItem) => {
                // 드래그된 아이템의 obj_id가 현재 아이템과 같거나 folder_div가 '01'이 아니면 드롭 가능
                return !selectedItems.includes(item.obj_id) && item.folder_div != '01';
              },
              collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
              }),
            }));

            const dndRef = React.useRef(null);
            drag(drop(dndRef)); // drag와 drop을 결합

            return (
              <div
                ref={dndRef}
                key={item.obj_id}
                data-id={item.obj_id}
                className={`flexRowBetween ${selectedItems.includes(item.obj_id) ? 'selected' : ''} ${isOver && canDrop ? 'dropHover' : ''} ${isDragging ? 'dragging' : ''}`}
                onDoubleClick={() => {
                  handleDoubleClick(item);
                }}
              >
                <Checkbox className="checkbox" checked={selectedItems.includes(item.obj_id)} onChange={(e) => handleChecked(e, item.obj_id)} disabled={item.folder_div === '99' ? true : false} />

                <div className="thumbnail flexColCenter">{getThumbnail(item)}</div>

                <div className="title flexColStart">
                  {/*<p>인증실패</p>*/}
                  <h1>{item.obj_nm}</h1>
                  {item.folder_div != '01' ? (
                    <div className="flexRowStart">
                      <p>총 {item.child_cnt}개</p>
                    </div>
                  ) : (
                    <div className="flexRowStart">
                      <img src={thumbanil} />
                      <h2>{item.ai_model_nm}</h2>
                      <p>
                        {item.cate_nm}
                        {!isEmpty(item.genre_nm) ? `/${item.genre_nm}` : '대체 텍스트'}
                      </p>
                    </div>
                  )}
                </div>

                <div className="channel flexColStart">
                  <p>{item.path_nm.slice(0, -1).join(' > ')}</p>
                </div>

                {/*<div className="mark flexColCenter">인증실패</div>*/}

                <div className="star flexColCenter" onClick={() => toggleBookmark(item)}>
                  {item.bookmark ? (
                    <>
                      <img src={starIcon} />
                    </>
                  ) : (
                    <>
                      <StarOutlined style={{ color: 'orange' }} />
                      <img src={starIcon} className="activeImg" />
                    </>
                  )}
                </div>

                <div className="flexColCenter date">{dayjs(item.lst_dt).format('YYYY-MM-DD')}</div>

                <div className="more flexColCenter">
                  <p>
                    <MoreOutlined />
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <article className="flexColCenter" id="admin">
        <div className="flexColCenter">
          <div className="searchBox flexRowBetween">
            <div className="flexRowCenter leftBox">
              {width < 700 ? (
                <>
                  <Select options={sortOptions} className="select" value={userPromptList.query.sort} />

                  <img src={starIcon} />
                  <img src={tashIcon} />
                </>
              ) : (
                <>
                  <Select options={searchOptions} className="select" value={userPromptList.query.keyword_type} />

                  <div className="search flexRowStart">
                    <img src={searchIcon} />
                    <Input className="input" placeholder="검색어를 입력해주세요." />
                  </div>
                </>
              )}
            </div>

            <div className="flexRowCenter rightBox">
              {width < 700 ? (
                <img src={searchIcon} />
              ) : (
                <>
                  <button id="logoutBtn">
                    <div>
                      <img src={starIcon} />
                      <img src={starIcon} className="activeImg" />
                    </div>
                  </button>

                  <button id="logoutBtn">
                    <div>
                      <img src={tashIcon} />
                      <img src={activeTrashIcon} className="activeImg" />
                    </div>
                    <p className="text">삭제</p>
                  </button>

                  <Select options={sortOptions} className="select" value={userPromptList.query.sort} />
                </>
              )}

              <div className="flexRowCenter listBtn">
                <div className="btn flexColCenter" onClick={() => setListTab(0)} id={listTab === 0 ? 'active' : 'nothing'}>
                  <p>
                    <UnorderedListOutlined />
                  </p>
                </div>
                <div className="btn flexColCenter" onClick={() => setListTab(1)} id={listTab === 1 ? 'active' : 'nothing'}>
                  <img src={listIcon} />
                </div>
              </div>
            </div>

            <div className="mobile flexRowBetween">
              <Select options={searchOptions} className="select" value={userPromptList.query.keyword_type} />

              <div className="search flexRowStart">
                <Input className="input" placeholder="검색어를 입력해주세요." />
              </div>
            </div>
          </div>

          {listTab === 0 && <ListComponent />}

          {listTab === 1 && (
            <div className="flexColCenter">
              <div className="list2 flexRowStart">
                <div className="flexColCenter" id="img">
                  <div className="thumbnail flexColCenter">
                    <div className="absoluteBox flexColBetween">
                      <div className="flexRowStart">
                        <Checkbox className="checkbox" />

                        <img src={thumbanil} />
                        <h2>Midjourney</h2>
                      </div>

                      <h1>아름다운 스테인글라스 제작 프롬프트</h1>
                    </div>

                    <h1>ABC</h1>
                    <img src={thumbanil} />
                  </div>
                  <div className="bottom flexRowBetween">
                    <p>총 25개</p>

                    <div className="flexRowCenter">
                      <img src={starIcon} />
                      <div className="more flexColCenter">
                        <p>
                          <MoreOutlined />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </article>
    </DndProvider>
  );
};

export default PromptManagement;

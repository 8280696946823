import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import authReducer from './auth';
import userListReducer from './userList';
import paymentListReducer from './paymentList';
import refundListReducer from './refundList';
import userPayListReducer from './userPayList';
import companyPayListReducer from './companyPayList';
import chatReducer from './chat';
import totalPayAmountListReducer from './totalPayAmountList';
import cartReducer from './cart';
import sellerListReducer from './sellerList';
import inquiryListReducer from './inquiryList';

const rootReducer = configureStore({
  reducer: persistReducer(
    {
      key: 'root',
      version: 1,
      storage,
    },
    combineReducers({
      auth: authReducer,
      userList: userListReducer,
      paymentList: paymentListReducer,
      refundList: refundListReducer,
      userPayList: userPayListReducer,
      companyPayList: companyPayListReducer,
      chat: chatReducer,
      totalPayAmountList: totalPayAmountListReducer,
      cart: cartReducer,
      sellerList: sellerListReducer,
      inquiryList: inquiryListReducer,
    }),
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(rootReducer);
export default rootReducer;

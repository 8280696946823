import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const INIT_STATE_USER_PAYLIST = {
  query: {
    keyword_type: 'mem_nick',
    keyword_text: '',
    startDt: dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
    endDt: dayjs().format('YYYY-MM-DD'),
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const userPayListSlice = createSlice({
  name: 'userPayList',
  initialState: INIT_STATE_USER_PAYLIST,
  reducers: {
    clearUserPayList: () => INIT_STATE_USER_PAYLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setUserPayList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearUserPayList, setQuery, setUserPayList, setLocationKey, setYScrollPosition } = userPayListSlice.actions;

export default userPayListSlice.reducer;

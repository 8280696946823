import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

const Loading = ({ isLoading, timeout = 1000 }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) setIsActive(true);
    }, timeout);

    return () => {
      setIsActive(false);
      clearTimeout(timer);
    };
  }, [isLoading]);

  if (isLoading && isActive) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'rgba(255,255,255,0.8)',
          zIndex: 9,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin spinning={isLoading}></Spin>
      </div>
    );
  }
};

export default Loading;

import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import UserPayList from './UserPayList';
import TotalPayAmountList from './TotalPayAmountList';
import CompanyPayList from './CompanyPayList';

const PayList = (props) => {
  const [activeTabKey, setActiveTabKey] = useState('userPayList'); // 활성화된 tab

  // 탭 변경시
  const handleTab = (key) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <Layout>
        <Tabs
          type="card"
          onChange={handleTab}
          items={[
            {
              label: '회원 거래내역',
              key: 'userPayList',
              forceRender: true,
              children: <UserPayList isActive={activeTabKey === 'userPayList'} />,
            },
            {
              label: '총 결제금액',
              key: 'totalPayList',
              forceRender: true,
              children: <TotalPayAmountList isActive={activeTabKey === 'totalPayList'} />,
            },
            {
              label: '업체 광고관리',
              key: 'companyPayList',
              forceRender: true,
              children: <CompanyPayList isActive={activeTabKey === 'companyPayList'} />,
            },
          ]}
        />
      </Layout>
    </>
  );
};

export default PayList;

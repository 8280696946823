import { restApi, querystring, headersForSession } from './config';
import { AUTH_ADMIN } from '@utils/constants';


// AI 인증 리스트
export const getAiCertList = (data) => {
    return restApi.get('/api/admin/aiCertList', querystring(data));
}

export const updateAiCert = (data) => {
    // headersForSession(AUTH_ADMIN);
    return restApi.post('/api/admin/updateAiCert', data);
};


import React from 'react';
// component
import ContentMusic from '@pages/main/ContentMusic';
import ContentImage from '@pages/main/ContentImage';
import CommunityHome from '@pages/communities';
import Banner from '@components/slider/Banner';
import ListImageCard from '@pages/main/ListImageCard';

const Main = (props) => {
  return (
    <>
      {/*<BannerMainTop />*/}
      <Banner bannerCd="BANNER001" bannerTp="Top" />

      <ContentMusic />

      {/*<BannerMainBody1 />*/}
      <Banner bannerCd={{ left: 'BANNER001', right: 'BANNER001' }} bannerTp="MiddleTwo" />

      <ContentImage />

      {/*<BannerMainBody2 />*/}
      <Banner bannerCd="BANNER001" bannerTp="MiddleOne" />

      <ListImageCard />

      {/*<BannerMainBody1 />*/}
      <Banner bannerCd={{ left: 'BANNER001', right: 'BANNER001' }} bannerTp="MiddleTwo" />

      <CommunityHome isMainPage={true} />

      {/*<BannerMainBottom />*/}
      <Banner bannerCd="BANNER001" bannerTp="Bottom" />
    </>
  );
};

export default Main;

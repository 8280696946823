import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
// component
import CardSquareImage3 from '@pages/main/CardSquareImage3';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListStaffPickImage = () => {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (image) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: image?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: image?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 스태프 픽 이미지 리스트 가져오기
  const listImage = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE001',
        keyword_tab: '',
        staff_pick: 'Y',
        offset: 0,
        limit: 12,
      });
      if (!data) return;
      setImageList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listImage());
  }, []);

  return (
    <div className="flexColCenter staff">
      <div className="titleBox flexRowBetween">
        <div className="flexRowCenter title">
          <img src={musicIcon3} alt="music_icon" />
          <p>스태프 픽</p>
        </div>

        <div id="whiteBtn" className="web" onClick={() => moveToPage(PATH.IMAGE_HOME)}>
          더보기
        </div>
      </div>

      <div className="staffList flexRowStart">
        {isLoading ? (
          <>
            <Skeleton
              avatar
              active
              paragraph={{
                rows: 4,
              }}
            />
            <Skeleton
              avatar
              active
              paragraph={{
                rows: 4,
              }}
            />
            <Skeleton
              avatar
              active
              paragraph={{
                rows: 4,
              }}
            />
          </>
        ) : (
          <>
            {imageList?.map((image) => (
              <CardSquareImage3 data={image} moveToDetailPage={() => moveToDetailPage(image)} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ListStaffPickImage;

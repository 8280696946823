import React, { useEffect, useState } from 'react';
import Loading from "@components/Loading";

const Tabs = ({ tabs, onTabClick, initialTab }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentComponent, setCurrentComponent] = useState(null);
    const [activeTab, setActiveTab] = useState(initialTab || tabs[0].id);

    useEffect(() => {
        setActiveTab(initialTab || tabs[0].id);
    }, [initialTab]);

    useEffect(() => {
        const loadComponent = async () => {
            setIsLoading(true);
            try {
                const selectedTab = tabs.find((tab) => tab.id === activeTab);
                if (selectedTab) {
                    await new Promise(resolve => setTimeout(resolve, 200));
                    setCurrentComponent(selectedTab.component);
                }
            } catch (error) {
                console.error("Error loading component:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadComponent();
    }, [activeTab, tabs]);

    const handleTabChange = (tabId) => {
        if (onTabClick && !onTabClick(tabId)) {
            return;
        }
        setActiveTab(tabId);
    };

    return (
        <>
            <article className="flexColCenter" id="prompt">
                <div className="rsWrapper flexColCenter">
                    <div className="flexRowCenter title">
                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                onClick={() => handleTabChange(tab.id)}
                                className="flexColCenter"
                                id={activeTab === tab.id ? "active" : "nothing"}
                            >
                                <div className="round"></div>
                                <h1>{tab.label}</h1>
                            </div>
                        ))}
                    </div>
                    {isLoading ? (
                        <Loading isLoading={isLoading} />
                    ) : (
                        currentComponent
                    )}
                </div>
            </article>
        </>
    );
};

export default Tabs;
import React, { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Skeleton } from 'antd';
// icon
import boostIcon from '@assets/images/common/boost_icon.png';
import useIcon from '@assets/images/common/use_icon.png';
import followIcon from '@assets/images/common/user_icon2.png';
import starIcon from '@assets/images/common/star_icon.png';
import { RightOutlined } from '@ant-design/icons';
import userThumb from '@assets/images/common/user_thumb.png';
import rankIcon from '@assets/images/common/rank_1.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import albumThumb from '@assets/images/common/album_thumb.png';
// component
import CardSquareImage2 from '@pages/main/CardSquareImage2';
// lib
import { koKRFormat } from '@utils/lib';
// path
import * as PATH from '@routes/pathName';
// api
import * as api from '@api/index';
// redux
import { setFollowingList } from '@stores/auth';

const DetailImage = ({ selectedImage }) => {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const auth = useSelector((s) => s.auth, shallowEqual);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (image) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: image?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: image?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 팔로우 상태 확인
  const checkFollowing = () => {
    const result = auth.followingList?.filter((following) => following?.mem_key === selectedImage?.mem_key);
    setIsFollowing(result.length > 0);
  };

  // 해당 사용자가 만든 이미지 리스트 가져오기
  const listImage = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        mem_key: selectedImage?.mem_key,
        cate_cd: 'CATE001',
        keyword_tab: 'new',
        offset: 0,
        limit: 4,
      });
      if (!data) return;
      setImageList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedImage]);

  // 팔로우 요청
  const requestFollow = useCallback(async () => {
    try {
      await api.addFollower({ mem_key: selectedImage?.mem_key, flw_mem_key: auth.mem_key });
      const { data } = await api.listFollower({ flw_mem_key: auth.mem_key });
      if (!data) return;
      const followingList = data.items.map((followingItem) => {
        return {
          mem_key: followingItem.mem_key,
          mem_email: followingItem.mem_email,
        };
      });
      dispatch(setFollowingList(followingList));
      message.success('팔로우 요청에 성공하였습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage) {
      checkFollowing();
      listImage();
    }
  }, [selectedImage, auth?.followingList]);

  return (
    <div className="rankBox flexColCenter">
      {isLoading ? (
        <Skeleton
          avatar
          active
          paragraph={{
            rows: 4,
          }}
          style={{ margin: '50px 0' }}
        />
      ) : (
        <div className="rsWrapper flexRowBetween">
          <div className="flexColCenter thumbnail">
            <div className="absoluteBox flexColBetween">
              <div className="flexRowBetween">
                <div className="flexRowCenter">
                  <img src={selectedImage?.icon_path ?? userThumb} alt="thumb" />
                  <p>{selectedImage?.ai_model_nm}</p>
                </div>

                <img src={selectedImage?.rank_icon_path ?? rankIcon} alt="rankIcon" />
              </div>

              <h1 onClick={() => moveToDetailPage(selectedImage)}>{selectedImage?.prpt_title}</h1>

              <div className="flexColEnd priceBox">
                <p>{`₩ ${koKRFormat(selectedImage?.sale_amt)}`}</p>
              </div>
            </div>
            <img src={selectedImage?.thum_path ?? albumThumb} />
          </div>

          <div className="infoBox flexColBetween">
            <div className="flexColStart userBox">
              <div className="flexRowStart">
                <img src={selectedImage?.mem_img_path ?? userIcon3} alt="user" />

                <div className="flexColStart">
                  <h1 onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: selectedImage?.mem_email, mem_key: selectedImage?.mem_key })}>{selectedImage?.mem_nick}</h1>

                  <div className="flexRowStart follow">
                    <img src={selectedImage?.icon_path ?? userThumb} />
                    <p>{`#${koKRFormat(selectedImage?.rnk_add)}`}</p>

                    {[...Array(selectedImage?.score)].map((_, index) => (
                      <img key={index} src={starIcon} />
                    ))}
                  </div>
                </div>
              </div>

              <div className="flexRowBetween btnBox">
                <div id="whiteBorderBtn">
                  <img src={boostIcon} />
                  후원하기
                </div>
                <div id="whiteBorderBtn">
                  <img src={useIcon} />
                  사용의뢰
                </div>
                <div id={'whiteBorderBtn'} className={isFollowing ? 'active' : ''} onClick={() => !isFollowing && requestFollow()}>
                  <img src={followIcon} />
                  팔로우
                </div>
              </div>
            </div>

            <div className="flexColStart contentsBox">
              <p onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: selectedImage?.mem_email, mem_key: selectedImage?.mem_key })}>
                {selectedImage?.mem_nick} <span>님의 다른 콘텐츠</span>
                <RightOutlined />
              </p>

              <div className="flexRowStart contentsList">
                {imageList?.map((image) => (
                  <CardSquareImage2 data={image} moveToDetailPage={() => moveToDetailPage(selectedImage)} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailImage;

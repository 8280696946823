import React from 'react';
import { LeftOutlined } from '@ant-design/icons';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className="arrowLeft flexColCenter" onClick={onClick}>
      <p>
        <LeftOutlined />
      </p>
    </div>
  );
};

export default PrevArrow;

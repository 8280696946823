import * as PATH from '@routes/pathName';
import { AI_CERT, ADMIN_INQUIRY_HOME, MY_MARKET_PLACE, MY_PROMPT_MANAGEMENT } from '@routes/pathName';
import { AppstoreOutlined, BarsOutlined, MessageOutlined } from '@ant-design/icons';
import React from 'react';

// 헤더 메뉴
export const headerMenu = [
  {
    key: '1',
    label: '프롬프트 빌더',
    children: [
      {
        key: '1-1',
        label: '프롬프트 생성',
        path: PATH.PROMPT_HOME,
      },
      {
        key: '1-2',
        label: '프롬프트 관리',
        path: PATH.PROMPT_MANAGEMENT,
      },
    ],
  },
  {
    key: '2',
    label: '음악',
    path: PATH.MUSIC_HOME,
  },
  {
    key: '3',
    label: '이미지',
    path: PATH.IMAGE_HOME,
  },
  {
    key: '4',
    label: '영상',
    path: PATH.VIDEO_HOME,
  },
  {
    key: '5',
    label: '텍스트',
    path: PATH.TEXT_HOME,
  },
  {
    key: '6',
    label: '커뮤니티',
    path: PATH.COMMUNITY_HOME,
  },
  {
    key: '7',
    icon: MessageOutlined,
    path: PATH.CHAT_HOME,
  },
  {
    key: '8',
    label: 'MY Page',
    path: PATH.MYPAGE_HOME,
    children: [
      {
        key: '8-0',
        label: '내 프로필(임시)',
        path: PATH.MYPAGE_PROFILE,
      },
      {
        key: '8-1',
        label: '내 프로필',
        path: PATH.PROFILE_PAGE,
      },
      {
        key: '8-2',
        label: '프롬프트 생성',
        path: PATH.PROMPT_HOME,
      },
      {
        key: '8-3',
        label: '프롬프트 관리',
        path: PATH.PROMPT_MANAGEMENT,
      },
      {
        key: '8-4',
        label: '프롬프트 판매',
        path: PATH.SALES_PROMPT_HOME,
      },
      {
        key: '8-5',
        label: '내 마켓플레이스',
        path: PATH.MY_MARKET_PLACE,
      },
    ],
  },
];

// 이미지 마켓플레이스 필터 아이템
export const filterItemMarketPlaceImage = [
  {
    label: 'Product',
    children: [
      {
        label: 'Prompts',
      },
      {
        label: 'Bundles',
      },
      {
        label: 'Apps',
      },
    ],
  },
  {
    label: 'Type',
    children: [
      {
        label: 'All',
      },
      {
        label: 'Image',
      },
      {
        label: 'Text',
      },
    ],
  },
  {
    label: 'Sort by',
    children: [
      {
        label: 'Trending',
      },
      {
        label: 'Most Popular',
      },
      {
        label: 'Newest',
      },
    ],
  },
  {
    label: 'Time',
    children: [
      {
        label: 'All time',
      },
      {
        label: 'Past Month',
      },
      {
        label: 'Past Week',
      },
      {
        label: 'Past 24 hours',
      },
    ],
  },
  {
    label: 'Model',
    children: [
      {
        label: 'All',
      },
      {
        label: 'DALL-E',
      },
      {
        label: 'GPT',
      },
      {
        label: 'Leonardo Ai',
      },
      {
        label: 'Llama',
      },
      {
        label: 'Midjourney',
      },
      {
        label: 'Stable Diffusion',
      },
    ],
  },
];

export const sidebarMenuItemAdmin = [
  {
    name: '회원 관리',
    children: [
      {
        name: '회원 관리',
        path: PATH.ADMIN_USER_LIST,
      },
      {
        name: '맴버십 플랜관리',
        path: PATH.ADMIN_PLAN_HOME,
      },
      {
        name: '랭킹 관리',
        path: PATH.ADMIN_RANKING_HOME,
      },
      {
        name: 'AI 인증 관리',
        path: PATH.AI_CERT,
      },
    ],
  },
  {
    name: '빌더 관리',
    children: [
      {
        name: '빌더 구성 관리',
        path: PATH.ADMIN_BUILDER_HOME,
        state: 'builder',
      },
      {
        name: '빌더 카테고리 관리',
        path: PATH.ADMIN_CATEGORY_HOME,
        state: 'category',
      },
      {
        name: 'AI모델 관리',
        path: PATH.ADMIN_MODEL_HOME,
      },
      {
        name: '파라미터 관리',
        path: PATH.ADMIN_PARAM_HOME,
      },
    ],
  },
  {
    name: '데이터 관리',
    children: [
      {
        name: '기초 데이터 관리',
        path: PATH.BASE_DATA_HOME,
      },
      {
        name: '그룹 관리',
        path: PATH.ADMIN_GROUP_HOME,
        state: 'group',
      },
      {
        name: '라이브러리 관리',
        path: PATH.ADMIN_LIBRARY_HOME,
        state: 'library',
      },
    ],
  },
  {
    name: '마켓플레이스',
    children: [
      {
        name: '프롬프트 검토',
        path: PATH.PROMPT_REVIEW_HOME,
      },
    ],
  },
  {
    name: '이벤트 관리',
    children: [
      {
        name: '쿠폰',
        path: PATH.EVENT_COUPON_HOME,
      },
    ],
  },
  {
    name: '정산 관리',
    children: [
      {
        name: '결제 관리',
        path: PATH.ADMIN_PAY_LIST,
      },
      {
        name: '수수료 관리',
        path: PATH.ADMIN_CHARGE_MANAGEMENT_HOME,
      },
      {
        name: '지급 관리',
        path: PATH.ADMIN_PAYMENT_MANAGEMENT_HOME,
      },
      {
        name: '환불 관리',
        path: PATH.ADMIN_REFUND_HOME,
      },
    ],
  },
  {
    name: '시스템 관리',
    children: [
      {
        name: '통계',
        path: PATH.ADMIN_HOME,
      },
      {
        name: '게시판 관리',
        path: PATH.INTEGRATED_BOARD_HOME,
      },
      {
        name: '고객문의 관리',
        path: PATH.ADMIN_INQUIRY_HOME,
      },
      {
        name: '광고 관리',
        path: PATH.BANNER_HOME,
      },
      {
        name: '보안 관리',
        path: PATH.ADMIN_HOME,
      },
      {
        name: '공통코드 관리',
        path: PATH.COMMON_CODE_HOME,
      },
      {
        name: '알림 관리',
        path: PATH.ADMIN_ALARM_HOME,
      },
      {
        name: '배치 & 스케줄러',
        path: PATH.ADMIN_HOME,
      },
      {
        name: '판매자 목록',
        path: PATH.SELLER_LIST,
      },
    ],
  },
];

export const userKeywordTypeList = [
  { label: '이메일', value: 'mem_email' },
  { label: '닉네임', value: 'mem_nick' },
];

export const baseDataDivList = [
  { label: '국문', value: 'ko_nm' },
  { label: '영문', value: 'en_nm' },
  { label: '설명', value: 'desc' },
  { label: 'key', value: 'key' },
  { label: 'attr', value: 'attr' },
];

export const couponSearchList = [
  { label: '이벤트명', value: 'cpn_nm' },
  { label: '이벤트 번호', value: 'cpn_cd' },
];

export const searchStatusList = [
  { label: '상태 전체', value: '000' },
  { label: '신규', value: '00' },
  { label: '진행중', value: '01' },
  { label: '종료', value: '99' },
];

export const boardCategoryList = [
  { label: '게시판', value: '1' },
  { label: '커뮤니티', value: '2' },
];

export const boardDisp = [
  { label: 'List 형', value: '1' },
  { label: '썸네일 형', value: '2' },
];

export const selectYN = [
  { label: 'O', value: 'Y' },
  { label: 'X', value: 'N' },
];

export const allowedExtensionsImage = ['.jpg', '.jpeg', '.png', '.svg'];
export const allowedExtensionsMp3 = ['.mp3'];
export const allowedExtensionsMp4 = ['.mp4'];

export const promptReviewList = [
  { label: '회원ID', value: 'mem_email' },
  { label: '프롬프트 제목', value: 'prpt_title' },
];

export const staffPick = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const promptStatusList = [
  { label: '상태', value: '000' },
  { label: '작성완료', value: '00' },
  { label: '판매요청', value: '01' },
  { label: '판매중', value: '02' },
  { label: '판매거절', value: '98' },
  { label: '판매중지', value: '99' },
];

export const rejectRsnCodeList = [
  { label: '거절사유1', value: 'REJECT001' },
  { label: '거절사유2', value: 'REJECT002' },
  { label: '거절사유3', value: 'REJECT003' },
];

export const bannerApr = [
  { label: '승인요청', value: '01' },
  { label: '활성화', value: '02' },
  { label: '반려', value: '91' },
  { label: '비활성', value: '99' },
];

// dayjs format: 년/월/일: y/M/d
export const periodList = [
  { label: '1년', value: '1y' },
  { label: '3개월', value: '3M' },
  { label: '1개월', value: '1M' },
  { label: '일주일', value: '7d' },
  { label: '오늘', value: '0d' },
];

// 카드형, 리스트형 구분
export const segDisplay = [
  { value: 'List', icon: <BarsOutlined /> },
  { value: 'Card', icon: <AppstoreOutlined /> },
];
export const chatTabs = [
  { key: 'normal', label: '유저' },
  { key: 'system', label: '시스템' },
];
export const sortOption = [
  { value: 'new', label: '최신순' },
  { value: 'old', label: '오래된순' },
];

export const marketplaceSrot = {
  label: '정렬',
  value: 'sort',
  children: [
    { label: '최신순', value: 'recent' },
    { label: '판매순', value: 'sales' },
    { label: '인기순', value: 'popular' },
  ],
};

export const boardSearch = [
  { label: '전체', value: '1' },
  { label: '제목', value: '2' },
  { label: '내용', value: '3' },
];

export const boardTotalSearch = [
  { label: '검색어 전체', value: '' },
  { label: '제목', value: '2' },
  { label: '내용', value: '3' },
  { label: '제목 + 내용', value: '1' },
  { label: '작성자', value: '4' },
];

export const boardMySearch = [
  { label: '게시물 전체', value: '' },
  { label: '작성 댓글', value: '1' },
  { label: '작성 게시물', value: '2' },
  { label: '좋아요 게시물', value: '3' },
];

export const boardSort = [
  { label: '최신순', value: '1' },
  { label: '인기순', value: '2' },
  { label: '오래된 순', value: '3' },
];

export const bankList = [
  { label: '산업은행', value: '0002' },
  { label: '기업은행', value: '0003' },
  { label: '국민은행', value: '0004' },
  { label: '수협중앙회', value: '0007' },
  { label: '농협은행', value: '0011' },
  { label: '지역농축협', value: '0012' },
  { label: '우리은행', value: '0020' },
  { label: 'SC은행', value: '0023' },
  { label: '씨티은행', value: '0027' },
  { label: '대구은행', value: '0031' },
  { label: '부산은행', value: '0032' },
  { label: '광주은행', value: '0034' },
  { label: '제주은행', value: '0035' },
  { label: '전북은행', value: '0037' },
  { label: '경남은행', value: '0039' },
  { label: '새마을금고', value: '0045' },
  { label: '신협', value: '0048' },
  { label: '저축은행', value: '0050' },
  { label: 'HSBC', value: '0054' },
  { label: '도이치은행', value: '0055' },
  { label: 'JP모간', value: '0057' },
  { label: 'BOA은행', value: '0060' },
  { label: 'BNP 은행', value: '0061' },
  { label: 'ICBC', value: '0062' },
  { label: '산림조합', value: '0064' },
  { label: 'CCB', value: '0067' },
  { label: '우체국', value: '0071' },
  { label: '하나은행', value: '0081' },
  { label: '신한은행', value: '0088' },
  { label: '케이뱅크', value: '0089' },
  { label: '카카오뱅크', value: '0090' },
  { label: '토스뱅크', value: '0092' },
  { label: '유안타증권', value: '0209' },
  { label: 'KB증권', value: '0218' },
  { label: '다올투자', value: '0227' },
  { label: '미래에셋', value: '0238' },
  { label: '삼성증권', value: '0240' },
  { label: '한국투자', value: '0243' },
  { label: 'NH투자', value: '0247' },
  { label: '교보증권', value: '0261' },
  { label: '하이증권', value: '0262' },
  { label: '현대차증권', value: '0263' },
  { label: '키움증권', value: '0264' },
  { label: 'LS증권', value: '0265' },
  { label: 'SK증권', value: '0266' },
  { label: '대신증권', value: '0267' },
  { label: '한화증권', value: '0269' },
  { label: '하나금융', value: '0270' },
  { label: '토스증권', value: '0271' },
  { label: '신한투자', value: '0278' },
  { label: 'DB금융', value: '0279' },
  { label: '유진투자', value: '0280' },
  { label: '메리츠증권', value: '0287' },
  { label: '부국증권', value: '0290' },
  { label: '신영증권', value: '0291' },
  { label: '케이프증권', value: '0292' },
  { label: '한국포스증권', value: '0294' },
];

// 세션처리 헤더값
export const AUTH_LOGIN = 'login';
export const AUTH_ADMIN = 'admin';
export const AUTH_BEGINNER = 'beginner';
export const AUTH_BASIC = 'basic';
export const AUTH_PRO = 'pro';

// 로컬스토리지 키값
export const FORBIDDEN_WORD_KEY = 'forbidden_word';

export const aiCertApr = [
  { label: '승인요청', value: '01' },
  { label: '승인완료', value: '02' },
  { label: '반려', value: '91' },
  { label: '비활성', value: '99' },
];

export const aiCertSort = [
  { label: '최신순', value: '1' },
  { label: '시작일 순', value: '2' },
  { label: '종료일 순', value: '3' },
];

export const profileHomeType = [
  { id: "all", value: "all", label: "전체" },
  { id: "music", value: "music", label: "음악" },
  { id: "image", value: "image", label: "이미지" },
  { id: "video", value: "video", label: "영상" },
  { id: "text", value: "text", label: "텍스트" },
];

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import mainBanner from '@assets/images/common/mainBanner.png';
import whiteArrowRight from '@assets/images/common/white_arrow_right.png';
import dayjs from 'dayjs';
import * as api from '@api/index';
const Banner = ({ bannerCd, bannerTp }) => {
  /**
   * bannerCd = {left: "BANNERCD", right: "BANNERCD"}
   * or
   * bannerCd = "BANNERCD"
   *
   * bannerTp = "Top", "Bottom", "MiddleOne", "MiddleTwo"
   * */

  const toDate = dayjs().format('YYYYMMDD');
  const [bannerList, setBannerList] = useState({ one: [], left: [], right: [] });
  const draggingRef = useRef(false); // 드레깅 유무

  const settings = (list) => ({
    dots: true,
    arrows: false,
    infinite: list?.length > 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
  });

  const getBannerList = async (cd, type) => {
    const params = { banner_cd: cd, to_date: toDate };
    const { data } = await api.getBannerByLoc(params);
    if (!data) return;

    setBannerList((prev) => ({ ...prev, [type]: data }));
  };

  const handleBeforeChange = () => {
    draggingRef.current = true;
  };
  const handleAfterChange = () => {
    draggingRef.current = false;
  };

  const moveToUrl = (item) => {
    if (!draggingRef.current) {
      window.open(item.banner_url, '_blank');
      updateBannerView(item.banner_seq);
    }
  };

  // 조회 수 업데이트
  const updateBannerView = (bannerSeq) => {
    api.updateBannerView({ banner_seq: bannerSeq });
  };

  const onError = (e) => {
    e.currentTarget.src = mainBanner;
  };

  useEffect(() => {
    if (bannerTp === 'MiddleTwo') {
      getBannerList(bannerCd.left, 'left');
      getBannerList(bannerCd.right, 'right');
    } else {
      getBannerList(bannerCd, 'one');
    }
  }, [bannerCd, bannerTp]);

  const BannerTopAndBottom = () => (
    <div className="slider-container" id={bannerTp === 'Top' ? 'mainSlider' : 'bannerSlider'}>
      <Slider {...settings(bannerList.one)}>
        {bannerList.one.map((item, index) => (
          <div className="bannerBox" key={index} onClick={() => moveToUrl(item)}>
            <div className="flexColCenter">
              <div className="rsWrapper flexColCenter">
                {bannerTp === 'Top' && (
                  <div id="whiteBorderBtn" onClick={() => moveToUrl(item)}>
                    <p>바로가기</p>
                    <img src={whiteArrowRight} alt="white_arrow_right" />
                  </div>
                )}
              </div>
            </div>
            <img src={item.banner_img_path} alt="main_banner" onError={onError} />
          </div>
        ))}
      </Slider>
    </div>
  );

  const BannerMiddleOne = () => (
    <article id="subBanner2" className="slider-container flexColCenter">
      <div className="rsWrapper">
        <Slider {...settings(bannerList.one)}>
          {bannerList.one.map((item, index) => (
            <div style={{ width: '100%' }} key={index} onClick={() => moveToUrl(item)}>
              <img src={item.banner_img_path} alt="subBanner" onError={onError} />
            </div>
          ))}
        </Slider>
      </div>
    </article>
  );

  const BannerMiddleTwo = () => (
    <article id="subBannerWrapper" className="flexColCenter">
      <div className="rsWrapper flexRowBetween">
        <div className="subBanner">
          <Slider {...settings(bannerList.left)}>
            {bannerList.left.map((item, index) => (
              <img src={item.banner_img_path} key={index} onClick={() => moveToUrl(item)} onError={onError} />
            ))}
          </Slider>
        </div>
        <div className="subBanner">
          <Slider {...settings(bannerList.right)}>
            {bannerList.right.map((item, index) => (
              <img src={item.banner_img_path} key={index} onClick={() => moveToUrl(item)} onError={onError} />
            ))}
          </Slider>
        </div>
      </div>
    </article>
  );

  if (bannerTp === 'Top' || bannerTp === 'Bottom') {
    return <BannerTopAndBottom />;
  }
  if (bannerTp === 'MiddleOne') {
    return <BannerMiddleOne />;
  }
  if (bannerTp === 'MiddleTwo') {
    return <BannerMiddleTwo />;
  }
  return null;
};

export default Banner;

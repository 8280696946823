import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Form, Input, message, Checkbox, Button } from 'antd';
// icon
import logo from '@assets/images/common/logo.png';
// component
import ButtonSocialLogin from '@components/ButtonSocialLogin';
// api
import * as api from '@api/index';
// redux
import { setAuth, clearAuth, setFollowingList, setFollowerList } from '@stores/auth';
// path
import * as PATH from '@routes/pathName';
// lib
import { encryptRSA, isAdmin } from '@utils/lib';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const INIT_STATE_LOGIN_INFO = {
  mem_email: '',
  mem_pwd: '',
};

const Login = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['rememberUserId']);

  // 로그인 요청
  const handleLogin = async (formData) => {
    try {
      const { data } = await api.login({
        mem_email: formData.mem_email,
        mem_pwd: encryptRSA(formData.mem_pwd),
      });
      if (!data) return;
      // '1.가입대기' 상태이므로, 회원가입 화면으로 이동
      else if (data.mem_stat === '01') navigate(`${PATH.SIGNUP}?mem_email=${data.mem_email}&mem_key=${data.mem_key}`);
      // '2.활성화' 또는 '3.탈퇴신청' 가입이 완료된 상태이므로, 로그인 처리 후, 메인 화면으로 이동
      else if (['02', '91'].includes(data.mem_stat)) {
        // 아이디 저장 유무: 쿠키에 1주일간 저장
        if (isRemember) setCookie('rememberUserId', formData.mem_email, { maxAge: 604800 });
        else removeCookie('rememberUserId');
        // 팔로잉 리스트 가져오기
        const { data: responseListFollowing } = await api.listFollower({ flw_mem_key: data.mem_key });
        const followingList = responseListFollowing.items.map((followingItem) => {
          return {
            mem_key: followingItem.mem_key,
            mem_email: followingItem.mem_email,
          };
        });
        // 팔로우 리스트 가져오기
        const { data: responseListFollower } = await api.listFollower({ mem_key: data.mem_key });
        const followerList = responseListFollower.items.map((followerItem) => {
          return {
            mem_key: followerItem.flw_mem_key,
            mem_email: followerItem.flw_mem_email,
          };
        });
        // 세션 정보 저장
        await Promise.all([
          localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify({ ...data, followingList, followerList })),
          dispatch(setAuth(data)),
          dispatch(setFollowingList(followingList)),
          dispatch(setFollowerList(followerList)),
        ]);
        if (isAdmin(data.mem_auth)) navigate(PATH.ADMIN_USER_LIST, { replace: true });
        else navigate(PATH.MAIN, { replace: true });
      }
    } catch (error) {
      message.warning(error.message);
      // 세션 정보 지우기
      localStorage.setItem(TOKEN_STORE_KEY, '{}');
      dispatch(clearAuth());
    }
  };

  // 체크박스 상태 업데이트
  const handleOnChangeCheckbox = (e) => {
    setIsRemember(e.target.checked);
  };

  // 세션 정보 초기화
  const initSession = async () => {
    await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, '{}'), dispatch(clearAuth())]);
  };

  useEffect(() => {
    initSession();
    // 쿠키에 저장된 아이디가 있으면, 아이디 가져오기
    if (cookies?.rememberUserId !== undefined) {
      form.setFieldsValue({ mem_email: cookies.rememberUserId });
      setIsRemember(true);
    }
  }, []);

  return (
    <section className="flexColCenter">
      <article id="login" className="flexColCenter">
        <div className="flexColCenter logo">
          <img src={logo} alt="logo" />
        </div>

        <Form form={form} name="sign-in" initialValues={INIT_STATE_LOGIN_INFO} onFinish={handleLogin} autoComplete="off" style={{ width: '100%' }}>
          <Form.Item name="mem_email" rules={[{ required: true, message: '이메일을 입력해주세요!' }]} style={{ marginBottom: 0 }}>
            <Input className="input" placeholder="E-mail" />
          </Form.Item>
          <Form.Item name="mem_pwd" rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]} style={{ marginBottom: 0 }}>
            <Input.Password className="input" placeholder="password" />
          </Form.Item>

          <div className="flexColStart check">
            <Checkbox style={{ fontSize: `13px` }} className="checkbox" checked={isRemember} onChange={handleOnChangeCheckbox}>
              이메일 주소 저장
            </Checkbox>
          </div>

          <Button id="purpleBtn" htmlType="submit">
            로그인하기
          </Button>
        </Form>

        <div className="flexRowCenter signup">
          <p>
            <span onClick={() => navigate(PATH.FORGET_EMAIL)}>아이디</span>
            <span> ∙ </span>
            <span onClick={() => navigate(PATH.FORGET_PASSWORD)}>비밀번호 찾기</span>
          </p>
          <p>
            <span onClick={() => navigate(PATH.SIGNUP)}>회원가입</span>
          </p>
        </div>

        <div className="flexColCenter line">
          <div className="absoluteBox flexColCenter"></div>
          <p>or</p>
        </div>

        <div className="flexColCenter">
          <ButtonSocialLogin provider="kakao" text="카카오로 시작하기" />
          {/* <ButtonSocialLogin provider="naver" text="네이버로 시작하기" /> */}
          <ButtonSocialLogin provider="google" text="구글로 시작하기" />
        </div>
      </article>
    </section>
  );
};

export default Login;

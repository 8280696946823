import React from 'react';
import Sidebar from './SidebarAdmin';
import Content from './ContentAdmin';
import Header from './HeaderAdmin';

const LayoutBasic = ({ ContentBody }) => {
  return (
    <>
      <Sidebar />
      <main className="l-main">
        <Header />
        <Content ContentBody={ContentBody} />
      </main>
    </>
  );
};

export default LayoutBasic;

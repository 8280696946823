import axios from 'axios';
import qs from 'qs';
import { validateSession, sessionErrorConfirm } from '@utils/lib';
import { AUTH_LOGIN, AUTH_ADMIN, AUTH_BEGINNER, AUTH_BASIC, AUTH_PRO } from '@utils/constants';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

// config
export const restApi = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
  withCredentials: true,
});

// 헤더 세팅: access token용
export const headersForAccessToken = () => {
  const tokenStore = JSON.parse(window?.localStorage?.getItem(TOKEN_STORE_KEY) || '{}');
  restApi.defaults.headers.common.authorization = `Bearer ${tokenStore.access_token}`;
};

// 헤더 세팅: 세션처리용
export const headersForSession = (header) => {
  if (![AUTH_LOGIN, AUTH_ADMIN, AUTH_BEGINNER, AUTH_BASIC, AUTH_PRO].includes(header)) return;
  restApi.defaults.headers.common.authType = header;
};

// 쿼리스트링 formatter
export const querystring = (query) => {
  return {
    params: query,
  };
};

// 요청 인터셉터
restApi.interceptors.request.use(
  async (config) => {
    await Promise.all([validateSession({ authType: config.headers.authType, isCallApi: true })]);
    return config;
  },
  async (error) => {
    console.warn(error);
    return Promise.reject(error);
  },
);

// 응답 인터셉터
restApi.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.warn(error);
    // 컨펌창 띄우기: 세션처리용
    if (['ERR701', 'ERR702', 'ERR703', 'ERR704', 'ERR705'].includes(error)) {
      sessionErrorConfirm(error);
      return Promise.resolve(true);
    }
    if (error?.response?.status === 500) error.message = '서버 에러';
    return Promise.reject(error);
  },
);

restApi.defaults.paramsSerializer = function (paramObj) {
  const params = new URLSearchParams();
  for (const key in paramObj) {
    params.append(key, paramObj[key]);
  }

  return params.toString();
};

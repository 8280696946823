import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox, Drawer, Input, Radio, message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import whiteCartIcon from '@assets/images/common/white_cart_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import thumbnail from '@assets/images/common/thumb.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import whiteSellIcon from '@assets/images/common/white_sell_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
import whiteCheckIcon from '@assets/images/common/white_check_icon.png';
// component
import Banner from '@components/slider/Banner';
// lib
import { koKRFormat, useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const TextHome = (props) => {
  const navigate = useNavigate();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterDrawerMobile, setFilterDrawerMobile] = useState(false);
  const [textList, setTextList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterToggle = useCallback(() => {
    setFilterDrawer(!filterDrawer);
  }, [filterDrawer]);

  const filterToggleMobile = useCallback(() => {
    setFilterDrawerMobile(!filterDrawerMobile);
  }, [filterDrawerMobile]);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 텍스트 리스트 가져오기
  const listText = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE004',
        keyword_tab: '',
        offset: 0,
        limit: 60,
      });
      if (!data) return;
      setTextList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listText());
  }, []);

  return (
    <article id="otherWrapper" className="flexColCenter">
      <Banner bannerCd="BANNER001" bannerTp="Top" />

      <div className="filter flexColCenter" id={filterDrawerMobile ? 'filter' : 'nothing'}>
        <div className="rsWrapper flexColCenter">
          <div className="listBox flexRowStart">
            <div className="flexRowStart title">
              <img src={mobileFilterIcon} />
              <h2>정렬</h2>
            </div>

            <Radio.Group
              value={2}
              style={{
                display: `flex`,
                flexDirection: `row`,
              }}
            >
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={2}
              >
                Midjourney
              </Radio>
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={3}
              >
                Dall-E
              </Radio>
            </Radio.Group>
          </div>

          <div className="listBox flexRowStart">
            <div className="flexRowStart title">
              <img src={mobileFilterIcon} />
              <h2>모델</h2>
            </div>

            <Radio.Group
              value={2}
              style={{
                display: `flex`,
                flexDirection: `row`,
              }}
            >
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={2}
              >
                Midjourney
              </Radio>
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={3}
              >
                Dall-E
              </Radio>
            </Radio.Group>
          </div>

          <div className="listBox flexRowStart">
            <div className="flexRowStart title">
              <img src={mobileFilterIcon} />
              <h2>장르</h2>
            </div>
            <Radio.Group
              value={2}
              style={{
                display: `flex`,
                flexDirection: `row`,
              }}
            >
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={2}
              >
                카테고리1
              </Radio>
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={3}
              >
                카테고리2
              </Radio>
              <Radio
                style={{
                  width: `140px`,
                  fontSize: `14px`,
                }}
                value={4}
              >
                카테고리3
              </Radio>
            </Radio.Group>
          </div>

          <div className="reset flexRowBetween">
            {filterDrawerMobile ? (
              <button id="whiteBtn">
                <img src={resetIcon} />
                <p>초기화</p>
              </button>
            ) : (
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>필터</h2>
              </div>
            )}

            {filterDrawerMobile ? (
              <button id="greyBtn2" onClick={filterToggleMobile}>
                <UpOutlined />
              </button>
            ) : (
              <button id="greyBtn4" onClick={filterToggleMobile}>
                <DownOutlined />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="rsWrapper flexColCenter">
        <div className="flexRowBetween">
          <div className="content flexColCenter">
            <div className="titleBox flexRowBetween">
              <h1>
                Midjourney <br />
                <span>최신 텍스트 프롬프트</span>
              </h1>

              <div className="search flexRowBetween">
                <img src={searchIcon} />
                <Input placeholder="텍스트를 검색해 주세요." />
              </div>

              <img src={mobileFilterIcon} onClick={filterToggle} />
            </div>

            <div className="searchBox flexRowBetween">
              <div className="flexRowCenter ">
                <div id="pinkBtn2">All</div>
                <div id="pinkBtn2" className="active">
                  카테고리1
                </div>
                <div id="pinkBtn2">카테고리2</div>
                <div id="pinkBtn2">카테고리3</div>
              </div>

              <div className="flexRowCenter">
                <div id="pinkBtn">
                  <div>
                    <img src={whiteSellIcon} />
                    <img src={whiteSellIcon} className="activeImg" />
                  </div>
                  <p>판매하기</p>
                </div>

                <div id="whiteBtn" className="flexRowCenter">
                  <div>
                    <img src={cartIcon} />
                    <img src={whiteCartIcon} className="activeImg" />
                  </div>
                  <p>담기</p>
                </div>
              </div>
            </div>

            {isLoading ? (
              <>
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                />
              </>
            ) : (
              <div className="listBox flexRowStart">
                {textList?.map((text) => (
                  <div className="list flexColCenter">
                    <div className="flexColBetween absoluteBox" onClick={() => moveToPage(PATH.PROMPT_DETAIL, { prpt_id: text?.prpt_id })}>
                      <div className="flexRowStart user">
                        <img src={text?.icon_path ?? userThum} />
                        <p>{text?.ai_model_nm}</p>
                      </div>

                      <div className="flexColEnd">
                        <div className="flexRowBetween title">
                          <h1>{text.prpt_title}</h1>
                          <p>{`₩${koKRFormat(text?.sale_amt)}`}</p>
                        </div>
                      </div>
                    </div>
                    <img src={text.thum_path ?? thumbnail} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="mobileFixedBox" className="flexRowBetween">
        <div className="flexColCenter select">
          <p>
            <span>2</span>개 선택
          </p>
        </div>
        <div className="flexRowCenter">
          <img src={resetIcon} />
          <img src={cartUpdateIcon} />
          <img src={cartIcon} />
        </div>
      </div>

      <Banner bannerCd="BANNER001" bannerTp="Bottom" />

      <Drawer open={filterDrawer} width={`100%`} headerStyle={{ display: `none` }}>
        <div id="mobileFilter" className="flexColCenter">
          <div className="flexColEnd">
            <p onClick={filterToggle}>
              <CloseOutlined />
            </p>
          </div>

          <div className="content flexColStart">
            <h1>필터</h1>
            <h2>어떤 이미지를 찾고 계신가요?</h2>

            <div className="searchBox flexRowBetween">
              <Input placeholder="검색어를 입력해주세요." />
              <img src={searchIcon} />
            </div>

            <div className="listBox flexColStart ">
              <h3>정렬</h3>

              <div className="flexRowStart">
                <div className="list flexRowCenter" id="active">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>전체</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>트렌드</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>인기</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>최신</p>
                </div>
              </div>
            </div>

            <div className="listBox flexColStart ">
              <h3>모델</h3>

              <div className="flexRowStart">
                <div className="list flexRowCenter" id="active">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>전체</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>Midjuerny</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>Dall-E</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>GPT</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>SUNO</p>
                </div>
              </div>
            </div>

            <div className="listBox flexColStart ">
              <h3>장르</h3>

              <div className="flexRowStart">
                <div className="list flexRowCenter" id="active">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>전체</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>카테고리1</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>카테고리2</p>
                </div>
                <div className="list flexRowCenter">
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>

                  <p>카테고리3</p>
                </div>
              </div>
            </div>
          </div>

          <div className="btnBox flexRowBetween">
            <button id="logoutBtn">
              <img src={resetIcon} />
              <p>초기화</p>
            </button>
            <button id="pinkBtn">검색</button>
          </div>
        </div>
      </Drawer>
    </article>
  );
};

export default TextHome;

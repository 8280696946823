import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { headerMenu as staticMenu } from '@utils/constants'; // constants.js에서 headerMenu를 가져옴
import { Layout, Menu } from 'antd';
import * as api from '@api/index';
import { BOARD_HOME, MYPAGE_PROFILE } from '@routes/pathName';
import qs from 'qs';
import { MessageOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

const BasicHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState('1');
  const [headerMenu, setHeaderMenu] = useState(staticMenu);
  const auth = useSelector((s) => s.auth, shallowEqual);

  const handlePost = ({}) => {
    navigate(`${MYPAGE_PROFILE}`, {
      state: {
        mem_email: auth.mem_email, mem_key: auth.mem_key
      },
    });

  };
  // 선택한 메뉴로 이동
  const moveToPage = (key) => {
    // 서브메뉴가 있는 경우
    if (key.includes('-')) {
      // board인 경우
      if (key.startsWith('6-')) {
        const finalKey = key.split('-')[1];

        const selectedBoard = headerMenu.find((menu) => menu.children?.some((child) => child.key === key));
        const boardTitle = selectedBoard ? selectedBoard.children.find((child) => child.key === key)?.label || '' : '';
        const dspMeth = selectedBoard.children.find((child) => child.key === key)?.dsp_meth || '1';

        const queryString = qs.stringify({ board_no: finalKey, board_title: boardTitle, dsp_meth: dspMeth });
        navigate(`${BOARD_HOME}?${queryString}`);
      } else {
        const headerKey = key.split('-')[0];
        const selectMenu = headerMenu.find((x) => x.key === headerKey);
        const finalMenu = selectMenu?.children.find((x) => x.key === key);

        if (finalMenu?.path === MYPAGE_PROFILE) {
/*          const queryString = qs.stringify({ mem_email: auth.mem_email, mem_key: auth.mem_key });
          navigate(`${MYPAGE_PROFILE}?${queryString}`);*/
          handlePost();
        } else navigate(finalMenu?.path);
      }
    } else {
      const selectedMenu = headerMenu.find((x) => x.key === key);
      navigate(selectedMenu?.path);
    }
  };

  // 현재 path로 메뉴 찾고, key 값 변경
  useEffect(() => {
    const currentPath = pathname.split('/').filter(Boolean)[0];
    const selectedMenu = headerMenu.find((x) => x.path === `/${currentPath}`);

    if (selectedMenu) {
      setSelectedKey(selectedMenu.key);
    }

    getSubMenu();
  }, [pathname]);

  // community sub menu
  const getSubMenu = useCallback(async () => {
    try {
      const { data } = await api.getIntegratedBoardList();
      if (!data) return;

      setHeaderMenu((prevMenu) =>
        prevMenu.map((menu) => {
          if (menu.key === '6') {
            return {
              ...menu,
              children: data.items.map((item) => ({
                key: `${menu.key}-${item.board_no}`,
                label: item.board_title,
                path: `${BOARD_HOME}/${item.board_no}`,
                dsp_meth: item.dsp_meth,
              })),
            };
          }
          return menu;
        }),
      );
    } catch (error) {
      console.error('메뉴 데이터를 가져오는 데 실패했습니다.', error);
    }
  }, []);

  // 모든 메뉴를 items로 처리
  const menuItems = headerMenu.map(({ key, label, icon, children }) => ({
    key,
    label,
    icon: icon ? React.createElement(icon) : undefined, // 아이콘이 있으면 추가
    children: children ? children.map(({ key, label }) => ({ key, label })) : undefined,
  }));

  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        padding: 0,
      }}
    >
      <Menu
        mode="horizontal"
        selectedKeys={[selectedKey]}
        items={menuItems}
        style={{
          flex: 1,
          minWidth: 0,
          justifyContent: 'flex-end',
          padding: '0 50px',
        }}
        onClick={({ key }) => moveToPage(key)}
      />
    </Header>
  );
};

export default BasicHeader;

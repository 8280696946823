import React from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
// icon
import closeIcon from '@assets/images/common/close_icon.png';
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import promptIcon from '@assets/images/common/prompt_icon.png';
import communityIcon from '@assets/images/common/community_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import messageIcon from '@assets/images/common/message_icon.png';
import ringIcon from '@assets/images/common/ring_icon.png';
import userIcon2 from '@assets/images/common/user_icon_2.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import membershipIcon from '@assets/images/common/membership_icon.png';
import markIcon from '@assets/images/common/mark_icon.png';
import greyArrowDownIcon from '@assets/images/common/grey_arrow_down.png';
import homeIcon from '@assets/images/common/home_icon.png';
import promptCreateIcon from '@assets/images/common/prompt_create_icon.png';
import promptAdminIcon from '@assets/images/common/propt_admin_icon.png';
import promptTrashIcon from '@assets/images/common/prompt_trash_icon.png';
import promptBuyIcon from '@assets/images/common/prompt_buy_icon.png';
import promptSellIcon from '@assets/images/common/prompt_sell_icon.png';
import noticeIcon from '@assets/images/common/notice_icon.png';
import clockIcon from '@assets/images/common/clock_icon.png';
import mobileMessageIcon from '@assets/images/common/mobile_message_icon.png';
import infoIcon from '@assets/images/common/info_icon.png';
import planIcon from '@assets/images/common/plan_icon.png';
import mobileRingIcon from '@assets/images/common/mobile_ring_icon.png';
import helpIcon from '@assets/images/common/help_icon.png';
import outIcon from '@assets/images/common/out_icon.png';
// active
import activeHomeIcon from '@assets/images/common/active_home_icon.png';
import activePromptCreateIcon from '@assets/images/common/active_prompr_create_icon.png';
import activePromptAdminIcon from '@assets/images/common/active_prompt_admin_icon.png';
import activePromptTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import activePromptBuyIcon from '@assets/images/common/active_prompt_buy_icon.png';
import activePromptSellIcon from '@assets/images/common/active_prompt_sell_icon.png';
import activeNoticeIcon from '@assets/images/common/active_notice_icon.png';
import activeClockIcon from '@assets/images/common/active_clock_icon.png';
import activeMobileMessageIcon from '@assets/images/common/active_mobile_message_icon.png';
import activeInfoIcon from '@assets/images/common/active_info_icon.png';
import activePlanIcon from '@assets/images/common/active_plan_icon.png';
import activeMobileRingIcon from '@assets/images/common/active_mobile_ring_icon.png';
import activeHelpIcon from '@assets/images/common/active_help_icon.png';
import activeOutIcon from '@assets/images/common/active_out_icon.png';
// path
import * as PATH from '@routes/pathName';
// lib
import { dayFormatKR } from '@utils/lib';
// redux
import { clearAuth } from '@stores/auth';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const MainMenuMobile = ({ cartToggle, menuToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 선택한 메뉴로 이동
  const moveToPage = (path) => {
    menuToggle();
    const queryString = qs.stringify({ mem_email: auth.mem_email, mem_key: auth.mem_key });
    navigate(`${path}?${queryString}`);
  };

  const logout = () => {
    window.confirm('로그아웃 하시겠습니까?').then(async (result) => {
      if (result) {
        await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, '{}'), dispatch(clearAuth())]);
        navigate(PATH.LOGIN);
      } else return;
    });
  };

  return (
    <div className="scroll flexColStart">
      <div className="flexRowBetween">
        <div className="mobileIconBox flexRowCenter">
          <div onClick={cartToggle}>
            <div className="round"></div>
            <img src={cartIcon} alt="cart_icon" />
          </div>
          <div onClick={() => moveToPage(PATH.CHAT_HOME)}>
            <div className="round"></div>
            <img src={messageIcon} alt="message_icon" />
          </div>
          <div>
            <div className="round"></div>
            <img src={ringIcon} alt="ring_icon" />
          </div>
        </div>

        {auth?.mem_key ? (
          ''
        ) : (
          <div id="purpleBtn" onClick={() => moveToPage(PATH.LOGIN)}>
            로그인
          </div>
        )}

        <img src={closeIcon} alt="close_icon" onClick={menuToggle} />
      </div>

      <div className="loginUser flexRowStart">
        <img src={auth.mem_img_path || userIcon3} alt="user_icon" />

        <div className="flexColStart">
          <h1>{auth.mem_nick}</h1>
          <p>{`${dayFormatKR(auth.fir_dt)} 가입`}</p>
        </div>
      </div>

      <div className="pointBox flexRowBetween">
        <div className="flexRowStart">
          <img src={userIcon2} />
          <p>#2,434</p>
        </div>

        <div className="flexRowCenter">
          <img src={membershipIcon} alt="membetship_icon" />
          <img src={markIcon} alt="mark_icon" />
        </div>
      </div>

      <div className="tabBox flexRowBetween">
        <div className="tab flexColCenter">Bagic</div>
        <div className="tab active flexColCenter">UPGRADE</div>
      </div>

      <div className="followingBox flexRowBetween">
        <div className="flexColCenter">
          <h1>123</h1>
          <p>팔로잉 수</p>
        </div>
        <div className="flexColCenter">
          <h1>123</h1>
          <p>팔로워 수</p>
        </div>
      </div>

      <div className="loginMenuBox flexColCenter">
        <div className="home flexRowStart">
          <img src={activeHomeIcon} alt="home_icon" className="activeIcon" />
          <img src={homeIcon} alt="home_icon" />
          <p>홈</p>
        </div>

        <div className="loginMenu flexRowBetween">
          <p>프롬프트 빌더</p>
          <img src={greyArrowDownIcon} alt="arrow_down" />
        </div>

        <div className="logSubMenuWrapper flexColCenter">
          <div className="loginSubMenu flexRowStart" onClick={() => moveToPage(PATH.CREATE_PROMPT)}>
            <img src={activePromptCreateIcon} alt="promt_icon" className="activeIcon" />
            <img src={promptCreateIcon} alt="promt_icon" />
            <p>프롬프트 생성</p>
          </div>

          <div className="loginSubMenu flexRowStart" onClick={() => moveToPage(PATH.PROMPT_MANAGEMENT)}>
            <img src={activePromptAdminIcon} alt="promt_icon" className="activeIcon" />
            <img src={promptAdminIcon} alt="promt_icon" />
            <p>프롬프트 관리</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activePromptTrashIcon} alt="promt_icon" className="activeIcon" />
            <img src={promptTrashIcon} alt="promt_icon" />
            <p>휴지통</p>
          </div>
        </div>

        <div className="loginMenu flexRowBetween">
          <p>마켓 플레이스</p>
          <img src={greyArrowDownIcon} alt="arrow_down" />
        </div>

        <div className="logSubMenuWrapper flexColCenter">
          <div className="loginSubMenu flexRowStart">
            <img src={activePromptBuyIcon} alt="promt_icon" className="activeIcon" />
            <img src={promptBuyIcon} alt="promt_icon" />
            <p>구매 프롬프트</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activePromptSellIcon} alt="promt_icon" className="activeIcon" />
            <img src={promptSellIcon} alt="promt_icon" />
            <p>판매 프롬프트</p>
          </div>
        </div>

        <div className="loginMenu flexRowBetween">
          <p>커뮤니티</p>
          <img src={greyArrowDownIcon} alt="arrow_down" />
        </div>

        <div className="logSubMenuWrapper flexColCenter">
          <div className="loginSubMenu flexRowStart">
            <img src={activeNoticeIcon} alt="promt_icon" className="activeIcon" />
            <img src={noticeIcon} alt="promt_icon" />
            <p>게시판</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activeClockIcon} alt="promt_icon" className="activeIcon" />
            <img src={clockIcon} alt="promt_icon" />
            <p>활동내역</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activeMobileMessageIcon} alt="promt_icon" className="activeIcon" />
            <img src={mobileMessageIcon} alt="promt_icon" />
            <p>메시지</p>
          </div>
        </div>

        <div className="loginMenu flexRowBetween">
          <p>설정</p>
          <img src={greyArrowDownIcon} alt="arrow_down" />
        </div>

        <div className="logSubMenuWrapper flexColCenter">
          <div className="loginSubMenu flexRowStart">
            <img src={activeInfoIcon} alt="promt_icon" className="activeIcon" />
            <img src={infoIcon} alt="promt_icon" />
            <p>기본 정보</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activePlanIcon} alt="promt_icon" className="activeIcon" />
            <img src={planIcon} alt="promt_icon" />
            <p>플랜 정보</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activeMobileRingIcon} alt="promt_icon" className="activeIcon" />
            <img src={mobileRingIcon} alt="promt_icon" />
            <p>알림 설정</p>
          </div>

          <div className="loginSubMenu flexRowStart">
            <img src={activeHelpIcon} alt="promt_icon" className="activeIcon" />
            <img src={helpIcon} alt="promt_icon" />
            <p>도움말</p>
          </div>

          <div className="loginSubMenu flexRowStart" onClick={logout}>
            <img src={activeOutIcon} alt="promt_icon" className="activeIcon" />
            <img src={outIcon} alt="promt_icon" />
            <p>로그아웃</p>
          </div>
        </div>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.MUSIC_HOME)}>
        <img src={musicIcon} alt="music_icon" />
        <p>Music</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.IMAGE_HOME)}>
        <img src={imageIcon} alt="image_icon" />
        <p>Image</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.VIDEO_HOME)}>
        <img src={videoIcon} alt="video_icon" />
        <p>Video</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.TEXT_HOME)}>
        <img src={textIcon} alt="text_icon" />
        <p>Text</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.CREATE_PROMPT)}>
        <img src={promptIcon} alt="prompt_icon" />
        <p>Prompt Bilder</p>
      </div>

      <div className="mobileMenu flexRowStart" onClick={() => moveToPage(PATH.COMMUNITY_HOME)}>
        <img src={communityIcon} alt="community_icon" />
        <p>Community</p>
      </div>
    </div>
  );
};

export default MainMenuMobile;

import React, { useCallback, useState, useEffect } from 'react';
import { Drawer, Input, Radio, message, Skeleton } from 'antd';
import { CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
import whiteCheckIcon from '@assets/images/common/white_check_icon.png';

const FilterMobile = ({ isActive, toggle }) => {
  return (
    <Drawer open={isActive} width={`100%`} headerStyle={{ display: `none` }}>
      <div id="mobileFilter" className="flexColCenter">
        <div className="flexColEnd">
          <p onClick={toggle}>
            <CloseOutlined />
          </p>
        </div>

        <div className="content flexColStart">
          <h1>필터</h1>
          <h2>어떤 이미지를 찾고 계신가요?</h2>

          <div className="searchBox flexRowBetween">
            <Input placeholder="검색어를 입력해주세요." />
            <img src={searchIcon} />
          </div>

          <div className="listBox flexColStart ">
            <h3>정렬</h3>

            <div className="flexRowStart">
              <div className="list flexRowCenter" id="active">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>전체</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>트렌드</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>인기</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>최신</p>
              </div>
            </div>
          </div>

          <div className="listBox flexColStart ">
            <h3>모델</h3>

            <div className="flexRowStart">
              <div className="list flexRowCenter" id="active">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>전체</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>Midjuerny</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>Dall-E</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>GPT</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>SUNO</p>
              </div>
            </div>
          </div>

          <div className="listBox flexColStart ">
            <h3>장르</h3>

            <div className="flexRowStart">
              <div className="list flexRowCenter" id="active">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>전체</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>카테고리1</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>카테고리2</p>
              </div>
              <div className="list flexRowCenter">
                <div className="round flexColCenter">
                  <img src={whiteCheckIcon} />
                </div>

                <p>카테고리3</p>
              </div>
            </div>
          </div>
        </div>

        <div className="btnBox flexRowBetween">
          <button id="logoutBtn">
            <img src={resetIcon} />
            <p>초기화</p>
          </button>
          <button id="pinkBtn">검색</button>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterMobile;

import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Button, Row, Col, Descriptions, Card } from 'antd';
import Draggable from 'react-draggable';

// 포트원 API 연동하기 전까지 하드코딩..
const INIT_STATE_USER_PAYDETAIL = {
  status: '결제 완료',
  id: '4832194623',
  requestedAt: '2024-10-17 15:23:16',
  paidAt: '2024-10-17 15:23:16',
  orderName: '프롬프트 1',
  method: {
    card: {
      number: '7045-****-****-2474',
    },
    approvalNumber: '9874390352',
    installment: {
      month: 0,
    },
    pointUsed: false,
  },
  amount: {
    total: 50000,
    paid: 50000,
    taxFree: 0,
    vat: 0,
    supply: 0,
    discount: 0,
  },
};

const UserPayDetail = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (isOpen) form.resetFields();
  }, [isOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          회원 거래내역 상세
        </div>
      }
      open={isOpen}
      width={820}
      closable={false}
      maskClosable={false}
      footer={
        <Button type="primary" onClick={onClose}>
          확인
        </Button>
      }
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Row>
        <Col span={24} style={{ paddingRight: '10px' }}>
          <Card title="결제 정보" className={'body-no-padder'} style={{ marginBottom: '20px' }}>
            <Descriptions size="small" column={6} colon={false} style={{ fontWeight: 400 }}>
              <Descriptions.Item span={3} label="결제 아이디">
                {INIT_STATE_USER_PAYDETAIL.id}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="결제 상태">
                {INIT_STATE_USER_PAYDETAIL.status}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="결제요청 일시">
                {INIT_STATE_USER_PAYDETAIL.requestedAt}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="결제완료 일시">
                {INIT_STATE_USER_PAYDETAIL.paidAt}
              </Descriptions.Item>
              <Descriptions.Item span={6} label="주문명">
                {INIT_STATE_USER_PAYDETAIL.orderName}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title="결제 수단" style={{ marginBottom: '20px' }}>
            <Descriptions size="small" column={6} colon={false} style={{ fontWeight: 400 }}>
              <Descriptions.Item span={3} label="카드 번호">
                {INIT_STATE_USER_PAYDETAIL.method.card.number}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="승인 번호">
                {INIT_STATE_USER_PAYDETAIL.method.approvalNumber}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="할부 개월 수">
                {INIT_STATE_USER_PAYDETAIL.method.installment.month}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="카드 포인트 사용여부">
                {INIT_STATE_USER_PAYDETAIL.method.pointUsed}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card title="결제 금액" style={{ marginBottom: '20px' }}>
            <Descriptions size="small" column={6} colon={false} style={{ fontWeight: 400 }}>
              <Descriptions.Item span={3} label="총 결제금액">
                {INIT_STATE_USER_PAYDETAIL.amount.total?.toLocaleString('ko-KR')}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="실제 결제금액">
                {INIT_STATE_USER_PAYDETAIL.amount.paid?.toLocaleString('ko-KR')}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="면세액">
                {INIT_STATE_USER_PAYDETAIL.amount.taxFree?.toLocaleString('ko-KR')}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="부가세액">
                {INIT_STATE_USER_PAYDETAIL.amount.vat?.toLocaleString('ko-KR')}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="공급사액">
                {INIT_STATE_USER_PAYDETAIL.amount.supply?.toLocaleString('ko-KR')}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="할인금액">
                {INIT_STATE_USER_PAYDETAIL.amount.discount?.toLocaleString('ko-KR')}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default UserPayDetail;

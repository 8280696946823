import React from 'react';
import { Button, Select, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DragAndDropList = ({
  list,
  setList,
  handleDeleteList,
  emphasisOptions,
  handleEmphasisChange,
  handleEmphasisInputChange,
  disabled
}) => {
  // 드래그 앤 드롭의 끝에 호출되는 함수
  const onDragEnd = (result) => {
    if (!result.destination || disabled) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);
    setList(reorderedList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="select flexColCenter"
          >
            {list.map((item, index) => (
              <Draggable
                key={String(item.seq)}
                draggableId={String(item.seq)}
                index={index}
                isDragDisabled={disabled}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flexRowBetween"
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: '#fff',
                      ...provided.draggableProps.style, // 드래그 시 스타일 적용
                    }}
                  >
                    <div className="flexRowCenter" >
                      {item.imgPath ? (
                        <img
                          src={item.imgPath || 'placeholder.png'}
                          alt=''
                        />
                      ) : (
                        <div
                          style={{
                            width: '64px', // 이미지와 동일한 크기
                            height: '36px', // 이미지와 동일한 크기
                            backgroundColor: '#fff', // 하얀색 박스
                            border: '1px solid #ddd', // 선택적으로 테두리를 추가
                            borderRadius: '4px', // 선택적으로 모서리를 둥글게
                            margin: '0 16px 0 0'
                          }}
                        />
                      )}
                      <h1>{item.title}</h1>
                    </div>
                    <div className="flexRowCenter">
                      <Select
                        style={{ width: 120, marginRight: '8px' }}
                        options={emphasisOptions}
                        value={item.emphasis?.emphasisValue || undefined}
                        onChange={(value) => handleEmphasisChange(index, value)}
                        disabled={disabled}
                      />
                      {item.emphasis?.in_yn === 'Y' && (
                        <Input
                          style={{ width: 100, marginRight: '8px' }}
                          value={item.emphasis?.inputValue || ''}
                          onChange={(e) =>
                            handleEmphasisInputChange(index, e.target.value)
                          }
                          placeholder="값 입력"
                          disabled={disabled}
                        />
                      )}
                      <Button
                        icon={<CloseOutlined />}
                        onClick={() => handleDeleteList(item.seq)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropList;

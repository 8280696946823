import React from 'react';
// icon
import starIcon from '@assets/images/common/star_icon.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
// lib
import { koKRFormat } from '@utils/lib';
import cartThumbnail from '@assets/images/common/cart_thumbanil.png';
import closeIcon from '@assets/images/common/close_icon.png';
import logo from '@assets/images/common/logo.png';

const CartCardSquare = ({ cart, deleteCart, onClick, selected }) => {
  return (
    <div
      className="cart flexColCenter"
      style={{
        border: selected ? 'solid #ff6699' : '',
      }}
      onClick={() => onClick(cart)}
    >
      <div className="thumbnailBox">
        <img src={cart.thum_path || cartThumbnail} alt="thumbnail" />

        <div className="flexColBetween">
          <div className="top flexRowBetween ">
            <div className="flexRowCenter">
              <img src={cart.icon_path || logo} alt="image" />
              <h1>{cart.ai_model_nm}</h1>
            </div>

            <div className="close">
              <img src={closeIcon} alt="close_icon" onClick={(e) => deleteCart(e, cart.gds_seq)} />
            </div>
          </div>

          <h1>{cart.gds_nm}</h1>
        </div>
      </div>
      <div className="bottom flexRowBetween">
        <img src={cart.mem_img_path || userIcon3} alt="image" />
        <div className="flexColStart">
          <h1>{cart.seller_nick}</h1>

          <div className="priceBox flexRowBetween">
            <div className="flexRowCenter">
              {[...Array(cart?.score_avg)].map((_, index) => (
                <img key={index} src={starIcon} alt="star_icon" />
              ))}
            </div>

            <p>{`₩ ${koKRFormat(cart.gds_amt)}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCardSquare;

import React from 'react';
import Slider from 'react-slick';
// component
import PrevArrow from '@components/slider/PrevArrow';
import NextArrow from '@components/slider/NextArrow';

// default config
const defaultConfig = {
  centerMode: true,
  infinite: true,
  centerPadding: '0',
  speed: 500,
  swipeToSlide: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const NewSlider = ({ config, Content }) => {
  return <Slider {...{ ...defaultConfig, ...(config && config) }}>{Content}</Slider>;
};

export default NewSlider;

import React, { useState, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Skeleton } from 'antd';
// icon
import userThumb from '@assets/images/common/user_thumb.png';
import mainIcon from '@assets/images/common/main_icon.png';
import boostIcon from '@assets/images/common/boost_icon.png';
import useIcon from '@assets/images/common/use_icon.png';
import followIcon from '@assets/images/common/user_icon2.png';
import starIcon from '@assets/images/common/star_icon.png';
import { RightOutlined } from '@ant-design/icons';
import rankIcon from '@assets/images/common/rank_1.png';
import albumThumb from '@assets/images/common/album_thumb.png';
import squreThumb from '@assets/images/common/squre_thumb.png';
// component
import CardSquareMusic1 from '@pages/main/CardSquareMusic1';
// lib
import { koKRFormat } from '@utils/lib';
// path
import * as PATH from '@routes/pathName';
// api
import * as api from '@api/index';
// redux
import { setFollowingList } from '@stores/auth';

const DetailMusic = ({ selectedMusic }) => {
  const navigate = useNavigate();
  const [musicList, setMusicList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const auth = useSelector((s) => s.auth, shallowEqual);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 팔로우 상태 확인
  const checkFollowing = () => {
    const result = auth.followingList?.filter((following) => following?.mem_key === selectedMusic?.mem_key);
    setIsFollowing(result.length > 0);
  };

  // 해당 사용자가 만든 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        mem_key: selectedMusic?.mem_key,
        cate_cd: 'CATE003',
        keyword_tab: 'new',
        offset: 0,
        limit: 5,
      });
      if (!data) return;
      setMusicList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedMusic]);

  // 팔로우 요청
  const requestFollow = useCallback(async () => {
    try {
      await api.addFollower({ mem_key: selectedMusic?.mem_key, flw_mem_key: auth.mem_key });
      const { data } = await api.listFollower({ flw_mem_key: auth.mem_key });
      if (!data) return;
      const followingList = data.items.map((followingItem) => {
        return {
          mem_key: followingItem.mem_key,
          mem_email: followingItem.mem_email,
        };
      });
      dispatch(setFollowingList(followingList));
      message.success('팔로우 요청에 성공하였습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, [selectedMusic]);

  useEffect(() => {
    if (selectedMusic) {
      checkFollowing();
      listMusic();
    }
  }, [selectedMusic, auth?.followingList]);

  return (
    <>
      <img src={mainIcon} alt="main_icon" className="mainIcon" />

      <div id="musicInfo" className="flexColCenter">
        <div className="mobileTop flexRowBetween">
          <div className="flexRowCenter">
            <img src={selectedMusic?.icon_path ?? userThumb} alt="user_icon" />
            <p>{selectedMusic?.ai_model_nm}</p>
          </div>

          <img src={selectedMusic?.rank_icon_path ?? rankIcon} alt="icon" />
        </div>
        {isLoading ? (
          <Skeleton
            avatar
            active
            paragraph={{
              rows: 4,
            }}
          />
        ) : (
          <>
            <div className="rsWrapper flexRowBetween">
              <div className="flexColCenter thumbBox">
                <img src={selectedMusic?.thum_path ?? albumThumb} alt="thumbnail" className="thumb" />

                <div className="flexColBetween absolBox">
                  <div className="flexRowBetween">
                    <div className="flexRowCenter">
                      <img src={selectedMusic?.icon_path ?? userThumb} alt="user_icon" />
                      <p>{selectedMusic?.ai_model_nm}</p>
                    </div>

                    <img src={selectedMusic?.rank_icon_path ?? rankIcon} alt="icon" />
                  </div>

                  <div className="name flexColStart">{selectedMusic?.mem_nick}</div>
                </div>
              </div>

              <div className="info flexColStart">
                <h1 onClick={() => moveToDetailPage(selectedMusic)}>{selectedMusic?.prpt_title}</h1>

                <div className="flexRowBetween">
                  <div className="userBox flexColStart">
                    <div className="userInfo flexRowBetween">
                      <img src={selectedMusic?.mem_img_path ?? squreThumb} alt="thumb" />

                      <div className="nameBox flexColStart">
                        <div className="flexRowStart">
                          <div className="badge flexColCenter">{selectedMusic?.plan_nm}</div>
                          <p onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: selectedMusic?.mem_email, mem_key: selectedMusic?.mem_key })}>{selectedMusic?.mem_nick}</p>
                        </div>

                        <div className="flexRowBetween">
                          <div className="follow flexRowCenter">
                            <img src={userThumb} />
                            <p>{`#${koKRFormat(selectedMusic?.rnk_add)}`}</p>
                          </div>

                          <div className="star flexRowCenter">
                            {[...Array(selectedMusic?.score)].map((_, index) => (
                              <img key={index} src={starIcon} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btnBox flexRowBetween">
                      <div id="whiteBorderBtn">
                        <img src={boostIcon} />
                        <p>후원하기</p>
                      </div>

                      <div id="whiteBorderBtn">
                        <img src={useIcon} />
                        <p>사용의뢰</p>
                      </div>

                      <div id={'whiteBorderBtn'} className={isFollowing ? 'active' : ''} onClick={() => !isFollowing && requestFollow()}>
                        <img src={followIcon} />
                        <p>팔로우</p>
                      </div>
                    </div>
                  </div>

                  <div className="contentsBox flexColStart">
                    <div className="flexRowStart" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: selectedMusic?.mem_email, mem_key: selectedMusic?.mem_key })}>
                      <h1>
                        {selectedMusic?.mem_nick} <span>님의 다른 콘텐츠</span>
                      </h1>
                      <p>
                        <RightOutlined />
                      </p>
                    </div>

                    <div className="musicBox flexRowStart">
                      {musicList?.map((music) => (
                        <CardSquareMusic1 data={music} moveToDetailPage={() => moveToDetailPage(selectedMusic)} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobileContentsBox flexRowBewteen">
              <div className="flexRowStart">
                <h1>
                  {selectedMusic?.mem_nick} <span>님의 다른 콘텐츠</span>
                </h1>
                <p>
                  <RightOutlined />
                </p>
              </div>

              <div className="musicBox flexRowStart">
                {musicList?.slice(0, 3).map((music) => (
                  <CardSquareMusic1 data={music} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailMusic;

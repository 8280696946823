import React, { useEffect, useState } from 'react';

import CreatePrompt from './CreatePrompt';
import PromptManagement2 from '@pages/myPages/PromptManagement';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CREATE_PROMPT, INQUIRY_EDIT, INQUIRY_LIST, PROMPT_MANAGEMENT } from '@routes/pathName';
import InquiryList from '@pages/inquiry/List';
import EditInquiry from '@pages/inquiry/Edit';
import InquiryDetail from '@pages/inquiry/Detail';
import PromptManagement from '@pages/prompt/PromptManagement';

const PromptHome = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const navigate = useNavigate();
  const [tab, setTab] = useState('create');

  const onChangeTab = (tabKey) => {
    switch (tabKey) {
      case 'create':
        navigate(CREATE_PROMPT); // 해당 경로로 이동
        setTab('create');
        break;
      case 'management':
        navigate(PROMPT_MANAGEMENT); // 해당 경로로 이동
        setTab('management');
        break;
      default:
        navigate('/prompt/management2');
    }
    setTab(tabKey);
  };

  useEffect(() => {
    if (path === '*') {
      onChangeTab('create');
    } else {
      setTab(path);
    }
  }, [pathname]);

  return (
    <>
      <article className="flexColCenter" id="prompt">
        <div className="rsWrapper flexColCenter">
          <div className="flexRowCenter title">
            <div onClick={() => onChangeTab('create')} className="flexColCenter" id={tab === 'create' ? 'active' : 'nothing'}>
              <div className="round"></div>
              <h1>프롬프트 생성</h1>
            </div>
            <div onClick={() => onChangeTab('management')} className="flexColCenter" id={tab === 'management' ? 'active' : 'nothing'}>
              <div className="round"></div>
              <h1>프롬프트 관리</h1>
            </div>
            <div onClick={() => onChangeTab('management2')} className="flexColCenter" id={tab === 'management2' ? 'active' : 'nothing'}>
              <div className="round"></div>
              <h1>프롬프트 관리2</h1>
            </div>
          </div>

          <Routes>
            <Route path="create" element={<CreatePrompt />} />
            <Route path="management" element={<PromptManagement />} />
            <Route path="management2" element={<PromptManagement2 />} />
          </Routes>
        </div>
      </article>
    </>
  );
};

export default PromptHome;

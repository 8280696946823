import { createSlice } from '@reduxjs/toolkit';
import { INIT_STATE_AUTH } from '@stores/auth';

const INIT_STATE = {
  items: [], // 장바구니 목록
  count: 0, // 장바구니 개수
  amount: 0, // 총 금액
  flag: false, // 기존조회 여부
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: INIT_STATE,
  reducers: {
    clearCart: () => INIT_STATE,
    setCartItems: (state, action) => {
      state.items = action.payload;
    },
    setCartCount: (state, action) => {
      state.count = action.payload;
    },
    setCartAmount: (state, action) => {
      state.amount = action.payload;
    },
    setCartFlag: (state, action) => {
      state.flag = action.payload;
    },
  },
});

export const { clearCart, setCartItems, setCartCount, setCartAmount, setCartFlag } = cartSlice.actions;

export default cartSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    chatRooms: [],
    currentRoom: null,
    messages: {},
    userProfile:[]
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChatRooms: (state, action) => {
            state.chatRooms = action.payload;
        },
        setCurrentRoom: (state, action) => {
            state.currentRoom = action.payload;
        },
        setMessages: (state, action) => {
            const { roomId, messages } = action.payload;
            state.messages = {
                ...state.messages,
                [roomId]: messages
            };
        },
        addMessage: (state, action) => {
            const { roomId, message } = action.payload;
            if (!state.messages[roomId]) {
                state.messages[roomId] = [];
            }
            state.messages[roomId] = [...state.messages[roomId], message];
            state.lastMessages[roomId] = message;
        },
        clearMessages: (state) => {
            state.messages = {};
            state.currentRoom = null;
            state.lastMessages = {};
        },
        setUserProfile: (state, action) => {
            state.userProfile = action.payload;
        },
    },
});

export const {
    setChatRooms,
    setCurrentRoom,
    setMessages,
    addMessage,
    clearMessages,
    setUserProfile,
} = chatSlice.actions;

export default chatSlice.reducer;
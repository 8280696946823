import React from 'react';
// icon
import userThumb from '@assets/images/common/user_thumb.png';
import squreThumb2 from '@assets/images/common/squre_thumb2.png';
// lib
import { koKRFormat } from '@utils/lib';

const CardSquareImage2 = ({ data, moveToDetailPage }) => {
  return (
    <div className="contents flexColCenter" key={data?.prpt_id} onClick={moveToDetailPage}>
      <div className="absoluteBox flexColBetween">
        <div className="flexRowStart">
          <img src={data?.icon_path ?? userThumb} />
          <h1>{data?.ai_model_nm}</h1>
        </div>

        <div className="flexColEnd price">{`₩ ${koKRFormat(data?.sale_amt)}`}</div>
      </div>
      <img src={data?.thum_path ?? squreThumb2} />
    </div>
  );
};

export default CardSquareImage2;

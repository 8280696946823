import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Drawer, Input, Radio } from 'antd';
import { isEmpty } from '@utils/lib';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import searchIcon from '@assets/images/common/search_icon.png';
import whiteCheckIcon from '@assets/images/common/white_check_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
import useWidth from '@hooks/useWidth';

const Filter = React.memo(({ filterItem, handleChangeFilter }) => {
  const width = useWidth();
  const isMobile = width < 800;
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterDrawerMobile, setFilterDrawerMobile] = useState(false);

  const [initItem, setInitItem] = useState(filterItem); // 초기 데이터
  const [cateFilterItem, setCateFilterItem] = useState(filterItem); // cate로 필터링된 데이터
  const [selectedItem, setSelectedItem] = useState({}); // 선택한 항목
  const [filterText, setFilterText] = useState(''); // 텍스트 검색

  const [mobileSelectedItem, setMobileSelectedItem] = useState({});
  const [mobileFilterText, setMobileFilterText] = useState('');

  // filter 초기값
  const initFilterState = filterItem.reduce((acc, item) => {
    if (item.value === 'sort') {
      // sort인 경우 첫 번째 자식 선택
      acc[item.value] = item.children?.[0]?.value || null;
    } else {
      acc[item.value] = 'all';
    }
    return acc;
  }, {});

  // 필터 초기화
  const initFilter = () => {
    setCateFilterItem(filterItem); //필터링된 데이터 세팅
    setSelectedItem(initFilterState); // 필터 초기값 세팅
    setFilterText('');

    if (isMobile) {
      setMobileSelectedItem(initFilterState);
      setMobileFilterText('');
    }
  };

  /** PC */
  // filter 항목
  const handleFilterChange = (e, itemValue) => {
    const newSelectedItem = { ...selectedItem, [itemValue]: e.target.value };
    setSelectedItem(newSelectedItem);
  };
  // filter 검색어
  const handleTextChange = (e) => {
    setFilterText(e.target.value);
  };

  /** 모바일 */
  // filter 항목
  const handleMobileChange = (grpCd, childCd) => {
    const newSelectedItem = { ...mobileSelectedItem, [grpCd]: childCd };
    setMobileSelectedItem(newSelectedItem);
  };
  // filter 검색어
  const handleMobileText = (e) => {
    setMobileFilterText(e.target.value);
  };
  // 검색 버튼 클릭시
  const handleMobileSearch = () => {
    // 조회할 selectedItem 과 동기화
    setSelectedItem(mobileSelectedItem);
    setFilterText(mobileFilterText);
    filterToggleMobile();
  };
  // 닫기 버튼 클릭시
  const handleMobileClose = () => {
    // 검색하지 않고 현재 상태 유지
    setMobileSelectedItem(selectedItem);
    setMobileFilterText(filterText);
    filterToggleMobile();
  };

  const filterToggle = useCallback(() => {
    setFilterDrawer(!filterDrawer);
  }, [filterDrawer]);

  const filterToggleMobile = useCallback(() => {
    setFilterDrawerMobile(!filterDrawerMobile);
  }, [filterDrawerMobile]);

  useEffect(() => {
    handleChangeFilter(selectedItem, filterText);
  }, [selectedItem, filterText]);

  useEffect(() => {
    const updateCateFilter = (selectedItem, setSelectedItem) => {
      if (selectedItem['CATE'] !== 'all') {
        // CATE 값이 all이 아닌 경우
        const updatedCateFilterItem = filterItem.map((item) => {
          // CATE값으로 필터링
          const filteredChildren = item.children.filter((child) => {
            const hasParent = child.parent?.includes(selectedItem.CATE);
            return isEmpty(child.parent) || hasParent;
          });

          // 기존 선택된 항목 중 필터링된 값에 포함되지 않으면 all로 변경
          if (filteredChildren.length && !filteredChildren.some((child) => child.value === selectedItem[item.value])) {
            setSelectedItem((prev) => ({
              ...prev,
              [item.value]: 'all',
            }));
          }

          return { ...item, children: filteredChildren };
        });
        setCateFilterItem(updatedCateFilterItem);
      } else {
        // CATE 값이 all인 경우 전체목록 세팅
        setCateFilterItem(filterItem);
      }
    };

    if (filterDrawerMobile) {
      updateCateFilter(mobileSelectedItem, setMobileSelectedItem); // Mobile
    } else {
      updateCateFilter(selectedItem, setSelectedItem); // PC
    }
  }, [selectedItem['CATE'], mobileSelectedItem['CATE']]);

  useEffect(() => {
    setInitItem(filterItem); //초기 데이터 세팅
    initFilter(); // 초기화
  }, [filterItem]);

  return (
    <>
      <div className="filter flexColCenter" id={filterDrawer ? 'filter' : 'nothing'}>
        <div className="rsWrapper flexColCenter">
          {cateFilterItem.map((item) => {
            return (
              <div className="listBox flexRowStart" key={item.value}>
                <div className="flexRowStart title">
                  <img src={mobileFilterIcon} />
                  <h2>{item.label}</h2>
                </div>
                <Radio.Group
                  style={{
                    display: `flex`,
                    flexDirection: `row`,
                  }}
                  value={selectedItem[item.value]}
                  onChange={(e) => handleFilterChange(e, item.value)}
                >
                  {item.value !== 'sort' ? (
                    <Radio
                      style={{
                        width: `140px`,
                        fontSize: `14px`,
                      }}
                      value="all"
                    >
                      전체
                    </Radio>
                  ) : (
                    ''
                  )}
                  {item.children?.map((child, childIndex) => (
                    <Radio
                      key={child.value}
                      style={{
                        width: `140px`,
                        fontSize: `14px`,
                      }}
                      value={child.value}
                    >
                      {child.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            );
          })}

          <div className="reset flexRowBetween">
            {filterDrawer ? (
              <button id="whiteBtn" onClick={initFilter}>
                <p>초기화</p>
              </button>
            ) : (
              <div className="flexRowStart title">
                <h2>필터</h2>
              </div>
            )}

            {filterDrawer ? (
              <button id="greyBtn2" onClick={filterToggle}>
                <UpOutlined />
              </button>
            ) : (
              <button id="greyBtn4" onClick={filterToggle}>
                <DownOutlined />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="rsWrapper flexColCenter">
        <div className="flexRowBetween">
          <div className="content flexColCenter">
            <div className="titleBox flexRowBetween">
              <h1></h1>

              <div className="search flexRowBetween">
                <img src={searchIcon} />
                <Input placeholder="태그 또는 제목을 검색해 주세요." value={filterText} onChange={handleTextChange} />
              </div>

              <img src={mobileFilterIcon} onClick={filterToggleMobile} />
            </div>
          </div>
        </div>
      </div>

      <Drawer open={filterDrawerMobile} width={`100%`} headerStyle={{ display: `none` }}>
        <div id="mobileFilter" className="flexColCenter">
          <div className="flexColEnd">
            <p onClick={handleMobileClose}>
              <CloseOutlined />
            </p>
          </div>

          <div className="content flexColStart">
            <h1>필터</h1>
            <h2></h2>

            <div className="searchBox flexRowBetween">
              <Input placeholder="태그 또는 제목을 검색해 주세요." value={mobileFilterText} onChange={handleMobileText} />
              <img src={searchIcon} />
            </div>

            {cateFilterItem.map((item) => {
              return (
                <div className="listBox flexColStart " key={item.value}>
                  <h3>{item.label}</h3>
                  <div className="flexRowStart">
                    {item.value !== 'sort' ? (
                      <div className="list flexRowCenter" id={mobileSelectedItem[item.value] == 'all' ? 'active' : ''} onClick={() => handleMobileChange(item.value, 'all')}>
                        <div className="round flexColCenter">
                          <img src={whiteCheckIcon} />
                        </div>

                        <p>전체</p>
                      </div>
                    ) : (
                      ''
                    )}
                    {item.children?.map((child, childIndex) => (
                      <div
                        className="list flexRowCenter"
                        key={child.value}
                        id={mobileSelectedItem[item.value] == child.value ? 'active' : ''}
                        onClick={() => handleMobileChange(item.value, child.value)}
                      >
                        <div className="round flexColCenter">
                          <img src={whiteCheckIcon} />
                        </div>

                        <p>{child.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="btnBox flexRowBetween">
            <button id="logoutBtn" onClick={initFilter}>
              <img src={resetIcon} />
              <p>초기화</p>
            </button>
            <button id="pinkBtn" onClick={handleMobileSearch}>
              검색
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
});

export default Filter;

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const INIT_STATE_TOTAL_PAYAMOUNT_LIST = {
  query: {
    startDt: dayjs().startOf('year').format('YYYY-MM-DD'),
    endDt: dayjs().endOf('year').format('YYYY-MM-DD'),
    dateType: 'year', // year | month | day
  },
  items: [],
  locationKey: '',
};

export const totalPayAmountListSlice = createSlice({
  name: 'totalPayAmountList',
  initialState: INIT_STATE_TOTAL_PAYAMOUNT_LIST,
  reducers: {
    clearTotalPayAmountList: () => INIT_STATE_TOTAL_PAYAMOUNT_LIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setTotalPayAmountList: (state, action) => {
      state.items = action.payload.items;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
  },
});

export const { clearTotalPayAmountList, setQuery, setTotalPayAmountList, setLocationKey } = totalPayAmountListSlice.actions;

export default totalPayAmountListSlice.reducer;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Checkbox, Input, Form, Button, Row, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
// icon
import logo from '@assets/images/common/logo.png';
// component
import ButtonSocialLogin from '@components/ButtonSocialLogin';
import Response from '@components/Response';
// path
import * as PATH from '@routes/pathName';
// lib
import { encryptRSA, validateForbiddenWord } from '@utils/lib';
// api
import * as api from '@api/index';

const INIT_STATE_LOGIN_INFO = {
  mem_email: '',
  mem_key: '',
};

const INIT_STATE_SIGNUP = {
  mem_email: '',
  mem_pwd: '',
  mem_pwd_confirm: '',
  mem_nick: '',
  all_check: false,
};

// 정규식: 이메일/비밀번호
const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
const regexPwd = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,12}$/;

const Signup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => ({ ...INIT_STATE_LOGIN_INFO, ...qs.parse(search?.substring(1) || '') }), [search]);
  // 이메일 인증 여부
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  // 닉네임 중복 여부
  const [isNickVerified, setIsNickVerified] = useState(false);
  // 이메일 발송 여부
  const [isSendEmailLoading, setIsSendEmailLoading] = useState(false);
  // 회원가입 여부
  const [isSignup, setIsSignup] = useState(false);
  // 응답 성공
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  // 응답 실패
  const [isFail, setIsFail] = useState(false);
  const [messageFail, setMessageFail] = useState('');
  // 동의항목 리스트
  const [agreeList, setAgreeList] = useState([]);
  // 체크된 동의항목 리스트
  const [checkedAgreeList, setCheckedAgreeList] = useState([]);
  // 3분 타이머
  const [timer, setTimer] = useState(10);
  // 타이머 실행 여부
  const [timerActive, setTimerActive] = useState(false);
  // 인증번호
  const [authNum, setAuthNum] = useState(0);
  // 인증번호 발송 여부
  const [isSendAuthNum, setIsSendAuthNum] = useState(false);
  // 인증번호 인증 여부
  const [isAuthNumVerified, setIsAuthNumVerified] = useState(false);

  // 동의항목 체크
  const onCheckOne = (e, agreeCd) => {
    const removedCheckedAgreeList = checkedAgreeList?.filter((checkedAgreeCd) => checkedAgreeCd !== agreeCd);
    if (e.target.checked) setCheckedAgreeList([...removedCheckedAgreeList, agreeCd]);
    else setCheckedAgreeList(removedCheckedAgreeList);
  };

  // 전체 동의항목 체크
  const onCheckAll = (e) => {
    const agreeCdList = agreeList?.map((agree) => {
      form.setFieldValue(agree?.cd, e.target.checked);
      return agree?.cd;
    });
    setCheckedAgreeList(e.target.checked ? agreeCdList : []);
    form.setFieldValue('all_check', e.target.checked);
  };

  // 응답 성공 모달창 열기
  const openResponseSuccess = (message) => {
    setMessageSuccess(message);
    setIsSuccess(true);
  };

  // 응답 성공 모달창 닫기
  const closeResponseSuccess = () => {
    setIsSuccess(false);
    if (isSignup) navigate(PATH.LOGIN, { replace: true });
  };

  // 응답 실패 모달창 열기
  const openResponseFail = (message) => {
    setMessageFail(message);
    setIsFail(true);
  };

  // 응답 실패 모달창 닫기
  const closeResponseFail = () => {
    setIsFail(false);
  };

  // 회원가입 요청
  const handleCreateUser = async (formData) => {
    try {
      if (!isEmailVerified) {
        openResponseFail('이메일 인증을 완료해주세요.');
        return;
      }
      if (!isNickVerified) {
        openResponseFail('닉네임 중복검사를 완료해주세요.');
        return;
      }
      if (!isAuthNumVerified) {
        openResponseFail('전화번호 인증을 완료해주세요.');
        return;
      }
      // 동의항목 추출
      const agree_list = agreeList?.map((agree) => {
        return { agree_div: agree?.cd, agree_yn: formData[agree.cd] ? 'Y' : 'N' };
      });
      // 소셜로그인 통해서 회원가입 하는 경우
      if (params.mem_email && params.mem_key) {
        await api.signupBySocialLogin({
          ...formData,
          mem_pwd: encryptRSA(formData.mem_pwd),
          mem_pwd_confirm: encryptRSA(formData.mem_pwd_confirm),
          mem_key: params.mem_key,
          // mem_div: 1.일반 / 2.기업
          mem_div: 'MEM_DIV001',
          plan_nm: '비기너',
          // mem_stat: 01.가입대기 / 02.활성화
          // 소셜로그인 통해서 회원가입하는 사람은 이메일인증 한것으로 보기 때문에, '02.활성화' 상태로 생성
          mem_stat: '02',
          agree_list,
        });
        setIsSignup(true);
        openResponseSuccess('회원가입에 성공하였습니다.');
      }
      // 일반 회원가입
      else {
        await api.signup({
          ...formData,
          mem_pwd: encryptRSA(formData.mem_pwd),
          mem_pwd_confirm: encryptRSA(formData.mem_pwd_confirm),
          // mem_div: 1.일반 / 2.기업
          mem_div: 'MEM_DIV001',
          plan_nm: '비기너',
          // mem_stat: 01.가입대기 / 02.활성화
          mem_stat: '02',
          agree_list,
        });
        setIsSignup(true);
        openResponseSuccess('회원가입에 성공하였습니다.');
      }
    } catch (error) {
      openResponseFail(`회원가입에 실패하였습니다: ${error.message}`);
    }
  };

  // 이메일 인증 요청
  const handleEmailVerification = async () => {
    try {
      setIsSendEmailLoading(true);
      const mem_email = form.getFieldValue('mem_email');

      if (!mem_email) {
        openResponseFail('이메일을 입력해주세요.');
        return;
      }
      if (!regexEmail.test(mem_email)) {
        openResponseFail('이메일 형식이 올바르지 않습니다!');
        return;
      }

      // 이메일 중복체크
      const { data: checkedEmail } = await api.checkEmail({ mem_email });
      if (checkedEmail === 0) {
        // 이메일 발송
        const verifedEmail = await api.requestEmailVerification({ mem_email });
        if (verifedEmail.status === 200) {
          openResponseSuccess('인증 이메일이 발송되었습니다. 이메일을 확인해주세요.');
        } else {
          openResponseFail(`인증 이메일 발송 중 오류가 발생했습니다: ${verifedEmail.data}`);
        }
      } else if (checkedEmail > 0) {
        setIsEmailVerified(false);
        openResponseFail('이미 사용중인 이메일 입니다.');
      }
    } catch (error) {
      console.error('이메일 인증 요청 중 오류:', error.response || error.message);
      openResponseFail('이메일 인증 요청 중 오류가 발생했습니다.');
    } finally {
      setIsSendEmailLoading(false);
    }
  };

  // 닉네임 중복 검사
  const handleDuplicateNick = async () => {
    try {
      const mem_nick = form.getFieldValue('mem_nick');

      if (!mem_nick) {
        openResponseFail('닉네임을 입력해주세요.');
        return;
      }

      const forbiddenWord = validateForbiddenWord(mem_nick);
      if (forbiddenWord !== 'Y') {
        setIsNickVerified(false);
        openResponseFail(`금칙어를 사용할 수 없습니다: ${forbiddenWord}`);
        return;
      }
      const { data: response } = await api.checkNick({ mem_nick });
      if (response === 0) {
        setIsNickVerified(true);
        openResponseSuccess('사용가능한 닉네임 입니다.');
      } else if (response > 0) {
        setIsNickVerified(false);
        openResponseFail('이미 사용중인 닉네임 입니다.');
      }
    } catch (error) {
      openResponseFail(`닉네임 중복검사에 실패하였습니다: ${error.message}`);
    }
  };

  // 소셜로그인 통해서 들어온 경우, 사용자 정보 가져오기
  const selectUser = useCallback(async ({ mem_email, mem_key }) => {
    try {
      const { data } = await api.selectUser({ mem_email, mem_key });
      if (!data) return;
      // '2.활성화' 또는 '3.탈퇴신청' 상태인 경우, 로그인 처리후 메인 화면으로 이동
      if (['2', '3'].includes(data.mem_stat)) {
        // 세션 정보 저장
        navigate(PATH.ADMIN_USER_LIST, { replace: true });
      }
      form.setFieldValue('mem_email', data.mem_email);
      form.setFieldValue('mem_nick', data.mem_nick);
    } catch (error) {
      openResponseFail(`사용자 정보 조회에 실패하였습니다: ${error.message}`);
    }
  }, []);

  // 공통코드 동의항목 조회
  const makeAgreeList = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'AGREE' });
      setAgreeList(response?.data);
      response?.data?.map((agree) => {
        form.setFieldValue(agree?.cd, false);
        return agree;
      });
    } catch (error) {
      openResponseFail(`동의항목 조회에 실패하였습니다: ${error.message}`);
    }
  };

  const handleAuthNumVerify = () => {
    console.log(authNum);
    form
      .validateFields(['auth_num'])
      .then(async (values) => {
        const { auth_num } = values;
        if (Number(auth_num) === authNum) {
          message.success('인증되었습니다.');
          setIsAuthNumVerified(true);
        } else {
          message.error('정확한 인증번호를 입력해주세요.');
        }
      })
      .catch(() => {
        message.error('인증번호를 정확히 입력해주세요.');
      });
  };

  const handlePhoneAuthentication = () => {
    form
      .validateFields(['mem_tel'])
      .then(async (values) => {
        const { mem_tel } = values;
        const params = {
          phone: mem_tel,
        };

        const response = await api.sendSMS(params);

        if (response.status === 200) {
          message.success('인증번호가 발송되었습니다.');
          setAuthNum(response.data);
          setIsSendAuthNum(true);
          setTimerActive(true);
          setTimer(180); // 타이머 초기화
        } else {
          message.error(response.data);
        }
      })
      .catch(() => {
        message.error('문자발송에 실패했습니다.');
      });
  };

  useEffect(() => {
    makeAgreeList();
    // 소셜로그인으로 들어온 경우
    if (params.verified === 'true' && params.mem_email && params.mem_key) {
      selectUser(params);
      setIsEmailVerified(true);
    }
    // 이메일 인증으로 들어온 경우
    if (params.verified === 'true' && params.email && !params.mem_key) {
      form.setFieldValue('mem_email', params.email);
      setIsEmailVerified(true);
      openResponseSuccess('이메일이 성공적으로 인증되었습니다.');
    }
  }, [params, params.mem_email, params.mem_key, selectUser, form]);

  useEffect(() => {
    let countdown;

    if (timerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && timerActive) {
      message.error('인증 시간이 초과되었습니다.'); // 메시지는 여기서 1번만 뜸
      setTimerActive(false); // 타이머 비활성화
    }

    return () => clearInterval(countdown); // 타이머 정리
  }, [timer, timerActive]);

  return (
    <>
      <section className="flexColCenter">
        <article id="signup" className="flexColCenter">
          <div className="flexColCenter logo">
            <img src={logo} alt="logo" />
          </div>

          <Form form={form} name="sign-up" initialValues={INIT_STATE_SIGNUP} onFinish={handleCreateUser} autoComplete="off" scrollToFirstError style={{ width: '100%' }}>
            <Form.Item
              // name="mem_email"
              style={{ marginBottom: '16px' }}
              rules={[
                { required: true, message: '이메일을 입력해주세요!' },
                () => ({
                  validator(_, value) {
                    if (!value || regexEmail.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('이메일 형식이 올바르지 않습니다!'));
                  },
                }),
              ]}
            >
              <Row className="email flexRowBetween">
                <Form.Item name="mem_email" noStyle>
                  <Input className="input" placeholder="이메일 주소를 입력해주세요." disabled={isEmailVerified || (params.mem_email && params.mem_key)} allowClear />
                </Form.Item>
                <Button id="pinkBtn" onClick={handleEmailVerification} disabled={isEmailVerified || (params.mem_email && params.mem_key)} loading={isSendEmailLoading}>
                  이메일인증
                </Button>
              </Row>
            </Form.Item>

            <Form.Item style={{ marginBottom: '16px' }} rules={[{ required: true, message: '닉네임을 입력해주세요!' }]}>
              <Row className="email flexRowBetween">
                <Form.Item name="mem_nick" noStyle>
                  <Input className="input" placeholder="닉네임을 입력해주세요." disabled={isNickVerified || !isEmailVerified} allowClear />
                </Form.Item>
                <Button id="pinkBtn" onClick={handleDuplicateNick} disabled={isNickVerified || !isEmailVerified}>
                  중복검사
                </Button>
              </Row>
            </Form.Item>

            <Form.Item style={{ marginBottom: '16px' }}>
              <Row className="email flexRowBetween">
                <Form.Item
                  name="mem_tel"
                  rules={[
                    { required: true, message: '전화번호를 입력해주세요.' },
                    {
                      pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                      message: '올바른 전화번호 형식이 아닙니다.',
                    },
                  ]}
                  noStyle
                >
                  <Input className="input" placeholder="전화번호를 입력해주세요." disabled={isAuthNumVerified || !isEmailVerified} allowClear />
                </Form.Item>
                <Button id="pinkBtn" onClick={handlePhoneAuthentication} disabled={timerActive}>
                  인증번호 발송
                </Button>
              </Row>
            </Form.Item>

            {isSendAuthNum ? (
              <Form.Item
                style={{ marginBottom: '16px' }}
                rules={[
                  { required: true, message: '인증번호를 입력해주세요.' },
                  {
                    pattern: /^\d{6}$/,
                    message: '올바른 인증번호 형식이 아닙니다.',
                  },
                ]}
              >
                <Row className="email flexRowBetween">
                  <Form.Item name="auth_num" noStyle>
                    <Input className="input" placeholder="인증번호를 입력해주세요." disabled={isAuthNumVerified} allowClear />
                  </Form.Item>
                  <Button id="pinkBtn" onClick={handleAuthNumVerify} disabled={isAuthNumVerified || !timerActive}>
                    인증
                  </Button>
                </Row>
              </Form.Item>
            ) : null}

            <Form.Item
              name="mem_pwd"
              style={{ marginBottom: '16px' }}
              rules={[
                { required: true, message: '비밀번호를 입력해주세요!' },
                () => ({
                  // 비밀번호 유효성 검사
                  validator(_, value) {
                    if (!value || regexPwd.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('비밀번호는 8~12자리/영문, 숫자, 특수기호 조합이어야 합니다!'));
                  },
                }),
              ]}
            >
              <Input.Password className="input" placeholder="비밀번호(8~12자리 영문,숫자,특수기호 포함)" disabled={!isEmailVerified} />
            </Form.Item>

            <Form.Item
              name="mem_pwd_confirm"
              dependencies={['mem_pwd']}
              style={{ marginBottom: '16px' }}
              rules={[
                { required: true, message: '비밀번호를 입력해주세요!' },
                ({ getFieldValue }) => ({
                  // 비밀번호 일치하는지 확인
                  validator(_, value) {
                    if (!value || getFieldValue('mem_pwd') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('비밀번호가 일치하지 않습니다!'));
                  },
                }),
              ]}
            >
              <Input.Password className="input" placeholder="비밀번호 재확인" disabled={!isEmailVerified} />
            </Form.Item>

            <Form.Item name="all_check" valuePropName={'checked'} style={{ width: '100%', height: '30px', marginBottom: '20px' }}>
              <div className="flexColStart check">
                <Checkbox
                  checked={agreeList.length === checkedAgreeList.length}
                  className="checkbox"
                  style={{ fontSize: `13px`, fontFamily: 'Pretendard', fontWeight: 400, margin: `10px 0 0` }}
                  onChange={onCheckAll}
                >
                  전체 동의
                </Checkbox>
              </div>
            </Form.Item>

            <div className="flexColCenter checkBox">
              {agreeList?.map((agree) => (
                <Form.Item
                  key={agree?.cd}
                  name={agree?.cd}
                  valuePropName={'checked'}
                  style={{ width: '100%', height: '22px', marginBottom: '20px' }}
                  rules={[agree?.cd_desc === '필수' ? { required: true, message: '필수 동의항목입니다!' } : { required: false }]}
                >
                  <div className="flexRowBetween">
                    <Checkbox checked={checkedAgreeList?.includes(agree?.cd)} style={{ fontSize: `14px`, fontFamily: 'Pretendard', fontWeight: 400 }} onChange={(e) => onCheckOne(e, agree?.cd)}>
                      {`${agree?.cd_nm}(${agree?.cd_desc})`}
                    </Checkbox>

                    <p style={{ fontFamily: 'Pretendard' }}>보기</p>
                  </div>
                </Form.Item>
              ))}
            </div>

            <Button id="purpleBtn" htmlType="submit">
              회원가입
            </Button>
          </Form>

          <div className="flexRowCenter signup">
            <p>
              <span onClick={() => navigate(PATH.FORGET_EMAIL)}>아이디</span>
              <span> ∙ </span>
              <span onClick={() => navigate(PATH.FORGET_PASSWORD)}>비밀번호 찾기</span>
            </p>
            <p>
              <span onClick={() => navigate(PATH.SIGNUP)}>회원가입</span>
            </p>
          </div>

          <div className="flexColCenter line">
            <div className="absoluteBox flexColCenter"></div>
            <p>or</p>
          </div>

          <div className="flexRowBetween">
            <ButtonSocialLogin provider="kakao" type="squre" text="카카오" />
            {/* <ButtonSocialLogin provider="naver" type="squre" text="네이버" /> */}
            <ButtonSocialLogin provider="google" type="squre" text="구글" />
          </div>
        </article>
      </section>

      <Response type="success" text={messageSuccess} isActive={isSuccess} onClose={closeResponseSuccess} />
      <Response type="error" text={messageFail} isActive={isFail} onClose={closeResponseFail} />
    </>
  );
};

export default Signup;

import React from 'react';
import { Header } from 'antd/es/layout/layout';

const GridHeader = ({ children, headerStyle }) => {
  return (
    <Header
      style={{
        background: 'none',
        lineHeight: '0',
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...headerStyle,
      }}
    >
      {children?.searchArea}
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>{children?.buttonArea}</div>
    </Header>
  );
};

export default GridHeader;

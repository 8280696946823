import styled from "styled-components";
import {Card} from "antd";

export const ChatContainer = styled.div`
    display: flex;
    width: 100%;
    height: 88vh;
    background: #f5f5f5;
    padding: 10px;
    gap: 5px;
    overflow: hidden;
`;

export const ChatRoomList = styled(Card)`
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-card-head {
        flex-shrink: 0;
        min-height: 57px;
        padding: 0;  // 탭을 위한 패딩 제거
        border-bottom: none;  // 탭과의 경계선 제거
    }

    .ant-card-body {
        flex: 1;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .ant-tabs {
        height: 100%;
        .ant-tabs-content {
            height: 100%;
        }
    }
`;

export const RoomListScroll = styled.div`
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 3px;
    }
`;

export const ChatRoom = styled.div`
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;

    &:hover {
        background: #f5f5f5;
    }

    ${props => props.$active && `
        background: #e6f7ff;
        &:hover {
            background: #e6f7ff;
        }
    `}
`;

export const ChatBox = styled(Card)`
    position: relative;
    flex: 1;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-card-head {
        flex-shrink: 0;
        min-height: 57px;
        border-bottom: 1px solid #f0f0f0;
    }

    .ant-card-body {
        flex: 1;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
`;

export const MessageList = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    ${props => props.$showProfile ? 'width: calc(100%);' : 'width: 100%;'}
    visibility: visible;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 3px;
    }
`;

export const MessageBubble = styled.div`
    background: ${props => {
        if (props.$isMine) return '#1890ff'; // 나의 메시지 색상
        if (props.$isAdmin) return '#f56a00'; // 관리자 메시지 색상
        return '#fff'; // 기본 색상
    }};
    color: ${props => (props.$isMine || props.$isAdmin) ? '#fff' : '#000'};
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 4px;
    word-break: break-word;
    position: relative;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 10px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }

    &:before {
        filter: drop-shadow(0 1px 2px rgba(0,0,0,0.15));
        ${props => props.$isMine
                ? `
                right: -6px;
                border-left: 8px solid #1890ff;
            `
                : `
                left: -6px;
                border-right: 8px solid ${props.$isAdmin ? '#f56a00' : '#fff'};
            `}
    }

    &:after {
        ${props => props.$isMine
                ? `
                right: -5px;
                border-left: 8px solid #1890ff;
            `
                : `
                left: -5px;
                border-right: 8px solid ${props.$isAdmin ? '#f56a00' : '#fff'};
            `}
    }
`;

export const MessageGroup = styled.div`
    margin-bottom: 16px;
    display: flex;
    flex-direction: ${props => props.$isMine ? 'row-reverse' : 'row'};
    gap: 10px;
    padding-${props => props.$isMine ? 'left' : 'right'}: 8px; // 고리 공간 확보
`;

export const MessageContent = styled.div`
    max-width: 70%;
    display: flex;
    align-items: center;
    flex-direction: ${props => (props.$isMine ? 'row-reverse' : 'row')};
`;

export const MessageTime = styled.div`
    font-size: 12px;
    color: #999;
    text-align: ${props => props.$isMine ? 'right' : 'left'};
`;

export const InputContainer = styled.div`
    padding: 15px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    gap: 10px;
    background: #fff;
`;

export const SenderName = styled.div`
    margin-bottom: 4px;
    font-size: 13px;
    color: ${props => props.$isAdmin ? '#f56a00' : '#666'};
    font-weight: ${props => props.$isAdmin ? 'bold' : 'normal'};
`;

export const Balloon = styled.div`
    position: absolute;
    background-color: #fff;
    padding: 6px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    min-width: 100px;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &:before {
        content: '';
        position: absolute;
        left: -6px;
        top: 10px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #fff;
        filter: drop-shadow(-2px 0 2px rgba(0,0,0,0.1));
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #999;
`;
export const DateDivider = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    &::before, &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #e8e8e8;
        margin: 0 10px;
    }
`;
export const DateLabel = styled.span`
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    color: #666;
`;

export const ProfileContainer = styled.div`
    width: 300px;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    border-radius: 3px;
`;

export const ProfileImage = styled.img`
    width: 70%;
    height: 22%;
    margin-top: 28%;
    background-color: #f56a00;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
`;


export const InfoSection = styled.div`
    margin-top: 5%;
    text-align: center;
    font-size: 16px;
`;

export const BadgeInfo = styled.div`
    margin: 15px 0;
`;
export const RankingInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
`;

export const SocialMediaIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 15px 0;
`;

export const StatSection = styled.div`
    margin-top: 15px;
`;

import { restApi, querystring } from './config';

// 통합 게시판 목록
export const getIntegratedBoardList = (data) => {
  return restApi.get('/api/admin/integratedBoard', querystring(data));
};
// 통합게시판 저장 / 수정
export const updatedIntegratedBoard = (data) => {
  return restApi.post('/api/admin/updatedIntegratedBoard', data);
};
// 게시판 목록 가져오기
export const getBoardList = (data) => {
  return restApi.get('/api/boards/boardList', querystring(data));
};
// 게시판 저장 / 수정
export const updatedBoard = (data) => {
  return restApi.post('/api/boards/updatedBoard', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// 게시판 조회수
export const increaseViewCount = async (data) => {
  return await restApi.post('/api/boards/viewCnt', data);
};
// 댓글 리스트
export const commentList = async (data) => {
  return await restApi.get('/api/comment/commentList', data);
};
// 댓글 저장 / 수정 / 삭제
export const updatedComment = async (data) => {
  return await restApi.post('/api/comment/updateComment', data);
};

/** 1:1 문의 */
export const saveInquiry = (data) => {
  return restApi.post('/api/inquiry/saveInquiry', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
export const getMemInquiryList = (data) => {
  return restApi.get('/api/inquiry/getMemInquiryList', querystring(data));
};

export const getMemInquiry = (data) => {
  return restApi.get('/api/inquiry/getMemInquiry', querystring(data));
};

export const saveAnswer = (data) => {
  return restApi.post('/api/inquiry/saveAnswer', data, {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });
};

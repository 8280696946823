import React from 'react';
// component
import ListPopularImage from '@pages/main/ListPopularImage';
import ListStaffPickImage from '@pages/main/ListStaffPickImage';
import ListNewImage from '@pages/main/ListNewImage';

const ContentImage = (props) => {
  return (
    <>
      <article id="popularWrapper" className="flexColCenter">
        <ListPopularImage />
      </article>

      <article id="listWrapper" className="flexColCenter">
        <div className="rsWrapper flexRowBetween">
          <ListStaffPickImage />
          <ListNewImage />
        </div>
      </article>
    </>
  );
};

export default ContentImage;

import React, { useRef, useImperativeHandle, useCallback, forwardRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const Editor = forwardRef(({ content, setContent, placeholder }, ref) => {
  const editorRef = useRef(null);
  const [files, setFiles] = useState([]);

  useImperativeHandle(ref, () => ({
    getContent: () => editorRef.current?.editor.getContents(),
    setContent: (newContent) => {
      if (editorRef.current?.editor) {
        editorRef.current.editor.setContents(newContent);
      }
    },
    getProcessedContent: getProcessedContent,
  }));

  const handleEditorChange = useCallback(
    (newContent) => {
      setContent(newContent);
    },
    [setContent],
  );

  const makeBase64ImageToFile = useCallback((base64String) => {
    const byteString = atob(base64String.split(',')[1]);
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const extension = mimeString.split('/')[1];
    return new File([ab], `image_${Date.now()}.${extension}`, { type: mimeString });
  }, []);

  const handleBase64ImageUpload = useCallback(
    async (content) => {
      const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;
      let match;
      const imageMap = {};
      const newFiles = [];

      while ((match = base64ImageRegex.exec(content)) !== null) {
        const base64Image = match[1];
        const file = makeBase64ImageToFile(base64Image);
        newFiles.push(file);
        imageMap[base64Image] = URL.createObjectURL(file);
      }

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      let contentWithImages = content;
      Object.entries(imageMap).forEach(([base64, url]) => {
        contentWithImages = contentWithImages.replace(base64, url);
      });
      return contentWithImages;
    },
    [makeBase64ImageToFile],
  );

  const getProcessedContent = useCallback(async () => {
    const content = editorRef.current?.editor.getContents();
    const processedContent = await handleBase64ImageUpload(content);

    // 파일을 handleBase64ImageUpload 함수에서 반환받고 즉시 사용
    const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;
    let match;
    const newFiles = [];

    while ((match = base64ImageRegex.exec(content)) !== null) {
      const base64Image = match[1];
      const file = makeBase64ImageToFile(base64Image);
      newFiles.push(file);
    }
    // console.log(files);

    return {
      content: processedContent,
      files: newFiles, // 파일 리스트를 반환
    };
  }, [handleBase64ImageUpload, makeBase64ImageToFile]);

  return (
    <SunEditor
      debounce={300}
      getSunEditorInstance={(sunEditor) => {
        editorRef.current = { editor: sunEditor };
      }}
      setContents={content}
      onChange={handleEditorChange}
      setOptions={{
        resizingBar: false,
        autoHeight: false,
        minHeight: 600,
        height: 'auto',
        overflow: 'auto',
        buttonList: [
          ['undo', 'redo'],
          ['font', 'fontSize', 'formatBlock'],
          ['paragraphStyle', 'blockquote'],
          ['bold', 'underline', 'italic', 'strike'],
          ['fontColor', 'hiliteColor', 'align', 'list', 'lineHeight'],
          ['image', /*'video', 'audio', */ 'table', 'link'],
          ['fullScreen', 'showBlocks', 'codeView'],
        ],
      }}
      placeholder={placeholder}
    />
  );
});

export default Editor;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { message, Layout, Form, Input, Button, Row, Col, Modal } from 'antd';
import RangePicker from '@components/RangePicker';
import { useLocation } from 'react-router-dom';
import Table from '@components/Table';
import ButtonExport from '@components/ButtonExportAUIGrid';
import { periodList } from '@utils/constants';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setSellerList, setLocationKey, setYScrollPosition, INIT_STATE_SELLERLIST, clearSellerList } from '@stores/sellerList';
import { useDebounce, secondsCompactFormat } from '@utils/lib';
import * as api from '@api/index';

const { Content, Header } = Layout;

const SellerList = () => {
  const { key: locationKey } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const sellerListRef = useRef();
  const sellerList = useSelector((s) => s.sellerList, shallowEqual);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 그리드 설정
  const sellerListGridProps = {
    editable: false,
    showRowCheckColumn: true,
  };

  // 그리드 칼럼 레이아웃 정의
  const sellerColumnLayout = [
    { dataField: 'seller_id', headerText: '판매자ID', width: '5%', visible: false },
    { dataField: 'business_tp', headerText: '사업자유형', width: '5%' },
    { dataField: 'company_nm', headerText: '상호', width: '10%' },
    { dataField: 'represent_nm', headerText: '대표자명', width: '5%' },
    { dataField: 'business_no', headerText: '사업자 등록번호', width: '8%' },
    { dataField: 'email', headerText: '이메일', width: '10%' },
    { dataField: 'phone', headerText: '전화번호', width: '8%' },
    { dataField: 'fund_dt', headerText: '개업일자', width: '5%' },
    { dataField: 'addr', headerText: '사업장주소', width: '15%' },
    { dataField: 'corp_no', headerText: '법인등록번호', width: '8%' },
    { dataField: 'bank_nm', headerText: '은행', width: '10%' },
    { dataField: 'holder_nm', headerText: '예금주', width: '5%' },
    { dataField: 'account', headerText: '계좌번호', width: '15%' },
  ];

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...sellerList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listSeller(newQuery));
  };

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    sellerListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  const handleSearchSeller = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...sellerList.query, keyword_text, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listSeller(newQuery));
  };

  //지급관리 목록 조회
  const listSeller = useCallback(async (query) => {
    sellerListRef.current.showAjaxLoader();
    try {
      const { data } = await api.listSeller(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && sellerListRef.current.getGridData()) {
        dispatch(
          setSellerList({
            items: [...sellerListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: sellerListRef.current.getGridData().length + data.items.length,
          }),
        );
        sellerListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setSellerList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        sellerListRef.current.setGridData(data.items);
      }
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: sellerListRef.current.getGridData().length,
      });
      sellerListRef.current.removeAjaxLoader();
    } catch (error) {
      message.warning(error.message);
    } finally {
    }
  }, []);

  // 날짜 필터링
  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...sellerList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listSeller(newQuery));
  };

  useEffect(() => {
    // 새로고침 또는 뒤로가기로 렌더링 됐을때
    if (locationKey === sellerList.locationKey) {
      sellerListRef.current.setGridData(sellerList.items);
      sellerListRef.current.setRowPosition(sellerList.yScrollPosition);
      setupGridEvents({
        offset: sellerList.query.offset,
        limit: sellerList.query.limit,
        totalCount: sellerList.totalCount,
        currentCount: sellerList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    dispatch(clearSellerList());
    dispatch(setLocationKey(locationKey));
    listSeller(INIT_STATE_SELLERLIST.query);
    form.setFieldsValue({ keyword_text: '' });
  }, [locationKey]);

  return (
    <>
      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
          <Row>
            <Form form={form} layout="inline" initialValues={sellerList?.query} onFinish={handleSearchSeller}>
              <Form.Item name="keyword_text">
                <Input placeholder="상호를 입력해주세요." allowClear style={{ minWidth: '300px' }} />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
            <Row>
              <RangePicker
                styleSelect={{ minWidth: '100px' }}
                placeholderSelect="판매자 등록일"
                optionsSelect={periodList}
                valueRangePicker={[sellerList?.query?.startDt ? dayjs(sellerList?.query?.startDt) : '', sellerList?.query?.endDt ? dayjs(sellerList?.query?.endDt) : '']}
                onChange={handleDatePay}
              />
            </Row>
          </Row>
          <Row>
            <Row>검색결과</Row>
            <Row style={{ marginLeft: '10px' }}>{`총 ${sellerList?.totalCount}건`}</Row>
            <Row>
              <Col>
                <ButtonExport ref={sellerListRef} fileName={`${secondsCompactFormat(new Date())}_판매자목록조회`} text="엑셀 다운" />
              </Col>
            </Row>
          </Row>
        </Header>
        <Content>
          <Table ref={sellerListRef} columnLayout={sellerColumnLayout} customGridProps={sellerListGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default SellerList;

import React from "react";
import Slider from "react-slick";
import "../../assets/css/imageSlider.css";
import "../../assets/css/common.css";
import "../../assets/css/reset.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import thumbnail from "../../assets/images/common/thumb.png";

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className="arrowRight flexColCenter" onClick={onClick}>
      <p>
        <RightOutlined />
      </p>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className="arrowLeft flexColCenter" onClick={onClick}>
      <p>
        <LeftOutlined />
      </p>
    </div>
  );
};

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slider-container" id="imageSlider">
      <Slider {...settings}>
        <div className="list flexColCenter">
          <div className="flexColCenter">
            <img src={thumbnail} />
          </div>
        </div>
        <div className="list flexColCenter">
          <div className="flexColCenter">
            <img src={thumbnail} />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;

import React from 'react';
import HeaderUser from './HeaderUser';
import FixedNav from '@components/FixedNav';
import Footer from './Footer';

const LayoutBasic = ({ ContentBody }) => {
  return (
    <>
      <HeaderUser />
      <section style={{ margin: `52px 0 0` }}>
        {ContentBody}
        <FixedNav />
      </section>
      <Footer />
    </>
  );
};

export default LayoutBasic;

import React from 'react';
// icon
import musicIcon from '@assets/images/common/music_icon_2.png';
import playIcon2 from '@assets/images/common/play_icon2.png';
import userThumb from '@assets/images/common/user_thumb.png';
import thumbnail from '@assets/images/common/thumb.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';

const CardSquareMusic2 = ({ data, isActive, openMusicPlayer, moveToDetailPage }) => {
  return (
    <div className="gerneBox" key={data?.prpt_id} id={isActive ? 'active' : ''}>
      <div className="flexColBetween absolBox" onClick={moveToDetailPage}>
        <div className="flexColBetween">
          <div className="flexRowStart user">
            <img src={data?.icon_path ?? userThumb} />
            <p>{data?.ai_model_nm}</p>
          </div>

          <div className="play flexColCenter">
            <img
              src={playIcon2}
              alt="playIcon"
              onClick={(e) => {
                e.stopPropagation();
                openMusicPlayer(data);
              }}
            />
            <div className="musicIcon flexColCenter">
              <img src={musicIcon} alt="musicIcon" />
            </div>
          </div>

          <div className="flexColStart title">
            <h1>{data?.prpt_title}</h1>
            <div className="flexRowStart">
              <img src={data?.mem_img_path ?? userIcon3} />
              <p>{data?.mem_nick}</p>
            </div>
          </div>
        </div>
      </div>
      <img src={data?.thum_path ?? thumbnail} />
    </div>
  );
};

export default CardSquareMusic2;

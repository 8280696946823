import { restApi, querystring, headersForSession } from './config';
import { AUTH_LOGIN } from '@utils/constants';

// 플레이리스트 추가
export const addPlaylistMusic = (data) => {
  headersForSession(AUTH_LOGIN);
  return restApi.post('/api/addPlaylistMusic', data);
};

// 플레이리스트 삭제
export const deletePlaylistMusic = (data) => {
  headersForSession(AUTH_LOGIN);
  return restApi.post('/api/deletePlaylistMusic', data);
};

// 플레이리스트 조회
export const listPlaylistMusic = (data) => {
  return restApi.get('/api/listPlaylistMusic', querystring(data));
};

// 마이뮤직 추가
export const addMyMusic = (data) => {
  headersForSession(AUTH_LOGIN);
  return restApi.post('/api/addMyMusic', data);
};

// 마이뮤직 삭제
export const deleteMyMusic = (data) => {
  headersForSession(AUTH_LOGIN);
  return restApi.post('/api/deleteMyMusic', data);
};

// 마이뮤직 조회
export const listMyMusic = (data) => {
  return restApi.get('/api/listMyMusic', querystring(data));
};

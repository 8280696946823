import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Select, message } from 'antd';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
// icon
import { DownOutlined, MoreOutlined, UpOutlined } from '@ant-design/icons';
import saveIcon from '@assets/images/common/save_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeCartIcon from '@assets/images/common/active_cart_icon.png';
import downloadIcon from '@assets/images/common/download_icon.png';
import activeDownloadIcon from '@assets/images/common/active_download_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import thumbnail from '@assets/images/common/thumb.png';
import albumThumb from '@assets/images/common/album_thumb.png';
import trashIcon from '@assets/images/common/prompt_trash_icon.png';
import activeTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import randomIcon from '@assets/images/common/random_icon.png';
import prevArrowIcon from '@assets/images/common/prev_arrow_icon.png';
import playIcon3 from '@assets/images/common/play_icon3.png';
import nextArrowIcon from '@assets/images/common/next_arrow_icon.png';
import repeatIcon from '@assets/images/common/repeat_icon.png';
import greyArrowIcon from '@assets/images/common/grey_arrow_right.png';
import activeHeadsetIcon from '@assets/images/common/active_headset_icon.png';
// mp3
import freepop01 from '@assets/songs/freepop_01.mp3';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';

const tabList = [
  { label: '플레이리스트', value: 1 },
  { label: '마이뮤직', value: 2 },
];

const PlayerMusic = () => {
  // 플레이리스트/마이뮤직 목록 데이터
  const [musicList, setMusicList] = useState([]);
  // 플레이리스트/마이뮤직 목록 데이터 총 갯수
  const [totalMusicList, setTotalMusicList] = useState(0);
  // 보관함 목록 데이터: prpt_id만 있음
  const [savedMusicList, setSavedMusicList] = useState([]);
  // 좋아요 목록 데이터: prpt_id만 있음
  const [likedMusicList, setLikedMusicList] = useState([]);
  const [selectedSpreadButton, setSelectedSpreadButton] = useState();
  // 현재 재생되는 음악의 item
  const [selectedMusicItem, setSelectedMusicItem] = useState();
  // 현재 재생되는 음악의 index
  const [selectedMusicIndex, setSelectedMusicIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 이전곡 재생
  const onPrevious = () => {
    if (selectedMusicIndex === 0) {
      setSelectedMusicItem(musicList[musicList.length - 1]);
      setSelectedMusicIndex(musicList.length - 1);
    } else {
      setSelectedMusicItem(musicList[selectedMusicIndex - 1]);
      setSelectedMusicIndex(selectedMusicIndex - 1);
    }
  };

  // 다음곡 재생
  const onNext = () => {
    if (selectedMusicIndex === musicList.length - 1) {
      setSelectedMusicItem(musicList[0]);
      setSelectedMusicIndex(0);
    } else {
      setSelectedMusicItem(musicList[selectedMusicIndex + 1]);
      setSelectedMusicIndex(selectedMusicIndex + 1);
    }
  };

  // 리스트에서 곡 선택
  const onSelectMusic = (music, index) => {
    setSelectedMusicItem(music);
    setSelectedMusicIndex(index);
  };

  // 더보기(...) 버튼 클릭시
  const onClickSpreadButton = (index) => {
    if (index === selectedSpreadButton) setSelectedSpreadButton('');
    else setSelectedSpreadButton(index);
  };

  // 탭 변경시
  const changeTab = async (index) => {
    setSelectedTab(index);
    await listMusic(index);
  };

  // 플레이리스트/마이뮤직 가져오기
  const listMusic = useCallback(async (tab) => {
    try {
      if (!auth?.mem_key) return;
      let data = {};
      if (tab === 1) {
        const response = await api.listPlaylistMusic({ mem_key: auth?.mem_key });
        data = response.data;
      }
      if (tab === 2) {
        const response = await api.listMyMusic({ mem_key: auth?.mem_key });
        data = response.data;
      }
      if (!data) return;
      setMusicList(data.items);
      setTotalMusicList(data.total);
      setSelectedMusicItem(data.items[0]);
      setSelectedMusicIndex(0);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.listMyMusic({ mem_key: auth?.mem_key });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setSavedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 추가
  const addMyMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.addMyMusic({
        mem_key: auth?.mem_key,
        prpt_id: music?.prpt_id,
        prpt_title: music?.prpt_title,
        prpt_path: music?.music_org_link,
      });
      if (!data) return;
      await listSavedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.deleteMyMusic({
        mem_key: auth?.mem_key,
        prpt_id: music?.prpt_id,
      });
      if (!data) return;
      await listSavedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 목록 가져오기
  const listLikedMusic = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.getMyPromptList({ mem_key: auth?.mem_key, keyword_tab: 'likes', filter_cate: ['CATE003'] });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setLikedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 추가
  const addLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
        fir_id: auth?.mem_key,
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 삭제
  const deleteLikeMusic = useCallback(async (music) => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.increaseLikeCount({
        target_id: music?.prpt_id,
        like_mem_key: auth?.mem_key,
        own_mem_key: music?.mem_key,
        like_div: 'P',
      });
      if (!data) return;
      await listLikedMusic();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listMusic(1));
    listSavedMusic();
    listLikedMusic();
  }, []);

  return (
    <div id="musicBox" className="flexColCenter">
      <div className="flexColCenter album">
        <div className="absoluteBox flexColCenter">
          <div className="flexColCenter">
            <div className="flexColCenter borderBox">
              <img src={userIcon3} />
            </div>
          </div>
        </div>
        <img src={selectedMusicItem?.thum_path} />
      </div>

      <div className="playBox flexColCenter">
        <p>{selectedMusicItem?.mem_nick}</p>

        <div className="flexRowBetween title">
          {savedMusicList.includes(selectedMusicItem?.prpt_id) ? (
            <img src={activeSaveIcon} onClick={() => deleteMyMusic(selectedMusicItem)} />
          ) : (
            <img src={saveIcon} onClick={() => addMyMusic(selectedMusicItem)} />
          )}

          <h1>{selectedMusicItem?.prpt_title}</h1>

          {likedMusicList.includes(selectedMusicItem?.prpt_id) ? (
            <img src={activeHeartIcon} onClick={() => deleteLikeMusic(selectedMusicItem)} />
          ) : (
            <img src={heartIcon} onClick={() => addLikeMusic(selectedMusicItem)} />
          )}
        </div>

        <div className="flexColStart">
          <AudioPlayer
            src={selectedMusicItem?.music_org_link ?? freepop01}
            loop={true}
            showSkipControls={true}
            showJumpControls={false}
            customIcons={{
              play: <img src={playIcon3} />,
              previous: <img src={prevArrowIcon} />,
              next: <img src={nextArrowIcon} />,
              loop: <img src={repeatIcon} />,
            }}
            customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, <div className="separator">/</div>, RHAP_UI.DURATION]}
            customControlsSection={[
              <div className="rhap_shuffle-controls">
                <button className="rhap_button-shuffle">
                  <img src={randomIcon} />
                </button>
              </div>,
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.ADDITIONAL_CONTROLS,
            ]}
            onClickPrevious={onPrevious}
            onClickNext={onNext}
            onListen={(e) => console.log('e: ', e)}
          />
        </div>
      </div>

      <div className="flexRowCenter tabBox">
        {tabList?.map((tab) => (
          <div key={tab?.value} className="tab flexColCenter" id={tab?.value === selectedTab ? 'active' : ''} onClick={() => changeTab(tab?.value)}>
            {tab?.label}
          </div>
        ))}
      </div>

      <div className="flexRowBetween top">
        <div className="flexRowCenter">
          <Checkbox className="check" />
          <p className="arrowDown">
            <DownOutlined />
          </p>
          <p className="arrowUp flexColCenter">
            <UpOutlined />
          </p>

          <p>
            {`${totalMusicList}곡 / `}
            <span>0곡</span>
          </p>
        </div>

        <div className="antSelect flexColCenter">
          <Select value={1}>
            <Select.Option value={1}>추가순</Select.Option>
            <Select.Option>오래된순</Select.Option>
          </Select>
        </div>
      </div>

      <div className="flexColCenter listBox">
        <div className="flexColCenter">
          {musicList?.map((music, index) => (
            <div key={index} className="list flexRowBetween" id={index === selectedMusicIndex ? 'active' : ''}>
              <Checkbox className="check" />

              <img src={music?.thum_path} className="thumbnail" />

              <div className="flexColStart titleBox">
                <div className="flexRowStart">
                  <img src={music?.icon_path} />
                  <h1>{music?.prpt_title}</h1>
                </div>

                <div className="flexRowStart">
                  <img src={music?.mem_img_path} />
                  <p>{music?.mem_nick}</p>
                </div>
              </div>

              <div className="playIcon flexColCenter" onClick={() => onSelectMusic(music, index)}>
                <img src={greyArrowIcon} />
                <img src={activeHeadsetIcon} className="activeIcon" />
              </div>
              <p onClick={() => onClickSpreadButton(music?.prpt_id)}>
                <MoreOutlined />
              </p>

              {index === selectedSpreadButton && (
                <div className="absoluteBox flexColCenter">
                  <div className="tab flexColCenter">곡정보</div>
                  <div className="tab flexColCenter">엔지니어 채널</div>
                  <div className="tab flexColCenter">사용권 구매</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flexRowBetween bottom">
        <div className="flexColCenter">
          <img src={saveIcon} />
          <img src={activeSaveIcon} className="activeIcon" />
        </div>
        <div className="flexColCenter">
          <img src={cartIcon} />
          <img src={activeCartIcon} className="activeIcon" />
        </div>
        <div className="flexColCenter">
          <img src={downloadIcon} />
          <img src={activeDownloadIcon} className="activeIcon" />
        </div>
        <div className="flexColCenter">
          <img src={trashIcon} />
          <img src={activeTrashIcon} className="activeIcon" />
        </div>
      </div>
    </div>
  );
};

export default PlayerMusic;

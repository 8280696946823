import { restApi, querystring } from './config';

// 쿠폰 조회
export const getCouponList = (data) => {
  return restApi.get('/api/coupon/couponSearch', querystring(data));
};
// 쿠폰 저장
export const couponSave = (data) => {
  return restApi.post('/api/coupon/couponSave', data);
};
// 쿠폰상세조회
export const getCpnDtlSearch = (data) => {
  return restApi.get('/api/coupon/cpnDtlSearch', querystring(data));
};

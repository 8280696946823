import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Form, Input, Layout, message, Row } from 'antd';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { ArrowDownOutlined, BookOutlined, DownOutlined, PictureOutlined, PlayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import qs from 'qs';
import { dayFormat, formatEncodingDate, formatNumber } from '@utils/lib';
import { ADMIN_INQUIRY_DETAIL, INQUIRY_EDIT } from '@routes/pathName';
import TextArea from 'antd/es/input/TextArea';
import '@assets/css/inquiry.css';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import starIcon from '@assets/images/common/star_icon.png';

const InquiryDetail = () => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const { state } = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(1000);
  const [fileList, setFileList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [writerProfile, setWriterProfile] = useState({});
  const [inquiryState, setInquiryState] = useState({
    query: {
      mem_key: state?.mem_key || 5,
      inqr_seq: state?.inqr_seq,
    },
    item: {},
  });

  /** api */
  // 문의내역 조회
  const getMemInquiry = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getMemInquiry(query);
      setInquiryState((prev) => ({ ...prev, item: data.item }));
      if (data.fileList) {
        setFileList(data.fileList.filter((file) => file.attr1 != 'c'));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 작성자 프로필 조회
  const getProfile = async (key) => {
    const params = { mem_key: key };
    const { data } = await api.userProfile({ params });
    setWriterProfile(data);
    console.log(data);
  };

  // 답변 저장 - 관리자용
  const saveAnswer = async () => {
    const answer = answerForm.getFieldValue('answer');
    const params = {
      mem_key: auth?.mem_key, // 답변 작성자
      answer: answer,
      inqr_seq: state.inqr_seq,
    };

    try {
      setIsLoading(true);
      setTime(0);
      const { data } = await api.saveAnswer(params);
      console.log(data);
    } catch (e) {
      message.error('답변작성에 실패했습니다.');
    } finally {
      setIsLoading(false);
      setTime(1000);
      getMemInquiry(inquiryState.query);
    }
  };

  /** event 핸들러 */
  const downloadFile = async (filePath, fileName) => {
    try {
      const response = await fetch(filePath);
      if (!response.ok) throw new Error('File download failed');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleReWriteClick = () => {
    navigate(INQUIRY_EDIT, { state: { inqr_seq: inquiryState.item.inqr_seq, mem_key: inquiryState.query.mem_key } });
  };

  useEffect(() => {
    console.log(state.inquiryList);
    getMemInquiry(inquiryState.query);
    getProfile(inquiryState.query.mem_key);
  }, []);

  const [answerForm] = Form.useForm();

  const AnswerArea = () => {
    if (inquiryState.item.inqr_stat == '02') {
      // 답변완료 상태일 때 답변 창만 보임 - 추가 문의/답글 제한
      return (
        <>
          <div className="answerBox">
            <div className="answer">
              <div span={24}>
                <p id="answerMem">{inquiryState.item.answer_mem_nick}</p>
              </div>
              <p> {inquiryState.item.answer}</p>
            </div>
          </div>
        </>
      );
    } else {
      // 미답변 상태
      if (state.flag == 'A') {
        // 관리자인 경우
        return (
          <Form form={answerForm} onFinish={saveAnswer} className="answerCreate flexRowBetween">
            <div className="create flexColStart">
              <p>답변하기</p>
              <div className="flexRowBetween">
                <TextArea className="input" placeholder="답변을 등록하세요" onChange={(e) => answerForm.setFieldsValue({ answer: e.target.value })} />
                <Button id="greyBtn" htmlType="submit">
                  저장
                </Button>
              </div>
            </div>
          </Form>
        );
      }
      if (state?.mem_key == auth?.mem_key) {
        // 본인 작성인 경우
        return (
          <Button onClick={handleReWriteClick} type="primary">
            수정
          </Button>
        );
      }
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} timeout={time} />
      <article id="inquiryDetail">
        <div className="title flexRowStart">
          <span>{inquiryState.item.inqr_div_nm}</span>
          <h1>{inquiryState.item.inqr_title}</h1>
          <p className="dt">{formatEncodingDate(inquiryState.item.lst_dt)}</p>
        </div>

        <div className="user flexRowStart">
          <div className="userIcon flexColCenter">
            <p>
              <img src={writerProfile.mem_img_path || userIcon3} alt="image" />
            </p>
          </div>

          <div className="flexColStart">
            <div className="grade flexRowStart">
              <div className="flexColCenter">{writerProfile.plan_nm}</div>
              <h2>{writerProfile.mem_nick}</h2>
            </div>

            <p>
              <div>
                <div className="profileBox flexRowBetween">
                  <div>
                    <div className="flexRowStart" onClick={toggleDropdown}>
                      <PictureOutlined style={{ fontSize: '22px' }} />
                      <div>#{writerProfile.agg_rank ? formatNumber(writerProfile.agg_rank.split(',')[0]) : 'N/A'}</div>
                      <DownOutlined style={{ fontSize: '18px' }} />
                    </div>
                    {isOpen && (
                      <div className="flexColStart profileToggle">
                        <div className="flexRowStart">
                          <PlayCircleOutlined style={{ fontSize: '22px' }} />
                          <div>#{writerProfile.agg_rank ? formatNumber(writerProfile.agg_rank.split(',')[1]) : 'N/A'}</div>
                        </div>
                        <div className="flexRowStart">
                          <BookOutlined style={{ fontSize: '22px' }} />
                          <div>#{writerProfile.agg_rank ? formatNumber(writerProfile.agg_rank.split(',')[3]) : 'N/A'}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flexRowCenter profileStar">
                    {[...Array(5)].map((_, index) => (
                      <img key={index} src={starIcon} alt="star_icon" className={index < writerProfile.score_avg ? 'filledStar' : 'emptyStar'} />
                    ))}
                  </div>
                </div>
              </div>
            </p>
          </div>
        </div>
        <Divider />
        <Row>
          <Col span={24}>첨부파일</Col>
          <ul>
            {fileList && fileList.length > 0 ? (
              fileList.map((file) => (
                <li key={file.file_seq} onClick={() => downloadFile(file.file_dwn_path, file.file_org_nm)}>
                  <ArrowDownOutlined /> {file.file_org_nm}
                </li>
              ))
            ) : (
              <li> 첨부된 파일이 없습니다. </li>
            )}
          </ul>
        </Row>

        <Divider />
        <Row>
          <div
            style={{
              minHeight: '500px',
              maxHeight: '800px',
              width: '100%',
              overflowY: 'auto',
              border: '1px solid #d9d9d9',
              padding: '8px',
              borderRadius: '2px',
            }}
            dangerouslySetInnerHTML={{ __html: inquiryState.item.contents }}
          />
        </Row>
        <AnswerArea />
      </article>
    </>
  );
};

export default InquiryDetail;

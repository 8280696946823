import React, { useCallback, useState, useEffect } from 'react';
import { Drawer, Input, Radio, message, Skeleton } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';

const Filter = ({ isActive, toggle }) => {
  return (
    <div className="filter flexColCenter" id={isActive ? 'filter' : 'nothing'}>
      <div className="rsWrapper flexColCenter">
        <div className="listBox flexRowStart">
          <div className="flexRowStart title">
            <img src={mobileFilterIcon} />
            <h2>정렬</h2>
          </div>

          <Radio.Group
            value={2}
            style={{
              display: `flex`,
              flexDirection: `row`,
            }}
          >
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={2}
            >
              Midjourney
            </Radio>
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={3}
            >
              Dall-E
            </Radio>
          </Radio.Group>
        </div>

        <div className="listBox flexRowStart">
          <div className="flexRowStart title">
            <img src={mobileFilterIcon} />
            <h2>모델</h2>
          </div>

          <Radio.Group
            value={2}
            style={{
              display: `flex`,
              flexDirection: `row`,
            }}
          >
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={2}
            >
              Midjourney
            </Radio>
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={3}
            >
              Dall-E
            </Radio>
          </Radio.Group>
        </div>

        <div className="listBox flexRowStart">
          <div className="flexRowStart title">
            <img src={mobileFilterIcon} />
            <h2>장르</h2>
          </div>
          <Radio.Group
            value={2}
            style={{
              display: `flex`,
              flexDirection: `row`,
            }}
          >
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={2}
            >
              카테고리1
            </Radio>
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={3}
            >
              카테고리2
            </Radio>
            <Radio
              style={{
                width: `140px`,
                fontSize: `14px`,
              }}
              value={4}
            >
              카테고리3
            </Radio>
          </Radio.Group>
        </div>

        <div className="reset flexRowBetween">
          {isActive ? (
            <>
              <button id="whiteBtn">
                <img src={resetIcon} />
                <p>초기화</p>
              </button>
              <button id="greyBtn2" onClick={toggle}>
                <UpOutlined />
              </button>
            </>
          ) : (
            <>
              <div className="flexRowStart title">
                <img src={mobileFilterIcon} />
                <h2>필터</h2>
              </div>
              <button id="greyBtn4" onClick={toggle}>
                <DownOutlined />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;

import React from 'react';
import { Row, Flex, Card, List } from 'antd';

const ListCardForKeyValue = ({ data = [], style = {} }) => {
  return (
    <List
      grid={{ gutter: 16, column: data.length }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          style={{ display: 'flex', maxWidth: '180px', margin: '0', padding: '8px 10px', border: 'solid 1px #CBCECF', borderRadius: '0.8rem' }}
        >
          <Row>{item.label}</Row>
          <Row>{item.value}</Row>
        </List.Item>
      )}
    />
  );
};

export default ListCardForKeyValue;

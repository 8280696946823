import React, { useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Checkbox, Input, Layout, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import { updAlrtSetting } from '@api/index';

const NoticeSetting = () => {
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const userAlertListRef = useRef();

  const columnLayout = [
    { dataField: 'alrt_contents', headerText: '알림 내용', width: '55%' },
    {
      dataField: 'email_yn',
      headerText: '이메일',
      width: '15%',
      renderer: {
        type: 'CheckBoxEditRenderer',
        showLabel: false, // 참, 거짓 텍스트 출력여부( 기본값 false )
        editable: true, // 체크박스 편집 활성화 여부(기본값 : false)
        checkValue: 'Y', // true, false 인 경우가 기본
        unCheckValue: 'N',
        disabledFunction: function (rowIndex, columnIndex, value, isChecked, item, dataField) {
          if (value == 'X') {
            return true;
          }
          return false;
        },
      },
    },
    {
      dataField: 'sms_yn',
      headerText: '문자',
      width: '15%',
      renderer: {
        type: 'CheckBoxEditRenderer',
        showLabel: false, // 참, 거짓 텍스트 출력여부( 기본값 false )
        editable: true, // 체크박스 편집 활성화 여부(기본값 : false)
        checkValue: 'Y', // true, false 인 경우가 기본
        unCheckValue: 'N',
        disabledFunction: function (rowIndex, columnIndex, value, isChecked, item, dataField) {
          if (value == 'X') {
            return true;
          }
          return false;
        },
      },
    },
    {
      dataField: 'alrt_yn',
      headerText: '메시지',
      width: '15%',
      renderer: {
        type: 'CheckBoxEditRenderer',
        showLabel: false, // 참, 거짓 텍스트 출력여부( 기본값 false )
        editable: true, // 체크박스 편집 활성화 여부(기본값 : false)
        checkValue: 'Y', // true, false 인 경우가 기본
        unCheckValue: 'N',
        disabledFunction: function (rowIndex, columnIndex, value, isChecked, item, dataField) {
          if (value == 'X') {
            return true;
          }
          return false;
        },
      },
    },
  ];

  const userAlertProps = {
    selectionMode: 'singleRow',
    editable: false,
    showRowCheckColumn: false,
    showRowNumColumn: false,
  };

  const getAlertSetting = async () => {
    try {
      const response = await api.getAlertSetting();
      userAlertListRef.current.setGridData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  //
  const alrtSettingSave = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;
    const saveItems = userAlertListRef.current.getEditedRowItems({});

    try {
      setLoading((prev) => ({ ...prev, saveLoad: true }));

      const response = await api.updAlrtSetting(saveItems);
      alert(response.data.returnMessage);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, saveLoad: false }));
      getAlertSetting();
    }
  };

  useEffect(() => {
    getAlertSetting();
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} timeout={0} />
      <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Content style={{ width: '1600px', padding: '50px' }}>
          <Table ref={userAlertListRef} columnLayout={columnLayout} customGridProps={userAlertProps} />
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Button onClick={alrtSettingSave}>저장</Button>
          </div>
        </Content>
      </Layout>
    </>
  );
};
export default NoticeSetting;

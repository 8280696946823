import React, { useState } from 'react';
import { Layout, Tabs, Col } from 'antd';
import { Link } from 'react-router-dom';
import ForgetEmail from './ForgetEmail';
import ForgetPassword from './ForgetPassword';
import { LOGIN } from '@routes/pathName';

const ForgetAccount = (props) => {
  const [activeTabKey, setActiveTabKey] = useState('forgetEmail'); // 활성화된 tab

  // 탭 변경시
  const handleTab = (key) => {
    setActiveTabKey(key);
  };

  return (
    <>
      <Layout style={{ maxWidth: 500, margin: 'auto' }}>
        <Col style={{ margin: '40px 0' }}>
          <p style={{ textAlign: 'center', fontSize: '40px', margin: '10px 0' }}>LOGO</p>
        </Col>
        <Tabs
          type="card"
          onChange={handleTab}
          items={[
            {
              label: '아이디 찾기',
              key: 'forgetEmail',
              forceRender: true,
              children: <ForgetEmail />,
            },
            {
              label: '비밀번호 찾기',
              key: 'forgetPassword',
              forceRender: true,
              children: <ForgetPassword />,
            },
          ]}
        />
        <Col style={{ display: 'flex' }}>
          <Link to={LOGIN} style={{ cursor: 'pointer', color: '#000', fontSize: '13px', marginRight: '5px' }}>
            로그인 화면으로 돌아가기
          </Link>
        </Col>
      </Layout>
    </>
  );
};

export default ForgetAccount;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as api from '@api/index';
import closeIcon from '@assets/images/common/close_icon.png';

import { Button, DatePicker, Select, Input, Upload, Form, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Table from '@components/Table';

import dayjs from "dayjs";
import {aiCertApr, aiCertSort} from "@utils/constants";
import '/src/assets/css/AiModelCertModal.css';
import {getLocalStorage, makeSelectBoxList, useDebounce} from "@utils/lib";
import Loading from "@components/Loading";

const { RangePicker } = DatePicker;

const AiModelCertModal = ({ visible, onClose }) => {
    const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
    const aiCertGridRef = useRef(null);

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [codes, setCodes] = useState([]);
    const [aiCertLocation, setAiCertLocation] = useState([]);

    const [showSection, setShowSection] = useState(false);
    const handleToggleSection = () => {
        setShowSection((prev) => !prev);
    };
    const [aiMembershipScreen, setAiMembershipScreen] = useState(null);
    const [delFileCaches, setDelFileCaches] = useState([]);
    const aiMembershipRef = useRef(null);

    const today = dayjs();
    const oneWeekAgo = today.subtract(7, 'day');

    const [sendDate, setSendDate] = useState({
        st_date: oneWeekAgo.format('YYYY-MM-DD'),
        end_date: today.format('YYYY-MM-DD'),
    });

    const handleDate = ({ startDt, endDt }) => {
        setAiCertListState((prev) => ({
            ...prev,
            query: {
                ...prev.query,
                startDt: startDt,
                endDt: endDt,
                offset: 0
            }
        }));

        setSendDate({
            st_date: startDt,
            end_date: endDt,
        });
    };

    const [aiCertListState, setAiCertListState] = useState({
        query: {
            keyword_type: '0',
            keyword_text: memKey,
            startDt: sendDate.st_date,
            endDt: sendDate.end_date,
            offset: 0,
            limit: 20,
            selector_3: '1'
        },
        items: [],
        totalCount: 0,
        currentCount: 0,
        yScrollPosition: 0,
    });

    const [formData, setFormData] = useState({
        seq: '',
        ai_model_cd: '',
        cert_start_dt: '',
        cert_end_dt: '',
        confirm_url: '',
    });

    const handleDebounce = useCallback(
        useDebounce((func) => func(), 300),
        []
    );

    const aiCertGridProps = {
        showRowCheckColumn: false,
        showRowNumColumn: false,
        showStateColumn: false,
        editable: false,
    };

    const aiCertColumnLayout = [
        { dataField: 'seq', headerText: '순번', width: '8%', visible: false },
        { dataField: 'ai_model_nm', headerText: '전문 AI 분야', width: '18%', editable: false },
        { dataField: 'cert_start_dt', headerText: 'AI멤버십 시작일', formatString: 'yyyy-mm-dd', dataType: 'date', width: '15%', editable: false },
        { dataField: 'cert_end_dt', headerText: 'AI멤버십 종료일', formatString: 'yyyy-mm-dd', dataType: 'date', width: '15%', editable: false },
        {
            dataField: 'img_path',
            headerText: '이미지',
            width: '35%',
            renderer: { type: 'TemplateRenderer' },
            labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                if (value) {
                    const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
                    return `<img src="${imgSrc}" alt="cert" style="height:25px; max-width:100%;">`;
                }
                return '<span>No image</span>';
            },
            editable: false,
        },
        {
            dataField: 'cert_stat_label',
            headerText: '인증상태',
            width: '15%',
        },
        { dataField: 'confirm_url', headerText: '링크', width: '10%', editable: false },
    ];

    const setupGridEvents = useCallback(() => {
        if (aiCertGridRef.current && typeof aiCertGridRef.current.bind === 'function') {
            aiCertGridRef.current.bind('vScrollChange', (event) => {
                handleDebounce(() => setAiCertListState((prev) => ({ ...prev, yScrollPosition: event.position })));
                if (event.position === event.maxPosition && aiCertListState.currentCount < aiCertListState.totalCount) {
                    handleScroll();
                }
            });
        }
    }, [handleDebounce, aiCertListState.currentCount, aiCertListState.totalCount, aiCertLocation]);

    const handleScroll = () => {
        const newQuery = { ...aiCertListState.query, offset: aiCertListState.query.offset + aiCertListState.query.limit };
        setAiCertListState((prev) => ({ ...prev, query: newQuery }));
        handleDebounce(() => getAiCertList(newQuery));
    };

    const getAiList = useCallback(async () => {
        try {
            const [model] = await Promise.all([api.fetchCommonDetailCode({ grp_cd: 'MODEL' })]);
            setCodes(makeSelectBoxList(model?.data, 'cd_desc', 'cd'));
        } catch (error) {
            message.warning(error.message);
        }
    }, []);

    const getAiCertList = useCallback(
        async (query) => {
            try {
                setIsLoading(true);
                const { data } = await api.getAiCertList(query);
                if (!data) return;

                const processedItems = data.items.map(item => {
                    const certStatLabel = aiCertApr.find(apr => apr.value === item.cert_stat)?.label || item.cert_stat;

                    return {
                        ...item,
                        cert_stat_label: certStatLabel,
                        cert_stat: item.cert_stat
                    };
                });

                setAiCertListState((prev) => ({
                    ...prev,
                    items: processedItems/*data.items*/,
                    totalCount: data.total,
                    currentCount: data.items.length,
                }));

                if (aiCertGridRef.current) {
                    aiCertGridRef.current.setGridData(processedItems);
                }
                setupGridEvents();
            } catch (error) {
                console.error('AI Cert List Fetch Error:', error);
                message.error(error.message || '데이터를 불러오는 데 실패했습니다.');
            } finally {
                setIsLoading(false);
            }
        },
        [setupGridEvents]
    );

    const handleSearchAiCert = ({ keyword_text , selector_3 = '1'}) => {
        const newQuery = { ...aiCertListState.query, keyword_text, selector_3, offset: 0 };
        setAiCertListState((prev) => ({ ...prev, query: newQuery }));
        handleDebounce(() => getAiCertList(newQuery));
    };
    const updateAiCert = async () => {
        try {
            const sendFormData = new FormData();
            const aiCertData = {
                ...formData
            };
            sendFormData.append('updateAiCerList', new Blob([JSON.stringify([aiCertData])], {
                type: 'application/json'
            }));
            if (aiMembershipScreen) {
                sendFormData.append('files', aiMembershipScreen);
            } else {
                message.error('AI 멤버십 스크린샷은 필수입니다. 이미지 파일을 선택하세요.');
                return;
            }

            const response = await api.updateAiCert(sendFormData);
            if (response.data.returnStatus === 'success') {
                message.success(response.data.returnMessage);
                getAiCertList(aiCertListState.query);
            } else {
                message.error(response.data.returnMessage || 'AI 인증 정보를 업데이트하는 데 실패했습니다.');
            }
        } catch (error) {
            console.error('updateAiCert 오류:', error);
            message.error(error?.response?.data?.message || 'AI 인증 정보를 업데이트하는 데 실패했습니다.');
        }
    };

    const handleAiMembershipClick = () => {
        aiMembershipRef.current?.click();
    };

    const handleAiMembershipChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            aiMembershipScreen?.status === 'done' && setDelFileCaches(prev => [...prev, aiMembershipScreen.url]);
            setAiMembershipScreen(file);
        }
    }

    useEffect(() => {
            if (visible) {
                getAiList();
                getAiCertList(aiCertListState.query);

                aiCertGridRef.current.bind('cellClick', (event) => {
                    const gridData = aiCertGridRef.current.getGridDataWithState('state');
                    const selectedRowData = gridData[event.rowIndex];

                    console.log(selectedRowData);

                    if(selectedRowData.cert_stat !== '01' && selectedRowData.cert_stat !== '99'){
                        document.querySelector.id('saveBtn').disabled = true;
                    } else {
                        document.querySelector.id('saveBtn').disabled = false;
                    }

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        seq: selectedRowData.seq,
                        ai_model_cd: selectedRowData.ai_model_cd,
                        cert_start_dt: selectedRowData.cert_start_dt,
                        cert_end_dt: selectedRowData.cert_end_dt,
                        confirm_url: selectedRowData.confirm_url,
                    }));

                    form.setFieldsValue({
                        ai_model_cd: selectedRowData.ai_model_cd,
                        cert_start_dt: selectedRowData.cert_start_dt,
                        cert_end_dt: selectedRowData.cert_end_dt,
                        confirm_url: selectedRowData.confirm_url
                    });

                    if (selectedRowData.img_path) {
                        fetch(selectedRowData.img_path)
                            .then(response => response.blob())
                            .then(blob => {
                                const file = new File([blob], selectedRowData.img_file_nm, { type: blob.type });
                                setAiMembershipScreen(file);
                            })
                            .catch(error => console.error('이미지 로딩 오류:', error));
                    }
                    setShowSection(true);
                });
            }

    }, [visible]);

    useEffect(() => {
        if(!showSection) {
            setFormData(prevFormData => ({
                ...prevFormData,
                seq: '',
                ai_model_cd: '',
                cert_start_dt: '',
                cert_end_dt: '',
                confirm_url: '',
            }));
            setAiMembershipScreen('');
        }
    }, [showSection]);

    if (!visible) return null;

    return (
        <Form form={form} layout="inline" initialValues={aiCertListState.query} onFinish={handleSearchAiCert}>
        <div className="modalArea" onClick={onClose}>
            <div className="modalInArea" onClick={(e) => e.stopPropagation()}>
                <img className="closeIcon" src={closeIcon}  alt="close" onClick={onClose} />
                <div className="popTitle">
                    <span>AI 모델 상업적 사용 인증</span>
                </div>
                {showSection && (
                    <section className="sectionArea">
                            <ul>
                                <div className="box">
                                    <li>
                                        <div className="text">
                                            <span>AI 멤버십 스크린 샷</span>
                                        </div>
                                        <Form.Item style={{marginTop: '15px'}}>
                                            <input
                                                type="file"
                                                ref={aiMembershipRef}
                                                onChange={handleAiMembershipChange}
                                                style={{display: 'none'}}
                                                accept="image/*"
                                            />
                                            <div
                                                onClick={handleAiMembershipClick}
                                                style={{
                                                    marginTop: '-12px',
                                                    marginRight: '-10px',
                                                    width: '122px',
                                                    height: '122px',
                                                    border: '1px dashed #d9d9d9',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {aiMembershipScreen ? (
                                                    <img
                                                        src={URL.createObjectURL(aiMembershipScreen)}
                                                        alt="AI 멤버십 스크린 샷"
                                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                    />
                                                ) : (
                                                    <span
                                                        style={{
                                                            color: 'white',
                                                            fontSize: '28px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            border: '1px solid #999',
                                                            textAlign: 'center',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.1)'
                                                        }}
                                                    >+</span>
                                                )}
                                            </div>
                                        </Form.Item>
                                    </li>
                                    <li className="saveLi">
                                        <li className="saveTitle">
                                            <div className="saveListContainer">
                                                <div className="write">전문 AI 분야</div>
                                            </div>
                                            <Select
                                                placeholder="AI 모델 선택"
                                                className="selectAiModel"
                                                options={codes}
                                                value={formData.ai_model_cd}
                                                onChange={(value) => setFormData({...formData, ai_model_cd: value})}
                                            />
                                        </li>

                                        <li className="saveTitle">
                                            <div className="saveListContainer">
                                                <div className="write">AI 멤버십 갱신일</div>
                                            </div>
                                            <DatePicker
                                                placeholder="시작일"
                                                style={{width: '35%'}}
                                                value={formData.cert_start_dt ? dayjs(formData.cert_start_dt) : null}
                                                onChange={(date, dateString) => setFormData({
                                                    ...formData,
                                                    cert_start_dt: dateString
                                                })}
                                                format="YYYY-MM-DD"
                                            />
                                            <DatePicker
                                                placeholder="종료일"
                                                style={{width: '35%'}}
                                                value={formData.cert_end_dt ? dayjs(formData.cert_end_dt) : null}
                                                onChange={(date, dateString) => setFormData({
                                                    ...formData,
                                                    cert_end_dt: dateString
                                                })}
                                                format="YYYY-MM-DD"
                                            />
                                        </li>

                                        <li className="saveTitle">
                                            <div className="saveListContainer">
                                                <div className="write">링크</div>
                                            </div>
                                            <Input
                                                placeholder="링크"
                                                style={{width: '70%'}}
                                                value={formData.confirm_url}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    confirm_url: e.target.value
                                                })}
                                            />
                                        </li>
                                    </li>
                                </div>
                            </ul>
                        <Button className="saveBtn" onClick={() => updateAiCert('s')}>등록</Button>
                    </section>
                )}
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}>
                    <RangePicker
                        style={{width: '300px'}}
                        value={[
                            sendDate?.st_date ? dayjs(sendDate.st_date) : oneWeekAgo,
                            sendDate?.end_date ? dayjs(sendDate.end_date) : today
                        ]}
                        onChange={(dates, dateStrings) => {
                            handleDate({
                                startDt: dateStrings[0],
                                endDt: dateStrings[1]
                            });
                        }}
                    />
                    <Button icon={<SearchOutlined/>} onClick={() => getAiCertList(aiCertListState.query)}/>
                    <Form.Item name="keyword_text">
                        <Input style={{display: 'none'}} placeholder="Keyword"/>
                    </Form.Item>
                    <Button onClick={handleToggleSection}>{showSection ? '닫기' : '추가'}</Button>
                    <Form.Item name="selector_3" initialValue="1">
                        <Select
                            style={{minWidth: '100px'}}
                            options={aiCertSort}
                            value={form.getFieldValue('selector_3')}
                            onChange={(value) => {
                                if (value) {
                                    handleSearchAiCert({
                                        ...form.getFieldsValue(),
                                        selector_3: value,
                                    });
                                    setTimeout(() => {
                                        form.setFieldsValue({ keyword_type: '0', keyword_text: memKey });
                                    }, 10);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <Loading isLoading={isLoading} />
                <Table ref={aiCertGridRef} columnLayout={aiCertColumnLayout} customGridProps={aiCertGridProps} />
            </div>
        </div>
        </Form>

    );
};

export default AiModelCertModal;
import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_PAYMENTLIST = {
  query: {
    keyword_text: '',
    startDt: '',
    endDt: '',
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const paymentSlice = createSlice({
  name: 'paymentList',
  initialState: INIT_STATE_PAYMENTLIST,
  reducers: {
    clearPaymentList: () => INIT_STATE_PAYMENTLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setPaymentList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearPaymentList, setQuery, setPaymentList, setLocationKey, setYScrollPosition } = paymentSlice.actions;

export default paymentSlice.reducer;

import React from 'react';
import { Modal } from 'antd';
// icon
import { CloseOutlined } from '@ant-design/icons';
// hook
import useWidth from '@hooks/useWidth';

const Response = ({ type, text, isActive, onClose }) => {
  const width = useWidth();

  return (
    <Modal open={isActive} footer={null} closable={false} width={width < 700 ? `100%` : `500px`}>
      <article id={type} className="flexColCenter">
        <div className="flexRowBetween">
          <div></div>
          <h1>알림</h1>
          <p style={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined />
          </p>
        </div>

        <div className="content">
          <p>{text}</p>
        </div>

        <button id="purpleBtn" onClick={onClose}>
          확인
        </button>
      </article>
    </Modal>
  );
};

export default Response;

import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Form, Input, Button, message, List } from 'antd';
import { Comment } from '@ant-design/compatible';
import { MoreOutlined } from '@ant-design/icons';
import * as api from '@api/index';
import {formatEncodingDate, getLocalStorage} from "@utils/lib";
import '/src/assets/css/comment.css';

const { TextArea } = Input;
const CommentItem = ({ comment, onReply, onDelete, onEdit, level = 1 }) => {
    const [replyVisible, setReplyVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [replyContent, setReplyContent] = useState('');
    const [editContent, setEditContent] = useState(comment.reply_contents);
    const [moreVisible, setMoreVisible] = useState(false);
    const [width, setWidth] = useState(0);
    const commentRef = useRef(null);
    const memKey = getLocalStorage('prptbk-token', 'mem_key');
    const memAuth = getLocalStorage('prptbk-token', 'mem_auth');

    const handleReply = () => {
        onReply(replyContent, null, comment.reply_seq);
        setReplyContent('');
        setReplyVisible(false);
    };

    const handleEdit = () => {
        onEdit(editContent, comment.reply_seq);
        setEditVisible(false);
    };

    const commentCard = {
        marginTop: '30px',
        height: 'auto',
        alignSelf: 'stretch',
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '16px 22px 25px 6px',
        border: '1px solid #d9d9d9',
        backgroundColor: 'rgba(62, 61, 83, 0.1)',
        borderRadius: '75px 75px 25px 75px ',
    }

    const reCommentCard = {
        height: 'auto',
        marginLeft:'40px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderBottomLeftRadius: '75px',
    }

    useEffect(() => {
        document.querySelectorAll('.anticon').forEach((icon) => {
            icon.removeAttribute('aria-label');
        });

        const elements = document.querySelectorAll(
            'section > div > div > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div > div > div.ant-comment-nested > div > div > div *'
        );
        elements.forEach((el) => {
            el.style.backgroundColor = 'rgba(0, 0, 0, 0) !important';
        });
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            const minusMargin = level > 1 ? 1 * (level - 1) - 110 : 0;
            const commentInner = commentRef.current.offsetWidth - 190;
            console.log(commentInner);
            const calculatedWidth = commentInner - minusMargin;
            setWidth(calculatedWidth);
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [level]);

    return (
        <div ref={commentRef}>
            <Comment
                style={level === 1 ?  commentCard : reCommentCard }
                avatar={level === 1 ? <Avatar src={comment.writer_profile || 'default-avatar.png'} size={100} style={{marginRight: '10px'}}/> : null}
                author={
                    <div className="box" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: width ? `${width}px` : 'auto',
                    }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span className="comment-name">@{comment.writer_nm}</span>
                            <span style={{color: '#6d6c75'}}>|</span>
                            <span className="comment-date">{formatEncodingDate(comment.lst_dt)}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <div
                                className="answer"
                                onClick={() => setReplyVisible(!replyVisible)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            >
                                답글
                            </div>
                            {(memAuth === 'R002' || memKey === comment.lst_id) && (
                                <MoreOutlined
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => setMoreVisible(!moreVisible)}
                                    aria-label=""
                                />
                            )}
                            {moreVisible && (
                                <div className="more-select">
                                    <ul>
                                        <li>
                                            <span onClick={() => {
                                                    setEditVisible(true);
                                                    setMoreVisible(false);
                                                }}>수정</span>
                                        </li>
                                        <li>
                                            <span onClick={() => {
                                                    onDelete(comment.reply_seq);
                                                    setMoreVisible(false);
                                            }}>삭제</span>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                }
                content={
                    editVisible ? (
                        <div
                            style={{
                                height: 'auto',
                                alignSelf: 'stretch',
                                flexGrow: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'stretch',
                                padding: '16px',
                                backgroundColor: 'white',
                            }}
                        >
                            <Input.TextArea
                                rows={1}
                                onChange={(e) => setEditContent(e.target.value)}
                                value={editContent}
                                placeholder="답글을 수정해주세요."
                                rootClassName="comment-input"
                            />
                            <Button
                                className="comment-btn"
                                htmlType="submit"
                                onClick={handleEdit}
                                style={{marginTop: 10}}
                            >
                                저장
                            </Button>
                        </div>
                    ) : (
                        <p>{comment.reply_contents}</p>
                    )
                }
            >
                {replyVisible && (
                    <div
                        style={{
                            height: 'auto',
                            alignSelf: 'stretch',
                            flexGrow: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'stretch',
                            padding: '16px',
                            backgroundColor: 'white',
                        }}
                    >
                        <Input.TextArea
                            rootClassName="comment-input"
                            rows={1}
                            onChange={(e) => setReplyContent(e.target.value)}
                            value={replyContent}
                            placeholder="답글을 남겨주세요."
                        />
                        <Button
                            className="comment-btn"
                            htmlType="submit"
                            onClick={handleReply}
                        >
                            저장
                        </Button>
                    </div>
                )}
                {comment.children && comment.children.length > 0 && (
                    <div>
                    {comment.children.map((reply) => (
                                    <CommentItem
                                        key={reply.reply_seq}
                                        comment={reply}
                                        onReply={onReply}
                                        onDelete={onDelete}
                                        onEdit={onEdit}
                                        level={level + 1}
                                    />
                                ))}
                            </div>
                        )}
                    </Comment>
                    </div>
                    );
                };

const CommentSystem = ({board_no, doc_no}) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const buildCommentTree = (data) => {
        const commentMap = new Map();
        data.forEach(comment => {
            commentMap.set(comment.reply_seq, {
                ...comment,
                children: []
            });
        });

        const rootComments = [];
        data.forEach(comment => {
            const commentWithChildren = commentMap.get(comment.reply_seq);

            if (!comment.parent_reply_seq || comment.parent_reply_seq === 0) {
                rootComments.push(commentWithChildren);
            } else {
                const parentComment = commentMap.get(comment.parent_reply_seq);
                if (parentComment) {
                    parentComment.children.push(commentWithChildren);
                }
            }
        });

        return rootComments;
    };

    const getComments = async () => {
        try {
            const params = {
                board_no,
                doc_no
            };
            const { data } = await api.commentList({ params });

            if (data) {
                const commentTree = buildCommentTree(data);
                setComments(commentTree);
            }
        } catch (error) {
            message.error('댓글을 불러오는데 실패했습니다.');
        }
    };

    const updateComment = async (content, existingReplySeq = null, parentReplySeq = null) => {
        try {
            const newComment = {
                board_no,
                doc_no,
                reply_seq: existingReplySeq || null,
                parent_reply_seq: parentReplySeq,
                reply_contents: content,
                del_yn: 'N'
            };
            await api.updatedComment(newComment);
            message.success(existingReplySeq ? '댓글이 수정되었습니다.' : '댓글이 추가되었습니다.');
            setNewComment('');
            getComments();
        } catch (error) {
            message.error('댓글 처리에 실패했습니다.');
        }
    };

    const deleteComment = async (existingReplySeq = null) => {
        try {
            const newComment = {
                board_no,
                doc_no,
                reply_seq: existingReplySeq,
                del_yn: 'Y'
            };
            await api.updatedComment(newComment);
            message.success('댓글이 삭제되었습니다.');
            getComments();
        } catch (error) {
            message.error('댓글 삭제에 실패했습니다.');
        }
    };


    const countComments = (comments) => {
        let count = comments.length;

        comments.forEach(comment => {
            if (comment.children && comment.children.length > 0) {
                count += countComments(comment.children);
            }
        });

        return count;
    };

    const totalCommentsCount = countComments(comments);

    useEffect(() => {
        getComments();
    }, [board_no, doc_no]);

    return (
        <div className="comment-system">
            <div className="titleBtnArea" style={{
                height: '71px',
                alignSelf: 'stretch',
                flexGrow: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '10px',
                padding: '0 0 20px',
                backgroundColor: '#fff',
            }}>
                <span style={{
                    width: '146px',
                    height: '32px',
                    flexGrow: 0,
                    fontFamily: 'Pretendard',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    color: '#0e0e1f',
                }}>댓글 남기기</span>
            </div>
            <Comment
                content={
                    <>
                        <Form.Item>
                            <div
                                className="write"
                                style={{
                                    alignSelf: 'stretch',
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '12px',
                                    padding: '0',
                                }}
                            >
                    <span
                        style={{
                            width: '112px',
                            height: '19px',
                            flexGrow: 0,
                            fontFamily: 'Pretendard',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: '-0.32px',
                            textAlign: 'center',
                            color: '#0e0e1f',
                        }}
                    >
                        댓글을 남겨주세요
                    </span>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <TextArea
                                        rows={1}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        value={newComment}
                                        placeholder="게시글에 대한 댓글을 남겨주세요."
                                        style={{
                                            width: '90%',
                                            height: '48px',
                                            flexGrow: 1,
                                            padding: '8px 12px',
                                            borderRadius: '4px',
                                            border: 'solid 1px #e2dfe4',
                                            backgroundColor: '#f7f7f8',
                                        }}
                                    />

                                    <Button
                                        htmlType="submit"
                                        onClick={() => updateComment(newComment)}
                                        type="primary"
                                        style={{
                                            width: '73px',
                                            height: '48px',
                                            marginLeft: '12px',
                                            borderRadius: '4px',
                                            backgroundColor: '#a3a1a7',
                                            color: '#fff',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    > 저장
                                    </Button>
                                </div>
                            </div>
                        </Form.Item>
                    </>
                }
            />
            {comments.length > 0 && (
                <List
                    className="box"
                    dataSource={comments}
                    /*
                                        header={`${totalCommentsCount} ${totalCommentsCount > 1 ? 'replies' : 'reply'}`}
                    */
                    itemLayout="horizontal"
                    renderItem={comment => (
                        <CommentItem
                            comment={comment}
                            onReply={updateComment}
                            onDelete={deleteComment}
                            onEdit={updateComment}
                            level={1}
                        />
                    )}
                />
            )}
        </div>
    );
};

export default CommentSystem;
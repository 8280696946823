import React, {useCallback, useEffect, useState} from 'react';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
import albumThumb from '@assets/images/common/album_thumb.png';
import chatGPT from '@assets/images/common/chat_gpt_logo.png';
import ranway from '@assets/images/common/runway_logo.png';
import midjourney from '@assets/images/common/mi_logo.png';
import dall from '@assets/images/common/dall_logo.png';
// component
import * as api from "@api/index";
import {message} from "antd";
import {useNavigate} from "react-router-dom";
import { BOARD_HOME } from '@routes/pathName';
import qs from 'qs';
import {getLocalStorage} from "@utils/lib";
import Loading from "@components/Loading";

const CommunityHome = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [communityList, setCommunityList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const memKey = getLocalStorage('prptbk-token', 'mem_key') || null;
    const planCode = getLocalStorage('prptbk-token', 'plan_cd') || 'PLAN000';

    const getIntegratedBoardList = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.getIntegratedBoardList(query);

            if (data?.items) {
                const userPlanLevel = parseInt(planCode.slice(-3));
                const filteredItems = [];

                for (let item of data.items) {
                    const boardLevel = parseInt(item.use_lv.slice(-3));
                    if (boardLevel <= userPlanLevel) {
                        filteredItems.push(item);
                    }
                }

                if(memKey == null){
                    setBoardList(filteredItems.filter((item) => item.board_cate === "2"));
                } else {
                    setCommunityList(filteredItems.filter((item) => item.board_cate === "1"));
                    setBoardList(filteredItems.filter((item) => item.board_cate === "2"));
                }

            } else {
                message.warning("데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            message.warning(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleItemClick = (finalKey, boardTitle, dspMeth) => {
        const queryString = qs.stringify({
            board_no: finalKey,
            board_title: boardTitle,
            dsp_meth: dspMeth,
        });
        navigate(`${BOARD_HOME}?${queryString}`);
    };

    useEffect(() => {
        getIntegratedBoardList();
    }, [getIntegratedBoardList]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <article id="infoWrapper" className="flexColCenter">
                <h1>
                    <span>다양한 분야의 프롬프트 엔지니어들과 </span>
                    <br />
                    정보를 공유하고 최신 뉴스를 확인하세요.
                </h1>
                <div className="rsWrapper flexColCenter">
                    {memKey != null && (
                        <>
                            <div className="titleBox flexRowBetween">
                                <div className="flexRowCenter title">
                                    <img src={musicIcon3} alt="music_icon" />
                                    <p>커뮤니티</p>
                                </div>

                                {/*<div id="whiteBtn" className="web">
                    더보기
                </div>*/}
                            </div>

                            <div className="listBox flexRowBetween">
                                {communityList.map((com, i) => (
                                    <div
                                        className="list flexColCenter"
                                        key={i}
                                        onClick={() => handleItemClick(com.board_no, com.board_title, com.dsp_meth)}
                                    >
                                        <div className="flexColCenter absoluteBox">
                                            {/*
                            <img src={com.icon || midjourney} alt={com.board_title || "Community Icon"} />
                            */}
                                            <p>{com.board_title || "Unknown Community"}</p>
                                        </div>
                                        <img src={com.board_img_path || albumThumb} alt="" />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <div className="titleBox flexRowBetween">
                        <div className="flexRowCenter title">
                            <img src={musicIcon3} alt="music_icon"/>
                            <p>게시판</p>
                        </div>

                        {/*<div id="whiteBtn" className="web">
                더보기
              </div>*/}
                    </div>

                    <div className="listBox flexRowBetween">
                        {boardList.map((com, i) => (
                            <div className="list flexColCenter" key={i} onClick={() => handleItemClick(com.board_no, com.board_title, com.dsp_meth)} >
                                <div className="flexColCenter absoluteBox">
{/*
                                    <img src={com.icon || midjourney} alt={com.board_title || "Community Icon"}/>
*/}
                                    <p>{com.board_title || "Unknown Community"}</p>
                                </div>
                                <img src={com.board_img_path || albumThumb} alt=""/>
                            </div>
                        ))}
                    </div>
                </div>
            </article>
        </>
    );
};

export default CommunityHome;

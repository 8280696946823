import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Typography, Divider, Switch, message, Popover } from 'antd';
import * as api from '@api/index';
import * as lib from '@utils/lib'

const { Title, Text } = Typography;

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;

const PlanInfo = () => {

  const memTel = lib.getLocalStorage('prptbk-token', 'mem_tel') || '';
  const memNick = lib.getLocalStorage('prptbk-token', 'mem_nick') || '';
  const memEmail = lib.getLocalStorage('prptbk-token', 'mem_email') || '';

  const [subscriptionType, setSubscriptionType] = useState(false); // true 연간, false 월간
  const [memberPlanInfo, setMemberPlanInfo] = useState({});
  const [membershipPlanList, setMembershipPlanList] = useState([]);
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(false)

  const parseDate = (dateStr) => {
    const year = parseInt(dateStr.slice(0, 4), 10);
    const month = parseInt(dateStr.slice(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(dateStr.slice(6, 8), 10);
    return new Date(year, month, day);
  };

  const fetchPlanInformation = () => {
    api.fetchPlanInformation()
    .then((response) => {
      setMemberPlanInfo(response.data);
      setMembershipPlanList(response.data.membershipPlanList);

      const startDate = parseDate(response.data.plan_start_dt);
      const endDate = parseDate(response.data.plan_end_dt);
      const diffInMs = Math.abs(endDate - startDate);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      diffInDays > 31 ? setYearly(true) : setMonthly(true);

    })
    .catch((error) => {
      console.log(error)
    })
  }

  const calculationAmount = async (membership, paymentType) => {
    console.log(memberPlanInfo.plan_cd)
    if(memberPlanInfo.plan_cd === 'PLAN001'){
      handleOpenPayment(membership, paymentType);
    }else if(membership.plan_cd === 'PLAN002'){ //베이직으로 업그레이드
      if(subscriptionType){ //연간
        
      }else{ //월간
        handleOpenPayment(membership, paymentType);
      }
    }else if(membership.plan_cd === 'PLAN003'){ //프로로 업그레이드
      if(subscriptionType){ //연간

      }else{ //월간

      }
    }
  }

  /**이노페이 결제 */
  const handleOpenPayment = async (membership, paymentType) => {
    if (window.innopay && window.innopay.goPay) {
      window.innopay.goPay({
        PayMethod: paymentType, // 결제수단
        MID: innopayMid, // 가맹점 MID
        MerchantKey: innopayLicenseKey,
        Amt: subscriptionType ? membership.year_amt + '' : membership.month_amt + '', // 결제금액
        GoodsName: membership.plan_nm + '플랜', // 상품명
        BuyerName: memNick, // 고객닉네임
        BuyerTel: memTel, // 고객 전화번호
        BuyerEmail: memEmail, // 고객 이메일
        ResultYN: 'Y', // 결제 결과창 출력 여부
        Moid: '1', // 주문번호
        ReturnURL: 'http://localhost:3000/profile/ProfilePage'
        + '?plan_cd=' + membership.plan_cd
        + '&plan_nm=' + membership.plan_nm
        + '&plan_start_dt=20241210'
        + '&plan_end_dt=20251209',
      });
    } else {
      console.error('이노페이 SDK가 로드되지 않았습니다.');
    }
  };

  useEffect(() => {
    fetchPlanInformation();

    // 이노페이 SDK 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
    script.charset = 'utf-8';
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(script);
    };
  }, [])

  return (
    <div className="plan-container">
      <div className="plan-use-header">
        <Title level={3}>사용 정보</Title>
        <Row gutter={[12, 12]} align="middle" style={{height : '150px'}}>
          <Col span={8} className="info-box-wrapper">
            <div className="info-box">
              <Text className="info-label">멤버십 이용 기간</Text>
              <Title level={5}>{memberPlanInfo.plan_start_dt ? lib.dayFormat(memberPlanInfo.plan_start_dt) + ' ~ ' + lib.dayFormat(memberPlanInfo.plan_end_dt) : ''}</Title>
              <Text className="info-note blue-text">플랜 : {memberPlanInfo.plan_nm ? memberPlanInfo.plan_nm + ' 사용' : ''}</Text>
            </div>
          </Col>
          <Col span={8} className="info-box-wrapper">
            <div className="info-box">
              <Text className="info-label">데이터 사용</Text>
              <Title level={5}>{memberPlanInfo.mem_used_file_size ? lib.formatDataSize(memberPlanInfo.mem_used_file_size) : '0MB'}</Title>
              <Text className="info-note blue-text">
                플랜데이터 : {memberPlanInfo.plan_data_size ? lib.formatDataSize(memberPlanInfo.plan_data_size) : ''} 
                / 추가 데이터 {memberPlanInfo.data_size ? lib.formatDataSize(memberPlanInfo.data_size) : ''}
              </Text>
            </div>
          </Col>
        </Row>
      </div>

      <div className="toggle-section">
        <div>
          <Switch 
            checkedChildren='연간' 
            unCheckedChildren='월간' 
            value={subscriptionType}
            onChange={(e) => setSubscriptionType(e)}
          />
          <Text className="info-label">Plan</Text>
        </div>
        <p>연간 이용으로 <span className="span-highlight">할인 혜택</span>을 받아보세요.</p>
      </div>

      <Divider />

      <div className="plan-section">
        <Row gutter={[16, 16]} justify="center" style={{height : '550px'}}>
          {membershipPlanList.map((membership) => (
            <Col xs={24} sm={8} md={6} className="plan-card-wrapper">
              <Card className={`plan-card ${memberPlanInfo.plan_cd === membership.plan_cd ? 'active' : ''}`} bordered={false} style={{display: 'flex', flexDirection: 'column'}}>
                <div className="plan-header">
                  <Title level={4} className="plan-price">
                    ₩ {subscriptionType ? lib.formatNumber(membership.year_amt) + ' / 년' : lib.formatNumber(membership.month_amt) + ' / 월'}
                  </Title>
                  <Text className="plan-title">
                    {membership.plan_nm} 플랜 / {lib.formatDataSize(membership.data_size)}
                  </Text>
                </div>
                <Divider />
                <div
                  className="plan-features plan-content"
                  dangerouslySetInnerHTML={{ __html: membership.contents }}
                />
                <div className="plan-bottom">
                  {membership.plan_cd === memberPlanInfo.plan_cd ? (
                    memberPlanInfo.plan_cd === 'PLAN001' ? ( //현재 비기너 플랜이면 월/연 결제 모두 이용중으로
                      <Button type="primary" block className="ant-used-btn">이용중</Button>
                    ) : (
                      subscriptionType ? (
                        yearly ? (
                          <Button type="primary" block className="ant-used-btn">이용중</Button>
                        ) : (
                          monthly ? (
                            <Button type="primary" block className="ant-upgrade-btn">
                              <Popover
                                placement='top'
                                content={(
                                  <>
                                    <button className="popover-btn" onClick={() => calculationAmount(membership, 'CARD')}>카드</button>
                                    <button className="popover-btn" onClick={() => calculationAmount(membership, 'EPAY')}>간편결제</button>
                                  </>
                                )}
                                style={{border: '1px solid black'}}
                              >
                                업그레이드
                              </Popover>
                            </Button>
                          ) : null
                        )
                      ) : (
                        yearly ? (
                          <Button type="default" block className="ant-down-btn">다운그레이드</Button>
                        ) : (
                          monthly ? (
                            <Button type="primary" block className="ant-used-btn">이용중</Button>
                          ) : null
                        )
                      )
                    )
                  ) : (
                    membership.plan_cd < memberPlanInfo.plan_cd ? (
                      <Button type="default" block className="ant-down-btn">다운그레이드</Button>
                    ) : (
                      <Button type="primary" block className="ant-upgrade-btn">
                        <Popover
                          placement='top'
                          content={(
                            <>
                              <button className="popover-btn" onClick={() => calculationAmount(membership, 'CARD')}>카드</button>
                              <button className="popover-btn" onClick={() => calculationAmount(membership, 'EPAY')}>간편결제</button>
                            </>
                          )}
                          style={{border: '1px solid black'}}
                        >
                          업그레이드
                        </Popover>
                      </Button>
                    )
                  )}
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default PlanInfo;
import React from 'react';
// icon
import thumbnail from '@assets/images/common/thumb.png';
import starIcon from '@assets/images/common/star_icon.png';
import userThumb from '@assets/images/common/user_thumb.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
import rankIcon from '@assets/images/common/rank_1.png';
// lib
import { koKRFormat } from '@utils/lib';

const CardSquareImage1 = ({ data }) => {
  return (
    <div className="list flexColCenter" key={data?.prpt_id}>
      <div className="flexColCenter">
        <img src={data?.thum_path ?? thumbnail} />

        <div className="flexColBetween absoluteBox">
          <div className="flexRowBetween user">
            <div className="flexRowCenter">
              <img src={data?.icon_path ?? userThumb} />
              <p>{data?.ai_model_nm}</p>
            </div>

            <img src={data?.rank_icon_path ?? rankIcon} />
          </div>

          <div className="flexColCenter title">
            <p>{data?.prpt_title}</p>

            <div className="greyBox flexRowBetween">
              <img src={data?.mem_img_path ?? userIcon3} />

              <div className="flexColStart">
                <p>{data?.mem_nick}</p>
                <div className="flexRowBetween">
                  <div className="flexRowStart imgBox">
                    <img src={data?.icon_path ?? userThumb} />
                    {[...Array(data?.score)].map(() => (
                      <img src={starIcon} />
                    ))}
                  </div>

                  <p>{`₩ ${koKRFormat(data?.sale_amt)}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSquareImage1;

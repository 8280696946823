import React from 'react';
// icon
import playIcon from '@assets/images/common/play_icon.png';
import userThumb from '@assets/images/common/user_thumb.png';
import thumbnail from '@assets/images/common/album_thumb.png';

const CardCircleMusic = ({ data, isActive, openMusicPlayer, layoutStyle, activeImgStyle, isActiveImg = true, isActiveTitle = true, isActiveDescription = true }) => {
  return (
    <div className="musicBox" key={data?.prpt_id} style={{ ...layoutStyle }}>
      <div className="flexColCenter">
        <img src={data?.icon_path ?? userThumb} />
        <h1>{data?.prpt_title}</h1>
        <p>{data?.mem_nick}</p>
      </div>

      <div className="flexColCenter" id="active">
        <img src={data?.icon_path ?? userThumb} alt="playIcon" className="mobileImg" />
        {isActiveImg && (
          <img
            src={playIcon}
            alt="playIcon"
            onClick={(e) => {
              e.stopPropagation();
              isActive && openMusicPlayer(data);
            }}
            style={{ ...(isActive && { cursor: 'pointer' }), ...activeImgStyle }}
          />
        )}
        <div className="flexColStart">
          {isActiveTitle && <h1>{data?.prpt_title}</h1>}
          {isActiveDescription && <p>{data?.mem_nick}</p>}
        </div>
      </div>
      <img src={data?.thum_path ?? thumbnail} />
    </div>
  );
};

export default CardCircleMusic;

import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Outlet, useLocation } from 'react-router-dom';
import CreateInquiry from '@pages/inquiry/Edit';
import InquiryList from '@pages/inquiry/List';
import EditInquiry from '@pages/inquiry/Edit';
import { INQUIRY_LIST, INQUIRY_EDIT } from '@routes/pathName';
import InquiryDetail from '@pages/inquiry/Detail';

const InquiryPage = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();

  const onChangeTab = (tabKey) => {
    switch (tabKey) {
      case 'edit':
        navigate(INQUIRY_EDIT); // 해당 경로로 이동
        break;
      case 'list':
        navigate(INQUIRY_LIST); // 해당 경로로 이동
        break;
      default:
        break;
    }
    setActiveTab(tabKey);
  };

  const tabs = [
    { value: 'edit', label: '문의 작성' },
    { value: 'list', label: '문의 내역' },
  ];

  useEffect(() => {
    if (pathname.includes('*')) onChangeTab('edit');
    setActiveTab(pathname.split('/')[3]);
  }, [pathname]);

  return (
    <>
      <article className="flexColCenter" id="prompt">
        <div className="rsWrapper flexColCenter">
          <div className="flexRowCenter title">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className="flexColCenter"
                onClick={() => onChangeTab(tab.value)} // tab의 key로 이동
                id={activeTab == tab.value ? 'active' : 'nothing'}
              >
                <div className="round"></div>
                <h1>{tab.label}</h1>
              </div>
            ))}
          </div>

          <Routes>
            <Route path="list" element={<InquiryList />} />
            <Route path="edit" element={<EditInquiry />} />
            <Route path="list/detail" element={<InquiryDetail />} />
          </Routes>
        </div>
      </article>
    </>
  );
};

export default InquiryPage;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import Table from '@components/Table';
import { useDebounce } from '@utils/lib';

const Ranking = (props) => {
  const [loading, setLoading] = useState({});
  const [cateList, setCateList] = useState([]);
  const [selectedButton, setSelectedButton] = useState('CATE001');
  const rankingGridRef = useRef();
  const isLoading = Object.values(loading).some((value) => value);
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const [userRankingList, setUserRankingList] = useState({
    query: {
      keyword_type: 'CATE001',
      keyword_text: '',
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      setCateList(response1.data);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  const rankingGridLayout = [
    { dataField: 'mem_key', headerText: '멤버 Key', visible: false },
    { dataField: 'total_rnk', headerText: '랭킹', width: '20%', dataType: 'numeric', editable: false },
    { dataField: 'mem_email', headerText: '이메일', width: '80%', style: 'left-custom-column', dataType: 'numeric', editable: false },
  ];

  const rankingGridProps = {
    editable: false,
    showRowNumColumn: true, // 행번호 유무
    showStateColumn: false,
  };

  // 랭킹 데이터 조회
  const fetchRankingData = async (query) => {
    try {
      setLoading((prev) => ({ ...prev, rankingLoad: true }));
      const { data } = await api.getUserRanking(query);

      // 두번째 이상 요청일 때 (스크롤 시 새로운 페이징 데이터 요청)
      if (query.offset && rankingGridRef.current.getGridData()) {
        rankingGridRef.current.appendData(data.items); // 기존 그리드에 다음 페이지 덧붙임
        setUserRankingList((prev) => ({
          ...prev,
          items: [rankingGridRef.current.getGridData()],
          totalCount: data.total,
          currentCount: rankingGridRef.current.getGridData().length,
        }));
      }
      // 첫번째 요청일때
      else {
        rankingGridRef.current.setGridData(data.items);
        setUserRankingList((prev) => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));
      }

      console.log('rankingGridRef.current = ', rankingGridRef.current);
    } catch (error) {
      console.error('랭킹 데이터 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, rankingLoad: false }));
    }
  };

  const handleButtonClick = (cd) => {
    const newQuery = { ...userRankingList.query, keyword_type: cd, offset: 0 };
    setSelectedButton(cd);
    setUserRankingList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => fetchRankingData(newQuery));
  };

  // 유저랭킹 - 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...userRankingList.query, offset: offset + limit };
    setUserRankingList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => fetchRankingData(newQuery));
  };

  // 유저 랭킹 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    rankingGridRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => setUserRankingList((prev) => ({ ...prev, yScrollPosition: event.position })), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  useEffect(() => {
    /** AUI Grid 이벤트 */
    setupGridEvents({
      offset: userRankingList.query.offset,
      limit: userRankingList.query.limit,
      totalCount: userRankingList.totalCount,
      currentCount: userRankingList.currentCount,
    });
  }, [userRankingList.query, userRankingList.currentCount]);

  useEffect(() => {
    getCommonCode();
    fetchRankingData(userRankingList.query); // 진입시 cate001로 랭킹조회
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Content style={{ width: '1600px', padding: '0 0 32px' }}>
          <Header style={{ background: 'none', padding: '25px', height: 'unset' }}>
            {cateList.map((item, index) => (
              <Button
                key={item.cd}
                style={{
                  marginLeft: '10px',
                  backgroundColor: selectedButton === item.cd ? '#CC00FF' : '', // 연보라색 배경
                  color: selectedButton === item.cd ? '#fff' : '', // 흰색 글자
                }}
                onClick={() => handleButtonClick(item.cd)}
              >
                {item.cd_nm}
              </Button>
            ))}
          </Header>
          <Table style={{ padding: '25px' }} ref={rankingGridRef} columnLayout={rankingGridLayout} customGridProps={rankingGridProps} />
        </Content>
      </Layout>
    </>
  );
};
export default Ranking;

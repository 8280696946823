import React, { useEffect, useState, useCallback, useRef } from 'react';
import { message, Layout, Form, Input, Button, Select, Row, Col } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setUserList, setLocationKey, setYScrollPosition, INIT_STATE_USERLIST, clearUserList } from '@stores/userList';
import Table from '@components/Table';
import Loading from '@components/Loading';
import ButtonExport from '@components/ButtonExportAUIGrid';
import * as api from '@api/index';
import { secondsFormat, useDebounce, secondsCompactFormat } from '@utils/lib';
import { userKeywordTypeList } from '@utils/constants';
import { ADMIN_USER_LIST, ADMIN_USER_DETAIL } from '@routes/pathName';
import WithdrawReason from './WithdrawReason';
import { encryptRSA, isCanceledUser, makeDataFormat, makeSelectBoxList } from '@utils/lib';

const { Content, Header } = Layout;

const AdminUserList = (props) => {
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const userListRef = useRef();
  const dispatch = useDispatch();
  const userList = useSelector((s) => s.userList, shallowEqual);
  const [isOpenWithdrawReasonModal, setIsOpenWithdrawReasonModal] = useState(false);
  const [isUpdateRows, setIsUpdateRows] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [memDivFormat, setMemDivFormat] = useState({});
  const [memStatList, setMemStatList] = useState([]);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 그리드 설정
  const userListGridProps = {
    showRowCheckColumn: true,
  };

  // 그리드 칼럼 레이아웃 정의
  const columnLayout = [
    {
      dataField: 'plan_nm',
      headerText: '맴버십 현황',
      width: '12%',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'fir_dt',
      headerText: '회원가입일시',
      dataType: 'date',
      dateInputFormat: 'yyyy-mm-dd', // 데이터의 날짜 형식
      formatString: 'yyyy.mm.dd', // 그리드에 보여줄 날짜 형식
      width: '18%',
      editable: false,
      labelFunction: (rowIndex, columnIndex, value) => {
        return secondsFormat(value);
      },
    },
    {
      dataField: 'mem_nick',
      headerText: '닉네임',
      width: '20%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_USER_DETAIL, { state: { mem_email: item?.mem_email, mem_key: item?.mem_key } });
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_email',
      headerText: '이메일',
      width: '24%',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_div',
      headerText: '사용자구분',
      width: '12%',
      editable: false,
      labelFunction: (rowIndex, columnIndex, value) => {
        return memDivFormat[value] || value;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_stat',
      headerText: '계정상태',
      width: '14%',
      renderer: {
        type: 'DropDownListRenderer',
        list: memStatList, // key-value Object 로 구성된 리스트
        keyField: 'value', // value 에 해당되는 필드명
        valueField: 'label', // key 에 해당되는 필드명
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    userListRef.current.bind(['vScrollChange', 'cellEditEnd'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
      // 셀 수정완료시
      else if (event.type === 'cellEditEnd') {
        // 계정상태가 탈퇴일때
        if (event.value === '99') {
          setSelectedRowItem(event.item);
          setSelectedRowIndex(event.rowIndex);
          openWithdrawReasonModal();
        }
      }
      //
    });
  };

  // 탈퇴 사유 모달창 열기
  const openWithdrawReasonModal = () => {
    setIsOpenWithdrawReasonModal(true);
  };

  // 탈퇴 사유 모달창 닫기
  const closeWithdrawReasonModal = () => {
    setIsOpenWithdrawReasonModal(false);
  };

  // 강퇴사유 일괄적용 버튼 클릭시
  const handleWithdrawReasonBatch = () => {
    const checkedRowItems = userListRef.current.getCheckedRowItems();
    if (!checkedRowItems.length) {
      message.error('선택된 회원이 없습니다.');
      return;
    };
    setIsUpdateRows(true);
    openWithdrawReasonModal();
  };

  // 공통코드 맴버상태 조회
  const makeMemStatList = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'MEM_STAT' });
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_nm', 'cd');
      setMemStatList(madeSelectBoxList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 맴버구분 조회
  const makeMemDivFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'MEM_DIV' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setMemDivFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 유저 목록 가져오기
  const listUser = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.listUser(query);
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && userListRef.current.getGridData()) {
        dispatch(
          setUserList({
            items: [...userListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: userListRef.current.getGridData().length + data.items.length,
          }),
        );
        userListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setUserList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        userListRef.current.setGridData(data.items);
      }
      dispatch(setQuery(query));
      dispatch(setLocationKey(locationKey));
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: userListRef.current.getGridData().length,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 검색
  const handleSearchUser = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...userList.query, keyword_type, keyword_text, offset: 0 };
    handleDebounce(() => listUser(newQuery));
  };

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...userList.query, offset: offset + limit };
    handleDebounce(() => listUser(newQuery));
  };

  // 선택된 행의 값 변경
  const handleUpdateRows = async (editedCellItem) => {
    // 행이 여러개일때
    if (isUpdateRows) {
      const checkedRowItems = userListRef.current.getCheckedRowItems();
      await Promise.all([
        checkedRowItems?.map((rowItem) => {
          const newRowItem = { ...rowItem.item, ...editedCellItem };
          return userListRef.current.updateRow(newRowItem, rowItem.rowIndex);
        }),
      ]);
      setIsUpdateRows(false);
    }
    // 행이 1개일때
    else {
      const newRowItem = { ...selectedRowItem, ...editedCellItem };
      userListRef.current.updateRow(newRowItem, selectedRowIndex);
    }
  };

  // 저장
  const handleSave = async () => {
    if(await window.confirm('저장하시겠습니까?')){
      const editedItems = userListRef.current.getEditedRowItems();
      try {
        await Promise.all([
          editedItems?.map((item) =>
            api.updateUser({
              ...item,
              mem_pwd: encryptRSA(item.mem_pwd),
              ...(!isCanceledUser(item.mem_stat) && { cnl_rsn_cd: '', cnl_rsn: '' }),
            }),
          ),
          dispatch(clearUserList()),
        ]);
        message.success('저장이 완료되었습니다.');
        listUser(INIT_STATE_USERLIST.query);
      } catch (error) {
        message.warning(error.message);
      }
    }
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearUserList());
    navigate(ADMIN_USER_LIST, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 공통코드 조회
    makeMemStatList();
    makeMemDivFormat();

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === userList.locationKey) {
      userListRef.current.setGridData(userList.items);
      userListRef.current.setRowPosition(userList.yScrollPosition);
      setupGridEvents({
        offset: userList.query.offset,
        limit: userList.query.limit,
        totalCount: userList.totalCount,
        currentCount: userList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      listUser(INIT_STATE_USERLIST.query);
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset' }}>
          <Form form={form} name="userList" layout="inline" initialValues={userList?.query} onFinish={handleSearchUser}>
            <Form.Item name="keyword_type">
              <Select options={userKeywordTypeList} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." allowClear />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
          <Row>
            <Row>검색결과</Row>
            <Row style={{ marginLeft: '10px' }}>{`총 ${userList.totalCount}명`}</Row>
            <Row>
              <Col>
                <ButtonExport ref={userListRef} fileName={`${secondsCompactFormat(new Date())}_회원관리조회`} text="엑셀 다운" />
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                <Button onClick={handleWithdrawReasonBatch}>강퇴사유 일괄적용</Button>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                <Button type="primary" onClick={handleSave}>
                  저장
                </Button>
              </Col>
            </Row>
          </Row>
        </Header>
        <Content style={{ marginTop: '5px' }}>
          <Table ref={userListRef} columnLayout={columnLayout} customGridProps={userListGridProps} />
        </Content>
      </Layout>
      <WithdrawReason isOpen={isOpenWithdrawReasonModal} onClose={closeWithdrawReasonModal} onSave={handleUpdateRows} />
    </>
  );
};

export default AdminUserList;

import React, { useCallback, useState, useEffect } from 'react';
import { Drawer, Input, Radio, message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import searchIcon from '@assets/images/common/search_icon.png';
import whiteCartIcon from '@assets/images/common/white_cart_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import thumbnail from '@assets/images/common/thumb.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import whiteSellIcon from '@assets/images/common/white_sell_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
// component
import Banner from '@components/slider/Banner';
import Filter from '@pages/images/Filter';
import FilterMobile from '@pages/images/FilterMobile';
// lib
import { koKRFormat, useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ImageHome = (props) => {
  const navigate = useNavigate();
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isActiveFilterMobile, setIsActiveFilterMobile] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const filterToggle = useCallback(() => {
    setIsActiveFilter(!isActiveFilter);
  }, [isActiveFilter]);

  const filterToggleMobile = useCallback(() => {
    setIsActiveFilterMobile(!isActiveFilterMobile);
  }, [isActiveFilterMobile]);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 이미지 리스트 가져오기
  const listImage = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE001',
        keyword_tab: '',
        offset: 0,
        limit: 60,
      });
      if (!data) return;
      setImageList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listImage());
  }, []);

  return (
    <article id="otherWrapper" className="flexColCenter">
      <Banner bannerCd="BANNER001" bannerTp="Top" />

      <Filter isActive={isActiveFilter} toggle={filterToggle} />

      <div className="rsWrapper flexColCenter">
        <div className="flexRowBetween">
          <div className="content flexColCenter">
            <div className="titleBox flexRowBetween">
              <h1>
                Midjourney <br />
                <span>최신 이미지 프롬프트</span>
              </h1>

              <div className="search flexRowBetween">
                <img src={searchIcon} />
                <Input placeholder="이미지를 검색해 주세요." />
              </div>

              <img src={mobileFilterIcon} onClick={filterToggleMobile} />
            </div>

            <div className="searchBox flexRowBetween">
              <div className="flexRowCenter ">
                <div id="pinkBtn2">All</div>
                <div id="pinkBtn2" className="active">
                  카테고리1
                </div>
                <div id="pinkBtn2">카테고리2</div>
                <div id="pinkBtn2">카테고리3</div>
              </div>

              <div className="flexRowCenter">
                <div id="pinkBtn">
                  <div>
                    <img src={whiteSellIcon} />
                    <img src={whiteSellIcon} className="activeImg" />
                  </div>
                  <p>판매하기</p>
                </div>

                <div id="whiteBtn" className="flexRowCenter">
                  <div>
                    <img src={cartIcon} />
                    <img src={whiteCartIcon} className="activeImg" />
                  </div>
                  <p>담기</p>
                </div>
              </div>
            </div>

            {isLoading ? (
              <>
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 4,
                  }}
                />
              </>
            ) : (
              <div className="listBox flexRowStart">
                {imageList?.map((image) => (
                  <div className="list flexColCenter">
                    <div className="flexColBetween absoluteBox" onClick={() => moveToPage(PATH.PROMPT_DETAIL, { prpt_id: image?.prpt_id })}>
                      <div className="flexRowStart user">
                        <img src={image?.icon_path ?? userThum} />
                        <p>{image?.ai_model_nm}</p>
                      </div>

                      <div className="flexColEnd">
                        <div className="flexRowBetween title">
                          <h1>{image.prpt_title}</h1>
                          <p>{`₩${koKRFormat(image?.sale_amt)}`}</p>
                        </div>
                      </div>
                    </div>
                    <img src={image.thum_path ?? thumbnail} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="mobileFixedBox" className="flexRowBetween">
        <div className="flexColCenter select">
          <p>
            <span>2</span>개 선택
          </p>
        </div>
        <div className="flexRowCenter">
          <img src={resetIcon} />
          <img src={cartUpdateIcon} />
          <img src={cartIcon} />
        </div>
      </div>

      <Banner bannerCd="BANNER001" bannerTp="Bottom" />

      <FilterMobile isActive={isActiveFilterMobile} toggle={filterToggleMobile} />
    </article>
  );
};

export default ImageHome;

import React from 'react';

const ButtonSocialLogin = ({ provider, type, text }) => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const kakaoClientId = process.env.REACT_APP_KAKAO_API_KEY;
  const naverClientId = process.env.REACT_APP_NAVER_CLIENT_ID;
  const naverClientSecret = process.env.REACT_APP_NAVER_CLIENT_SECRET;
  const oauthRedirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL;
  const authUrl = {
    google: `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&scope=openid%20profile%20email&redirect_uri=${`${oauthRedirectUrl}?provider=google`}`,
    kakao: `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoClientId}&redirect_uri=${`${oauthRedirectUrl}?provider=kakao`}&response_type=code`,
    naver: `https://nid.naver.com/oauth2.0/authorize?client_id=${naverClientId}&redirect_uri=${`${oauthRedirectUrl}?provider=naver`}&state=${naverClientSecret}&response_type=code`,
  };
  const imgUrl = {
    google: '../assets/images/common/google_icon.png',
    kakao: '../assets/images/common/kakao_icon.png',
    naver: '../assets/images/common/naver_icon.png',
  };

  const openSocialLogin = () => {
    window.location.replace(authUrl[provider]);
  };

  return (
    <button className={type} id={provider} onClick={openSocialLogin}>
      <img src={imgUrl[provider]} alt={provider} />
      <p>{text}</p>
    </button>
  );
};

export default ButtonSocialLogin;

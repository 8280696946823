import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sidebarMenuItemAdmin } from '@utils/constants';
import * as PATH from '@routes/pathName';
import { isAdmin } from '@utils/lib';

const Sidebar = () => {
  const [selectedMenuGroup, setSelectedMenuGroup] = useState('Member');
  const [isFoldSidebar, setIsFoldSidebar] = useState(false);
  const { pathname } = useLocation();
  const auth = useSelector((s) => s.auth, shallowEqual);

  const handleMenuGroup = (menuGroupName) => {
    setSelectedMenuGroup(menuGroupName);
  };

  const toggleSidebar = () => {
    setIsFoldSidebar(!isFoldSidebar);
  };

  return (
    <>
      <aside className={`l-sidebar ${isFoldSidebar ? 'fold' : ''}`}>
        <div className="btn-area">
          {/* <!-- hamburger btn-menu --> */}
          <button className="btn-area__btn " onClick={toggleSidebar}>
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>

        <nav className="l-nav">
          <Link className="l-nav__logo" to={isAdmin(auth?.mem_auth) ? PATH.ADMIN_USER_LIST : PATH.MAIN}></Link>
          {/* <!-- navigation  --> */}
          <div className="menu-area">
            {sidebarMenuItemAdmin?.map((menu, index) => (
              <div key={`menu${index}`} className={`menu-area__group ${selectedMenuGroup === menu.name ? 'on' : ''}`}>
                <input id={`menu${index}`} className="menu-area__check" type="radio" name="accordion-1" value="toggle" />
                <label htmlFor={`menu${index}`} className="menu-area__menu" onClick={() => handleMenuGroup(menu.name)}>
                  <span className="menu-area__text">{menu.name}</span>
                  <i className="fa-solid fa-angle-down"></i>
                </label>
                <ul className="menu-area__content">
                  {menu?.children?.map((subMenu, subIndex) => (
                    <li key={`subMenu${subIndex}`} className={`menu-area__smenu ${pathname === subMenu.path ? 'active' : ''}`}>
                      <Link to={subMenu.path} state={{ division: subMenu.state }}>
                        {subMenu.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;

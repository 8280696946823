import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { clearAuth } from '@stores/auth';
import { LOGIN } from '@routes/pathName';

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const HeaderAdmin = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    window.confirm('로그아웃 하시겠습니까?').then(async (result) => {
      if (result) {
        await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, '{}'), dispatch(clearAuth())]);
        navigate(LOGIN);
      } else return;
    });
  };

  const items = [
    {
      label: '내 정보',
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Row onClick={logout}>로그아웃</Row>,
      key: '1',
    },
  ];

  return (
    <>
      <header className="l-header depth2" style={{ justifyContent: 'flex-end' }}>
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Space style={{ cursor: 'pointer', margin: 'auto 30px' }}>
            <UserOutlined style={{ fontSize: '20px', background: '#009dff', color: '#fff', padding: '5px', borderRadius: '1.6rem' }} />
          </Space>
        </Dropdown>
      </header>
    </>
  );
};

export default HeaderAdmin;

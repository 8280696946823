import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
// component
import NewSlider from '@components/slider/NewSlider';
import CardSquareImage1 from '@pages/main/CardSquareImage1';
import DetailImage from '@pages/main/DetailImage';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';

const ListPopularImage = () => {
  const [imageList, setImageList] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 인기 이미지 리스트 가져오기
  const listImage = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: 'CATE001',
        keyword_tab: 'popular',
        offset: 0,
        limit: 6,
      });
      if (!data) return;
      setImageList(data.items);
      setSelectedImage(data.items[0]);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => listImage());
  }, []);

  return (
    <>
      <h1>당신의 모든 창의력을 실현할 수 있는 AI 이미지를 느껴보세요</h1>
      <div className="rsWrapper flexColCenter">
        <div className="titleBox flexRowBetween">
          <div className="flexRowCenter title">
            <img src={musicIcon3} alt="music_icon" />
            <p>인기 콘텐츠</p>
          </div>

          <div id="whiteBtn" className="web">
            더보기
          </div>
        </div>
      </div>

      <div className="contentsSlide flexColCenter">
        <div className="flexRowBetween whiteBox">
          <div className="white"></div>
          <div className="white"></div>
        </div>

        <div className="rsWrapper flexColCenter">
          <div className="slider-container" id="popularSlider">
            {isLoading ? (
              <Skeleton
                active
                paragraph={{
                  rows: 4,
                }}
              />
            ) : (
              <NewSlider
                config={{
                  centerPadding: '200px',
                  slidesToShow: 3,
                  dots: true,
                  responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 3,
                        centerPadding: '150px',
                      },
                    },
                    {
                      breakpoint: 1280,
                      settings: {
                        slidesToShow: 3,
                        centerPadding: '50px',
                      },
                    },
                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 2,
                        centerPadding: '0',
                        centerMode: false,
                      },
                    },
                  ],
                  afterChange: (index) => {
                    setSelectedImage(imageList[index]);
                  },
                }}
                Content={imageList?.map((image) => (
                  <CardSquareImage1 data={image} />
                ))}
              />
            )}
          </div>
        </div>
      </div>
      <DetailImage selectedImage={selectedImage} />
    </>
  );
};

export default ListPopularImage;

import { restApi, querystring } from './config';

// 로그인
export const login = (data) => {
  return restApi.post('/api/login', data);
};
// 소셜로그인
export const socialLogin = (data) => {
  return restApi.post('/api/social-login', data);
};
// access token, refresh token 재발급
export const reissueToken = (refreshToken) => {
  return restApi.get('/api/reissue', {
    headers: { Authorization: `Bearer ${refreshToken}` },
  });
};
// 로그아웃
export const logout = () => {
  return restApi.get('/api/logout');
};
// 이메일 중복검사
export const checkEmail = (data) => {
  return restApi.get('/api/email/check', querystring(data));
};
// 닉네임 중복검사
export const checkNick = (data) => {
  return restApi.get('/api/nick/check', querystring(data));
};
// 이메일 인증 요청
export const requestEmailVerification = (payload) => restApi.post('/api/user/send-verification-email', null, { params: payload });
// 이메일 인증 확인
export const verifyEmail = (payload) => restApi.get('/api/user/verify-email', querystring(payload));
// 회원가입
export const signup = (data) => {
  return restApi.post('/api/signup', data);
};
// 회원가입: 소셜로그인용
export const signupBySocialLogin = (data) => {
  return restApi.post('/api/signup/social-login', data);
};
// 이메일 찾기
export const forgetEmail = (data) => {
  return restApi.get('/api/forget/email', querystring(data));
};
// 비밀번호 찾기
export const forgetPassword = (data) => {
  return restApi.get('/api/forget/password', querystring(data));
};
// 이메일 인증 요청: 비밀번호 변경용
export const sendVerificationEmailForNewPassword = (payload) => {
  return restApi.post('/api/forget/send-verification-email', null, { params: payload });
};
// 이메일 인증 확인: 비밀번호 변경용
export const verifyEmailForNewPassword = (data) => {
  return restApi.get('/api/forget/verify-email', querystring(data));
};
// 비밀번호 변경
export const updatePassword = (data) => {
  return restApi.put('/api/password', data);
};

import { Button, Form, Input, Layout, Row, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { periodList, userKeywordTypeList } from '@utils/constants';
import Table from '@components/Table';
import { Content, Header } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import RangePicker from '@components/RangePicker';
import { dayFormat, isEmpty, useDebounce } from '@utils/lib';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { ADMIN_INQUIRY_DETAIL } from '@routes/pathName';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearInquiryList, INIT_STATE_INQUIRYLIST, setInquiryList, setQuery, setYScrollPosition, setLocationKey } from '@stores/inquiryList';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const AdminInquiryHome = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { key: locationKey } = useLocation();

  const [searchForm] = Form.useForm();
  const inquiryGridRef = useRef(); // 유저 랭킹 그리드
  const inquiryList = useSelector((s) => s.inquiryList, shallowEqual); // inuiry List
  const dispatch = useDispatch();
  const initInquirylist = {
    ...INIT_STATE_INQUIRYLIST,
    query: {
      ...INIT_STATE_INQUIRYLIST.query,
      selector_1: '01',
    },
  };

  const inqrStatList = [
    { label: '미답변', value: '01' },
    { label: '답변완료', value: '02' },
  ];

  // Grid props
  const inquiryGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    showStateColumn: false,
    editable: false,
  };

  const inquiryGridLayout = [
    { dataField: 'inqr_seq', headerText: '문의 순번', width: '30%', visible: false },
    { dataField: 'mem_key', headerText: '멤버 Key', width: '30%', visible: false },
    { dataField: 'inqr_div', headerText: '문의구분 코드', width: '30%', visible: false },
    { dataField: 'inqr_div_nm', headerText: '문의 유형', width: '30%' },
    {
      dataField: 'inqr_title',
      headerText: '제목',
      width: '100%',
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_INQUIRY_DETAIL, { state: { inqr_seq: item?.inqr_seq, mem_key: item?.mem_key, flag: 'A' } });
        },
      },
    },
    {
      dataField: 'lst_dt',
      headerText: '문의일',
      width: '30%',
      labelFunction: (rowIndex, columnIndex, value) => {
        return dayFormat(value);
      },
    },
    {
      dataField: 'inqr_stat',
      headerText: '답변여부',
      width: '30%',
      renderer: {
        type: 'TemplateRenderer',
      },
      // dataField 로 정의된 필드 값이 HTML 이라면 labelFunction 으로 처리할 필요 없음.
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        // HTML 템플릿 작성
        if (value == '01') {
          return '<div> 미답변 </div>';
        } else if (value == '02') {
          return '<div> 답변 완료 </div>';
        }
      },
    },
  ];

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 초기상태
  const handleInitState = () => {
    dispatch(clearInquiryList(initInquirylist));
  };

  // 검색버튼 컨트롤
  const handleSearchInquiryList = ({ keyword_type, keyword_text, selector_1 }) => {
    const newQuery = {
      ...inquiryList.query,
      keyword_type: isEmpty(keyword_type) ? '' : keyword_type,
      keyword_text: keyword_text,
      selector_1: isEmpty(selector_1) ? '' : selector_1,
      offset: 0,
    };
    dispatch(setQuery(newQuery));
    handleDebounce(() => getInquiryList(newQuery));
  };

  // 날짜 picker 컨트롤
  const handleDate = ({ startDt, endDt }) => {
    const newQuery = { ...inquiryList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => getInquiryList(newQuery));
  };

  // 스크롤 페이징 컨트롤
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...inquiryList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => getInquiryList(newQuery));
  };

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    inquiryGridRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  /** api */
  const getInquiryList = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getMemInquiryList(query);

      // 첫번째 요청일 시
      if (!(query.offset && inquiryGridRef.current.getGridData())) {
        inquiryGridRef.current.setGridData(data.items);
        dispatch(
          setInquiryList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
      } else {
        // 두번째 이상 요청 (스크롤 시 새로운 페이징 데이터 요청)
        inquiryGridRef.current.appendData(data.items); // 기존 그리드에 다음 페이지 덧붙임
        dispatch(
          setInquiryList({
            items: inquiryGridRef.current.getGridData(),
            totalCount: data.total,
            currentCount: inquiryGridRef.current.getGridData().length,
          }),
        );
      }
      dispatch(setQuery(query));
      dispatch(setLocationKey(locationKey));
    } catch (e) {
      console.log('실패');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    /** AUI Grid 이벤트 */
    setupGridEvents({
      offset: inquiryList.query.offset,
      limit: inquiryList.query.limit,
      totalCount: inquiryList.totalCount,
      currentCount: inquiryList.currentCount,
    });
  }, [inquiryList.query, inquiryList.currentCount]);

  useEffect(() => {
    if (locationKey === inquiryList.locationKey) {
      inquiryGridRef.current.setGridData(inquiryList.items);
      inquiryGridRef.current.setRowPosition(inquiryList.yScrollPosition);
      setupGridEvents({
        offset: inquiryList.query.offset,
        limit: inquiryList.query.limit,
        totalCount: inquiryList.totalCount,
        currentCount: inquiryList.currentCount,
      });
    } else {
      handleInitState(); // inquiry List 초기화
      handleDebounce(() => getInquiryList(initInquirylist.query)); // 조회
    }
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />

      <Layout>
        <Header style={{ background: 'none', padding: '0', height: 'unset', display: 'flex', justifyContent: 'space-between' }}>
          <Form form={searchForm} layout="inline" initialValues={inquiryList.query} onFinish={handleSearchInquiryList}>
            <Form.Item name="selector_1">
              <Select options={inqrStatList} allowClear placeholder="답변상태" style={{ minWidth: '150px' }} />
            </Form.Item>
            <Form.Item name="keyword_type">
              <Select options={userKeywordTypeList} style={{ minWidth: '80px' }} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." allowClear style={{ minWidth: '300px' }} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
          <Row>
            <RangePicker
              styleSelect={{ width: '100px' }}
              placeholderSelect="지급일시"
              optionsSelect={periodList}
              valueRangePicker={[inquiryList.query.startDt ? dayjs(inquiryList.query.startDt) : '', inquiryList.query.endDt ? dayjs(inquiryList.query.endDt) : '']}
              onChange={handleDate}
            />
          </Row>
        </Header>

        <Content style={{ marginTop: '5px' }}>
          <Table ref={inquiryGridRef} columnLayout={inquiryGridLayout} customGridProps={inquiryGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default AdminInquiryHome;

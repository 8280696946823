// SellerRegisterModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Steps, Form, Input, Button, Select, message } from 'antd';
import * as api from '@api/index';
import axios from 'axios';
import { bankList } from '@utils/constants';

const { Step } = Steps;
const { Option } = Select;

const businessEncodingKey = process.env.REACT_APP_BUSINESS_ENCODING_KEY;
const businessDecodingKey = process.env.REACT_APP_BUSINESS_DECODING_KEY;

const SellerRegist = ({ visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(10); // 3분 타이머
  const [timerActive, setTimerActive] = useState(false);
  const [verificationData, setVerificationData] = useState({
    businessType: '',
    phone: '',
    verificationCode: '',
  });

  const [sellerInfo, setSellerInfo] = useState({})

  const [businessVerified, setBusinessVerified] = useState(false); // 사업자 인증 여부
  const [businessDisabled, setBusinessDisabled] = useState(false);

  const [accountBusinessType, setAccountBusinessType] = useState("P"); //계좌구분
  const [accountVerifed, setAccountVerifed] = useState(false);


  useEffect(() => {
    let countdown;
  
    if (timerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && timerActive) {
      message.error("인증 시간이 초과되었습니다."); // 메시지는 여기서 1번만 뜸
      setTimerActive(false); // 타이머 비활성화
    }
  
    return () => clearInterval(countdown); // 타이머 정리
  }, [timer, timerActive]);

  // 모달 닫을 때 모든 데이터 초기화
  const resetModal = () => {
    form.resetFields();
    setCurrentStep(0);
    setTimer(180);
    setTimerActive(false);
    setVerificationData({ businessType: '', phone: '', verificationCode: '' });
    setBusinessDisabled(false)
    setAccountVerifed(false);
  };

  const handleNext = () => {
  
    if (currentStep === 1) {
      if (!businessVerified) {
        message.error("사업자 인증을 완료해주세요.");
        return;
      }
    }
    setCurrentStep((prev) => prev + 1);
  };

  const getNextButtonDisabled = () => {
    if (currentStep === 1) {
      return !businessVerified; // 사업자 인증 완료 여부
    }
    return false; // Step 3 이상은 항상 활성화
  };

  const startPhoneVerification = () => {
    const formValues = form.getFieldsValue();
    setSellerInfo({businessType : formValues.businessType})
    form.validateFields(['businessType', 'phone', 'name', 'email'])
      .then(async (values) => {
        const { businessType, phone } = values;
        const params = {
          phone : phone,
        }
        setVerificationData({ ...verificationData, businessType, phone });

        const response = await api.sendSMS(params);
        
        if(response.status === 200){
          setVerificationData((prev) => ({...prev,  verificationCode : response.data }));
          message.success('인증번호가 발송되었습니다.');
          setTimerActive(true);
          setTimer(180); // 타이머 초기화
          setSellerInfo((prev) => ({
            ...prev,
            individual : {
              name: values.name,
              email: values.email,
              phone: values.phone
            }
          }))
        }else{
          message.error(response.data);
        }
      })
      .catch(() => {
        message.error('사업자 유형과 전화번호를 정확히 입력해주세요.');
      });
  };

  const verifyPhoneCode = () => {
    form.validateFields(['businessType','phone','verificationCode'])
      .then(({ businessType, phone, verificationCode }) => {
        if(verificationData.businessType !== businessType || verificationData.phone !== phone){
          message.error('입력하신 정보가 일치하지 않습니다.');
        }else if(verificationData.verificationCode !== Number(verificationCode)){
          message.error('인증번호가 일치하지 않습니다.');
        }else{
          message.success('본인 인증이 완료되었습니다.');
          setSellerInfo((prev) => ({
            ...prev,
            businessType : businessType,
            phone : phone
          }))
          setTimerActive(false);
          setCurrentStep((prev) => prev + 1);
        }
      })
      .catch(() => {
        message.error('사업자 유형과 전화번호를 정확히 입력해주세요.');
      });
  };

  // Step 2 사업자 인증
  const handleBusinessVerification = async () => {
    form
      .validateFields(["business_no", "company_nm", "represent_nm", "fund_dt", "buisinessPhone", "businessEmail"])
      .then((values) => {

        const params = {
          "businesses": [
            {
              "b_no": values.business_no,
              "start_dt": values.fund_dt,
              "p_nm": values.represent_nm,
              "p_nm2": "",
              "b_nm": values.company_nm,
              "corp_no": "",
              "b_sector": "",
              "b_type": "",
              "b_adr": "",
            }
          ]
        }

        axios.post(
          'http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey='+businessEncodingKey, params
        )
        .then((response) => {
          if(response.data.data[0].status){
            message.success('사업자 인증 완료');
            const formValues = form.getFieldsValue();
            setSellerInfo((prevInfo) => ({
              ...prevInfo,
              company : {
                name: formValues.company_nm,
                representativeName: formValues.represent_nm,
                businessRegistrationNumber: formValues.business_no,
                email: formValues.businessEmail,
                phone: formValues.buisinessPhone,
              }
            }));
            setBusinessVerified(true);
            setBusinessDisabled(true);
          }else{
            message.error('정확한 사업자 정보를 입력해주세요.');
          }
        })
        .catch((error) => {
          console.log(error)
        })
      })
      .catch(() => {
        message.error("사업자 정보를 정확히 입력해주세요.");
      });
  };

  const verifyBankAccount = async () => {

    form
      .validateFields(["accountBusinessType", "bank_cd", "holder_nm", "account", "identityNumber"])
      .then(async (values) => {

        const params = {
          account : {
            accountBusinessType: values.accountBusinessType,
            bankCode: values.bank_cd,
            accountNumber: values.account,
            identityNumber: values.identityNumber
          }
        }

        setSellerInfo((prevInfo) => ({
          ...prevInfo,
          account : {
            bankCode: values.bank_cd,
            accountNumber: values.account,
            holderName: values.holder_nm,
          }
        }));

        // const response = await api.accountAuthentication(params);
        // if(response.data.accountName && values.holder_nm === response.data.accountName){
          message.success("정상적으로 인증되었습니다.");
          setAccountVerifed(true);
        // }else{
          // message.error(response.data.resultMessage);
        // }
      })
      .catch((error) => {
        console.log(error)
      })
  };

  const handleFinish = async () => {
    await setSellerInfo((prev) => ({
      ...prev,
      refSellerId: 'sellerRegistTest001'
    }))
    console.log(sellerInfo)
    const response = await api.makeSeller(sellerInfo);
    console.log(response);
    message.success("판매자 등록이 완료되었습니다.");
    // onClose();
  };



  useEffect(() => {
    console.log(sellerInfo);
  }, [sellerInfo])

  return (
    <Modal
      title="판매자 등록"
      open={visible}
      onCancel={() => {
        resetModal();
        onClose();
      }}
      footer={null}
    >
      <Steps current={currentStep} style={{ marginBottom: 24 }}>
        <Step title="본인 인증" />
        <Step title="사업자 인증" />
        <Step title="계좌 인증" />
      </Steps>

      <Form form={form} layout="horizontal">
        {currentStep === 0 && (
          <>
            <Form.Item
              name="businessType"
              label="사업자 유형"
              rules={[{ required: true, message: "사업자 유형을 선택해주세요." }]}
            >
              <Select placeholder="선택하세요">
                <Option value="INDIVIDUAL_BUSINESS">개인사업자</Option>
                <Option value="CORPORATE">법인사업자</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="name"
              label="이름"
              rules={[
                { required: true, message: "이름을 입력해주세요." },
                {
                  max: 5,
                  message: "이름은 최대 5자까지 입력 가능합니다.",
                },
              ]}
            >
              <Input placeholder="이름을 입력해주세요 (최대 5자)" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="전화번호"
              rules={[
                { required: true, message: "전화번호를 입력해주세요." },
                {
                  pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                  message: '올바른 전화번호 형식이 아닙니다.',
                },
              ]}
            >
              <Input placeholder="전화번호 (예: 01012345678 또는 02-1234-5678)" />
            </Form.Item>
            <Form.Item
              name="email"
              label="이메일"
              rules={[
                { required: true, message: "이메일을 입력해주세요." },
                {
                  type: "email",
                  message: "올바른 이메일 형식이 아닙니다.",
                },
              ]}
            >
              <Input placeholder="example@example.com" />
            </Form.Item>
            {!timerActive ? (
              <Button onClick={startPhoneVerification}>본인 인증</Button>
            ) : null}

            {timerActive && (
              <p>
                인증번호 입력 (남은 시간: {Math.floor(timer / 60)}분 {timer % 60}초)
              </p>
            )}
            {timerActive && (
              <Form.Item
                name="verificationCode"
                label="인증번호"
                rules={[{ required: true, message: "인증번호를 입력해주세요." }]}
              >
                <Input placeholder="6자리 숫자" />
              </Form.Item>
            )}
            {timerActive ? (
              <Button type="primary" onClick={verifyPhoneCode}>인증 확인</Button>
            ) : null}
          </>
        )}

        {currentStep === 1 && (
          <>
            <Form.Item
              name="business_no"
              label="사업자 등록번호"
              rules={[
                { required: true, message: "사업자 등록번호를 입력해주세요." },
                { pattern: /^\d{10}$/, message: "사업자 등록번호는 10자리 숫자여야 합니다." },
              ]}
            >
              <Input placeholder="사업자 등록번호 (10자리)" disabled={businessDisabled}/>
            </Form.Item>
            <Form.Item
              name="company_nm"
              label="상호"
              rules={[
                { required: true, message: "상호를 입력해주세요." },
              ]}
            >
              <Input placeholder="상호" disabled={businessDisabled} />
            </Form.Item>
            <Form.Item
              name="represent_nm"
              label="대표자명"
              rules={[{ required: true, message: "대표자명을 입력해주세요." }]}
            >
              <Input placeholder="대표자명" disabled={businessDisabled} />
            </Form.Item>
            <Form.Item
              name="fund_dt"
              label="개업일자"
              rules={[{ required: true, message: "개업일자를 입력해주세요." }]}
            >
              <Input placeholder="YYYYMMDD" disabled={businessDisabled} />
            </Form.Item>
            <Form.Item
              name="buisinessPhone"
              label="사업자 전화번호"
              rules={[
                { required: true, message: "전화번호를 입력해주세요." },
                {
                  pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                  message: '올바른 전화번호 형식이 아닙니다.',
                },
              ]}
            >
              <Input placeholder="전화번호 (예: 01012345678 또는 02-1234-5678)" disabled={businessDisabled}/>
            </Form.Item>
            <Form.Item
              name="businessEmail"
              label="사업자 이메일"
              rules={[
                { required: true, message: "이메일을 입력해주세요." },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "올바른 이메일 형식이 아닙니다.",
                },
              ]}
            >
              <Input placeholder="이메일 (예: example@example.com)" disabled={businessDisabled}/>
            </Form.Item>

            <Button type="primary" onClick={handleBusinessVerification} disabled={businessDisabled}>
              사업자 인증
            </Button>

          </>
        )}

        {currentStep === 2 && (
          <>

            <Form.Item
              name="accountBusinessType"
              label="계좌구분"
              rules={[{ required: true, message: "계좌구분을 선택해주세요." }]}
            >
              <Select
                placeholer="계좌구분"
                options={[
                  {label: '개인', value: 'P'},
                  {label: '사업자', value: 'B'}
                ]}
                onChange={(e) => setAccountBusinessType(e)}
                disabled={accountVerifed}
              />
            </Form.Item>
            <Form.Item
              name="bank_cd"
              label="은행"
              rules={[{ required: true, message: "은행을 입력해주세요." }]}
            >
              <Select 
                showSearch
                placeholder="은행" 
                options={bankList}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                disabled={accountVerifed}
              />
            </Form.Item>
            <Form.Item
              name="holder_nm"
              label="예금주"
              rules={[{ required: true, message: "예금주를 입력해주세요." }]}
            >
              <Input placeholder="예금주" disabled={accountVerifed}/>
            </Form.Item>
            <Form.Item
              name="account"
              label="계좌번호"
              rules={[{ required: true, message: "계좌번호를 입력해주세요." }]}
            >
              <Input placeholder="계좌번호" disabled={accountVerifed}/>
            </Form.Item>
            <Form.Item
              name="identityNumber"
              label={accountBusinessType === 'P' ? '생년월일' : '사업자등록번호'}
              rules={[{ required: true, message: accountBusinessType === 'P' ? '생년월일을 입력해주세요.' : '사업자등록번호를 입력해주세요.' }]}
            >
              <Input placeholder={accountBusinessType === 'P' ? '생년월일' : '사업자등록번호'} disabled={accountVerifed}/>
            </Form.Item>
            <Button type="primary" onClick={verifyBankAccount} disabled={accountVerifed}>
              계좌 인증
            </Button>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Button type="primary" onClick={handleFinish} disabled={!accountVerifed}>
              완료
            </Button>
          </>
        )}

        <div style={{ marginTop: 24 }}>
          {currentStep === 1 && (
            <Button 
              type="primary" 
              onClick={handleNext}
              disabled={getNextButtonDisabled()}
            >
              다음
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default SellerRegist;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual} from 'react-redux';
import { Input, Button, Badge, Spin, message, Upload, Avatar, Checkbox } from 'antd';
import { SendOutlined, PictureOutlined, DeleteOutlined, MoreOutlined, YoutubeOutlined, InstagramOutlined, TwitterOutlined, TrophyOutlined} from '@ant-design/icons';
import { setChatRooms, setCurrentRoom, addMessage, setMessages, clearMessages, setUserProfile } from '@stores/chat.js';
import * as api from '@api/index';
import {formatMessageDate, getLocalStorage} from '@utils/lib';

import {
    ChatContainer,
    ChatRoomList,
    RoomListScroll,
    ChatRoom,
    ChatBox,
    MessageList,
    MessageBubble,
    MessageGroup,
    MessageContent,
    MessageTime,
    InputContainer,
    SenderName,
    Balloon,
    LoadingContainer,
    EmptyMessageContainer,
    DateDivider,
    DateLabel,
    ProfileContainer,
    ProfileImage,
    InfoSection,
    BadgeInfo,
    RankingInfo,
    SocialMediaIcons,
    StatSection,
} from '@pages/chat/chatComponent';

const ChatBase = ({ tabId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabId);
    const chatRooms = useSelector(state => state.chat.chatRooms) || [];
    const currentRoom = useSelector(state => state.chat.currentRoom);
    const messages = useSelector(state => {
        if (!currentRoom?.id) return [];
        const roomMessages = state.chat.messages[currentRoom.id];
        return roomMessages || [];
    }, shallowEqual);
    const userProfile = useSelector(state => state.chat.userProfile) || [];

    const [newMessage, setNewMessage] = useState('');
    const messageEndRef = useRef(null);
    const messageListRef = useRef(null);
    const prevMessagesLengthRef = useRef(0);

    const [selectedRoomForOptions, setSelectedRoomForOptions] = useState(null);
    const [roomOptionsPosition, setRoomOptionsPosition] = useState({ top: 0, left: 0 });
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [roomListLoading, setRoomListLoading] = useState(false);
    const [previousRooms, setPreviousRooms] = useState([]);
    const [previousRoom, setPreviousRoom] = useState(null);

    const currentUserKey = getLocalStorage('prptbk-token', 'mem_key').toString();;

    const scrollToBottom = useCallback((smooth = false) => {
        if (messageListRef.current) {
            const scrollOptions = {
                top: messageListRef.current.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto'
            };
            messageListRef.current.scrollTo(scrollOptions);
        }
    }, []);

    // 채팅방 목록 조회
    const fetchRooms = useCallback(async () => {
        try {
            setRoomListLoading(true);
            const msgDiv = activeTab === 'normal' ? 'user' : 'system';
            const params = { mem_key: currentUserKey, msg_div: msgDiv };
            const response = await api.rooms({ params });
            const formattedRooms = response.data.map(room => ({
                id: room.chat_id,
                name: room.chat_nm,
                unread: room.confirm_cnt || 0,
                del_yn: room.del_yn,
                lastMessage: room.contents || '새로운 채팅방',
                mem_key: room.mem_key,
                mem_img_path: room.mem_img_path,
                block_status: room.block_status,
                chat_div: room.chat_div,
                lst_id: room.lst_id,
                lst_dt: room.lst_dt
            })).filter(room => msgDiv !== 'system' || room.del_yn !== "0");
            const hasRoomListChanged = JSON.stringify(previousRooms) !== JSON.stringify(formattedRooms);

            if (hasRoomListChanged) {
                dispatch(setChatRooms(formattedRooms));
                setPreviousRooms(formattedRooms);
            } else {
                handleTabChange(activeTab);
                const updatedRooms = formattedRooms.map(room => {
                    const updatedRoom = formattedRooms.find(r => r.id === room.id);
                    if (updatedRoom) {
                        return {
                            ...room,
                            unread: updatedRoom.unread,
                            lastMessage: updatedRoom.lastMessage,
                            lst_dt: updatedRoom.lst_dt
                        };
                    }
                    return room;
                });
                dispatch(setChatRooms(updatedRooms));
            }
        } catch (error) {
            message.error('채팅방을 불러오는데 실패했습니다.');
        } finally {
            setRoomListLoading(false);
        }
    }, [activeTab, dispatch, previousRooms]);

    // 채팅방 메세지 조회
    const fetchMessages = useCallback(async () => {
        if (!currentRoom) return;
        setLoading(true);
        try {
            const params = {
                chat_id: currentRoom.id,
                mem_key: currentUserKey
            };
            const response = await api.messages({ params });
            const formattedMessages = response.data.map(msg => ({
                id: msg.msg_seq,
                sender: msg.sender,
                content: msg.contents,
                lst_dt: msg.lst_dt,
                isMine: msg.fr_mem_key === currentUserKey,
                isAdmin: msg.fr_mem_key === '0',
            }));

            dispatch(setMessages({
                roomId: currentRoom.id,
                messages: formattedMessages
            }));

        } catch (error) {
            message.error('메시지를 불러오는데 실패했습니다.');
        } finally {
            requestAnimationFrame(() => {
                scrollToBottom(false);
            });
            setLoading(false);
        }
    }, [currentRoom, dispatch, scrollToBottom]);

    // 채팅방 프로필 조회
    const getProfile = async (room) => {
        setLoading(true);
        const params = { mem_key: room.mem_key };
        const response = await api.userProfile({ params });
        dispatch(setUserProfile(response.data));
    }

    const handleTabChange = (key) => {
        setActiveTab(key);
        dispatch(setChatRooms(null));
        dispatch(clearMessages());
    };

    const handleRoomSelect = useCallback((room) => {
        const hasRoomChanged = JSON.stringify(previousRoom) !== JSON.stringify(room);
        if (hasRoomChanged) {
            setLoading(true);
            dispatch(setCurrentRoom(room));
            setPreviousRoom(room);
            getProfile(room);
            checkMessage(room.id);
            setLoading(false);
        } else {
            dispatch(setCurrentRoom(room));
        }
    }, [dispatch, previousRoom, activeTab]);

    const handleRoomOptions = (e, room) => {
        e.stopPropagation();
        const targetElement = e.currentTarget;
        const rect = targetElement.getBoundingClientRect();
        const titleMiddleY = rect.top + (rect.height / 2);

        setRoomOptionsPosition({
            top: titleMiddleY - 100,
            left: rect.right - 430
        });
        setSelectedRoomForOptions(prev => prev?.id === room.id ? null : room);
    };

    const handleRoomBlock = (room) => {
        api.blockMember({
            mem_key: parseInt(currentUserKey, 10),
            blk_mem_key: room.mem_key
        })
            .then(response => {
                if(selectedRoomForOptions.block_status !== 'N'){
                    message.success(`해당 사용자 차단을 해제했습니다.`);
                } else {
                    message.success(`해당 사용자를 차단했습니다.`);
                }
                fetchRooms();
                setSelectedRoomForOptions(null);
            })
            .catch(error => {
                console.error("Error blocking member:", error);
                message.error('차단에 실패했습니다.');
            });
    };
    const checkMessage = async (roomId) => {
        try {
            if (!roomId || !currentUserKey) {
                message.error('필수 정보가 누락되었습니다.');
                return;
            }
            const memKey = parseInt(currentUserKey, 10);
            const data = {
                chat_id: roomId,
                mem_key: memKey
            };
            await api.checkMessage(data);
        } catch (error) {
            console.error('Error details:', error.response?.data || error);
            message.error('메세지 확인을 실패했습니다.');
        }
    };

    const handleSendMessage = async (options = {}) => {
        const { file } = options;

        if (!currentRoom) {
            message.error('채팅방을 선택해주세요.');
            return;
        }

        try {
            const formData = new FormData();
            if (file) {
                if (file.size / 1024 / 1024 >= 10) {
                    message.error('파일은 10MB보다 작아야 합니다!');
                    return;
                }
                formData.append('files', file);
            }
            const params = {
                chat_id: currentRoom.id,
                sender: `${currentUserKey}|${currentRoom.mem_key}`,
                msg_div: 'user',
                mem_key: currentUserKey
            }
            formData.append('chatVO', new Blob([JSON.stringify(params)], { type: 'application/json' }));

            const response = await api.uploadMessage(formData);
            console.log(response);
            if (file) console.log(`${file.name} 파일이 업로드되었습니다.`);

            if (newMessage?.trim()) {
                const response = await api.sendMessage({
                    chat_id: currentRoom.id,
                    sender: `${currentUserKey}|${currentRoom.mem_key}`,
                    msg_div: 'user',
                    contents: newMessage,
                    mem_key: currentUserKey
                });

                if (response.data.status === 200) {
                    dispatch(addMessage({
                        roomId: currentRoom.id,
                        message: {
                            id: Date.now(),
                            sender: currentUserKey,
                            content: newMessage,
                            isMine: true,
                            lst_dt: new Date().toISOString()
                        }
                    }));
                    requestAnimationFrame(() => {
                        scrollToBottom(true);
                    });
                }
                setNewMessage('');
            }
            await fetchMessages();
            await fetchRooms();
        } catch (error) {
            console.error('Error sending message:', error);
            message.error('메시지 전송에 실패했습니다.');
        }
    };
    const handleLeaveRoom = useCallback(async () => {
        const msgDiv = activeTab === 'normal' ? 'user' : 'system';
        if(selectedRooms.length === 0){
            setSelectedRooms(chatRooms.map((room) => currentRoom.id));
        }

        try{
            const deletionPromises = selectedRooms.map(async (roomId) => {
                const data = {
                    chat_id: roomId,
                    msg_div: msgDiv,
                    mem_key: parseInt(currentUserKey, 10)
                }
                return await api.closeRoom(data);
            });

            await Promise.all(deletionPromises);

            setSelectedRooms([]);
            setSelectAll(false);

            if (currentRoom) {
                fetchMessages();
            }
            fetchRooms();
            dispatch(setCurrentRoom(null));
            setSelectedRoomForOptions(null);
            if(msgDiv === "user"){
                message.success('채팅방을 나갔습니다.');
            } else {
                message.success('시스템 알림을 삭제했습니다.');
            }
        } catch (error) {
            console.error(error)
            if(msgDiv === "user"){
                message.error('채팅방 나가기에 실패했습니다.');
            } else {
                message.error('시스템 알림 삭제를 실패했습니다.');
            }
        }
    }, [selectedRooms, currentRoom, activeTab, dispatch]);

    const groupMessagesByDate = (messages) => {
        const groups = [];
        let currentDate = null;

        messages.forEach(message => {
            const messageDate = new Date(message.lst_dt);
            const dateString = messageDate.toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });

            if (dateString !== currentDate) {
                currentDate = dateString;
                groups.push({ type: 'date', date: dateString });
            }
            groups.push({ type: 'message', data: message });
        });
        return groups;
    };

    const handleSelectAll = () => {
        if (chatRooms.length === 0) {
            setSelectAll(false);
            setSelectedRooms([]);
            return;
        }

        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        if (newSelectAll) {
            setSelectedRooms(chatRooms.map((room) => room.id));
        } else {
            setSelectedRooms([]);
        }
    };

    const handleCheckboxChange = (roomId) => {
        setSelectedRooms((prevSelected) =>
            prevSelected.includes(roomId)
                ? prevSelected.filter((id) => id !== roomId) // 선택 해제
                : [...prevSelected, roomId] // 선택 추가
        );
    };
    const handleLinkClick = (link, platform) => {
        if (link) {
            window.open(link, '_blank', 'noopener noreferrer');
        } else {
            message.warning(`${platform} 링크가 없습니다`);
        }
    };

    const formatBadgeInfo = (bdg_nm) => {
        return bdg_nm ? bdg_nm.split('|').map(badge => ` ${badge} `).join('/ ') : "뱃지 정보가 없습니다.";
    };

    const formatRankingInfo = (categories, ranks) => {
        const categoryArray = categories ? categories.split(',') : [];
        const rankArray = ranks ? ranks.split(',') : [];
        const columnWidth = `${100 / categoryArray.length}%`;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '100%' }}>
                <div style={{ display: 'flex', width: '120%' }}>
                    {categoryArray.map((category, index) => (
                        <span key={`category-${index}`} style={{ width: columnWidth, textAlign: 'center' }}>
                        {category}
                    </span>
                    ))}
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                    {rankArray.map((rank, index) => (
                        <span key={`rank-${index}`} style={{ width: columnWidth, textAlign: 'center', fontSize: '100%' }}>
                        {rank}
                    </span>
                    ))}
                </div>
            </div>
        );
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}.${month}.${day}`;
    };

    useEffect(() => {
        dispatch(setCurrentRoom(null));
        scrollToBottom();
    }, []);

    useEffect(() => {
        fetchRooms();
    }, [activeTab]);

    useEffect(() => {
        if (messages.length > prevMessagesLengthRef.current) {
            requestAnimationFrame(() => {
                scrollToBottom();  // smooth 스크롤 사용
            });
        }
        prevMessagesLengthRef.current = messages.length;
    }, [messages.length, scrollToBottom]);

    useEffect(() => {
        if (currentRoom) {
            const initialize = async () => {
                if (messageListRef.current) {
                    messageListRef.current.style.visibility = 'hidden';
                }
                await fetchMessages();
                scrollToBottom();
                if (messageListRef.current) {
                    messageListRef.current.style.visibility = 'visible';
                }
            };
            initialize();
        } else {
            dispatch(clearMessages());
        }
    }, [currentRoom]);

    useEffect(() => {
        if (messages.length > prevMessagesLengthRef.current) {
            requestAnimationFrame(() => {
                scrollToBottom();
            });
        }
        prevMessagesLengthRef.current = messages.length;
    }, [messages.length]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectedRoomForOptions && !event.target.closest('.room-options')) {
                setSelectedRoomForOptions(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [selectedRoomForOptions]);

    const renderMessage = (msg) => {
        if (msg.content.includes('/file/message')) {
            const imgSrc = msg.content;
            return (
                <img src={imgSrc} alt="message" style={{ height: '100%', maxWidth: '100%' }}
                     onLoad={() => {
                         scrollToBottom(false);
                     }}
                />
            );
        }
        return msg.content;
    };

    return (
        <ChatContainer>
            <ChatRoomList
                title={
                    <div style={{position: 'absolute', width:'70%', marginLeft: '50px'}}>
                        {chatRooms.length !== 0 ? (
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                                style={{
                                    position: 'absolute',
                                    left: '-35px',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}>전체</Checkbox>
                        ) : (
                            null
                        )}
                        {selectedRooms.length > 0 && (
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={handleLeaveRoom}
                                style={{
                                    position: 'absolute',
                                    width: '28%',
                                    right: '-45px',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                            >
                                삭제
                            </Button>
                        )}
                    </div>
                }
            >
                {roomListLoading ? (
                    <LoadingContainer>
                        <Spin />
                    </LoadingContainer>
                ) : (
                    <RoomListScroll>
                        {chatRooms.length === 0 ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '90%',
                                color: '#999'
                            }}>
                                채팅방 목록이 없습니다.
                            </div>
                        ) : (
                            chatRooms.map((room, index) => (
                                <ChatRoom
                                    key={index}
                                    onClick={() => handleRoomSelect(room)}
                                    $active={currentRoom && currentRoom.id === room.id}
                                >
                                    <div style={{ display: 'flex', gap: '12px', width: '100%' }}>
                                        <Checkbox
                                            checked={selectedRooms.includes(room.id)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={() => handleCheckboxChange(room.id)}
                                            style={{ flexShrink: 0 }}
                                        />
                                        <Avatar src={room.mem_img_path || 'default-avatar.png'} size={45} style={{marginLeft:'-5px'}}/>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 0 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <span style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {room.name}
                                        </span>
                                                <Badge count={room.unread} style={{ marginLeft: '4px' }}/>
                                                {activeTab === 'normal' && (
                                                    <div className="room-options">
                                                        <MoreOutlined
                                                            onClick={(e) => handleRoomOptions(e, room)}
                                                            style={{ marginLeft: '4px', cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: '4px'
                                            }}>
                                <span style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    flex: 1
                                }}>
                                    {room.lastMessage.includes('/file/message') ? '사진' : room.lastMessage}
                                </span>
                                                <span style={{
                                                    fontSize: '12px',
                                                    color: '#999',
                                                    marginLeft: '8px',
                                                    flexShrink: 0
                                                }}>
                                    {formatMessageDate(room.lst_dt)}
                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </ChatRoom>
                            ))
                        )}
                    </RoomListScroll>
                )}
            </ChatRoomList>
            <ChatBox title={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',     position: 'relative' }}>
                    {currentRoom ? '> ' + currentRoom.name : '채팅방 선택'}
                </div>
            }
            >
                <MessageList
                    $showProfile={activeTab === 'normal'}
                    ref={messageListRef}
                    style={{
                        visibility: loading && messages.length === 0 ? 'hidden' : 'visible',
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto'
                    }}
                >
                    {loading && messages.length === 0 ? (
                        <LoadingContainer>
                            <Spin/>
                        </LoadingContainer>
                    ) : messages.length > 0 ? (
                        groupMessagesByDate(messages).map((item, index) => {
                            if (item.type === 'date') {
                                return (
                                    <DateDivider key={`date-${index}`}>
                                        <DateLabel>{item.date}</DateLabel>
                                    </DateDivider>
                                );
                            }
                            const msg = item.data;
                            return (
                                <MessageGroup key={`msg-${msg.id}`} $isMine={msg.isMine}>
                                    <MessageContent $isMine={msg.isMine}>
                                        <div>
                                            <SenderName $isAdmin={msg.isAdmin}>
                                                {!msg.isMine ? (msg.isAdmin ? '관리자' : msg.sender) : null}
                                            </SenderName>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <MessageBubble $isMine={msg.isMine} $isAdmin={msg.isAdmin}>
                                                    {renderMessage(msg)}
                                                </MessageBubble>
                                            </div>
                                            <MessageTime $isMine={msg.isMine}>
                                                {new Date(msg.lst_dt).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })}
                                            </MessageTime>
                                        </div>
                                    </MessageContent>
                                </MessageGroup>
                            );
                        })
                    ) : (
                        <EmptyMessageContainer>
                            메시지가 없습니다.
                        </EmptyMessageContainer>
                    )}
                    <div ref={messageEndRef}/>
                </MessageList>
                {selectedRoomForOptions && (
                    <Balloon
                        style={{
                            top: `${roomOptionsPosition.top}px`,
                            left: `${roomOptionsPosition.left}px`
                        }}
                        className="room-options"
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleRoomBlock(selectedRoomForOptions)}
                            >
                                {selectedRoomForOptions.block_status === 'N' ? '차단' : '차단해제'}
                            </Button>
                            <Button
                                type="primary"
                                danger
                                size="small"
                                onClick={() => handleLeaveRoom(selectedRoomForOptions)}
                            >
                                나가기
                            </Button>
                        </div>
                    </Balloon>
                )}
                {activeTab === 'normal' && (
                    <>
                        <InputContainer>
                            {/*<Upload
                        name="file"
                        customRequest={handleFileUpload}
                        showUploadList={false}
                    >
                        <Button
                            icon={<FileOutlined/>}
                            type="text"
                        />
                    </Upload>*/}
                            <Upload
                                name="image"
                                customRequest={handleSendMessage}
                                showUploadList={false}
                                accept="image/*"
                            >
                                <Button
                                    icon={<PictureOutlined/>}
                                    type="text"
                                />
                            </Upload>
                            <Input
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="메시지를 입력하세요"
                                onPressEnter={handleSendMessage}
                            />
                            <Button
                                type="primary"
                                icon={<SendOutlined/>}
                                onClick={handleSendMessage}
                            />
                        </InputContainer>
                    </>)}
            </ChatBox>
            {activeTab === 'normal' && messages.length > 0 && (
                <ProfileContainer>
                    <ProfileImage src={userProfile.mem_img_path} alt="Profile" />
                    <InfoSection>
                        <div style={{marginBottom:'5%'}}>{userProfile.mem_email} / {userProfile.mem_nick}</div>
                        <div>
                            <strong>유저 소개글</strong><br/>
                            {userProfile.about_me || "소개글이 없습니다."}
                        </div>
                    </InfoSection>

                    <BadgeInfo>
                        <strong>뱃지 정보</strong><br/>
                        {formatBadgeInfo(userProfile.bdg_nm)}
                    </BadgeInfo>

                    <RankingInfo>
                        <strong>분야별 랭킹
                            <TrophyOutlined style={{ fontSize: '18px', color: '#FFD700', marginLeft: '5px' }} />
                        </strong>
                        {formatRankingInfo(userProfile.agg_cate_nm, userProfile.agg_rank)}
                    </RankingInfo>

                    <StatSection>
                        <div><strong>팔로잉 수 : {userProfile.following_cnt} | 팔로워 수 : {userProfile.follwer_cnt}</strong></div>
                        <div><strong>유저 총별점 (리뷰)</strong> : {userProfile.score_avg || "N/A"}</div>
                        <div><strong>가입일</strong> : {userProfile.fir_dt ? formatDate(userProfile.fir_dt) : "N/A"}</div>
                    </StatSection>

                    <SocialMediaIcons>
                <span onClick={() => handleLinkClick(userProfile.youtube_link, 'YouTube')}>
                    <YoutubeOutlined style={{ fontSize: '32px', color: '#FF0000', cursor: 'pointer' }} />
                </span>
                        <span onClick={() => handleLinkClick(userProfile.insta_link, 'Instagram')}>
                    <InstagramOutlined style={{ fontSize: '32px', color: '#C13584', cursor: 'pointer' }} />
                </span>
                        <span onClick={() => handleLinkClick(userProfile.x_link, 'X')}>
                    <TwitterOutlined style={{ fontSize: '32px', color: '#1DA1F2', cursor: 'pointer' }} />
                </span>
                    </SocialMediaIcons>

                    <Button type="primary" size="large" style={{ marginTop: '35px' }} onClick={() => alert('방문하기 기능 준비중입니다.')}>
                        방문하기
                    </Button>
                </ProfileContainer>
            )}
        </ChatContainer>
    );
};

export default ChatBase;